import { Circle, usePerformanceMonitor } from '@react-three/drei'
import { Bloom, EffectComposer, GodRays, HueSaturation, Noise, Vignette } from '@react-three/postprocessing'
import { BlendFunction, KernelSize } from 'postprocessing'
import { Suspense, forwardRef, useRef, useState } from 'react'
import Sizes from './Utils/Sizes'
import { useControls } from 'leva'
/* 
const Sun = forwardRef(function Sun(props, forwardRef) {
    const sunColor = '#ffffff'
  
    return (
      <Circle args={[10, 10]} ref={forwardRef} position={[30, 10, 32]} rotation={ [ 0, Math.PI * -.4, 0 ] } {...props}>
        <meshBasicMaterial color={sunColor} />
      </Circle>
    )
  }) */ 

export default function Effects(props) {
    const [enableEffects, setEnableEffects] = useState(false)
    //const material = useRef()

   /*  const { hue, saturation } = useControls('Postprocessing - HueSaturation', {
        hue: {
          value: 3.11,
          min: 0,
          max: Math.PI * 2,
        },
        saturation: {
          value: 2.05,
          min: 0,
          max: Math.PI * 2,
        },
      }) */
      //console.log(material);

    usePerformanceMonitor({ onIncline: () => setEnableEffects(true), onDecline: () => setEnableEffects(false) })
    return <>
        <EffectComposer stencilBuffer enabled={ enableEffects }>
            <Bloom luminanceThreshold={1} intensity={.4} levels={3}  />
        </EffectComposer> 
    </>
}


{/* <Suspense fallback={null}>
        <Sun ref={material} />

        {material.current && ( 
        <EffectComposer stencilBuffer enabled={ enableEffects }>
            <Bloom luminanceThreshold={1} intensity={.4} levels={3}  />
            <GodRays
                sun={material}
                exposure={.34} decay={.9} blur={true}
            />

            <HueSaturation hue={hue} saturation={saturation} />

            <Vignette /> 
        </EffectComposer> )} 
    </Suspense>  */}
