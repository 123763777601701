import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function ModelSmartphone(props) {
  const { nodes } = useGLTF("/models/UV/Smartphone.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_MVL_BTN1.geometry}
        material={props.materials.baked}
        position={[-3.701, 3.061, -0.134]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={[0.088, 0.088, 0.054]}
      />
    </group>
  );
}