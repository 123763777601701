import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { folder, useControls } from "leva";
import React, { useEffect, useRef } from "react";
import { ModelBase } from "./ModelBase";

export function ModelMinecart(props) {
  const { nodes } = useGLTF("/models/UV/Minecart.glb");
  
  const bucket = useRef()
  
  const config = useControls('Modelos', {
    'Vagoneta': folder({
      cartSpeed: { value: 2.62, min: 0, max: 10, step: .001 },
      cartRange: { value:-0.17, min: -1, max: 1, step: .001 },
    }),
  })

  useFrame((state, delta) => {
    if (props.expStep === 5) {
      bucket.current.rotation.x = Math.cos(state.clock.elapsedTime * config.cartSpeed) * config.cartRange - Math.PI * .5
    }
  })

  /* useEffect(() => {
    if (props.expStep === 6) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
      <group>
        <mesh
          ref={ bucket }
          geometry={nodes.DIA_VAGONETA_Caja.geometry}
          material={ props.materials.baked }
          position={[0.08, 9.71, 0.01]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={-3.5}
        />
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_VAGONETA_Chasis.geometry}
          material={ props.materials.baked }
          position={[-0.06, 2.79, -0.04]}
          rotation={[-Math.PI, 0, 0]}
          scale={[0.25, 0.25, 4.85]}
        />

        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
      </group>

    </group>
  );
}