import { useEffect, useRef, useState } from 'react';
import { Footer } from './Front';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { gsap } from 'gsap';
import { NavLink } from "react-router-dom";

export function Faqs() {
	const contenedor = useRef();

	const configFront = {
		pages: 6
	};

	const [loaded, setLoaded] = useState(false);
	const sliderdia = useRef();
	const [openAccordion, setOpenAccordion] = useState(null);
	const accordionRefs = useRef([]);

	useEffect(() => {
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

		iniScrollSmoother();

		setTimeout(function () {
			animaciones('faq');
		}, 2000);
	}, [loaded]);

	useEffect(() => {
		setLoaded(true);
	}, []);

	const faqs = [
		{
			id: 1,
			title: '¿Qué es Dianelum?',
			content:
				'Dianelum es una distribuidora mundial de diamantes creados en laboratorio a partir de un carbono exclusivo y utilizando energías renovables.'
		},
		{
			id: 2,
			title: '¿Cuál es la misión de Dianelum?',
			content:
				'La misión de Dianelum es liderar el sector del lujo ético y la revolución energética a través del uso de diamantes creados en laboratorio con cero emisiones de carbono.'
		},
		{
			id: 3,
			title: '¿Cómo contribuye Dianelum a la sostenibilidad?',
			content:
				'Dianelum prioriza la sostenibilidad y el desarrollo de energías verdes como alternativa a las fuentes no renovables. Además, busca impulsar una fuente de energía sostenible a través de las baterías verdes de nanodiamantes.'
		},
		{
			id: 4,
			title: '¿Cuál es la visión de Dianelum?',
			content:
				'La visión de Dianelum es liderar hacia un futuro sostenible y próspero, siendo pioneros en la lucha contra el cambio climático y siendo una fuente de inspiración para la adopción de prácticas sostenibles.'
		},
		{
			id: 5,
			title: '¿Qué valores promueve Dianelum?',
			content:
				'Dianelum se basa en valores como la sostenibilidad, la innovación, la revolución, el equilibrio y la dignidad, buscando crear un cambio positivo en la industria.'
		},
		{
			id: 6,
			title:
				'¿Cómo contribuye Dianelum a evitar la minería y sus consecuencias?',
			content:
				'Dianelum ofrece una alternativa hermosa y sostenible a la extracción de diamantes de la Tierra mediante la creación de diamantes en laboratorio, evitando así la explotación laboral y el daño al medio ambiente.'
		},
		{
			id: 7,
			title: '¿Cómo contribuye Dianelum a un futuro más limpio y consciente?',
			content:
				'Dianelum se compromete a ofrecer diamantes sostenibles que preservan el medio ambiente y promueven un futuro más limpio y consciente al evitar la actividad minera y utilizar energías renovables en su proceso de creación.'
		},
		{
			id: 8,
			title: '¿Dianelum produce diamantes de mina?',
			content:
				'No, Dianelum produce diamantes creados en laboratorio. Estos diamantes son fabricados utilizando tecnología avanzada y no involucran la extracción de diamantes de la Tierra.'
		},
		{
			id: 9,
			title: '¿Los diamantes de Dianelum tienen certificación?',
			content:
				'Sí, los diamantes de Dianelum cuentan con certificación de institutos independientes reconocidos, como el Instituto Gemológico Internacional. Esto garantiza su autenticidad y calidad.'
		},
		{
			id: 10,
			title:
				'¿Cuáles son las ventajas de elegir diamantes de laboratorio en lugar de diamantes de mina?',
			content:
				'Al elegir diamantes de laboratorio, se evita la actividad minera y sus consecuencias devastadoras, como la explotación laboral y el daño al medio ambiente. Además, los diamantes de laboratorio ofrecen trazabilidad y se producen de manera más ética y sostenible.'
		},
		{
			id: 11,
			title:
				'¿Dianelum utiliza energías renovables en su proceso de creación de diamantes?',
			content:
				'Sí, Dianelum utiliza energías renovables, como reactores de plasma impulsados por energías verdes, en su proceso de creación de diamantes. Esto contribuye a reducir las emisiones de carbono y promover un enfoque más sostenible.'
		},
		{
			id: 12,
			title:
				'¿Dianelum participa en proyectos relacionados con energía sostenible?',
			content:
				'Sí, Dianelum busca contribuir eficientemente al desarrollo de las baterías verdes de nanodiamantes, impulsando así una fuente de energía sostenible prácticamente ilimitada. Están comprometidos con la investigación y el desarrollo de soluciones innovadoras.'
		},
		{
			id: 13,
			title:
				'¿Son los diamantes de laboratorio tan valiosos como los diamantes de mina?',
			content:
				'Sí, los diamantes de laboratorio tienen el mismo valor que los diamantes de mina en términos de sus características físicas y propiedades. Ambos tipos de diamantes se evalúan según los mismos estándares de calidad conocidos como las 4C (corte, color, claridad y quilate).'
		},
		{
			id: 14,
			title: '¿Son auténticos los diamantes de laboratorio?',
			content:
				'Sí, los diamantes de laboratorio son auténticos diamantes. Comparten las mismas características y propiedades físicas que los diamantes de mina. Para ser considerados auténticos, tanto los diamantes de laboratorio como los diamantes de mina deben ser certificados por institutos gemológicos reconocidos.'
		},
		{
			id: 15,
			title:
				'¿Los diamantes de laboratorio tienen algún tipo de certificación?',
			content:
				'Sí, los diamantes de laboratorio pueden ser certificados por institutos gemológicos reconocidos, al igual que los diamantes de mina. Los certificados suelen incluir información detallada sobre las características del diamante, como el corte, el color, la claridad, el quilate y otros datos relevantes.'
		},
		{
			id: 16,
			title: '¿Cómo puedo obtener rentabilidad al invertir en Dianelum?',
			content:
				'Al invertir en Dianelum, puedes esperar obtener rentabilidades anuales que oscilan entre el 12,5% y el 24% gracias a la demanda creciente de diamantes creados y su exclusivo modelo de negocio.'
		},
		{
			id: 17,
			title: '¿Qué respaldo tienen mis inversiones en Dianelum?',
			content:
				'Tus inversiones en Dianelum están respaldadas por bancos de diamantes exclusivos de alta calidad, compuestos por diamantes cuidadosamente seleccionados y certificados.'
		},
		{
			id: 18,
			title:
				'¿Cómo garantiza Dianelum la autenticidad y valor de sus diamantes?',
			content:
				'Dianelum realiza auditorías externas realizadas por expertos en certificación y trazabilidad de diamantes para verificar la autenticidad y calidad de sus diamantes. Además, cada diamante proviene de fuentes éticas y sostenibles.'
		},
		{
			id: 19,
			title:
				'¿Qué medidas de seguridad implementa Dianelum en su cadena de suministro?',
			content:
				'Dianelum realiza auditorías externas para garantizar la trazabilidad de sus diamantes. Además, implementa un sistema de trazabilidad basado en blockchain para registrar cada etapa del ciclo de vida de los diamantes.'
		},
		{
			id: 20,
			title:
				'¿Qué hace Dianelum para ofrecer desarrollos exclusivos a sus inversores?',
			content:
				'Dianelum se esfuerza por ofrecer experiencias únicas y productos de calidad excepcional en cada proyecto. Su compromiso con la excelencia impulsa la creación de propuestas exclusivas y atractivas.'
		},
		{
			id: 21,
			title: '¿Cuál es la propuesta de valor de invertir en Dianelum?',
			content:
				'Dianelum ofrece a los inversores acceso al banco de diamantes a través de un formato de préstamo participativo. Esto les brinda rendimientos estimados a lo largo de 5 años y opciones flexibles para recuperar su inversión al finalizar.'
		},
		{
			id: 22,
			title: '¿Cómo se genera el retorno de inversión en Dianelum?',
			content:
				'Dianelum genera ingresos a través de la rotación de activos, aprovechando el valor y la demanda de los diamantes en diferentes mercados. También establece contratos estratégicos con clientes y socios comerciales para garantizar una alta rotación de sus diamantes.'
		},
		{
			id: 23,
			title:
				'¿Cómo se garantiza la seguridad y mitigación de riesgos en las inversiones en Dianelum?',
			content:
				'Dianelum implementa un enfoque sólido de seguridad al requerir un colateral en diamantes equivalente al valor de la inversión. También Implementa un sistema de trazabilidad basado en blockchain para fortalecer la transparencia y la autenticidad.'
		},
		{
			id: 24,
			title:
				'¿Qué ventajas ofrece una Oferta de Tokens de Seguridad (STO) en la inversión en Dianelum?',
			content:
				'La STO permite una mayor transparencia, cumplimiento normativo, mayor liquidez, acceso global y fraccionamiento de activos.'
		},
		{
			id: 25,
			title:
				'¿Qué opciones tienen los inversores al finalizar los 5 años de inversión?',
			content:
				'Los inversores tienen la opción de recuperar el principal invertido o capitalizar su inversión a través de acciones de la compañía mediante warrants.'
		},
		{
			id: 26,
			title:
				'¿Puedo vender mis inversiones en Dianelum antes de que expire el plazo acordado?',
			content:
				'La posibilidad de vender tus inversiones antes del plazo acordado puede depender de las condiciones y términos específicos de tu inversión. En algunos casos, es posible que Dianelum ofrezca opciones de salida anticipada, pero también pueden aplicarse restricciones o penalizaciones. Es importante revisar los detalles de tu contrato de inversión para conocer las opciones disponibles.'
		},
		// {
		// 	id: 27,
		// 	title:
		// 		'¿Cuál es el proceso para vender mis inversiones en el mercado secundario de Dianelum?',
		// 	content:
		// 		'Dianelum generalmente proporciona un proceso y una plataforma específica para que los inversores puedan listar y vender sus inversiones en el mercado secundario. Debes consultar con Dianelum para obtener información detallada sobre cómo funciona este proceso y los pasos que debes seguir.'
		// }
	];

	const listadoFaqs = faqs.map((f,k) => (
		<li key={k}>
			<div
				className={`faqs  ${openAccordion === f.id ? 'open' : ''}`}
				ref={(el) => (accordionRefs.current[f.id] = el)}>
				<div
					className="faqs__header"
					onClick={() => handleAccordionClick(f.id)}>
					<div className="faqs__header_rombo ani_scroll_fade">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 26.983 26.983">
							<path
								fill="#9bcaa6"
								stroke="#141515"
								d="M.707 13.491 13.491.707 26.276 13.49 13.491 26.275z"
								data-name="Rectángulo 2450"
							/>
						</svg>
					</div>
					<div className="faqs__header_number ani_scroll_fade">
						{f.id > 9 ? f.id : '0' + f.id}
					</div>
					<div className="faqs__header_title ani-scroll-enter">{f.title}</div>
				</div>
				<div className="faqs__data">
					<div className="faqs__data_cont">{f.content}</div>
				</div>
				<div className="faqs__line ani_scroll_lineW100"></div>
			</div>
		</li>
	));
	const handleAccordionClick = (index) => {
		if (index === openAccordion) {
			gsap.to(accordionRefs.current[index].querySelector('.faqs__data'), {
				height: 0,
				duration: 0.4,
				ease: 'power2.out',
				onComplete: () => setOpenAccordion(null)
			});
		} else {
			if (openAccordion !== null) {
				gsap.to(
					accordionRefs.current[openAccordion].querySelector('.faqs__data'),
					{
						height: 0,
						duration: 0.4,
						ease: 'power2.out'
					}
				);
			}
			setOpenAccordion(index);
			gsap.fromTo(
				accordionRefs.current[index].querySelector('.faqs__data'),
				{ height: 0 },
				{
					height: 'auto',
					duration: 0.4,
					ease: 'power2.out'
				}
			);
		}
	};

	return (
		<>
<div id="smooth-wrapper">
  <div id="smooth-content">

  <div className="header-pin">
      <div className="header header2 header-ani header__faqs">
          <div className="header__data">
            
            <div className="pieza-header-container">
				<div className="pieza pieza-header">
					<span className="pieza__line pieza__1"></span>
					<span className="pieza__line pieza__2"></span>
					<span className="pieza__line pieza__3"></span>
					<span className="pieza__line pieza__4"></span>
					<span className="pieza__rombo pieza__5"></span>
				</div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            	<span data-delay="1.2" >PREGUNTAS FRECUENTES</span>
            </div>

	    	<div className='header__titulo_ani'>
				<div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>NO TE QUEDES</span><span className='linet linet2'>CON NINGUNA</span><span className='linet linet3'>DUDA</span></div></div>
				<div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>NO TE QUEDES</span><span className='linet linet2'>CON NINGUNA</span><span className='linet linet3'>DUDA</span></div></div>
				<div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>NO </span>TE QUE<span className='ocu'>DES</span></span><span className='linet linet2'><span className='ocu'>CO</span>N NINGU<span className='ocu'>NA</span></span><span className='linet linet3'><span className='ocu'></span>DUDA<span className='ocu'></span></span></div></div>
          	</div>
            
            <div className="header__ani_center">
				<div className='header__ani_rombo'></div>
				<div className="header__ani --faq">
			<img src="images/animaciones/header_faqs/DIA_FAQS_V2000.png" id='header_img' className='header__ani_img' />
              	</div>
	      
            </div>
          </div>

          <div className="cita cita-header">
        <div className="cita__title ani_ini_mask">
          <span data-delay="2">Obten información útil</span>
        </div>
        <div className="cita__cita ani_ini_line" data-delay="2">Explora nuestras FAQs y obtén información sobre Dianelum y nuestras inversiones</div>
      </div>

          <div className="header__arrow">
            <a href="#" onClick={(e)=> ancla('faqs-listado')}
              className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
              <span className="btn__rombo_cont"></span>
              <span className="btn__rombo_txt">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                    <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                      <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                      <path d="M7.543 13.804V0" data-name="Línea 43" />
                    </g>
                  </svg>
                </span>
              </span>
            </a>
          </div>
      </div>
    </div>

    <div className="bloque__faqs " id="faqs-listado">
      <ul>{listadoFaqs}</ul>
      <div className="bloque__hablamos bloque__hablamos-faqs cta-hablamos">
        <div className="bloque_hablamos_txt ">Estamos aquí para resolver tus dudas.</div>
        <div className="bloque__hablamos_cont_btn bloque__hablamos_cont_btn-negro">
	<NavLink to="/contacto" className="btn_hablamos">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1436.96 453.688"><g stroke="#9bcaa6" data-name="Grupo 7722"><g fill="none" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6307"><path d="M1436.21 338.157V115.538L1321.428.75H115.532L.75 115.538v222.619l114.782 114.782h1205.9Z" data-name="Trazado 37422"/><path d="M.752 115.535 718.479.753l717.733 114.782" data-name="Trazado 37423"/><path d="m.752 338.157 717.727 114.782 717.733-114.782" data-name="Trazado 37424"/><path d="M115.53.751V452.94" data-name="Línea 45"/><path d="M1321.431.751V452.94" data-name="Línea 46"/></g><g data-name="Grupo 6309"><path fill="none" strokeMiterlimit="10" d="M744.237 74.461h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.06 74.461h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.681 101.019V47.905" data-name="Línea 65"/><path fill="#141515" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="none" strokeMiterlimit="10" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g><g data-name="Grupo 6310"><path fill="none" strokeMiterlimit="10" d="M744.236 388.808h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.059 388.808h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.68 415.366v-53.114" data-name="Línea 65"/><path fill="#96c495" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="#141515" strokeMiterlimit="10" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g></g></svg>
            <span>HABLAMOS</span>
          </NavLink>
        </div>
      </div>
    </div>
    <Footer  tipo="headerVerde" />
  </div>
</div>
		</>
	);
}
