import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { BaseTube } from "./BaseTube";
import { ModelBase } from "./ModelBase";
import { gsap, Power1, Power2 } from "gsap/all";

export function ModelAirplane(props) {
  const { nodes } = useGLTF("/models/UV/Airplane.glb");

  const plane = useRef()
  const rightWheel = useRef()
  const leftWheel = useRef()
  const centralWheel = useRef()
  const rightDoor_right = useRef()
  const rightDoor_left = useRef()
  const leftDoor_right = useRef()
  const leftDoor_left = useRef()
  const centralDoor_right = useRef()
  const centralDoor_left = useRef()
  const leftPropeller = useRef()
  const rightPropeller = useRef()

  const tweens = useRef([])

  //const windowsInitialColor = props.colors.element
  //const windowsLightColor = props.colors.light

  const reset = () => {
    if (tweens.current.length) {
      for (let i = 0; i < tweens.current.length; i++) {
        if (i === 3 || i === 4) { tweens.current[i].pause() }
        tweens.current[i].kill()
      }
    }
    plane.current.position.z = -2.6
    plane.current.position.y = 0.9
    plane.current.rotation.x = 1.6
    plane.current.rotation.z = 1.24
    leftPropeller.current.rotation.x = 0
    rightPropeller.current.rotation.x = 0
    rightDoor_right.current.rotation.x = 0
    rightDoor_left.current.rotation.x = 0
    leftDoor_right.current.rotation.x = 0
    leftDoor_left.current.rotation.x = 0
    centralDoor_right.current.rotation.x = 0
    centralDoor_left.current.rotation.x = 0
    rightWheel.current.rotation.x = 0
    leftWheel.current.rotation.x = 0
    centralWheel.current.rotation.y = 0
  }

  useEffect(() => {

    if (props.positivePartState) {
      reset()
      const p = { p: 0 }
      gsap.to(p, {
        p: 1,
        duration: 4.5,
        onComplete: () => {
          tweens.current[0] = gsap.fromTo(plane.current.position, {z: -2.6}, {
            z: 100,
            duration: 15,
            delay: 1,
            ease: Power1.easeInOut
          })
          tweens.current[1] = gsap.fromTo(plane.current.position, {y: 0.9}, {
            y: 300,
            duration: 40,
            delay: 1,
            ease: Power2.easeInOut
          })
          tweens.current[2] = gsap.fromTo(plane.current.rotation, {x: 1.6, z: 1.24}, {
            x: Math.PI * .4,
            z: 1.1,
            duration: 15,
            delay: 1
          }) 
          tweens.current[3] = gsap.fromTo(leftPropeller.current.rotation, { x: 0 }, { x: Math.PI * 2, repeat: -1, ease: "none", duration: .1 })
          tweens.current[4] = gsap.fromTo(rightPropeller.current.rotation, { x: 0 }, { x: Math.PI * 2, repeat: -1, ease: "none", duration: .1 })


          tweens.current[5] = gsap.fromTo(rightDoor_right.current.rotation, { x: 0 }, { x: Math.PI * .5, duration: 3, delay: 2.5 })
          tweens.current[6] = gsap.fromTo(rightDoor_left.current.rotation, { x: 0 }, { x: -Math.PI * .5, duration: 3, delay: 2.5 })
          
          tweens.current[7] = gsap.fromTo(leftDoor_right.current.rotation, { x: 0 }, { x: Math.PI * .6, duration: 3, delay: 2.5 })
          tweens.current[8] = gsap.fromTo(leftDoor_left.current.rotation, { x: 0 }, { x: -Math.PI * .6, duration: 3, delay: 2.5 })

          tweens.current[9] = gsap.fromTo(centralDoor_right.current.rotation, { x: 0 }, { x: Math.PI * .5, duration: 3, delay: 2.5 })
          tweens.current[10] = gsap.fromTo(centralDoor_left.current.rotation, { x: 0 }, { x: -Math.PI * .5, duration: 3, delay: 2.5 })
          

          tweens.current[11] = gsap.fromTo(rightWheel.current.rotation, { x: 0 }, { x: Math.PI * .5, duration: 1.5, delay: 2.5 })
          tweens.current[12] = gsap.fromTo(leftWheel.current.rotation, { x: 0 }, { x: -Math.PI * .5, duration: 1.5, delay: 2.5 }) 
          tweens.current[13] = gsap.fromTo(centralWheel.current.rotation, { y: 0 }, { y: -Math.PI * .5, duration: 1.5, delay: 2.5 })

        }
      })
    } else {
      setTimeout(() => {
        reset()
      }, 2000);
    }
  }, [props.positivePartState])

  return (
    <group {...props} dispose={null}>
      <group position-y={ -.04 }>
        <group scale={ 3.47 } position={ [ 0, 2.36, 0 ] }>

          <mesh
            ref={ plane }
            geometry={nodes.DIA_PLANE_FUSELAJE.geometry}
            material={props.materials.baked}
            position={[-0.048, 0.899, -2.598]}
            rotation={[1.598, -0.01, 1.235]}
            scale={0.642}
          >
            <mesh
              ref={ centralWheel }
              geometry={nodes.DIA_PLANE_Amortiguador_Central.geometry}
              material={props.materials.baked}
              position={[9.284, -0.047, 1.246]}
            />
            <mesh
              ref={ leftWheel }
              geometry={nodes.DIA_PLANE_Amortiguador_Dcha.geometry}
              material={props.materials.baked}
              position={[3.783, -3.234, 1.079]}
            />
            <mesh
              ref={ rightWheel }
              geometry={nodes.DIA_PLANE_Amortiguador_Izq.geometry}
              material={props.materials.baked}
              position={[3.783, 3.234, 1.079]}
            />
            <mesh
              ref={ leftPropeller }
              geometry={nodes.DIA_PLANE_Helice_Dcha.geometry}
              material={props.materials.baked}
              position={[-0.43, -2.023, -0.217]}
            />
            <mesh
              ref={ rightPropeller }
              geometry={nodes.DIA_PLANE_Helice_Izq.geometry}
              material={props.materials.baked}
              position={[-0.43, 2.023, -0.217]}
            />
            <mesh
              ref={ rightDoor_right }
              geometry={nodes.DIA_PLANE_Rueda_Tapa1.geometry}
              material={props.materials.baked}
              position={[3.738, 3.533, 1.138]}
            />
            <mesh
              ref={ rightDoor_left }
              geometry={nodes.DIA_PLANE_Rueda_Tapa2.geometry}
              material={props.materials.baked}
              position={[3.949, 2.006, 1.25]}
            />
            <mesh
              ref={ leftDoor_right }
              geometry={nodes.DIA_PLANE_Rueda_Tapa3.geometry}
              material={props.materials.baked}
              position={[3.949, -2.006, 1.25]}
            />
            <mesh
              ref={ leftDoor_left }
              geometry={nodes.DIA_PLANE_Rueda_Tapa4.geometry}
              material={props.materials.baked}
              position={[3.738, -3.533, 1.138]}
            />
            <mesh
              ref={ centralDoor_right }
              geometry={nodes.DIA_PLANE_Rueda_Tapa5.geometry}
              material={props.materials.baked}
              position={[9.064, 0.312, 1.389]}
            />
            <mesh
              ref={ centralDoor_left }
              geometry={nodes.DIA_PLANE_Rueda_Tapa6.geometry}
              material={props.materials.baked}
              position={[9.064, -0.312, 1.389]}
            />
          </mesh>
          <mesh
            geometry={nodes.DIA_PLANE_TORRE.geometry}
            material={props.materials.baked}
            position={[-2.865, 1.122, -1.144]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.567, 0.567, 1.692]}
          />

        </group>

        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } secondMaterial={ props.materials.baked_floor_2 } secondFloorMaterial={ props.materials.baked_floor_3 } transition={ { duration: 2, delay: 6.3 } } trigger={ props.positivePartState } color={ props.elementColor } />
        <BaseTube matrixAutoUpdate={ props.matrixAutoUpdate } position={ [ 15.65, .002, 7 ] } rotation={ [ 0, -Math.PI * .65, 0 ] } color={ props.elementColor } positivePart={ props.positivePart } expStep={ props.expStep } positivePartState={ props.positivePartState } />
      </group>
    </group>
  );
}