export function Arrow({ onClick, disabled, orientation }) {

    return <div className={'hud-arrow ' + orientation + ' ' + ((disabled) ? 'is-disabled' : '') } onClick={ onClick }>
        <svg
            className="hud-arrow__outline"
            xmlns="http://www.w3.org/2000/svg"
            width="135.492"
            height="62.076"
            viewBox="0 0 135.492 62.076"
        >
            <g fill="none">
                <path
                    d="M 67.74667358398438 62.07509231567383 L 67.53816223144531 61.97956085205078 L 0.9935929179191589 31.4925365447998 L 0.0001693345402600244 31.03740882873535 L 0.9935929179191589 30.5822811126709 L 67.53816223144531 0.09527289867401123 L 67.74667358398438 -0.0002597240672912449 L 67.95518493652344 0.09527289867401123 L 134.4997406005859 30.5822811126709 L 135.4931793212891 31.03740882873535 L 134.4997406005859 31.4925365447998 L 67.95518493652344 61.97956085205078 L 67.74667358398438 62.07509231567383 Z M 2.404049634933472 31.03740882873535 L 67.74667358398438 60.9737548828125 L 133.0892944335938 31.03740882873535 L 67.74667358398438 1.101062059402466 L 2.404049634933472 31.03740882873535 Z"
                    stroke="none"
                    fill="#9bcaa6"
                />
            </g>
        </svg>

        <svg
            className="hud-arrow__icon"
            xmlns="http://www.w3.org/2000/svg"
            width="135.492"
            height="62.076"
            viewBox="0 0 135.492 62.076"
        >
            <g transform="translate(50.078 22.813)">
                <g fill="none">
                <path
                    d="M 17.9520435333252 16.45061683654785 L 17.24287414550781 16.12567138671875 L 0.9934407472610474 8.680115699768066 L 9.930626401910558e-05 8.224972724914551 L 0.9934636950492859 7.769874572753906 L 17.24289703369141 0.3252509236335754 L 17.9520435333252 0.000351391383446753 L 17.9520435333252 0.7803791761398315 L 17.9520435333252 15.6705436706543 L 17.9520435333252 16.45061683654785 Z M 2.403853178024292 8.225033760070801 L 16.95079231262207 14.89048480987549 L 16.95079231262207 1.560391664505005 L 2.403853178024292 8.225033760070801 Z"
                    stroke="none"
                    fill="#9bcaa6"
                />
                </g>
                <g transform="translate(17.451 7.723)" fill="none">
                <path
                    d="M 15.19501495361328 1.001251578330994 L 3.115161177902337e-07 1.001251578330994 L 3.115161177902337e-07 0 L 15.19501495361328 0 L 15.19501495361328 1.001251578330994 Z"
                    stroke="none"
                    fill="#9bcaa6"
                />
                </g>
            </g>
        </svg>

        <div className="hud-arrow__bg"></div>
        <div className="hud-arrow__bg-first"></div>
        <div className="hud-arrow__bg-warning"></div>
    </div>
}