import { AdaptiveDpr, AdaptiveEvents, Loader, PerformanceMonitor, useProgress } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  Link,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Experience from './Experience';
import { Front, Header } from './Front/Front';
import Test from './Test';
import './index.css';
import './Assets/main.css';

import { Marca } from './Front/Marca';
import { Producto } from './Front/Producto';
import { Inversion } from './Front/Inversion';

import { LoadingScreen } from './LoadingScreen';
import { Leva } from 'leva';
import TransitionComponent from './Transitions';

import { Faqs } from './Front/Faqs';
import { Aviso } from './Front/Aviso';
import { Contacto } from './Front/Contacto';
import { HorizontalWarning } from './HorizontalWarning';
import { Page404 } from './Front/Page404';
import { CookieConsent } from './CookieConsent';
import WindowHandler from './Utils/WindowHandler';
import Resizer from './Resizer';
import FrontCanvas from './Front/FrontCanvas';
import { Cursor } from './Front/Components/Cursor';
import { Helmet } from 'react-helmet'


function Controller() {

  const [dpr, setDpr] = useState(1.5)
  const [startClicked, setStartClicked] = useState(false)
  const [startTouchDeviceClicked, setStartTouchDeviceClicked] = useState(false)
  const [canStart, setCanStart] = useState(false)

  const isTouch = WindowHandler().isTouch
  

  const clickStart = () => {
    if (isTouch) {
      setStartTouchDeviceClicked(true)
    } else {
      setStartClicked(true)
    }
  }

  const { progress } = useProgress()
  return <>

    <LoadingScreen onStartClick={ () => clickStart() } canStart={ canStart } />
    <HorizontalWarning showWarning={ startTouchDeviceClicked } onStartClick={ () => setStartClicked(true) } />
    <Header classes='experience' />
    <Suspense>
      <Canvas dpr={dpr} flat gl={ { stencil: true } }>
        <PerformanceMonitor flipflops={3} onFallback={() => setDpr(1)} >
          <Experience start={ startClicked } canStart={ () => setCanStart(true) } showWarning={ () => document.querySelector('.horizontal-warning').classList.remove('loaded') } progress={ progress } />
          <Leva hidden />
        </PerformanceMonitor>

        <AdaptiveDpr pixelated />
        <AdaptiveEvents />
      </Canvas>
    </Suspense>
    <CookieConsent />
  </>
}

const router = createBrowserRouter([
  {
    path: "",
    element: <Suspense>
      <Helmet>
        <title>Bienvenido a Dianelum</title>
        <meta name="description" content="Tu inversión segura, ética y garantizada en diamantes 100% sostenibles, 100% auténticos." />
        <link rel="canonical" href="/" />
      </Helmet>
      <TransitionComponent>
        <Controller/>
      </TransitionComponent>
    </Suspense>,
    errorElement: <Page404 />
  },
  {
    path: "/marca",
    element: 
    <Suspense>
      <Helmet>
        <title>Diamantes Sostenibles y Éticos - Nuestra Historia</title>
        <meta name="description" content="Únete a la revolución de los diamantes sostenibles y éticos. Descubre cómo redefinimos los diamantes con conciencia y ética." />
        <link rel="canonical" href="/marca" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
          <Marca />
        </>
      </TransitionComponent>
      <CookieConsent />
      <Resizer />
    </Suspense>,
  },
  {
    path: "/producto",
    element: 
    <Suspense>
      <Helmet>
        <title>Diamantes de Laboratorio: Exclusividad Consciente</title>
        <meta name="description" content="Descubre la combinación perfecta entre exclusividad y conciencia con nuestros diamantes. Impulsando un futuro más consciente y responsable." />
        <link rel="canonical" href="/producto" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
        <Producto />
        </>
      </TransitionComponent>
      <CookieConsent />
      <Resizer />
    </Suspense>,
  },
  {
    path: "/inversion",
    element: 
    <Suspense>
      <Helmet>
        <title>Invierte en Diamantes Sostenibles: Rendimiento y Seguridad</title>
        <meta name="description" content="Invierte en un mercado en crecimiento con Dianelum. Obtén rendimientos atractivos y opciones flexibles respaldadas por diamantes de alta calidad certificados." />
        <link rel="canonical" href="/inversion" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
          <Inversion />
        </>
      </TransitionComponent>
      <CookieConsent />
      <Resizer />
    </Suspense>,
  },
  {
    path: "/faqs",
    element: 
    <Suspense>
      <Helmet>
        <title>Preguntas Frecuentes sobre Dianelum</title>
        <meta name="description" content="Explora nuestras FAQs para obtener información detallada sobre Dianelum y las oportunidades de inversión en diamantes sostenibles." />
        <link rel="canonical" href="/faqs" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
        <Faqs />
        </>
      </TransitionComponent>
      <CookieConsent />
      <Resizer />
    </Suspense>,
  },
  {
    path: "/contacto",
    element: 
    <Suspense>
      <Helmet>
        <title>Contacto, hablemos</title>
        <meta name="description" content="Estamos encantados de atenderte. Contáctanos en Madrid o a través de nuestro correo electrónico. Juntos, creemos un futuro más brillante y sostenible." />
        <link rel="canonical" href="/faqs" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
        <Contacto />
        </>
      </TransitionComponent>
      <CookieConsent />
      <Resizer />
    </Suspense>,
  },
  {
    path: "/aviso-legal-y-condiciones-de-uso",
    element: 
    <Suspense>
      <Helmet>
        <title>Aviso legal y condiciones de uso</title>
        <meta name="description" content="Antes de utilizar este sitio, te pedimos que leas detenidamente esta sección." />
        <link rel="canonical" href="/aviso-legal-y-condiciones-de-uso" />
      </Helmet>
      <Header />
      <TransitionComponent>
        <>
        <Aviso />
        </>
      </TransitionComponent>
      <Resizer />
    </Suspense>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
  <RouterProvider router={router} />
  <FrontCanvas />
  <Cursor />
</>
);
