import { gsap } from "gsap/all";
import { useEffect, useState } from "react";


export default function HideOn({ on, delay, children }) {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if (on) {
            if (visible) {
                const p = {p:0}
                gsap.to(p, {p: 1, duration: delay, onComplete: () => {
                    setVisible(false)
                }})
            }
        } else {
            if (!visible) {
                setVisible(true)
            }
        }
    }, [on])

    return <>
        <group visible={ visible }>
            { children }
        </group>
    </>
}