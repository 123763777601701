import { gsap } from 'gsap';

import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { hideCursor, showCursor } from './Components/Cursor';

gsap.registerPlugin(
	SplitText,
	ScrollTrigger,
	ScrollSmoother,
	ScrollToPlugin,
	CustomEase
);
gsap.config({ nullTargetWarn: false });
ScrollTrigger.config({
	autoRefreshEvents: "visibilitychange,DOMContentLoaded,load", // notice "resize" isn't in the list
	ignoreMobileResize: true
});

let scroller

export function iniScrollSmoother() {
	if (!(window.innerWidth <= 600) && !((('ontouchstart' in window) ||
	(navigator.maxTouchPoints > 0) ||
	(navigator.msMaxTouchPoints > 0)))) {
		scroller = ScrollSmoother.create({
			smooth: 2,
			wrapper: '#smooth-wrapper',
			content: '#smooth-content',
			effects: true,
			normalizeScroll: true,
			ignoreMobileResize: true
		});
		scroller.paused(true)
		setTimeout(() => {
			scroller.paused(false)
		}, 2000);
	} else {
		document.body.style.overflow = 'hidden'
		setTimeout(() => {
			document.body.style.overflow = 'unset'
			document.body.style.overflowX = 'hidden'
		}, 2000);
	}
}

export function animaciones(page, isTouch) {
	document.getElementById('header').classList.remove('h-verde');

	const html = document.querySelector('html');
	if (html) {
		html.classList.add('loaded');
	}

	// / PRIMERO LIMPIO.
	const opacityIni = document.getElementsByClassName('opacityIni');
	for (let i = 0; i < opacityIni.length; i++) {
		opacityIni[i].classList.remove('opacityIni');
	}

	let pieza__1 = '3.542vw';
	let anchoAltoBtn = '4.167vw';
	let anchoAltoBtnAl = '5.729vw';
	let footer__linea = '100%';

	let linehlr2 = 'calc(100% - 9vw)';
	let bloque__sl__pantalla = '90%';

	let marquesina__scroll = '30vw';

	let har_b = '-20.646vw';
	let har_r = '-21.563vw';
	let har_br = '-17.271vw';
	let har_rr = '-48.594vw';

	let hal_bl = '-33.458vw';
	let hal_bt = '-36.24vw';

	let hal_blr = '-56.146vw';
	let hal_btr = '-17.813vw';

	let footer__rombo = '17.448vw';

	if (window.innerWidth <= 1024) {
		pieza__1 = '4.8vw';
		anchoAltoBtn = '8.201vw';
		linehlr2 = 'calc(100% - 12vw)';
		bloque__sl__pantalla = '60%';
	}
	if (window.innerWidth <= 992) {
		footer__linea = '200%';

		har_b = '-41.646vw';
		har_r = '-37.563vw';
		har_br = '-32.271vw';
		har_rr = '-75.594vw';

		hal_bl = '-57.458vw';
		hal_bt = '-84.24vw';
		hal_blr = '-120.146vw';
		hal_btr = '-50.813vw';
	}
	if (window.innerWidth <= 600) {
		pieza__1 = '13vw';

		har_b = '-48.646vw';
		har_r = '-45.563vw';
		har_br = '-44.271vw';
		har_rr = '-175.594vw';

		marquesina__scroll = '75vh';
		footer__rombo = '30vw';
		anchoAltoBtnAl = '8.201vw';
	}

	let iniTie = 0.6;

	gsap.utils.toArray('.ani_ini_fade').forEach((box) => {
		if(box.dataset.delay){
			iniTie = box.dataset.delay
		}
		var AniFade = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
		AniFade.to(box,{y: 0,opacity: 1},iniTie);
		AniFade.play();
	});

	CustomEase.create("cubic", "0.19, 1, 0.22, 1");

	gsap.utils.toArray('.ani_ini_mask span').forEach((box) => {
		if(box.dataset.delay){
			iniTie = box.dataset.delay
		}
		var AniFade = gsap.timeline({defaults: {duration: 1,ease: 'cubic'}});
		AniFade.to(box,{y: 0,rotate:0},iniTie);
		AniFade.play();
	});
	if(document.querySelector('.ani_ini_line')){
		document.querySelector('.ani_ini_line').classList.add('active');
	}
	
	gsap.utils.toArray('.ani_ini_line').forEach((box) => {
		if(box.dataset.delay){
			iniTie = box.dataset.delay
		}
		var lineas = new SplitText(box, { type: "lines",linesClass:'ail_line' });
		var lineasHijas = new SplitText(lineas.lines, {
			type: "lines",
			charsClass: 'lineChild'
		      });

		var AniFade = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
		AniFade.to(lineasHijas.lines,{y: 0,rotate:0,stagger: 0.1},iniTie);
		AniFade.play();
	});

	gsap.utils.toArray('.slider__cont_cont').forEach((box) => {
		var lineas = new SplitText(box, { type: "lines",linesClass:'ail_line' });
		var lineasHijas = new SplitText(lineas.lines, {
			type: "lines",
			charsClass: 'lineChild'
		      });
	});
	

	gsap.utils.toArray('.ani_ini_mask_fade span').forEach((box) => {
		if(box.dataset.delay){
			iniTie = box.dataset.delay
		}
		var AniFade = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
		AniFade.to(box,{y: 0,opacity: 1},iniTie);
		AniFade.play();
	});

	var ani_header = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
	ani_header.to('header',{top: '0'},0);
	ani_header.play();

	var ani_headerAniRombos = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
	ani_headerAniRombos.to('.header .header__ani_right .header__ani',{bottom: har_b,right: har_r},iniTie + 0.1);
	ani_headerAniRombos.to('.header .header__ani_right .header__ani_rombo',{bottom: har_br,right: har_rr},iniTie);
	ani_headerAniRombos.to('.header__contacto_data .header__contacto_data_left ',{opacity: 1,y: 0},iniTie);
	ani_headerAniRombos.to('.header__contacto_data .header__contacto_data_right ',{opacity: 1,y: 0},iniTie);
	ani_headerAniRombos.to('.header__contacto_data .header__contacto_data_line ',{width: '100%',left: 0},iniTie);
	ani_headerAniRombos.to('.header .header__ani_left .header__ani',{top: hal_bt,left: hal_bl},iniTie + 0.1);
	ani_headerAniRombos.to('.header .header__ani_left .header__ani_rombo',{top: hal_btr,left: hal_blr},iniTie);

	ani_headerAniRombos.to('.header .header__ani_center .header__ani_rombo',{opacity: 1},iniTie);

	ani_headerAniRombos.to('.header-marca .header__arrow',{bottom: '2vw',width: anchoAltoBtn},iniTie);
	ani_headerAniRombos.to('.header-marca .header__arrow .btn__rombo_svg ',{width: anchoAltoBtn},iniTie);
	ani_headerAniRombos.to('.header-marca .pieza-header',{x: '0',opacity: 1,scale: 1},iniTie);
	ani_headerAniRombos.to('.header-marca .pieza-header.pieza .pieza__1',{width: pieza__1},iniTie);

	ani_headerAniRombos.to('.header__btn404',{opacity:1},iniTie);

	

	ani_headerAniRombos.play();



	var iniTie2 = 0.4;


	
	let topFigure = '1vw'
	if (page === 'faq') topFigure = '2vw'
	if (page === 'inversion') topFigure = '2vw'
	if (window.innerWidth <= 600) {
		if (page === 'faq') topFigure = '8vw'
		if (page === 'inversion') topFigure = '5vw'
	}

	let header__ani_center_Top = "6vw";
	if (page === 'faq' || page === 'producto') header__ani_center_Top = '4.5vw'
	if (page === 'contacto') header__ani_center_Top = '5vw'

	// ANIMACION DE ENTRADA CABECERA
	var ani_headerAni2 = gsap.timeline({defaults: {duration: 0.6,ease: 'power2.out', }});

	ani_headerAni2.to('.header .header__ani_center .header__ani_rombo',{clipPath:"polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)"},iniTie2);
	
	ani_headerAni2.to('.header.header-ani .header__ani_center',{top: header__ani_center_Top},iniTie2 + 0.8);

	ani_headerAni2.to('.header .header__ani_center .header__ani',{top: topFigure,opacity: 1},iniTie2 + 0.8);
	ani_headerAni2.to('.header-ani .pieza-header',{x: '0',opacity: 1,scale: 1},iniTie2 + 0.8);
	ani_headerAni2.to('.header-ani .pieza-header.pieza .pieza__1',{width: pieza__1},iniTie2 + 0.8);

	ani_headerAni2.to('.header.header-ani .header__arrow',{bottom: '2vw',width: anchoAltoBtn},iniTie2 + 1);
	ani_headerAni2.to('.header.header-ani .header__arrow .btn__rombo_svg ',{width: anchoAltoBtn},iniTie2 + 1);

	ani_headerAni2.play();

	// ANIMACION CABECERA SCROLL
	let ejecutarAni = false;
	let imgAnimacionUrl = '';
	if(page == 'producto'){
		imgAnimacionUrl = `images/animaciones/header_producto/DIA_22`;
		ejecutarAni = true;
	}
	if(page == 'inversion'){
		imgAnimacionUrl = `images/animaciones/header_inversion/DIA_43`;
		ejecutarAni = true;
	}
	if(page == 'faq'){
		imgAnimacionUrl = `images/animaciones/header_faqs/DIA_FAQS_V2`;
		ejecutarAni = true;
	}
	if(page == 'contacto'){
		imgAnimacionUrl = `images/animaciones/header_contacto/DIA_63`;
		ejecutarAni = true;
	}
	if(page == 'aviso'){
		imgAnimacionUrl = `images/animaciones/header_aviso/DIA_64`;
		ejecutarAni = true;
	}
	if(page == '404'){
		imgAnimacionUrl = `images/animaciones/header_404/DIA_66`;
		ejecutarAni = false;
	}

	if(ejecutarAni){
		var ani_headerScroll2 = gsap.timeline({defaults: {duration: 2,ease: 'linear'}});
		if (page === 'aviso') {
			ani_headerScroll2.to('.header.header-ani .header__ani_center',{marginTop: '-11vw',scale:1.8},0);
		} else {
			ani_headerScroll2.to('.header.header-ani .header__ani_center',{marginTop: '0vw',scale:1.8},0);
		}

		let header__pretitulo = '-24vw';
		let header__pieza = '-28vw';
		if (window.innerWidth <= 600) {
			header__pretitulo = '-200px';
			header__pieza = '-230px';
		}

		ani_headerScroll2.to('.header2 .header__data .header__pretitulo',{marginTop: header__pretitulo},0);

		ani_headerScroll2.to('.header2 .pieza-header-container',{y:header__pieza },0);
		ani_headerScroll2.to('.header2 .pieza .pieza__2',{width: '16vw',left: 'calc(50% - 8vw)', top:'calc(3.542vw - 2)' },0);

	/* 	if (window.innerWidth <= 600) {
			ani_headerScroll2.to('.header-ani .cita-header',{top: '40vw'},0);
		} */


		let start = window.innerHeight + 2000
		if (window.innerWidth <= 600) {
			start = 2000
		}

		// var start = window.innerHeight + 500;
		let imgAnimacion = document.getElementById("header_img");
		let IdImagenSeguimiento = '00';
		if(imgAnimacion){
			const images = [];
			let frameCount = 120;
			for (let i = -1; i < frameCount; i++) {
				const img = new Image();
				let IdImagen = i;
				if(IdImagen < 10){
					IdImagen = "0"+IdImagen
				}
				if(IdImagen < 100){
					IdImagen = "0"+IdImagen
				}
				img.src = imgAnimacionUrl+IdImagen+".png";
				images.push(img);
			}

			ScrollTrigger.create({
				trigger: '.header-pin',
				pin: '.header.header-ani',
				animation: ani_headerScroll2,
				pinSpacing: false,
				start: '100% '+start,
				end: '100% 100%',
				scrub: true,
				onUpdate: (self) => {
					var porcentaje = self.progress * (frameCount - 1);
					var IdImagen = Math.trunc(porcentaje);
					if(IdImagen < 10){
						IdImagen = "0"+IdImagen
					}
					if(IdImagen < 100){
						IdImagen = "0"+IdImagen
					}
					if(IdImagenSeguimiento != IdImagen){
						imgAnimacion.src = imgAnimacionUrl+IdImagen+".png";
					}
					IdImagenSeguimiento = IdImagen;
				}
			});
		}

		var ani_headerScroll3 = gsap.timeline({defaults: {duration: 0.8,ease: 'linear'}});
		ani_headerScroll3.to('.header2 .header__ani',{marginTop: '50vw'},0);
		if (page === 'aviso') {
			ani_headerScroll3.fromTo('.header.header-ani .header__ani_center',{marginTop: '-11vw',scale:1.8},{marginTop: '20vw',scale:0},0);
		} else {
			ani_headerScroll3.fromTo('.header.header-ani .header__ani_center',{marginTop: '0vw',scale:1.8},{marginTop: '20vw',scale:0},0);
		}

		ScrollTrigger.create({
			trigger: '.header-pin',
			animation: ani_headerScroll3,
			start: '100% 90%',
			end: '100% 00%',
			scrub: true,
		});

	}



	let split = SplitText.create('h1', {
		type: 'lines,chars',
		linesClass: 'split-line',
		charsClass: 'split-char'
	});

	gsap.to(split.chars, {
		opacity: 1,
		y: 0,
		ease: 'power2.out',
		duration: 0.8,
		stagger: 0.015,
		rotateX: '0deg'
	});

	// ANIMACION DE SCROOLL FONDO NEGRO
	var AniFade = gsap.timeline({
		paused: true,
		reversed: true,
		defaults: {
			duration: 1,
			ease: 'linear'
		}
	});

	AniFade.to(
		'.bloque__blanco .bloque__blanco-fondo',
		{
			transform: 'skewX(0deg) skewY(0deg)'
		},
		'0'
	);

	ScrollTrigger.create({
		trigger: '.bloque__blanco-fondo',
		animation: AniFade,
		start: '1% 100%',
		end: '1% 0%',
		scrub: true
	});

	if (!(window.innerWidth <= 700)) {
		// ANIMACION DE SCROOLL HEADER PIN
		var AniFade = gsap.timeline({paused: true,reversed: true,defaults: {duration: 1,ease: 'linear'}});
		AniFade.to('.header__back ',{opacity: 0.7},'0');

		ScrollTrigger.create({
			trigger: '.header1',
			start: '100% 100%',
			pin: '.header1',
			animation: AniFade,
			pinSpacing: false,
			end: '100% 0%',
			scrub: true
		});
	}

	gsap.utils.toArray('.ani-scroll-rotate').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'lineas'
			}
		});

		AniFade.to(
			box,
			{
				rotate: '180deg'
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0% 100%',
			end: '100% 0%',
			scrub: true
		});
	});

	let widthTitle = '32vw';
	if (window.innerWidth <= 1024) {
		widthTitle = '40vw';
	}
	if (window.innerWidth <= 600) {
		widthTitle = '45vw';
	}
	
	var inicioHeaderTitular = 0;
	var inicioHeaderTitular_scale = 1;

	if(page == 'inversion' || page == 'producto' || page == 'faq' || page == 'contacto' || page == 'aviso' || page == '404'){
		inicioHeaderTitular = 1.2;
		inicioHeaderTitular_scale = 0;
	}

	var AniTitle = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});

	if(inicioHeaderTitular_scale){
		AniTitle.to(".header__titulo_ani",{scale:"1"},inicioHeaderTitular);
	}
	
	AniTitle.to(".header__titulo_ani .header__titulo_ani_1",{width:widthTitle,left: "0px"},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_1 .linet1",{x: "0px"},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_1 .linet3",{x: "0px"},inicioHeaderTitular);

	AniTitle.to(".header__titulo_ani .header__titulo_ani_2",{width:widthTitle},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_2 .linet1",{x: "0px"},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_2 .linet3",{x: "0px"},inicioHeaderTitular);

	AniTitle.to(".header__titulo_ani .header__titulo_ani_3 .tit",{left:"50%"},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_3 .linet1",{x: "0px"},inicioHeaderTitular);

	 AniTitle.to(".header__titulo_ani .header__titulo_ani_3 .linet2",{x: "0px"},inicioHeaderTitular);

	AniTitle.to(".header__titulo_ani .header__titulo_ani_3 .linet3",{x: "0px"},inicioHeaderTitular);
	AniTitle.to(".header__titulo_ani .header__titulo_ani_3",{opacity:0,duration:0.1},inicioHeaderTitular + 0.9)
	AniTitle.play();

	if(page == 'marca' || page == 'inversion'){
		var ani_headerScroll3 = gsap.timeline({defaults: {duration: 2,ease: 'linear'}});
			
		let imgAnimacion = document.getElementById("int_header_img");
		let IdImagenSeguimiento = '00';
		let imgAnimacionUrl2 = 'images/animaciones/header_int_sostenibilidad/DIA_12';
		if(page == 'inversion'){
			imgAnimacionUrl2 = 'images/animaciones/header_int_flexibilidad/DIA_55';
		}
		
		const images = [];
		let frameCount = 120;
		for (let i = -1; i < frameCount; i++) {
			const img = new Image();
			let IdImagen = i;
			if(IdImagen < 10){
				IdImagen = "0"+IdImagen
			}
			if(IdImagen < 100){
				IdImagen = "0"+IdImagen
			}
			img.src = imgAnimacionUrl2+IdImagen+".png";
						
			images.push(img);
		}

		ScrollTrigger.create({
			trigger: '.bloque__txtc__imgani',
			animation: ani_headerScroll2,
			start: '-50% 100%',
			end: '50% 0%',
			scrub: true,
			onUpdate: (self) => {
				var porcentaje = self.progress * (frameCount - 1);
				var IdImagen = Math.trunc(porcentaje);
				if(IdImagen < 10){
					IdImagen = "0"+IdImagen
				}
				if(IdImagen < 100){
					IdImagen = "0"+IdImagen
				}
				if(IdImagenSeguimiento != IdImagen){
					imgAnimacion.src = imgAnimacionUrl2+IdImagen+".png";
				}
				IdImagenSeguimiento = IdImagen;
			}
		});
	}


	setTimeout(function () {
		if(page == 'marca' || page == 'inversion'){
			let widthTitle2 = '45vw';
			if (window.innerWidth <= 600) {
				widthTitle2 = '50vw';
			}
			let widthPieza = '5.2vw';
			if (window.innerWidth <= 1024) {
				widthPieza = '4.8vw';
			}

			var AniTitle = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});

			var inicioHeaderTitular = 0;
			
			AniTitle.to(".int_header__titulo_ani",{scale:"1"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_1",{width:widthTitle2,left: "0px"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_1 .linet1",{x: "0px"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_1 .linet3",{x: "0px"},inicioHeaderTitular);
		
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_2",{width:widthTitle2},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_2 .linet1",{x: "0px"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_2 .linet3",{x: "0px"},inicioHeaderTitular);
		
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_3 .tit",{left:"50%"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_3 .linet1",{x: "0px"},inicioHeaderTitular);
		
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_3 .linet2",{x: "0px"},inicioHeaderTitular);
		
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_3 .linet3",{x: "0px"},inicioHeaderTitular);
			AniTitle.to(".int_header__titulo_ani .int_header__titulo_ani_3",{opacity:0,duration:0.1},inicioHeaderTitular + 0.9);

			AniTitle.to(".bloque__txtc__pretitulo span",{y: 0},inicioHeaderTitular + 0.4);

			AniTitle.to(".bloque__txtc_pieza",{x: '0',opacity: 1,scale: 1}, inicioHeaderTitular + 0.6);
			AniTitle.to(".bloque__txtc_pieza .pieza__1",{width: widthPieza,right:0}, inicioHeaderTitular + 0.6);

			if(page == 'marca'){
				AniTitle.to(".bloque__txtc__imgani",{y: '-10vw',}, inicioHeaderTitular + 0.6);
			}
			if(page == 'inversion'){
				AniTitle.to(".bloque__txtc__imgani",{y: '-20vw',}, inicioHeaderTitular + 0.6);
			}

			
			ScrollTrigger.create({
				trigger: ".int_header__titulo_ani",
				animation: AniTitle,
				start: 'center 100%',
				end: 'center 100%',
			});


			var AniTitle2 = gsap.timeline({defaults: {duration: 1,ease: 'linear'}});
			AniTitle2.to(".bloque__txtc__imgani",{scale: '0.8'}, 0);

			if(page == 'marca'){
				AniTitle2.to(".bloque__txtc__imgani .bloque__txtc__imgani_cont",{y:'9vw'}, 0);
			}
			if(page == 'inversion'){
				AniTitle2.to(".bloque__txtc__imgani .bloque__txtc__imgani_cont",{y:'25vw'}, 0);
			}


			ScrollTrigger.create({
				trigger: ".bloque__txtc__imgani",
				animation: AniTitle2,
				start: '-40% 100%',
				end: '-40% 0%',
				scrub:true,
			});
		}

		gsap.utils.toArray('.ani-scroll-text-small').forEach((box) => {
			let split = SplitText.create(box, {
				type: 'lines,chars',
				linesClass: 'asts-line',
				charsClass: 'asts-char'
			});

			var AniFade = gsap.to(split.chars, {
				color: '#141515',
				stagger: 0.08
			});

			ScrollTrigger.create({
				trigger: box,
				animation: AniFade,
				start: '0px 90%',
				end: '0px 60%',
				scrub: true
			});
		});

		gsap.utils.toArray('.ani-scroll-enter').forEach((box) => {
			let split = SplitText.create(box, {
				type: 'lines,chars',
				linesClass: 'split-line',
				charsClass: 'split-char'
			});

			var Ani = gsap.to(split.chars, {
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				duration: 0.8,
				stagger: 0.015,
				rotateX: '0deg'
			});

			ScrollTrigger.create({
				trigger: box,
				animation: Ani,
				start: '0px 95%',
				end: '0px 95%'
			});
		});

		gsap.utils.toArray('.bloque__listado_item').forEach((box) => {
			let split = SplitText.create(
				box.querySelector('.bloque__listado_item-titulo'),
				{
					type: 'lines,chars',
					linesClass: 'split-line',
					charsClass: 'split-char'
				}
			);

			var AniListItem = gsap.timeline({
				defaults: {
					duration: 0.8,
					ease: 'power2.out'
				}
			});

			AniListItem.to(split.chars, {
				opacity: 1,
				y: 0,
				stagger: 0.015,
				rotateX: '0deg'
			});

			AniListItem.to(
				box.querySelector('.bloque__listado_item-num span'),
				{
					y: '0'
				},
				0
			);
			AniListItem.to(
				box.querySelector('.bloque__listado_item-linea svg'),
				{
					height: '0.781vw'
				},
				0.2
			);

			AniListItem.to(
				box.querySelector('.bloque__listado_item-linea .line'),
				{
					width: '100%'
				},
				0.4
			);
			if (window.innerWidth <= 600) {
				AniListItem.to(
					box.querySelector('.bloque__listado_item-img-movil'),
					{
						opacity: '1'
					},
					0
				);
			}

			ScrollTrigger.create({
				trigger: box,
				animation: AniListItem,
				start: 'center 90%',
				end: 'center 90%'
			});
		});
		ScrollTrigger.refresh();
	}, 500);


//bloque__listado_item
//.bloque__listado_item-txt

	gsap.utils.toArray('.bloque__listado_item').forEach((box) => {
		if (window.innerWidth >= 600) {
			var lineas = new SplitText(box.querySelector('.bloque__listado_item-txt'), { type: "lines",linesClass:'ail_line' });
			var lineasHijas = new SplitText(lineas.lines, {
				type: "lines",
				charsClass: 'lineChild'
			      });
	
			var AniFade = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
			AniFade.to(lineasHijas.lines,{y: 0,rotate:0,stagger: 0.1},0);
			
			AniFade.reverse(0)
	
			box.addEventListener("mouseenter", () => AniFade.play());
			box.addEventListener("mouseleave", () => AniFade.reverse(0.3));
		}else{
			var lineas = new SplitText(box.querySelector('.bloque__listado_item-txt'), { type: "lines",linesClass:'ail_line' });
			var lineasHijas = new SplitText(lineas.lines, {
				type: "lines",
				charsClass: 'lineChild'
			      });
	
			var AniFade = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
			AniFade.to(lineasHijas.lines,{y: 0,rotate:0,stagger: 0.1},0);
			
			ScrollTrigger.create({
				trigger: box,
				animation: AniFade,
				start: '90% 90%',
				end: '90% 90%'
			});
			
		}
		
	});





	gsap.utils.toArray('.ani_scroll_rombodeco svg').forEach((box) => {
		var ani = gsap.to(box, {
			width: '100%',
			ease: 'power2.out',
			duration: 0.8
		});

		ScrollTrigger.create({
			trigger: box,
			animation: ani,
			start: '0px 90%',
			end: '0px 90%'
		});
	});

	gsap.utils.toArray('.ani_scroll_mask span').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniFade.to(
			box,
			{
				y: 0
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0px 90%',
			end: '0px 90%'
		});
	});

	gsap.utils.toArray('.ani_scroll_pieza').forEach((box) => {
		var AniScrollPieza = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniScrollPieza.to(
			box,
			{
				x: '0',
				opacity: 1,
				scale: 1
			},
			0
		);
		AniScrollPieza.to(
			box.querySelector('.pieza__1'),
			{
				width: pieza__1
			},
			0
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniScrollPieza,
			start: '0px 90%',
			end: '0px 90%'
		});
	});

	gsap.utils.toArray('.ani_scroll_pieza_grande').forEach((box) => {
		var AniScrollPieza = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniScrollPieza.to(
			box,
			{
				x: '0',
				opacity: 1,
				scale: 1
			},
			0
		);
		AniScrollPieza.to(
			box.querySelector('.pieza__1'),
			{
				width: '5.2vw',
				right:0
			},
			0
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniScrollPieza,
			start: '0px 90%',
			end: '0px 70%',
		});
	});

	gsap.utils.toArray('.ani_scroll_rombo').forEach((box) => {
		var AniScrollPieza = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniScrollPieza.to(
			box,
			{
				x: '0',
				opacity: 1,
				scale: 1
			},
			0
		);
		AniScrollPieza.to(
			box.querySelector('.pieza__1'),
			{
				width: pieza__1
			},
			0
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniScrollPieza,
			start: '0px 90%',
			end: '0px 90%'
		});
	});

	gsap.utils.toArray('.ani_scroll_fade').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniFade.to(
			box,
			{
				y: 0,
				opacity: 1
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0px 90%',
			end: '0px 90%'
		});
	});
	
	gsap.utils.toArray('.ani_scroll_line, .ani_scroll_line_p p').forEach((box) => {
		var lineas = new SplitText(box, { type: "lines",linesClass:'ail_line' });
		var lineasHijas = new SplitText(lineas.lines, {
			type: "lines",
			charsClass: 'lineChild'
		      });

		var AniFade = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
		AniFade.to(lineasHijas.lines,{y: 0,rotate:0,stagger: 0.1},0);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0px 90%',
			end: '0px 90%'
		});
	});



	// var bloque_hablamos_txt = gsap.timeline({
	// 	defaults: {
	// 		duration: 1,
	// 		ease: 'power2.out'
	// 	}
	// });

	// bloque_hablamos_txt.to(
	// 	'.bloque_hablamos_txt',
	// 	{
	// 		y: 0,
	// 		opacity: 1
	// 	},
	// 	'0'
	// );

	// ScrollTrigger.create({
	// 	trigger: '.bloque__hablamos',
	// 	animation: bloque_hablamos_txt,
	// 	start: '-10% 100%',
	// 	end: '-10% 100%'
	// });

	gsap.utils.toArray('.ani_scroll_lineW100').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniFade.fromTo(
			box,
			{
				width: '0%'
			},
			{
				width: '100%'
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0px 90%',
			end: '0px 90%'
		});
	});

	gsap.utils.toArray('.line-h-l-r').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});

		AniFade.fromTo(
			box,
			{
				width: '0%'
			},
			{
				width: '100%'
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0px 95%',
			end: '0px 95%'
		});
	});

	var AniFade = gsap.timeline({
		defaults: {
			duration: 1,
			ease: 'power2.out'
		}
	});

	AniFade.fromTo(
		'.line-h-l-r2',
		{
			width: '0%'
		},
		{
			width: linehlr2
		},
		'0'
	);
	AniFade.fromTo(
		'.btn__rombo-vision',
		{
			opacity: 0,
			width: '2.729vw',
			right: '4.8vw'
		},
		{
			opacity: 1,
			width: '7.448vw',
			right: '3vw',
			duration: 0.6
		},
		'0.6'
	);

	ScrollTrigger.create({
		trigger: '.line-h-l-r2',
		animation: AniFade,
		start: '0px 95%',
		end: '0px 95%'
	});

	var AniFade = gsap.timeline({
		defaults: {
			duration: 0.6,
			ease: 'power2.out'
		}
	});

	AniFade.to(
		'.slider__rombo',
		{
			width: '19.063vw',
			height: '19.063vw'
		},
		'0'
	);
	AniFade.to(
		'.slider__cont_1 .ail_line div',
		{y: 0,rotate:0,stagger: 0.1},
		'0.2'
	);

	AniFade.to(
		'.slider__data .slider__title .slider__title__inner',
		{
			y: '0',
		},
		'0.2'
	);
	AniFade.fromTo(
		'.slider .slider__figuras .slider__figuras__figura img',
		{
			left: '16vw',
			opacity: 0
		},
		{
			left: '12vw',
			opacity: 1
		},
		'0.2'
	);
	AniFade.fromTo(
		'.slider .slider__figuras .slider__ff2',
		{
			left: '30px',
			opacity: 0
		},
		{
			left: '0px',
			opacity: 1
		},
		'0.4'
	);

	ScrollTrigger.create({
		trigger: '.slider',
		animation: AniFade,
		start: '50% 100%',
		end: '50% 0%'
	});

	var AniFade = gsap.timeline({
		defaults: {
			duration: 0.6,
			ease: 'power2.out'
		}
	});

	AniFade.to(
		'.slider .slider__controller',
		{
			width: '12vw'
		},
		'0'
	);

	ScrollTrigger.create({
		trigger: '.slider',
		animation: AniFade,
		start: '95% 100%',
		end: '95% 0%'
	});

	gsap.utils.toArray('.bloque__triangulo').forEach((box) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 0.6,
				ease: 'linear'
			}
		});

		AniFade.to(
			box.querySelector('.bloque__triangulo__tri'),
			{
				top: '-300px'
			},
			'0'
		);
		AniFade.to(
			box.querySelector('.bloque__triangulo__tri').querySelector('img'),
			{
				bottom: '-30px'
			},
			'0'
		);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0% 100%',
			end: '100% 0%',
			scrub: true
		});
	});

	var AniFade = gsap.timeline({
		defaults: {
			duration: 0.6,
			ease: 'linear'
		}
	});

	AniFade.to(
		'.marquesina__scroll .marquesina__scroll_inner',
		{
			x: '-90vw'
		},
		'0'
	);
	AniFade.to(
		'.marquesina__scroll',
		{
			y: marquesina__scroll
		},
		'0'
	);

	AniFade.to('.marquesina__scroll .marquesina__scroll_inner svg,.marquesina__scroll .marquesina__scroll_inner img',{rotate: '360deg'},'0');

	AniFade.to(
		'.bloque__vision .vision_figura1_fondo',
		{
			top: '-60vw'
		},
		'0'
	);
	AniFade.to(
		'.bloque__vision .vision_figura1_figura',
		{
			top: '-50vw'
		},
		'0'
	);

	AniFade.to(
		'.bloque__vision .vision_figura2_fondo',
		{
			top: '0vw'
		},
		'0'
	);
	AniFade.to(
		'.bloque__vision .vision_figura2_figura',
		{
			top: '4vw'
		},
		'0'
	);

	ScrollTrigger.create({
		trigger: '.bloque__vision_maque',
		animation: AniFade,
		start: '0% 100%',
		end: '100% 0%',
		scrub: true
	});

	setTimeout(function(){
		if (!(window.innerWidth <= 600)) {
			let bloque__colscroll_bottom = '90%';
			if (window.innerWidth <= 1366) {
				bloque__colscroll_bottom = '100%';
			}
	
			ScrollTrigger.create({
				trigger: '.bloque__colscroll',
				pin: '.bloque__colscroll_colimgs',
				pinSpacing: true,
				start: '0% 0%',
				end: '100% 100%',
				scrub: true,	
			});
	
			gsap.utils.toArray('.bloque__colscroll_info').forEach((box) => {
				ScrollTrigger.create({
					trigger: box,
					start: '-100% 20%',
					end: '0% 20%',
					onLeaveBack(){
						if(box.id == 'colscroll_1'){
							setTimeout(function(){
								if(document.getElementById('bloque__colscroll_colimgs_ani')){
									document.getElementById('bloque__colscroll_colimgs_ani').classList.remove('anima');
								}
								
							},200)
							if(document.getElementById('colscroll_1_img')){
								document.getElementById('colscroll_1_img').classList.remove('colscroll_active');
							}
													
						}
					},
					onEnter() {
						if(document.getElementById('bloque__colscroll_colimgs_ani')){
							document.getElementById('bloque__colscroll_colimgs_ani').classList.add('anima');						
						}

						if (document.getElementById('colscroll_1_img') != null) {
							document.getElementById('colscroll_1_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_2_img') != null) {
							document.getElementById('colscroll_2_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_3_img') != null) {
							document.getElementById('colscroll_3_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_4_img') != null) {
							document.getElementById('colscroll_4_img').classList.remove('colscroll_active');
						}
						if (document.getElementById(box.id + '_img') != null) {
							document.getElementById(box.id + '_img').classList.add('colscroll_active');
						}
					},
					onEnterBack() {
						
						if (document.getElementById('colscroll_1_img') != null) {
							document.getElementById('colscroll_1_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_2_img') != null) {
							document.getElementById('colscroll_2_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_3_img') != null) {
							document.getElementById('colscroll_3_img').classList.remove('colscroll_active');
						}
						if (document.getElementById('colscroll_4_img') != null) {
							document.getElementById('colscroll_4_img').classList.remove('colscroll_active');
						}
						if (document.getElementById(box.id + '_img') != null) {
							document.getElementById(box.id + '_img').classList.add('colscroll_active');
						}
					}
				});
			});
		}
		ScrollTrigger.refresh();
	},1000);
	
	if (page == 'producto') {
		if (!(window.innerWidth <= 600)) {
			let sections = gsap.utils.toArray('.contenedor__scroll_lateral-seccion');
			if (sections != null) {
				gsap.to(sections, {
					xPercent: -100 * (sections.length - 1),
					ease: 'none',
					scrollTrigger: {
						trigger: '.contenedor__scroll_lateral',
						pin: true,
						scrub: 1,
						end: () =>
							'+=' +
							(document.querySelector('.contenedor__scroll_lateral') !== null
								? document.querySelector('.contenedor__scroll_lateral')
										.offsetWidth
								: '')
					}
				});
			}
		}
	}
	setTimeout(function(){

	//let start = window.innerHeight + 1000;
	// var start = window.innerHeight + 500;
	const imgAnimacionUrl = 'images/animaciones/david_locco/DIA_21'
	let imgAnimacionTeam = document.getElementById("team_img");

	const images = [];
	let frameCount = 0;
	for (let i = -1; i < frameCount; i++) {
		const img = new Image();
		let IdImagen = frameCount;
		if(IdImagen < 10){
			IdImagen = "0"+IdImagen
		}
		img.src = imgAnimacionUrl+IdImagen+".png";
		images.push(img);
	}
	
	gsap.utils.toArray('.bloque__equipo_item').forEach((box) => {
		let bloque__equipo_item = '0%';
		/* if (window.innerWidth <= 600) {
			bloque__equipo_item = '0%';
		} */
		ScrollTrigger.create({
			trigger: box,
			endTrigger: '.end-element',
			start: '0% '+bloque__equipo_item,
			end: '100% 100%',
			scrub: true,
			pin: true,
			pinSpacing: false,
		});
	});
	gsap.utils.toArray('.bloque__equipo').forEach((box, index) => {
		var AniFade = gsap.timeline({
			defaults: {
				duration: 3,
				ease: 'linear'
			}
		});


		const delay0 = 1
		const delay1 = 3
		const delay2 = 5
		const delay3 = 7
		const delay4 = 9

		const rotation = (window.innerWidth < 768) ? '500deg' : '360deg'
		
		const top1 = isTouch ? '0%' : '30%'
		const top2 = isTouch ? '10%' : '33%'
		const y = isTouch ? '40%' : '50%'
		const x1 = isTouch ? '-160vw' : '-140vw'
		const x2 = isTouch ? '160vw' : '140vw'

		AniFade.to( box.querySelector('.bloque__equipo_nombre1.--1'), { top: top1, y: y, x: x1 }, delay0 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre2.--1'), { top: top2, y: y, x: x2 }, delay0 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre1.--2'), { top: top1, y: y, x: x1 }, delay1 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre2.--2'), { top: top2, y: y, x: x2 }, delay1 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre1.--3'), { top: top1, y: y, x: x1 }, delay2 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre2.--3'), { top: top2, y: y, x: x2 }, delay2 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre1.--4'), { top: top1, y: y, x: x1 }, delay3 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre2.--4'), { top: top2, y: y, x: x2 }, delay3 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre1.--5'), { top: top1, y: y, x: x1 }, delay4 );
		AniFade.to( box.querySelector('.bloque__equipo_nombre2.--5'), { top: top2, y: y, x: x2 }, delay4 );


		AniFade.to(box.querySelector('.bloque__equipo_nombre1.--1 img'),{rotate: rotation},delay0);
		AniFade.to(box.querySelector('.bloque__equipo_nombre2.--1 img'),{rotate: rotation},delay0);
		AniFade.to(box.querySelector('.bloque__equipo_nombre1.--2 img'),{rotate: rotation},delay1);
		AniFade.to(box.querySelector('.bloque__equipo_nombre2.--2 img'),{rotate: rotation},delay1);
		AniFade.to(box.querySelector('.bloque__equipo_nombre1.--3 img'),{rotate: rotation},delay2);
		AniFade.to(box.querySelector('.bloque__equipo_nombre2.--3 img'),{rotate: rotation},delay2);
		AniFade.to(box.querySelector('.bloque__equipo_nombre1.--4 img'),{rotate: rotation},delay3);
		AniFade.to(box.querySelector('.bloque__equipo_nombre2.--4 img'),{rotate: rotation},delay3);
		AniFade.to(box.querySelector('.bloque__equipo_nombre1.--5 img'),{rotate: rotation},delay4);
		AniFade.to(box.querySelector('.bloque__equipo_nombre2.--5 img'),{rotate: rotation},delay4);

		ScrollTrigger.create({
			trigger: box,
			animation: AniFade,
			start: '0% 100%',
			end: '100% 0%',
			scrub: true
		});
	});
	gsap.utils.toArray('.bloque__equipo').forEach((box) => {
		ScrollTrigger.create({
			trigger: box,
			start: '0% 100%',
			end: '100% 0%',
			onUpdate: (self) => {
				let porcentaje = ((self.progress * 59) * 6) % 60;
				let IdImagen = Math.trunc(porcentaje);
				if(IdImagen < 10){
					IdImagen = "0"+IdImagen
				}
				imgAnimacionTeam.src = imgAnimacionUrl+IdImagen+".png";
			},
			scrub: true
		});
	});

	ScrollTrigger.refresh();
	},1000);

	var AniFade = gsap.timeline({
		defaults: {
			duration: 0.8,
			ease: 'power2.out'
		}
	});
	/* let btn_hablamos_w = "74.740vw";
	let btn_hablamos_h = "32vw";
	if (window.innerWidth <= 768) {
		btn_hablamos_h = '38vw'
	}
	if (window.innerWidth <= 600) {
		btn_hablamos_w = 'calc(100% - 30px)'
	} */
	let btn_hablamos_w = "74.740vw";
	if (window.innerWidth <= 600) {
		btn_hablamos_w = 'calc(100% - 30px)'
	}
	AniFade.to(
		'.btn_hablamos',
		{
			width: btn_hablamos_w,
			/* height: btn_hablamos_h, */
			opacity: 1
		},
		'0'
	);
	AniFade.to(
		'.btn_hablamos span',
		{
			transform: 'translate(-50%,-50%)',
			opacity: 1
		},
		'0.3'
	);
	ScrollTrigger.create({
		trigger: '.btn_hablamos',
		animation: AniFade,
		start: '0% 100%',
		end: '0% 100%'
	});
	var AniFade = gsap.timeline({
		defaults: {
			duration: 1,
			ease: 'linear'
		}
	});

	if (window.innerWidth <= 600) {
		AniFade.fromTo(
			'.card1',
			{ x: '-50%', y: '-45%' },
			{ x: '-50%', y: '-45%' },
			'0'
		);
		AniFade.fromTo(
			'.card2',
			{ x: '-50%', y: '-47%' },
			{ x: '-50%', y: '-49%' },
			'0'
		);
		AniFade.fromTo(
			'.card3',
			{ x: '-50%', y: '-49%' },
			{ x: '-50%', y: '-51%' },
			'0'
		);
	} else {
		AniFade.fromTo(
			'.card1',
			{ x: '-55%', y: '-45%' },
			{ x: '-55%', y: '-45%' },
			'0'
		);
		AniFade.fromTo(
			'.card2',
			{ x: '-52%', y: '-48%' },
			{ x: '-48%', y: '-53%' },
			'0'
		);
		AniFade.fromTo(
			'.card3',
			{ x: '-49%', y: '-51%' },
			{ x: '-41%', y: '-61%' },
			'0'
		);
	}

	if (document.querySelector('.cards') !== null) {
		ScrollTrigger.create({
			trigger: '.cards',
			animation: AniFade,
			start: '0% 100%',
			end: '0% 0%',
			scrub: true
		});

		ScrollTrigger.create({
			trigger: '.cards',
			start: '0% 0%',
			end: window.innerHeight * 2 + ' 0%',
			scrub: true,
			pin: '.cards__container',
			pinSpacing: true,
			onUpdate: (self) => {
				if (document.getElementById('cards') != null) {
					var porcentaje = self.progress * 100;
					if (porcentaje > 0 && porcentaje < 33.333) {
						document.getElementById('cards').classList.remove('card_active_1');
						document.getElementById('cards').classList.remove('card_active_2');
						document.getElementById('cards').classList.remove('card_active_3');

						document.getElementById('cards').classList.add('card_active_1');
					}
					if (porcentaje > 33.333 && porcentaje < 66.666) {
						document.getElementById('cards').classList.remove('card_active_1');
						document.getElementById('cards').classList.remove('card_active_2');
						document.getElementById('cards').classList.remove('card_active_3');

						document.getElementById('cards').classList.add('card_active_2');
					}
					if (porcentaje > 66.666 && porcentaje < 100) {
						document.getElementById('cards').classList.remove('card_active_1');
						document.getElementById('cards').classList.remove('card_active_2');
						document.getElementById('cards').classList.remove('card_active_3');

						document.getElementById('cards').classList.add('card_active_3');
					}
				}
			}
		});
	}

	

	if (!(window.innerWidth <= 600)) {
		aniBajadaImagen("bloque__sl05");
		ScrollTrigger.create({
			trigger: '.bloque__scroll__later',
			start: '0% 0%',
			end: '100% 100%',
			scrub: true,
			pin: '.bloque__sl__fixed',
			pinSpacing: false
		});
		var AniFade = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power2.out'
			}
		});
		AniFade.fromTo(
			'.bloque__scroll__later .bloque__sl__mask .bloque__sl__rombo',
			{
				bottom: '14vw',
				height: '0.406vw'
			},
			{
				bottom: '0vw',
				height: '26.406vw'
			},
			'0'
		);
		ScrollTrigger.create({
			trigger: '.bloque__scroll__later',
			animation: AniFade,
			start: '0% 30%',
			end: '0% 30%'
		});
		gsap.utils.toArray('.bloque__sl__pantalla').forEach((box) => {
			var AniFade = gsap.timeline({
				defaults: {
					duration: 1,
					ease: 'linear'
				}
			});
			aniBajadaImagen("bloque__sl05");
			ScrollTrigger.create({
				trigger: box,
				animation: AniFade,
				// start: "0% 85%",
				// end: "0% 0%",
				start: '0% ' + bloque__sl__pantalla,
				end: '80% ' + bloque__sl__pantalla,
				scrub: true,
				onEnter() {
					aniSubidaImagen(box.id);
				},
				onEnterBack() {
					aniSubidaImagen(box.id);
				},
				onLeave() {
					if (box.id != 'bloque__sl05') {
						aniBajadaImagen(box.id);
					}
				},
				onLeaveBack() {
					aniBajadaImagen(box.id);
				}
			});
		});
		function aniSubidaImagen(id) {
			var AniFade = gsap.timeline({
				defaults: {
					duration: 1,
					ease: 'power2.out'
				}
			});

			AniFade.to(
				'#' + id + '_img',
				{
					bottom: '0vw'
				},
				'0'
			);
			AniFade.play();
		}
		function aniBajadaImagen(id) {
			var AniFade = gsap.timeline({
				defaults: {
					duration: 1,
					ease: 'power2.out'
				}
			});

			AniFade.to(
				'#' + id + '_img',
				{
					bottom: '-60vw'
				},
				'0'
			);
			AniFade.play();
		}
	}

	const aniFooter = gsap.timeline({ paused: true, ease: 'power2.out',duration:3 });
	const f = document.querySelector('footer');
	let colorBorde = '#9bcaa6';
	if (f) {
		if (f.classList[0] == 'verde') {
			colorBorde = '#141515';
		}
	}
	

	aniFooter.to('footer .footer .footer__rombo',{width: footer__rombo,height: footer__rombo,borderColor: colorBorde,opacity: 1},0);
	aniFooter.to('footer .footer .footer__rombo a',{opacity: 1},0.1);
	aniFooter.to('footer .footer .footer__linea1 span', {left: "0%"},0.07);
	aniFooter.to('footer .footer .footer__linea2 span', {left:"0%"},0.05);
	aniFooter.to('footer .footer .footer__linea3', {width: footer__linea},0);
	aniFooter.to('footer .footer__menu_svg', {marginLeft: '-6vw',opacity: 1},0.2);
	aniFooter.to('footer .footer__menu_svg .footer__menu_svg_1', {width:'100%',left:0},0.2);
	aniFooter.to('footer .footer__menu_svg .footer__menu_svg_2', {height:'100%'},0.2);
	aniFooter.to('footer .footer__txt_top',{top: '3vh',opacity: 1},0.4);
	aniFooter.to('footer .footer__txt_bottom',{bottom: '2.208vw',opacity: 1},0.4);
	aniFooter.to('footer .footer__btn-contacto',{bottom: '1.354vw',opacity: 1},0.4);
	aniFooter.to('footer .footer__btn-contacto .btn__rombo',{height: anchoAltoBtnAl,opacity: 1},0.4);
	aniFooter.to('footer .footer__btn-rs .btn__rombo_svg',{width: anchoAltoBtn,margin: '0vw',opacity: 1},0.4);
	aniFooter.to('footer .footer__btn-scroll .btn__rombo_svg',{width: anchoAltoBtn,margin: '0vw',opacity: 1},0.4);
	
	var iniAniFooter = "0%";
	
	ScrollTrigger.create({
		trigger: 'footer',
		animation: aniFooter,
		start:  iniAniFooter + ' 50%',
		end: iniAniFooter + ' 0%',
	});

	gsap.utils.toArray('.footer__menu-a').forEach((box) => {
		let split = SplitText.create(box, {
			type: 'lines,chars',
			linesClass: 'split-line',
			charsClass: 'split-char'
		});

		var Ani = gsap.to(split.chars,{
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				duration: 0.8,
				stagger: 0.15,
				rotateX: '0deg'
			},
			0
		);

		ScrollTrigger.create({
			trigger: 'footer',
			animation: Ani,
			start:  '0% 50%',
			end: '0% 0%',
		});
	});


	var AniFooter = gsap.timeline({ defaults: { duration: 2, ease: 'linear' } });

	AniFooter.fromTo('footer .footer',{marginTop: '-50vh'},{marginTop: '0vw'},'0');

	ScrollTrigger.create({
		trigger: 'footer',
		animation: AniFooter,
		start:  iniAniFooter+ ' 100%',
		end: iniAniFooter+ ' 0%',
		scrub: true,
		
	});




	if (document.getElementsByClassName('headerVerde').length) {
		gsap.utils.toArray('.headerVerde').forEach((box) => {
			ScrollTrigger.create({
				trigger: box,
				start: '0% 60px',
				end: '100% 60px',
				onEnter() {
					document.getElementById('header').classList.add('h-verde');
				},
				onLeave() {
					document.getElementById('header').classList.remove('h-verde');
				},
				onEnterBack() {
					document.getElementById('header').classList.add('h-verde');
				},
				onLeaveBack() {
					document.getElementById('header').classList.remove('h-verde');
				}
			});
		});
	}

	var bloque__carrousel = gsap.timeline({
		defaults: {
			duration: 1,
			ease: 'power2.out'
		}
	});
	bloque__carrousel.to(
		'.carrousel__controller',
		{
			width: '12vw'
		},
		'0'
	);
	bloque__carrousel.to(
		'.carrousel__item',
		{
			y: 0,
			stagger: 0.1
		},
		'0'
	);
	ScrollTrigger.create({
		trigger: '.bloque__carrousel',
		animation: bloque__carrousel,
		start: '0% 100%',
		end: '0% 0%'
	});
	const cursor = document.querySelector('.bloque__listado_hover');
	gsap.set(cursor, {
		x: 0,
		y: 0
	});
	document.addEventListener('mousemove', movecursorAni);
	function movecursorAni(e) {
		gsap.to(cursor, {
			duration: .4,
			x: e.clientX,
			y: e.clientY
		});
	}
	const bloque__listado = document.querySelector('.bloque__listado');
	const bloque__listado_item = document.getElementsByClassName(
		'bloque__listado_item'
	);
	const bloque__listado_hover_img = document.getElementsByClassName(
		'bloque__listado_hover_img'
	);
	if (bloque__listado) {
		bloque__listado.addEventListener('mouseover', function (e) {
			document.querySelector('.bloque__listado_hover').classList.add('active');
			hideCursor()
		});
		bloque__listado.addEventListener('mouseout', function (e) {
			document.querySelector('.bloque__listado_hover').classList.remove('active');
			showCursor()
		});
	}
	let itemActivo = '';
	for (var i = 0; i < bloque__listado_item.length; i++) {
		// Creamos el evento mouseover para cada imagen
		bloque__listado_item[i].addEventListener('mouseover', function (e) {
			if (document.getElementById(itemActivo)) {
				document.getElementById(itemActivo).classList.remove('active');
			}

			if (document.getElementById(e.toElement.id + '_img')) {
				itemActivo = e.toElement.id + '_img';
				document.getElementById(e.toElement.id + '_img').classList.add('active');
			}
		});

		bloque__listado_item[i].addEventListener('mouseout', function (e) {});
	}

	const body = document.body;
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	let lastScroll = 0;

	window.addEventListener("scroll", () => {
		const currentScroll = window.pageYOffset;
		if (currentScroll <= 0) {
		  body.classList.remove(scrollUp);
		  return;
		}
	      
		if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
		  // down
		  body.classList.remove(scrollUp);
		  body.classList.add(scrollDown);

		} else if (
		  currentScroll < lastScroll &&
		  body.classList.contains(scrollDown)
		) {
		  // up
		  body.classList.remove(scrollDown);
		  body.classList.add(scrollUp);
		}
		lastScroll = currentScroll;
	});



	//// PAGINA 404
	let div404 = document.getElementById('header__404_data');
	if(div404){
		let scale = Math.min(
			window.innerWidth / (div404.offsetWidth),    
			(window.innerHeight - 100) / (div404.offsetHeight)
		);
		div404.style.transform = "translate(-50%, -50%) " + "scale(" + scale + ")";
	}


}
export function ancla(c) {
	let st = window.innerHeight * 5 + window.innerWidth + window.innerHeight * 0.5;
	if (c != 'slsCols') {
		const targetEl = document.getElementById(c);
		const targetRect = targetEl.getBoundingClientRect();
		st = targetRect.top;
	}
	gsap.to(window, {
		scrollTo: st,
		ease: 'power2.out'
	});
	return false;
}
export function anclaTop() {
	gsap.to(window, {
		scrollTo: 0,
		ease: 'power2.out'
	});
	return false;
}