import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef, useState } from "react";
import Sizes from "../../Utils/Sizes";
import { Edges, MeshDiscardMaterial, Svg, Text, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import WindowHandler from "../../Utils/WindowHandler";
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { Wireframe } from 'three/addons/lines/Wireframe.js';
import { WireframeGeometry2 } from 'three/addons/lines/WireframeGeometry2.js';

export default function Button3d({ element }) {

    const [pageReady, setPageReady] = useState(false)
    const [buttonVisible, setButtonVisible] = useState(false)
    const button = useRef()
    const buttonGroup = useRef()
    const buttonContainer = useRef()
    const { invalidate, camera } = useThree()
    const { width, height } = Sizes()

    const [multiplierHeight, setMultiplierHeight] = useState(.00085)

    const { nodes } = useGLTF('./models/3d_button.glb')

    useEffect(() => {
        if (!pageReady) return
        if (!element.id) return
        if (!document.querySelector('#'+element.id)) return

        ScrollTrigger.create({
            trigger: '#'+element.id,
            start: -element.offsetHeight+'px 120%',
            end: '+='+element.offsetHeight+'px -20%',
            scrub: true,
            markers: true,
            onEnter() {
                setButtonVisible(true)
            },
            onEnterBack() {
                setButtonVisible(true)
            },
            onLeave() {
                setButtonVisible(false)
            },
            onLeaveBack() {
                setButtonVisible(false)
            },
            onUpdate: () => {
                const rect = element.getBoundingClientRect();
                if (rect.top) {
                    if (buttonContainer.current) {
                        buttonContainer.current.position.y = (- (rect.top / height) * 2 + 1.02) * .4
                        //buttonContainer.current.position.x = (((rect.left + (element.offsetWidth * .5)) / width) * 2 - 1) 
                        buttonContainer.current.position.x = (rect.left - window.innerWidth / 2) / window.innerWidth * 2
                        buttonContainer.current.lookAt(camera.position)
                        console.log( ((rect.left + (element.offsetWidth * .5)) /* / width */) /* * 1.5 - 1 */ )
                    }
                }
                invalidate()
            }
        });

        /* return () => {
            diamondScrollTl.kill(true)
        } */
    }, [pageReady])

    // ON PAGE LOADED
    useEffect(() => {
        const onPageLoad = () => {
          setPageReady(true);
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const [buttonColor, setButtonColor] = useState() 
    const [linesColor, setLinesColor] = useState() 

    const textMaterial = new THREE.MeshBasicMaterial({ color: linesColor })

    useEffect(() => {
        const color = element.getAttribute('color')
        switch(color) {
            case 'blanco':
                setButtonColor('#ffffff')
                setLinesColor('#141515')
                break
            case 'verde':
                setButtonColor('#9bcaa6')
                setLinesColor('#141515')
                break
            case 'negro':
                setButtonColor('#141515')
                setLinesColor('#9bcaa6')
                break
        } 
    }, [])


    const hoverOn = (e) => {
        gsap.to(buttonGroup.current.rotation, {
            x: Math.PI * .15,
            y: Math.PI * -.06,
            onUpdate: () => {
                invalidate()
            }
        })
    }
    const hoverOut = (e) => {
        gsap.to(buttonGroup.current.rotation, {
            x: 0,
            y: 0,
            onUpdate: () => {
                invalidate()
            }
        })
    }
/*     const hoverOut = (e) => {
        console.log("OUT");
        if (!buttonState) return
        gsap.to(buttonContainer.current.rotation, {
            x: 0,
            onUpdate: invalidate()
        })
        setButtonState(false)
    } */

    useEffect(() => {
        if (!pageReady) return
        if (!element) return

        if (element) {
            element.addEventListener('mouseenter', hoverOn)
            element.addEventListener('mouseleave', hoverOut)
        }

        return () => {
            if (element) {
                element.removeEventListener('mouseenter', hoverOn)
                element.removeEventListener('mouseleave', hoverOut)
            }
        }

    }, [pageReady])

    const computedX = () => {
        const rect = element.getBoundingClientRect();
        if (rect.left)
            return (rect.left / width) * 2 - 1
        else 
            return 0
    }

    const getText = () => {
        const span = element.querySelector('span')
        if (span.innerHTML)
            return span.innerHTML
        else
            return 'BOTÓN'
    }

    const getSize = () => {
        let sizeMult = .55

        if (width <= 600) {
            sizeMult = .75
        } else if (width <= 768) {
            sizeMult = .75
        } else if (width <= 1024) {
            sizeMult = .75
        } else if (width <= 1366) {
            sizeMult = .75
        } else if (width <= 1440) {
            sizeMult = .6
        }


        return (width * .0001) * sizeMult
    }

    return <>
    
        <group ref={ buttonContainer } position={ [ computedX(), -height * multiplierHeight, 0 ] } visible={ buttonVisible } >
            <group ref={ buttonGroup }  scale={ getSize() }>
                <mesh ref={ button } geometry={ nodes.DIA_Button.geometry } scale={ [ 1.7, .5, .3 ] }>
                    <meshBasicMaterial color={ buttonColor } />
                    <Edges
                        scale={1}
                        threshold={15}
                        color={ linesColor }
                    />
                </mesh>
                <mesh geometry={ nodes.DIA_Button.geometry } scale={ [ 1.7, .5, .3 ] } position={ [ 0.0005, -0.0005, 0.0005 ] }>
                    <MeshDiscardMaterial />
                    <Edges
                        scale={1}
                        threshold={15} 
                        color={ linesColor }
                    />
                </mesh>
                <mesh geometry={ nodes.DIA_Button.geometry } scale={ [ 1.7, .5, .3 ] } position={ [ -0.0005, 0.0005, 0.0005 ] }>
                    <MeshDiscardMaterial />
                    <Edges
                        scale={1}
                        threshold={15} 
                        color={ linesColor }
                    />
                </mesh>
                <Text
                    textAlign={ 'center' }
                    anchorX={ 'center' }
                    font={ "/fonts/kento-regular.woff" }
                    material={ textMaterial }
                    position={ [ 0, 0, 0.151 ] }
                    scale={ 0.12 }
                >
                    { getText() }
                </Text>
                <Text
                    textAlign={ 'center' }
                    anchorX={ 'center' }
                    font={ "/fonts/kento-regular.woff" }
                    material={ textMaterial }
                    position={ [ 0, 0, -0.151 ] }
                    rotation={ [ 0, Math.PI, Math.PI ] }
                    scale={ 0.12 }
                >
                    { getText() }
                </Text>

            </group>
        </group>

    </>
}