import { OrbitControls, useGLTF } from '@react-three/drei'
import { gsap } from 'gsap/all'
import { folder, useControls } from 'leva'
import { Perf } from 'r3f-perf'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils"
import Diamond from './Diamond'
import Effects from './Effects'
import BaseGroup from './Experience/BaseGroup'
import HiddenBases from './Experience/HiddenBases'
import HideOn from './Experience/HideOn'
import { Hud } from './Experience/Hud'
import { Lantern } from './Experience/Lantern'
import Base from './Experience/Models/Base'
import { ModelAirplane } from './Experience/Models/ModelAirplane'
import { ModelBattery } from './Experience/Models/ModelBattery'
import { ModelBuildings } from './Experience/Models/ModelBuildings'
import { ModelBullets } from './Experience/Models/ModelBullets'
import { ModelCar } from './Experience/Models/ModelCar'
import { ModelChimeneys } from './Experience/Models/ModelChimeneys'
import ModelForcep from './Experience/Models/ModelForcep'
import { ModelForest } from './Experience/Models/ModelForest'
import { ModelLaboratory } from './Experience/Models/ModelLaboratory'
import { ModelLoupe } from './Experience/Models/ModelLoupe'
import { ModelMine } from './Experience/Models/ModelMine'
import { ModelMinecart } from './Experience/Models/ModelMinecart'
import { ModelMiner } from './Experience/Models/ModelMiner'
import { ModelOpenSkyMine } from './Experience/Models/ModelOpenSkyMine'
import { ModelPortal } from './Experience/Models/ModelPortal'
import { ModelRobot } from './Experience/Models/ModelRobot'
import { ModelScientist } from './Experience/Models/ModelScientist'
import { ModelTestTubes } from './Experience/Models/ModelTestTubes'
import { ModelWindmills } from './Experience/Models/ModelWindmills'
import { PathingScene } from './Experience/Models/PathingScene'
import Terrain, { ThirdFloor } from './Experience/Terrain'
import { TextRevealer } from './Experience/TextRevealer'
import modelsInfo from './Experience/modelsInfo'
import TextureManager from './TextureManager'
import Sizes from './Utils/Sizes'
import WindowHandler from './Utils/WindowHandler'
import { iOS } from './Utils/iOS'

let experience = true
if(window.location.hash === '#dev') {
  experience = false
}
let debug = false
if(window.location.hash === '#debug') {
  debug = true
}
const masterVolume = .25

function Experience({ start, canStart, progress, showWarning }) {
  document.body.classList.add("bodyExperiencia")
  // Debug controls
  const config = useControls('Experiencia', {
    Z_modelsOffset: { value: 56, min: 0, max: 100, step: .1 },
    X_modelsOffset: { value: 16, min: 0, max: 100, step: .1 },
    pages: { value: 56, min: 1, max: 200, step: 1 },
    FPS: true
  })
  const configElements = useControls('Modelos', {
    speed: { value: .3, min: 0, max: 1, step: .01 },
    'Mina': folder({
      xAxis1: { value: 0, min: -150, max: 150, step: .01 },
      zAxis1: { value: /* -37.70 */-67.36, min: -150, max: 150, step: .01 },
      scale1: { value: 2.52, min: 0, max: 10, step: .001 },
    }),
    'Chimeneas': folder({
      rot2: { value: -0.30, min: -1, max: 1, step: .01 },
      xAxis2: { value: -42.10, min: -150, max: 150, step: .01 },
      zAxis2: { value: -13.20, min: -150, max: 150, step: .01 },
      scale2: { value: 1.19, min: 0, max: 10, step: .001 },
    }),
    'Minero': folder({
      rot3: { value: -0.73, min: -1, max: 1, step: .01 },
      xAxis3: { value: 34.50, min: -150, max: 150, step: .01 },
      zAxis3: { value: -7.49, min: -150, max: 150, step: .01 },
      scale3: { value: 0.6, min: 0, max: 10, step: .001 },
    }),
    'Mina cielo abierto': folder({
      rot4: { value: 0, min: -1, max: 1, step: .01 },
      xAxis4: { value: -44.30, min: -150, max: 150, step: .01 },
      zAxis4: { value: 3.20, min: -150, max: 150, step: .01 },
      scale4: { value: 1.19, min: 0, max: 10, step: .001 },
    }),
    'Vagoneta': folder({
      rot5: { value: -0.72, min: -1, max: 1, step: .01 },
      xAxis5: { value: -94.01, min: -150, max: 150, step: .01 },
      zAxis5: { value: 38.70, min: -150, max: 150, step: .01 },
      scale5: { value: 1.17, min: 0, max: 10, step: .001 },
    }),
    'Balas': folder({
      rot6: { value: 0.14, min: -1, max: 1, step: .01 },
      xAxis6: { value: 114.90, min: -150, max: 150, step: .01 },
      zAxis6: { value: -28.20, min: -150, max: 150, step: .01 },
      scale6: { value: 1.18, min: 0, max: 10, step: .001 },
    }),
    'Coche': folder({
      rot7: { value: -0.32, min: -1, max: 1, step: .01 },
      xAxis7: { value: -83.00, min: -250, max: 250, step: .01 },
      zAxis7: { value: -76.30, min: -250, max: 250, step: .01 },
      scale7: { value: 2.18, min: 0, max: 10, step: .001 },
    }),
    'Brazo robótico': folder({
      rot8: { value: -0.28, min: -1, max: 1, step: .01 },
      xAxis8: { value: -37.38, min: -250, max: 250, step: .01 },
      zAxis8: { value: -33.93, min: -250, max: 250, step: .01 },
      scale8: { value: 1.05, min: 0, max: 10, step: .001 },
    }),
    'Edificios': folder({
      rot9: { value: -0.76, min: -1, max: 1, step: .01 },
      xAxis9: { value: 39.30, min: -250, max: 250, step: .01 },
      zAxis9: { value: -40.94, min: -250, max: 250, step: .01 },
      scale9: { value: 2.02, min: 0, max: 10, step: .001 },
    }),
    'Avión': folder({
      rot10: { value: 0.54, min: -1, max: 1, step: .01 },
      xAxis10: { value: 91.55, min: -250, max: 250, step: .01 },
      zAxis10: { value: -89.69, min: -250, max: 250, step: .01 },
      scale10: { value: 2.09, min: 0, max: 10, step: .001 },
    }),
    'Portal': folder({
      zPeanas: { value: 270.07, min: 0, max: 400, step: .01 },
      zPortal: { value: 286.72, min: 0, max: 400, step: .01 },
    }),
  })
  const configTexts = useControls('Textos', {
    'Mina': folder({
      pos1: { x: 0, y: -0.4, z: 15 },
      rot1: { x: 0, y: 0, z: 0 },
      scale1: { value: 0.61, min: 0, max: 10, step: .001 },
    }),
    'Chimeneas': folder({
      pos2: { x: 0.2, y: 5.7, z: -14.1 },
      rot2: { x: 0, y: 4.65, z: 0 },
      scale2: { value: 0.70, min: 0, max: 10, step: .001 },
    }),
    'Minero': folder({
      pos3_1: { x: -12.0, y: 11, z: 29.4 },
      rot3_1: { x: 0, y: 1.76, z: 0 },
      pos3_2: { x: 2, y: 4, z: 5 },
      rot3_2: { x: 0, y: 1.35, z: 0 },
      scale3: { value: 1.8, min: 0, max: 10, step: .001 },
    }),
    'Mina Cielo Abierto': folder({
      pos4_1: { x: 9, y: 3.9, z: 15.5 },
      rot4_1: { x: 0, y: -1.51, z: 0 },
      pos4_2: { x: -2, y: 3, z: 9 },
      rot4_2: { x: 0, y: -1.35, z: 0 },
      scale4: { value: 0.82, min: 0, max: 10, step: .001 },
    }),
    'Balas': folder({
      pos5_1: { x: -3.1, y: 5.98, z: 17.17 },
      rot5_1: { x: 0, y: 1.38, z: 0 },
      pos5_2: { x: 2, y: 4, z: 9 },
      rot5_2: { x: 0, y: 1.35, z: 0 },
      scale5: { value: 0.95, min: 0, max: 10, step: .001 },
    }),
    'Vagoneta': folder({
      pos6_1: { x: 1.7, y: 5, z: -18.8 },
      rot6_1: { x: 0, y: -1.65, z: 0 },
      pos6_2: { x: -5.2, y: 2, z: -9 },
      rot6_2: { x: 0, y: -1.6, z: 0 },
      scale6: { value: 0.92, min: 0, max: 10, step: .001 },
    }),
  })
  const configDiamond = useControls('Diamante (refracción)', {
    size: { value: 1.03, min: .001, max: 3, step: .001 },
    yPos: { value: 5.49, min: -10, max: 10, step: .001 },
    height: { value: -1.6, min: -20, max: 20, step: .1 },
    rotSpeed: { value: .1, min: 0, max: .8, step: .001 },
    rotForce: { value: 1, min: 0, max: 5, step: .001 },
  })
  const configTerrain = useControls('Terreno', {
    'Primer terreno': folder({
      longitud_1: { value: 404.0, min: 0, max: 500 , step: .5 },
      anchura_1: { value: 1000, min: 0, max: 1000 , step: .5 },
      pos_x_1: { value: 0, min: -100, max: 100 , step: .5 },
      pos_y_1: { value: 0, min: -100, max: 100 , step: .5 },
      pos_z_1: { value: -29, min: -40, max: 40 , step: .5 },
    }),
    'Segundo terreno': folder({
      longitud_2: { value: 87.0, min: 0, max: 100 , step: .5 },
    }),
    'Tercer terreno': folder({
      longitud_3: { value: 100, min: 0, max: 100 , step: .5 },
    }),
  })
  
  const portalZPosition = configElements.zPortal
  const hiddenBasesZPosition = configElements.zPeanas



  // Geometries
  const planeGeometry = useMemo(() => new THREE.PlaneGeometry( 1, 1 ), [])
  const boxGeometry = useMemo(() => new THREE.BoxGeometry(1, 1, 1), [])
  const tallBoxGeometry = useMemo(() => new THREE.BoxGeometry(1, 2, 1), [])
  const dome = useGLTF("/models/Dome.glb");
  const geom = useMemo(() => {
    let mergedGeometries = new THREE.BoxGeometry(0, 0, 0)

    let box = new THREE.BoxGeometry(1.4, 1, 1.2)
    let plane = new THREE.PlaneGeometry(.8, .3)
    plane.translate(0, -.27, 2)
    let merged = BufferGeometryUtils.mergeBufferGeometries([mergedGeometries, plane, box])
    return merged
  }, [])
  


  // Colors
  const colorConfig = useControls('Color', {
    'Negativo': folder({
      elementos_showed: '#373937',
      floor_showed: '#242524',
      background_showed: '#121111',
    }),
    'Positivo': folder({
      elementos_hidden: '#a1e2b0',
      floor_hidden: '#a1e2b0',
      floor_hidden2: '#8ec79b',
      background_hidden: '#b2e1bd',
      light: '#b2e1bd'
    }),
  })



  // Materials
  const invisibleMaterial = useMemo(() => new THREE.MeshBasicMaterial().opacity = 0, [])

  const standardMaterialShow = useMemo(() => new THREE.MeshMatcapMaterial({ color: colorConfig.floor_showed }), [])

  const standardMaterialFloorHidden = useMemo(() => new THREE.MeshMatcapMaterial({ color: colorConfig.floor_hidden }), [])

  const standardMaterialFloorHiddenStatic = useMemo(() => new THREE.MeshMatcapMaterial( { color: colorConfig.floor_hidden } ), [])
  const standardMaterialFloorHiddenStatic2 = useMemo(() => new THREE.MeshMatcapMaterial( { color: colorConfig.floor_hidden2 } ), [])
  
  const standardBackgroundMaterialShow = useMemo(() => new THREE.MeshBasicMaterial( { color: colorConfig.background_showed, side: THREE.BackSide } ), [])
  const standardBackgroundMaterialHide = useMemo(() => new THREE.MeshBasicMaterial( { color: colorConfig.background_hidden, side: THREE.BackSide } ), [])

  const textMaterialNegative = new THREE.MeshBasicMaterial({ color: '#9bcaa6', transparent: true })
  const textMaterialPositive = new THREE.MeshBasicMaterial({ color: '#141515', transparent: true })


  // References
  const baseHidden1 = useRef()
  const volume = useRef(true)
  const BSO = useRef()
  const screenWidth = WindowHandler().width 
  const isTouch = WindowHandler().isTouch
  const isTablet = screenWidth <= 1024 && isTouch
  const isMobilePortrait = screenWidth <= 600 && screenWidth < WindowHandler().height && isTouch



  // Texture loader
  const textureManager = new TextureManager(modelsInfo, { negative: colorConfig.elementos_showed, positive: colorConfig.elementos_hidden }, { negative: colorConfig.floor_showed, positive: colorConfig.floor_hidden }, isTouch)
  const modelTextures = textureManager.loadTextures()



  // Hovered state
  const hovered = useRef(false)
  const [hoveredState, setHoveredState] = useState(false)
  const [hoveredSound, setHoveredSound] = useState(false)
  const hoveredItem = useRef(0)
  const setHover = (bool, item, boolSound) => {
    if (bool === false) 
    hovered.current = bool
    if (expStep !== item) return
    setHoveredState(bool)
    hoveredItem.current = item
    hovered.current = bool
    //setHoveredSound(boolSound)
  }



  // Cursor
  const cursor = {}
  cursor.x = 0
  cursor.y = 0
  const { height, width } = Sizes();

  window.addEventListener('mousemove', (e) => {
    cursor.x = e.clientX / width - .5
    cursor.y = e.clientY / height - .5
  })



  // Start experience animation
  const [experienceState, setExperienceState] = useState(0) // 0 = Waiting for input | 1 = Playing initial animation | 2 = Scroll experience
  const [matrixAutoUpdate, setMatrixAutoUpdate] = useState(true) 

  const initialAnimation = () => {
    const dur = 3
    setExperienceState(1)
    
    const e = { e: 0 }
    gsap.to(e, {
      e: 1,
      duration: dur,
      onComplete: function() {
        setExperienceState(2)

        if (document.querySelector('.hud__scroll'))
          document.querySelector('.hud__scroll').classList.remove('hide-scroll-warning')
        /* if (document.querySelector('.mobile-scroll-advice'))
          document.querySelector('.mobile-scroll-advice').classList.remove('hide-scroll-warning') */
        const p = { p: 0 }
        gsap.to(p, {
          p: 1,
          duration: 2, 
          onComplete: () => {
            setHiddenBases(false)
          }
        })
      }
    })
  }

  const expMoving = useRef(true)
  const positivePart = useRef(false)
  const hiddenBases = useRef(true)
  const [positivePartState, setPositivePartState] = useState(false)
  const [expStep, setExpStep] = useState(0)
  const [expStep_PREV, setExpStep_PREV] = useState(0)
  const loupeActive = useRef(false)

  const setPositivePart = (type) => {
    positivePart.current = type
    setPositivePartState(type)
    if (type) {
      document.querySelector('html').classList.add('positive-part')
    } else {
      document.querySelector('html').classList.remove('positive-part')
    }
  }
  const setHiddenBases = (type) => {
    hiddenBases.current = type
  }
  
  const stepBack = () => {
    if (expStep > 0 && expMoving.current === false) {
      setExpStep(expStep - 1)
      setExpStep_PREV(expStep)
      expMoving.current = true
      setHover(false)
      document.body.classList.add('camera-moving')
    }
  }
  const stepsAmmount = (isTouch) ? 15 : 11
  const stepForward = () => {
    if (expStep < stepsAmmount && expMoving.current === false) {
      setExpStep(expStep + 1)
      setExpStep_PREV(expStep)
      expMoving.current = true
      setHover(false)
      if (expStep < 6 && dianelumnizer === true) {
        setDianelumnizer(false)
        if (isTouch)
          document.body.classList.remove('dianelumnizer')
      }
      document.body.classList.add('camera-moving')
    }
  }

  

  
  useEffect(() => {
    var xDown = null;                                                        
    var yDown = null;

    function getTouches(evt) {
      return evt.touches ||        // browser API
        evt.originalEvent.touches; // jQuery
    }                                                     
                                                                          
    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];                                      
      xDown = firstTouch.clientX;                                      
      yDown = firstTouch.clientY;                                      
    };                                                
                                                                          
    function handleTouchMove(evt) {
      if ( ! xDown || ! yDown ) {
        return;
      }

      var xUp = evt.touches[0].clientX;                                    
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
                                                                          
      if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
          /* right swipe */ 
        } else {
          /* left swipe */
        }                       
      } else {
        if (experienceState === 3) {
          if ( yDiff > 0 ) {
            stepForward();
            document.querySelector('.mobile-scroll-advice').classList.add('hide-scroll-warning')
          } else { 
            stepBack();
            document.querySelector('.mobile-scroll-advice').classList.add('hide-scroll-warning')
          }                                                                 
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;                                             
    };
    if (isTouch) {
      document.addEventListener('touchstart', handleTouchStart, false);        
      document.addEventListener('touchmove', handleTouchMove, false);
    }  

    return () => {
      document.removeEventListener('touchstart', handleTouchStart, false);        
      document.removeEventListener('touchmove', handleTouchMove, false);
    }
  }, [expStep, experienceState])

  const changeVolume = (bool) => {
    if (!BSO.current) return

    if (volume.current) {
      volume.current = false
      BSO.current.muted = true
    } else {
      volume.current = true
      BSO.current.muted = false
    }
    if (bool === true || bool === false) {
      volume.current = bool
      BSO.current.muted = !bool
    }
  }

  useEffect(() => {
    return () => {
      changeVolume(false)
      if (iOS()) {
        BSO.current.muted = true
      }
      document.body.classList.remove("bodyExperiencia")
    }
  }, [])

  const callBackFunction = () => {
    document.body.classList.remove('camera-moving')
    expMoving.current = false
    if (document.querySelector('.hud__scroll'))
      document.querySelector('.hud__scroll').classList.remove('hide-scroll-warning')
    if (document.querySelector('.mobile-scroll-advice'))
      document.querySelector('.mobile-scroll-advice').classList.remove('hide-scroll-warning')
    if (isTouch)
      document.querySelector('.light-switch').classList.add('waiting')
  }



  useEffect(() => {
    if (expStep === 0) {
      document.querySelector('html').classList.add('first-step')
    } else {
      document.querySelector('html').classList.remove('first-step')
    }
    const tempF = (e) => {
      if (e.wheelDeltaY < 0 && e.deltaY > 160 && experienceState === 3) {
        stepForward()
        document.querySelector('.hud__scroll').classList.add('hide-scroll-warning')
      }
      if (e.wheelDeltaY > 0 && e.deltaY < -160 && experienceState === 3) {
        stepBack()
        document.querySelector('.hud__scroll').classList.add('hide-scroll-warning')
      }
    }
    if (!isTouch)
    window.addEventListener("wheel", tempF)
    return () => {
      if (!isTouch)
      window.removeEventListener("wheel", tempF)
    }
  }, [expStep, experienceState])


  if (WindowHandler().isTouch) {
    document.querySelector('html').classList.add('is-touch')
  }


  const delayMine = 1
  const delayChimeneys = 3
  const delayMiner = 3
  const delayOpenSkyMine = 3
  const delayBullets = 3
  const delayMinecart = 3


  useEffect(() => {
    if (progress === 100) {
      setExperienceState(1)
      initialAnimation()
    }
  }, [progress])

  const [iosMusic, setIosMusic] = useState(false)

  useEffect(() => {
    const touchStartListener = () => {
      if (iosMusic == false && iOS()) {
        setIosMusic(true)
        window.removeEventListener("touchstart", touchStartListener)
        BSO.current = new Audio('/sounds/bso.mp3')
        BSO.current.volume = masterVolume
        BSO.current.play()
      }
    }

    window.addEventListener("touchstart", touchStartListener)
    return () => {
      window.removeEventListener("touchstart", touchStartListener)
    }
  }, [])

  useEffect(() => {
    const touchStartListener = () => {
      if (iOS()) {
        /* volume.current = false */
        BSO.current.muted = true
      }
    }
    if (start && document.querySelector('.hud__speaker')) {
      document.querySelector('.hud__speaker').addEventListener("touchstart", touchStartListener)
    }

    return () => {
      if (document.querySelector('.hud__speaker')) {
        document.querySelector('.hud__speaker').removeEventListener("touchstart", touchStartListener)
      }
    }
  }, [start])

  function playAudio(){
    return new Promise(res=>{
      BSO.current.volume = 0
        BSO.current.loop = true
        BSO.current.play()
        gsap.to(BSO.current, {
          volume: .25,
          duration: 1,
        })
    })
  }

  async function playMusic() {
    BSO.current = new Audio('/sounds/bso.mp3')
    await playAudio()
  }

  useEffect(() => {
    if (start && experienceState == 2) {
      const revAudio = new Audio('/sounds/revealer.mp3')
      document.body.classList.add('start-clicked')
      setTimeout(() => {
        if (!isTouch) {
          const p = {p:0}
          gsap.to(p, {p: 1, duration: 4, onComplete: () => {
            revAudio.play()
          }})
        }
        setExperienceState(3)
        setMatrixAutoUpdate(false)

        if (isTouch) {
          setTimeout(() => {
            dianOn()
            setTimeout(() => {
              dianOff()
              if (isTouch)
                document.querySelector('.light-switch').classList.add('waiting')
            }, 2000);
          }, 3400);
        }
        setTimeout(() => {
          document.body.classList.add('can-interact')
        }, 4000);
      }, 2000);
      if (!iOS()) {
        playMusic()
      }
    }
  }, [start])

  const canStartAll = () => {
    canStart()
  }

  const [dianelumnizer, setDianelumnizer] = useState(false)

  const dianOn = () => {
    setDianelumnizer(true)
    if (isTouch)
    document.body.classList.add('dianelumnizer')
  }
  const dianOff = () => {
    setDianelumnizer(false)
    if (isTouch)
    document.body.classList.remove('dianelumnizer')
  }

  const toggleDianelumnizer = () => {
    setDianelumnizer(!dianelumnizer)
    if (isTouch)
    document.body.classList.toggle('dianelumnizer')
  }

  // Check if it's CPU or GPU bound //
  // const {scene} = useThree()
  // scene.overrideMaterial = new THREE.MeshBasicMaterial({ color: "green" });

  return (
    <>
      {modelTextures && <>

      { debug && <Perf matrixUpdate position='top-left' /> }
      <Effects />
      {!experience && <OrbitControls makeDefault minDistance={ 6 } />}

        <Diamond
          expStep={ expStep }
          expStep_PREV={ expStep_PREV }
          position={ [ 0, 3, -7.75/* -30 */ ] }
          rotation={ [ Math.PI * .5, 0, 0 ] }
          scale={ configDiamond.size }
          configDiamond={ configDiamond }
          standardBackgroundMaterialShow={ standardBackgroundMaterialShow }
          domeGeometry={ dome.nodes.Cylinder.geometry }
          negative={ true }
          positivePart={ positivePart }
          positivePartState={ positivePartState }
          dianelumnizer={ dianelumnizer }
        />
        
        <PathingScene scale={ 150 } position={ [ 0, 0, 150 ] } 
          experience={ experience } 
          animation={ experienceState >= 2 }
          renderEverything={ experienceState == 1 }
          canRender={ () => canStartAll() }
          expStep_PREV={ expStep_PREV } 
          expStep={ expStep } 
          endCallback={ () => callBackFunction() } 
          loupeTrue={ () => loupeActive.current = true } 
          loupeFalse={ () => loupeActive.current = false }
          stepBack={ () => stepBack() }
          stepForward={ () => stepForward() }
          volume={ volume }
        >

          { experienceState > 0 && 
          <Lantern show={ experienceState === 3 } cursor={ cursor } expStep={ expStep } expStep_PREV={ expStep_PREV } hovered={ hovered } setPositivePart={ setPositivePart } setHiddenBases={ setHiddenBases } blend={ expStep >= 9 } dianelumnizer={ dianelumnizer } turnDianelumnizerOn={ () => dianOn() } turnDianelumnizerOff={ () => dianOff() }>
            <Suspense>
              <Diamond
                expStep={ expStep }
                expStep_PREV={ expStep_PREV }
                position={ [ 0, 3, -7.75/* -30 */ ] }
                rotation={ [ Math.PI * .5, 0, 0 ] }
                scale={ configDiamond.size }
                configDiamond={ configDiamond }
                standardBackgroundMaterialShow={ standardBackgroundMaterialHide }
                domeGeometry={ dome.nodes.Cylinder.geometry }
                dianelumnizer={ dianelumnizer }
              />
                
                
                <ModelLoupe materials={ modelTextures[18] } scale={ 1.14 } cursor={ cursor } loupeActive={ loupeActive } expStep={ expStep } />

                
                <HideOn on={ expStep >= 8 } delay={ 4 }>
                  <BaseGroup
                    positionX={ 0 + configElements.xAxis1 }
                    positionZ={ -12 + configElements.zAxis1 }
                    scale={ configElements.scale1 }
                  >
                    <Base>
                      <ModelLaboratory materials={ modelTextures[1] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos1.x, configTexts.pos1.y + .3, configTexts.pos1.z ] }
                      rotation={ [ configTexts.rot1.x, configTexts.rot1.y, configTexts.rot1.z ] }
                      scale={ configTexts.scale1 }
                    >
                      <TextRevealer 
                        text={ ['Para entendernos...'] } 
                        trigger={ expStep === 0 && experienceState === 3 }
                        position={ [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.2 : .7 }
                        negative={ false }
                        pretitle
                        delay={ delayMine }
                        material={ textMaterialPositive }
                      />
                      <TextRevealer 
                        text={ ['POR EL OTRO,','DIANELUM.'] } 
                        trigger={ expStep === 0 && experienceState === 3 }
                        position={ [ 0, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        negative={ false }
                        delay={ delayMine }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <Suspense>

                  <BaseGroup
                    positionX={ -config.X_modelsOffset + configElements.xAxis2 }
                    positionZ={ .5 * config.Z_modelsOffset + configElements.zAxis2 }
                    scale={ configElements.scale2 }
                  >
                    <Base
                      spinSpeed={ configElements.speed }
                      yStartRotationPoint={ Math.PI * configElements.rot2 }
                    >
                      <ModelWindmills materials={ modelTextures[3] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos2.x, configTexts.pos2.y, configTexts.pos2.z ] }
                      rotation={ [ configTexts.rot2.x, configTexts.rot2.y, configTexts.rot2.z ] }
                      scale={ configTexts.scale2 }
                    >
                      <TextRevealer
                        text={ ['Huella de Carbono.'] }
                        trigger={ expStep === 1 }
                        position={ isMobilePortrait ? [ 4, 1.5, 0 ] : [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.25 : .55 }
                        negative={ false }
                        align={ 'left' }
                        delay={ delayChimeneys }
                        pretitle
                        material={ textMaterialPositive }
                      />
                      <TextRevealer
                        text={ ['LA DE DIANELUM,','UN CERO EN HUELLA','DE CARBONO.'] } 
                        trigger={ expStep === 1 }
                        position={ isMobilePortrait ? [ 4, 0, 0 ] : [ 0, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        align={ 'left' }
                        negative={ false }
                        delay={ delayChimeneys }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <BaseGroup 
                    positionX={ config.X_modelsOffset + configElements.xAxis3 }
                    positionZ={ 1 * config.Z_modelsOffset + configElements.zAxis3 }
                    scale={ configElements.scale3 }
                  >
                    <Base
                      spinSpeed={ configElements.speed }
                      yStartRotationPoint={ Math.PI * configElements.rot3 }
                    >
                      <ModelScientist materials={ modelTextures[5] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos3_1.x, configTexts.pos3_1.y, configTexts.pos3_1.z ] }
                      rotation={ [ configTexts.rot3_1.x, configTexts.rot3_1.y, configTexts.rot3_1.z ] }
                      scale={ configTexts.scale3 }
                    >
                      <TextRevealer
                        text={ ['Distintas procedencias.'] } 
                        trigger={ expStep === 2 }
                        position={ [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.25 : .6 }
                        negative={ false }
                        delay={ delayMiner }
                        pretitle
                        align={ 'left' }
                        material={ textMaterialPositive }
                      />
                      <TextRevealer
                        text={ ['DIANELUM UTILIZA','CARBONOS EXCLUSIVOS','VINCULADOS A MARCAS.'] } 
                        trigger={ expStep === 2 }
                        position={ [ -.25, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        negative={ false }
                        align={ 'left' }
                        delay={ delayMiner }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <BaseGroup
                    positionX={ -config.X_modelsOffset + configElements.xAxis4 }
                    positionZ={ 1.5 * config.Z_modelsOffset + configElements.zAxis4 }
                    scale={ configElements.scale4 }
                  >
                    <Base
                      spinSpeed={ configElements.speed }
                      yStartRotationPoint={ Math.PI * configElements.rot4 }
                    >
                      <ModelForest materials={ modelTextures[7] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos4_1.x, configTexts.pos4_1.y, (isTablet) ? 14.5 : configTexts.pos4_1.z ] }
                      rotation={ [ configTexts.rot4_1.x, configTexts.rot4_1.y, configTexts.rot4_1.z ] }
                      scale={ configTexts.scale4 }
                    >
                      <TextRevealer
                        text={ ['Evitando la minería.'] } 
                        trigger={ expStep === 3 }
                        position={ [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.25 : .6 }
                        negative={ false }
                        align={ 'right' }
                        pretitle
                        delay={ delayOpenSkyMine }
                        material={ textMaterialPositive }
                      />
                      <TextRevealer
                        text={ (isTablet) ? ['DIANELUM','CONTRIBUYE A','PRESERVARLOS Y','HACERLOS','PROLIFERAR.'] : ['DIANELUM CONTRIBUYE','A PRESERVARLOS Y','HACERLOS','PROLIFERAR.'] } 
                        trigger={ expStep === 3 }
                        position={ [ 0, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        negative={ false }
                        align={ 'right' }
                        delay={  delayOpenSkyMine }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <BaseGroup
                    positionX={ -config.X_modelsOffset + configElements.xAxis6 }
                    positionZ={ 2.5 * config.Z_modelsOffset + configElements.zAxis6 }
                    scale={ configElements.scale6 }
                  >
                    <Base
                      spinSpeed={ configElements.speed }
                      yStartRotationPoint={ Math.PI * configElements.rot6 }
                    >
                      <ModelTestTubes materials={ modelTextures[11] } bubblesColor={ colorConfig.elementos_hidden } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos5_1.x, configTexts.pos5_1.y, configTexts.pos5_1.z ] }
                      rotation={ [ configTexts.rot5_1.x, configTexts.rot5_1.y, configTexts.rot5_1.z ] }
                      scale={ configTexts.scale5 }
                    >
                      <TextRevealer
                        text={ ['Desarrollo y futuro.'] }
                        trigger={ expStep === 4 }
                        position={ isMobilePortrait ? [ 2, 1.5, 0 ] : [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.25 : .6 }
                        negative={ false }
                        pretitle
                        align={ 'left' }
                        delay={ delayBullets }
                        material={ textMaterialPositive }
                      />
                      <TextRevealer
                        text={ ['DIANELUM ESTIMULA,','EL DESARROLLO,','LA INVESTIGACIÓN','Y EL FUTURO GLOBAL.'] }
                        trigger={ expStep === 4 }
                        negative={ false }
                        position={ isMobilePortrait ? [ 2, 0, 0 ] : [ 0, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        align={ 'left' }
                        delay={ delayBullets }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <BaseGroup
                    positionX={ config.X_modelsOffset + configElements.xAxis5 }
                    positionZ={ 2 * config.Z_modelsOffset + configElements.zAxis5 }
                    scale={ configElements.scale5 }
                  >
                    <Base
                      spinSpeed={ configElements.speed }
                      yStartRotationPoint={ Math.PI * configElements.rot5 }
                    >
                      <ModelBattery materials={ modelTextures[9] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
                    </Base>
                    <group
                      position={ [ configTexts.pos6_1.x, configTexts.pos6_1.y, (isTablet) ? -15 :configTexts.pos6_1.z ] }
                      rotation={ [ configTexts.rot6_1.x, configTexts.rot6_1.y, configTexts.rot6_1.z ] }
                      scale={ configTexts.scale6 }
                    >
                      <TextRevealer
                        text={ ['Energía limpia.'] }
                        trigger={ expStep === 5 }
                        position={ [ 0, 1.5, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        scale={ (isTouch) ? 1.5 : .6 }
                        negative={ false }
                        pretitle
                        align={ 'left' }
                        delay={ delayMinecart }
                        material={ textMaterialPositive }
                      />
                      <TextRevealer
                        text={ (isTablet) ? ['DIANELUM, EL','USO EXCLUSIVO','DE ENERGÍAS','SOSTENIBLES.'] : ['DIANELUM,','EL USO EXCLUSIVO DE','SOSTENIBLES.'] }
                        position={ [ 0, 0, 0 ] }
                        rotation={ [ 0, 0, 0 ] }
                        trigger={ expStep === 5 }
                        negative={ false }
                        align={ 'left' }
                        delay={ delayMinecart }
                        material={ textMaterialPositive }
                      />
                    </group>
                  </BaseGroup>

                  <HiddenBases visible={ hiddenBases } position={ [ 0, 0, hiddenBasesZPosition ] }>
                        
                    <BaseGroup
                      positionX={ configElements.xAxis7 }
                      positionZ={ configElements.zAxis7 }
                      scale={ configElements.scale7 } 
                      ref={ baseHidden1 }
                    >
                      <Base yStartRotationPoint={ Math.PI * configElements.rot7 }>
                          <ModelCar matrixAutoUpdate={ matrixAutoUpdate } materials={ modelTextures[15] } colors={ { element: 0x446d52, light: colorConfig.light } } elementColor={ colorConfig.elementos_hidden } positivePart={ positivePart } positivePartState={ positivePartState } expStep={ expStep } expStep_PREV={ expStep_PREV } />
                      </Base> 
                    </BaseGroup>  

                    <BaseGroup
                      positionX={ configElements.xAxis8 } 
                      positionZ={ configElements.zAxis8 }
                      scale={ configElements.scale8 }
                    >
                      <Base yStartRotationPoint={ Math.PI * configElements.rot8 }>
                        <ModelRobot matrixAutoUpdate={ matrixAutoUpdate } materials={ modelTextures[14] } colors={ { element: 0x446d52, light: colorConfig.light } } elementColor={ colorConfig.elementos_hidden } positivePart={ positivePart } positivePartState={ positivePartState } expStep={ expStep } expStep_PREV={ expStep_PREV } />
                      </Base>
                    </BaseGroup>

                    <BaseGroup
                      positionX={ configElements.xAxis9 }
                      positionZ={ configElements.zAxis9 }
                      scale={ configElements.scale9 }
                    > 
                      <Base yStartRotationPoint={ Math.PI * configElements.rot9 }>
                        <ModelBuildings matrixAutoUpdate={ matrixAutoUpdate } materials={ modelTextures[16] } colors={ { element: 0x446d52, light: colorConfig.light } } elementColor={ colorConfig.elementos_hidden } positivePart={ positivePart } positivePartState={ positivePartState } expStep={ expStep } expStep_PREV={ expStep_PREV } />
                      </Base>
                    </BaseGroup>

                    <BaseGroup
                      positionX={ configElements.xAxis10 }
                      positionZ={ configElements.zAxis10 }
                      scale={ configElements.scale10 }
                    >
                      <Base yStartRotationPoint={ Math.PI * configElements.rot10 }>
                        <ModelAirplane matrixAutoUpdate={ matrixAutoUpdate } materials={ modelTextures[13] } colors={ { element: 0x446d52, light: colorConfig.light } } elementColor={ colorConfig.elementos_hidden } positivePart={ positivePart } positivePartState={ positivePartState } expStep={ expStep } expStep_PREV={ expStep_PREV } />
                      </Base>
                    </BaseGroup>

                  </HiddenBases>

                  <ModelPortal
                    matrixAutoUpdate={ matrixAutoUpdate }
                    materials={ modelTextures[12] }
                    position-z={ portalZPosition }
                    position-y={ .01 } 
                    scale={ 1.8 }
                    cableColor={ { positive: colorConfig.elementos_hidden, negative: colorConfig.elementos_showed } }
                    negative={ false }
                    expStep={ expStep }
                    positivePartState={ positivePartState }
                    positivePart={ positivePart }
                    lightColor={ colorConfig.light }
                  />

                  <group position={ [ 0, 27, 330 ] }>
                    <TextRevealer
                      text={ ['Superconductor'] }
                      trigger={ expStep === 6 }
                      position={ [ 0, 2, 0 ] }
                      rotation={ [ 0, 0, 0 ] }
                      scale={ (isTouch) ? 2.25 : 1.2 }
                      negative={ false }
                      pretitle
                      delay={ 8 }
                      material={ textMaterialPositive }
                      align={ 'center' }
                    />
                    <TextRevealer
                      text={ isMobilePortrait ? 
                        ['LO QUE HASTA AHORA SE,','DESECHABA AHORA ES EL','SUPERCONDUCTOR DEL FUTURO.', 'LA ENERGÍA VERDE DEFINITIVA.'] :
                        ['LO QUE HASTA AHORA SE DESECHABA,','AHORA ES EL SUPERCONDUCTOR DEL FUTURO.','LA ENERGÍA VERDE DEFINITIVA.']
                      }
                      position={ [ 0, 0, 0 ] }
                      rotation={ [ 0, 0, 0 ] }
                      trigger={ expStep === 6 }
                      negative={ false }
                      delay={ 8 }
                      scale={ (isTouch) ? 1.3 : 1.2 }
                      delayCallback={ (expStep_PREV === 7) ? 2 : 6 }
                      lineHeight={ 1.25 }
                      endCallback={ () => callBackFunction() }
                      material={ textMaterialPositive }
                      align={ 'center' }
                    />
                  </group>

                  <mesh
                    matrixAutoUpdate={ matrixAutoUpdate }
                    position={ [ configTerrain.pos_x_1, configTerrain.pos_y_1, configTerrain.longitud_1 / 2 + configTerrain.pos_z_1 - 200 ] }
                    material={ standardMaterialFloorHidden }
                    geometry={ planeGeometry }
                    scale={ [ configTerrain.anchura_1, configTerrain.longitud_1 + 400, 1 ] }
                    rotation={ [ -Math.PI * .5, 0, 0 ] }
                  />

                  </Suspense>

                </HideOn>







                <group position={ [ 0, -22, 413 ] }>
                  <TextRevealer
                    text={ ['100% calidad original.'] }
                    trigger={ expStep === 8 }
                    position={ [ 0, (isTouch) ? 1.9 : 1.5, 0 ] }
                    rotation={ [ 0, 0, 0 ] }
                    scale={ (isTouch) ? 1.9 : 1.2 }
                    negative={ false }
                    pretitle
                    align={ 'center' }
                    delay={ 4.5 }
                    material={ textMaterialPositive }
                  />
                  <TextRevealer
                    text={ ['EL MISMO DIAMANTE,','LO MIRES POR DONDE LO MIRES.'] }
                    trigger={ expStep === 8 }
                    negative={ false }
                    position={ [ 0, 0, 0 ] }
                    rotation={ [ 0, 0, 0 ] }
                    scale={ 1.2 }
                    lineHeight={ 1.2 }
                    align={ 'center' }
                    delay={ 4.5 }
                    material={ textMaterialPositive }
                    endCallback={ () => callBackFunction() }
                  />
                </group>

                <group position={ [ 0, -53, 480 ] }>
                  <TextRevealer
                    text={ ['La mejor inversión.'] }
                    trigger={ expStep === 10 }
                    position={ [ 0, (isTouch) ? 2.5 : 1.5, 0 ] }
                    rotation={ [ 0, 0, 0 ] }
                    scale={ (isTouch) ? 1.7 : 1.2 }
                    negative={ false }
                    pretitle
                    align={ 'center' }
                    delay={ 3 }
                    material={ textMaterialPositive }
                  />
                  <TextRevealer
                    text={ ['UNA INVERSIÓN ÉTICA,','SOSTENIBLE Y EN ALZA IMPARABLE.'] }
                    trigger={ expStep === 10 }
                    negative={ false }
                    position={ [ 0, 0, 0 ] }
                    rotation={ [ 0, 0, 0 ] }
                    scale={ 1.2 }
                    lineHeight={ 1.2 }
                    align={ 'center' }
                    delay={ 3 }
                    material={ textMaterialPositive }
                    endCallback={ () => callBackFunction() }
                  />
                </group>

                <mesh
                  matrixAutoUpdate={ matrixAutoUpdate }
                  position={ [ configTerrain.pos_x_1, - configTerrain.longitud_2 / 2 + configTerrain.pos_y_1, (configTerrain.longitud_1 + configTerrain.longitud_2 / 2) - configTerrain.longitud_2 / 2 + configTerrain.pos_z_1 ] }
                  material={ standardMaterialFloorHiddenStatic2 }
                  geometry={ planeGeometry }
                  scale={ [ configTerrain.anchura_1, configTerrain.longitud_2, 1 ] }
                  rotation={ [ 0, 0, 0 ] }
                />

                <ThirdFloor
                  material={ standardMaterialFloorHiddenStatic }
                  materialPhoneWall={ standardMaterialFloorHiddenStatic }
                  invisibleMaterial={ invisibleMaterial }
                  cushionMaterial={ modelTextures[19] }
                  smartphoneMaterial={ modelTextures[20] }
                  colorPositive={ colorConfig.elementos_hidden }
                  colorFloorPositive={ colorConfig.floor_hidden }
                  args={ configTerrain }
                  cursor={ cursor }
                  expStep={ expStep }
                  expStep_PREV={ expStep_PREV }
                  matrixAutoUpdate={ matrixAutoUpdate }
                />

                <ModelForcep 
                  position={ [ 18, 6, portalZPosition + 20 ] }
                  materials={ modelTextures[17] }
                  portalZPosition={ portalZPosition }
                  expStep={ expStep }
                  expStep_PREV={ expStep_PREV }
                />

              
              

            </Suspense>
          </Lantern> }


          <Hud 
            onClickStart={ initialAnimation } 
            stepBack={ stepBack }
            stepForward={ stepForward }
            changeVolume={ changeVolume }
            expStep={ expStep }
            expStep_PREV={ expStep_PREV }
            experienceState={ experienceState } 
            hovered={ hovered }
            toggleDianelumnizer={ () => toggleDianelumnizer() }
            positivePartState={ positivePartState }
            dianelumnizer={ dianelumnizer }
            showWarning={ showWarning }
            callBackFunction={ () => callBackFunction() }
          />

        </PathingScene>

        <Terrain
          materialShowed={ standardMaterialShow }
          materialHidden={ standardMaterialFloorHidden }
          materialBackgroundShowed={ standardBackgroundMaterialShow }
          materialBackgroundHidden={ standardBackgroundMaterialHide }
          configTerrain={ configTerrain }
          positivePartState={ positivePartState }
          dianelumnizer={ dianelumnizer }
          matrixAutoUpdate={ matrixAutoUpdate }
        >

          <BaseGroup
            positionX={ 0 + configElements.xAxis1 }
            positionZ={ -12 + configElements.zAxis1 }
            scale={ configElements.scale1 }
          >
            <Base>
              <ModelMine materials={ modelTextures[0] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            {/* <mesh
              position={ [ 0, 3, 0 ] }
              geometry={ boxGeometry }
              material={ invisibleMaterial }
              scale={ 9 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 0, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 0)}}
            /> */}
            <mesh
              position={ [ 0, 3, 0 ] }
              geometry={ geom }
              visible={ expStep === 0 }
              material={ /* standardMaterialFloorHidden */ invisibleMaterial }
              scale={ 9 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 0))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 0)}}
            />
            <group
              position={ [ configTexts.pos1.x, configTexts.pos1.y + .3, configTexts.pos1.z ] }
              rotation={ [ configTexts.rot1.x, configTexts.rot1.y, configTexts.rot1.z ] }
              scale={ configTexts.scale1 }
            >
              {/* <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ standardMaterialFloorHidden invisibleMaterial }
                position={ [ 0, -.3, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ [ 15, 7, 1 ] }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 0, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 0)}}
              /> */}
              <TextRevealer 
                text={ ['Para entendernos...'] } 
                trigger={ expStep === 0 && experienceState === 3 }
                position={ [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.2 : .7 }
                negative={ true }
                pretitle
                delay={ delayMine }
                delayCallback={ (expStep_PREV === 1) ? delayMine : 4 }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer 
                text={ ['POR UN LADO,','UN DIAMANTE DE MINA'] } 
                trigger={ expStep === 0 && experienceState === 3 }
                position={ [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                negative={ true }
                delay={ delayMine }
                material={ textMaterialNegative }
                fade={ hovered.current === true && hoveredItem.current === 0 && experienceState === 3 && hoveredState }
              />
            </group>
            
          </BaseGroup>
            

          <BaseGroup
            positionX={ -config.X_modelsOffset + configElements.xAxis2 }
            positionZ={ .5 * config.Z_modelsOffset + configElements.zAxis2 }
            scale={ configElements.scale2 }
          >
            <Base
              spinSpeed={ configElements.speed }
              yStartRotationPoint={ Math.PI * configElements.rot2 }
            >
              <ModelChimeneys materials={ modelTextures[2] } spinSpeed={ configElements.speed } smokeColor={ colorConfig.elementos_showed } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            <mesh
              position={ [ 0, 5, 0 ] }
              geometry={ boxGeometry }
              material={ invisibleMaterial }
              scale={ 10 }
              visible={ expStep === 1 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 1, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 1)}}
            />
            <group
              position={ [ configTexts.pos2.x, configTexts.pos2.y, configTexts.pos2.z ] }
              rotation={ [ configTexts.rot2.x, configTexts.rot2.y, configTexts.rot2.z ] }
              scale={ configTexts.scale2 }
            >
              <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ /* standardMaterialFloorHidden */ invisibleMaterial }
                position={ [ 9, -.3, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ [ 20, 7, 1 ] }
                visible={ expStep === 1 }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 1, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 1)}}
              />
              <TextRevealer
                text={ ['Huella de Carbono.'] }
                trigger={ expStep === 1 }
                position={ isMobilePortrait ? [ 4, 1.5, 0 ] : [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.25 : .55 }
                negative={ true }
                delay={ delayChimeneys }
                pretitle
                align={ 'left' }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer
                text={ isMobilePortrait ? 
                  ['LA EXTRACCIÓN','DEL UNO, OBTIENE','UN CERO EN', 'SOSTENIBILIDAD.'] :
                  ['LA EXTRACCIÓN DEL','UNO, OBTIENE UN CERO','EN SOSTENIBILIDAD.']
                } 
                trigger={ expStep === 1 }
                position={ isMobilePortrait ? [ 4, 0, 0 ] : [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                align={ 'left' }
                negative={ true }
                delay={ delayChimeneys }
                fade={ hovered.current === true && hoveredItem.current === 1 && hoveredState }
                material={ textMaterialNegative }
              />
            </group>
          </BaseGroup>
            

          <BaseGroup 
            positionX={ config.X_modelsOffset + configElements.xAxis3 }
            positionZ={ 1 * config.Z_modelsOffset + configElements.zAxis3 }
            scale={ configElements.scale3 }
          >
            <Base
              spinSpeed={ configElements.speed }
              yStartRotationPoint={ Math.PI * configElements.rot3 }
            >
              <ModelMiner materials={ modelTextures[4] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            <mesh
              position={ [ -6, 12, 0 ] }
              geometry={ tallBoxGeometry }
              material={ /* standardMaterialFloorHidden */ invisibleMaterial }
              scale={ [23, 13, 16] }
              visible={ expStep === 2 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 2, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 2)}}
            />
            <group
              position={ [ configTexts.pos3_1.x, configTexts.pos3_1.y, configTexts.pos3_1.z ] }
              rotation={ [ configTexts.rot3_1.x, configTexts.rot3_1.y, configTexts.rot3_1.z ] }
              scale={ configTexts.scale3 }
            >
              <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ /* standardMaterialFloorHidden */ invisibleMaterial }
                position={ [ 8, -.5, 0 ] }
                scale={ [ 20, 7, 1 ] }
                visible={ expStep === 2 }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 2, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 2)}}
              />
              <TextRevealer
                text={ ['Distintas procedencias.'] } 
                trigger={ expStep === 2 }
                position={ [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.25 : .6 }
                negative={ true }
                delay={ delayMiner }
                pretitle
                align={ 'left' }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer
                text={ ['UNO PROVIENE','DE LA EXPLOTACIÓN','MINERA.'] } 
                trigger={ expStep === 2 }
                position={ [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                negative={ true }
                align={ 'left' }
                delay={ delayMiner }
                fade={ hovered.current === true && hoveredItem.current === 2 && hoveredState }
                material={ textMaterialNegative }
              />
            </group>
          </BaseGroup>



          <BaseGroup
            positionX={ -config.X_modelsOffset + configElements.xAxis4 }
            positionZ={ 1.5 * config.Z_modelsOffset + configElements.zAxis4 }
            scale={ configElements.scale4 }
          >
            <Base
              spinSpeed={ configElements.speed }
              yStartRotationPoint={ Math.PI * configElements.rot4 }
            >
              <ModelOpenSkyMine materials={ modelTextures[6] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            <mesh
              position={ [ 0, 3, 0 ] }
              geometry={ boxGeometry }
              material={ invisibleMaterial }
              scale={ 13 }
              visible={ expStep === 3 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 3, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 3)}}
            />
            <group
              position={ [ configTexts.pos4_1.x, configTexts.pos4_1.y, (isTablet) ? 14.5 : configTexts.pos4_1.z ] }
              rotation={ [ configTexts.rot4_1.x, configTexts.rot4_1.y, configTexts.rot4_1.z ] }
              scale={ configTexts.scale4 }
            >
              <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ /* standardMaterialFloorHidden */ invisibleMaterial }
                position={ [ -8.5, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ [ 20, 7, 1 ] }
                visible={ expStep === 3 }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 3, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 3)}}
              />
              <TextRevealer
                text={ ['Evitando la minería.'] } 
                trigger={ expStep === 3 }
                position={ [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.25 : .6 }
                negative={ true }
                align={ 'right' }
                pretitle
                delay={ delayOpenSkyMine }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer
                text={ ['UNO CONLLEVA EL','DESGASTE COMPLETO','DE ECOSISTEMAS.'] } 
                trigger={ expStep === 3 }
                position={ [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                negative={ true }
                align={ 'right' }
                delay={ delayOpenSkyMine }
                fade={ hovered.current === true && hoveredItem.current === 3 && hoveredState }
                material={ textMaterialNegative }
              />
            </group>
          </BaseGroup>

          
          <BaseGroup
            positionX={ -config.X_modelsOffset + configElements.xAxis6 }
            positionZ={ 2.5 * config.Z_modelsOffset + configElements.zAxis6 }
            scale={ configElements.scale6 }
          >
            <Base
              spinSpeed={ configElements.speed }
              yStartRotationPoint={ Math.PI * configElements.rot6 }
            >
              <ModelBullets materials={ modelTextures[10] } smokeColor={ colorConfig.elementos_showed } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            <mesh
              position={ [ 0, 5, 0 ] }
              geometry={ boxGeometry }
              material={ invisibleMaterial }
              scale={ 13 }
              visible={ expStep === 4 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 4, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 4)}}
            />
            <group
              position={ [ configTexts.pos5_1.x, configTexts.pos5_1.y, configTexts.pos5_1.z ] }
              rotation={ [ configTexts.rot5_1.x, configTexts.rot5_1.y, configTexts.rot5_1.z ] }
              scale={ configTexts.scale5 }
            >
              <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ /* standardMaterialFloorHidden */ invisibleMaterial }
                position={ [ 7, -.6, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ [ 16, 7, 1 ] }
                visible={ expStep === 4 }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 4, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 4)}}
              />
              <TextRevealer
                text={ ['Desarrollo y futuro.'] }
                trigger={ expStep === 4 }
                position={ isMobilePortrait ? [ 2, 1.5, 0 ] : [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.25 : .6 }
                negative={ true }
                pretitle
                align={ 'left' }
                delay={ delayBullets }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer
                text={ isMobilePortrait ?
                  ['UNO HA SIDO FUENTE','DE CONFLICTOS','HUMANOS POR', 'TODO EL MUNDO.'] :
                  ['UNO HA SIDO FUENTE','DE CONFLICTOS HUMANOS','POR TODO EL MUNDO.'] 
                }
                trigger={ expStep === 4 }
                negative={ true }
                position={ isMobilePortrait ? [ 2, 0, 0 ] : [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                align={ 'left' }
                delay={ delayBullets }
                fade={ hovered.current === true && hoveredItem.current === 4 && hoveredState }
                material={ textMaterialNegative }
              />
            </group>
          </BaseGroup>


          <BaseGroup
            positionX={ config.X_modelsOffset + configElements.xAxis5 }
            positionZ={ 2 * config.Z_modelsOffset + configElements.zAxis5 }
            scale={ configElements.scale5 }
          >
            <Base
              spinSpeed={ configElements.speed }
              yStartRotationPoint={ Math.PI * configElements.rot5 }
            >
              <ModelMinecart materials={ modelTextures[8] } expStep={ expStep } matrixAutoUpdate={ matrixAutoUpdate } />
            </Base>
            <mesh
              position={ [ 0, 4, 0 ] }
              geometry={ boxGeometry }
              material={ invisibleMaterial }
              scale={ 12 }
              visible={ expStep === 5 }
              onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 5, true))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 5)}}
            />
            <group
              position={ [ configTexts.pos6_1.x, configTexts.pos6_1.y, (isTablet) ? -15 :configTexts.pos6_1.z ] }
              rotation={ [ configTexts.rot6_1.x, configTexts.rot6_1.y, configTexts.rot6_1.z ] }
              scale={ configTexts.scale6 }
            >
              <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={ planeGeometry }
                material={ /* standardMaterialFloorHidden */ invisibleMaterial }
                position={ [ 7, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ [ 16, 8, 1 ] }
                visible={ expStep === 0 }
                onPointerOver={(e) => {e.stopPropagation(); (setHover(true, 5, false))}} onPointerOut={(e) => {e.stopPropagation(); setHover(false, 5)}}
              />
              <TextRevealer
                text={ ['Energía limpia.'] }
                trigger={ expStep === 5 }
                position={ [ 0, 1.5, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                scale={ (isTouch) ? 1.5 : .6 }
                negative={ true }
                pretitle
                align={ 'left' }
                delay={ delayMinecart }
                endCallback={ () => callBackFunction() }
                material={ textMaterialNegative }
              />
              <TextRevealer
                text={ (isTablet) ? ['Y SI UNO IMPLICA','EL USO INTENSIVO','DE ENERGÍAS','NO RENOVABLES...'] : ['Y SI UNO IMPLICA','EL USO INTENSIVO DE','NO RENOVABLES...'] }
                position={ [ 0, 0, 0 ] }
                rotation={ [ 0, 0, 0 ] }
                trigger={ expStep === 5 }
                negative={ true }
                align={ 'left' }
                delay={ delayMinecart }
                fade={ hovered.current === true && hoveredItem.current === 5 && hoveredState }
                material={ textMaterialNegative }
              />
            </group>
          </BaseGroup>

          <ModelPortal 
            matrixAutoUpdate={ matrixAutoUpdate }
            materials={ modelTextures[12] }
            position-z={ portalZPosition }
            position-y={ .01 }
            scale={ 1.8 }
            cableColor={ { positive: colorConfig.elementos_hidden, negative: colorConfig.elementos_showed } }
            negative={ true }
            expStep={ expStep }
            positivePart={ positivePart }
            positivePartState={ positivePartState }
            lightColor={ colorConfig.light }
          />


        </Terrain> 

      </>}
      
    </>

  )
}

export default Experience