import { shaderMaterial, useScroll } from '@react-three/drei'
import { extend, useFrame } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import * as THREE from 'three'
import hexToRgb from '../../../Utils/hexToRgb'
import { _BEFORE_ARC } from '../../settings'

export default function BulletSmokeCustomMaterial(props) {
    
    const materialRef = useRef()

    const color = new THREE.Color(hexToRgb(props.color).r, hexToRgb(props.color).g, hexToRgb(props.color).b)

    const BsMaterial = shaderMaterial(
        { 
            uTime: 0,
            uColor: color || new THREE.Color(.85, .85, .85),
            uElevation: .3,
            uFrequency: 1,
        },
        // vertex shader
        /*glsl*/`

        varying vec2 vUv;

        uniform float uTime;
        uniform float uElevation;
        uniform float uFrequency;
        
        void main() {
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);
            float elevation = sin(modelPosition.y * uFrequency + uTime) * uElevation * uv.x;
            modelPosition.x += elevation;
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectedPosition = projectionMatrix * viewPosition;
            gl_Position = projectedPosition;

            vUv = uv;
        }

        `,
        // fragment shader
        /*glsl*/`

        uniform vec3 uColor;
        varying vec2 vUv;

        void main() {
            float strength = (- abs(vUv.x - .5)) + .5;

            gl_FragColor = vec4(uColor.r * .01, uColor.g * .01, uColor.b * .01, strength * .5);
        }

        `
    )
    
    // declaratively
    extend({ BsMaterial })

    /* useFrame((state, delta) => {
        materialRef.current.uTime -= delta
    }) */
    
    return <bsMaterial ref={ materialRef } {...props} transparent />
}