import { Edges, MeshDiscardMaterial, useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef, useState } from "react";
import Sizes from "../../Utils/Sizes";
import * as THREE from "three";

gsap.registerPlugin(
	ScrollTrigger,
);

export default function MenuButton() {
    
    const [pageReady, setPageReady] = useState(false)
    const [buttonVisible, setButtonVisible] = useState(false)
    const button = useRef()
    const buttonContainer = useRef()
    const { invalidate } = useThree()
    const { width, height } = Sizes()
    const { camera } = useThree()

    const [multiplierHeight, setMultiplierHeight] = useState(.00039)
    const [multiplierWidth, setMultiplierWidth] = useState(.000376)

    const { nodes } = useGLTF('./models/3d_menu.glb')

    const hoverButton = document.querySelector('.header__menu-content')

    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    const hoverEffect = (e) => {

        const rect = hoverButton.getBoundingClientRect()
        //console.log(rect.top + hoverButton.offsetHeight);
        //console.log(width - rect.right + hoverButton.offsetWidth);

        cursor.x = e.clientX / width - .5
        cursor.y = e.clientY / height - .5

        
        button.current.rotation.set(
            THREE.MathUtils.lerp(button.current.rotation.x, cursor.y * .1, .1),
            THREE.MathUtils.lerp(button.current.rotation.y, cursor.x * .4, .1),
            button.current.rotation.z,
        )
        invalidate()

    }

    useEffect(() => {
        if (!pageReady) return

        // Content...
        buttonContainer.current.lookAt(camera.position)
        
        if (hoverButton) {
            hoverButton.addEventListener('mouseover', hoverEffect)
        }

        return () => {
            if (hoverButton) {
                hoverButton.removeEventListener('mouseover', hoverEffect)
            }
        }

    }, [pageReady])

    // ON PAGE LOADED
    useEffect(() => {
        const onPageLoad = () => {
          setPageReady(true);
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);


    useFrame(() => {

    })

    
    return <>

        <group ref={ buttonContainer } position={ [ width * multiplierWidth, height * multiplierHeight, 0 ] } >
                <mesh ref={ button } geometry={ nodes.pSphere1.geometry } position={ [ 0, 0, -40 ] } scale={ .22 } rotation-y={ Math.PI }>
                    <MeshDiscardMaterial />
                    <Edges
                        threshold={1}
                        color="#141515"
                    />
                </mesh>
        </group>
    </>
}