import { useGLTF, useTexture } from "@react-three/drei";
import React, { useEffect, useMemo } from "react";
import { ModelBase } from "./ModelBase";
import * as THREE from 'three'

export function ModelMine(props) {
  const { nodes } = useGLTF("/models/UV/Mine.glb");

  /* useEffect(() => {
    if (props.expStep === 1) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
      <group rotation={ [ 0, Math.PI, 0 ] } /* position-y={ -.38 } */>
        <group scale={ 1.02 }>
          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            geometry={nodes.DIA_1A_Mina.geometry}
            material={ props.materials.baked }
            position={[0.26, 4.34, 1.91]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[6.53, 5.3, 6.39]}
          />


        </group>


        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } />

      </group>
    </group>
  );
}