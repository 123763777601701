import { useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { _BEFORE_ARC, _ON_ARC } from "./settings";


export default function HiddenBases(props) {
    const group = useRef()

    useFrame(() => {
        group.current.visible = props.visible.current
    })

    return <>
        <group {...props} ref={ group }>
            { props.children }
        </group>
    </>
}