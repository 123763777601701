import { useRef } from "react"
import { SvgButton } from "./Experience/SvgButton"


export function HorizontalWarning({ showWarning, onStartClick }) {

  const horizontalWarningRef = useRef()
  const startClicked = () => {
    horizontalWarningRef.current.classList.add('loaded')
    onStartClick()
  }

  return <>
  {showWarning && <div className='horizontal-warning' ref={ horizontalWarningRef }>

    <div className="hw__bottom">
      <p className="hw__title">EXPLORA el ENTORNO</p>
      <p className="hw__text">Sigue estas recomendaciones para mejorar la experiencia y comparar el diamante de mina con Dianelum.</p>

      <SvgButton onClick={ () => startClicked() } text={ 'CONTINUAR' } classes={ 'ls__start-button only-horizontal' } x={ -67.881 } ready={ true } />
    </div>


    <div className="hw__items">

      <div className="hw__item">

        <div className="hw__mobile-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="88.863" height="88.863" viewBox="0 0 88.863 88.863">
            <g id="Grupo_7874" data-name="Grupo 7874" transform="translate(-177.404 -636.404)">
              <g id="Rectángulo_2731" data-name="Rectángulo 2731" opacity="0.978">
                <rect id="Rectángulo_2731-2" data-name="Rectángulo 2731" width="61.836" height="61.836" transform="translate(178.111 680.836) rotate(-45)" fill="#9bcaa6"/>
                <path id="Rectángulo_2731_-_Contorno" data-name="Rectángulo 2731 - Contorno" d="M-.5-.5H62.336V62.336H-.5ZM61.336.5H.5V61.336H61.336Z" transform="translate(178.111 680.836) rotate(-45)" fill="#141515"/>
              </g>
              <g id="Grupo_7801" data-name="Grupo 7801" transform="translate(214.191 664.32)">
                <path id="Trazado_37925" data-name="Trazado 37925" d="M-19858.482-18761.963l-4.68-4.191-3.117-2.8-7.367,6.98-.687-.727,8.035-7.613.344.307,3.461,3.105,4.68,4.191Z" transform="translate(19873.99 18769.633)" fill="#141515"/>
                <path id="Trazado_37926" data-name="Trazado 37926" d="M15.507,7.665,7.708.68.344,7.656-.344,6.93,7.689-.68l8.485,7.6Z" transform="translate(15.84 32.844) rotate(180)" fill="#141515"/>
                <path id="Línea_495" data-name="Línea 495" d="M.5,31.634h-1V0h1Z" transform="translate(7.898 0.16)" fill="#141515"/>
              </g>
            </g>
          </svg>
        </div>

        <p className="hw__item-desc">Desliza hacia arriba y abajo para navegar por la experiencia</p>
      </div>

      <div className="hw__item">

        <div className="hw__switch-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="103" height="58" viewBox="0 0 103 58">
            <g id="Grupo_7831" data-name="Grupo 7831" transform="translate(0.211 0.374)">
              <g id="Rectángulo_2703" data-name="Rectángulo 2703" transform="translate(-0.211 -0.374)" fill="none" stroke="#141515" strokeWidth="1.3">
                <rect width="103" height="58" rx="29" stroke="none"/>
                <rect x="0.65" y="0.65" width="101.7" height="56.7" rx="28.35" fill="none"/>
              </g>
              <path id="Trazado_37881" data-name="Trazado 37881" d="M24.224,0A24.224,24.224,0,1,1,0,24.224,24.224,24.224,0,0,1,24.224,0Z" transform="translate(50.012 4.366)" fill="#141515"/>
              <g id="Grupo_7611" data-name="Grupo 7611" transform="translate(64.859 19.213)">
                <line id="Línea_470" data-name="Línea 470" y1="18.754" transform="translate(9.377 0)" fill="none" stroke="#9bcaa6" strokeWidth="1.3"/>
                <line id="Línea_471" data-name="Línea 471" x2="18.754" transform="translate(0 9.377)" fill="none" stroke="#9bcaa6" strokeWidth="1.3"/>
                <line id="Línea_472" data-name="Línea 472" y1="18.754" transform="translate(16.008 2.746) rotate(45)" fill="none" stroke="#9bcaa6" strokeWidth="1.3"/>
                <line id="Línea_473" data-name="Línea 473" x2="18.754" transform="translate(2.746 2.746) rotate(45)" fill="none" stroke="#9bcaa6" strokeWidth="1.3"/>
              </g>
            </g>
          </svg>
        </div>

        <p className="hw__item-desc">Enciende, apaga y descubre cómo Dianelum cambia el entorno</p>
      </div>

    </div>  

    <div className="hw__button-container">
      {/* <span className="hw__waiting-step1">Esperando al Paso 1</span> */}
      <SvgButton onClick={ () => startClicked() } text={ 'CONTINUAR' } classes={ 'ls__start-button' } x={ -67.881 } ready={ true } />
    </div>


      
      
    </div> }

  </>
}