const modelsInfo = [
  {
    model: 'mine',
    positive: false,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'laboratory',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'chimeneys',
    positive: false,
    double_side: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'windmills',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'miner',
    positive: false,
    flipY: true,
    flipYBase: false,
  },
  {
    model: 'scientist',
    positive: true,
    flipY: true,
    flipYBase: false,
  },
  {
    model: 'openskymine',
    positive: false,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'forest',
    positive: true,
    double_side: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'minecart',
    positive: false,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'battery',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'bullets',
    positive: false,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'testtubes',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'portal',
    static: true,
    double_mask_floor: 'positive',
    dynamic_floor: true,
    double_side: true,
    flipY: false,
    flipYBase: false,
    double_color: true,
  },
  {
    model: 'airplane',
    positive: true,
    double_side: true,
    dynamic_floor: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'robot',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'car',
    positive: true,
    double_side: true,
    dynamic_floor: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'buildings',
    positive: true,
    flipY: false,
    flipYBase: false,
  },
  {
    model: 'forcep',
    static: true,
    floor: false,
    flipY: false,
    flipYBase: false,
    positive: true,
  },
  {
    model: 'loupe',
    static: true,
    floor: false,
    flipY: false,
    flipYBase: false,
    positive: true,
  },
  {
    model: 'cushion',
    static: true,
    floor: false,
    dynamic_map: true,
    flipY: false,
    flipYBase: false,
    positive: true,
  },
  {
    model: 'smartphone',
    static: true,
    floor: false,
    flipY: false,
    flipYBase: false,
    positive: true,
  },
]

export default modelsInfo