import { useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import { MathUtils } from "three";

const SmokeParticle = ({ material, geometry, active }) => {
    
    const ref = useRef();
    const heightC = 5;
    const [data] = useState({
        x: (Math.random() - .5) * MathUtils.randFloatSpread(0.3),
        y: MathUtils.randFloatSpread(heightC),
        z: (Math.random() - .5) * MathUtils.randFloatSpread(0.3),
        rX: Math.random() * Math.PI,
        rY: Math.random() * Math.PI,
        rZ: Math.random() * Math.PI,
        scale: Math.min(Math.max(Math.random(), .2), .7),
        movement: Math.min(Math.random(), 0.7)
    });
    
    useFrame(() => {
        if (!active) return
        
        ref.current.position.set(
            data.x * (ref.current.position.y * 5)/*  + data.y * 0.001 + data.movement * 0.01 */,
            (data.y += 0.01),
            data.z * (ref.current.position.y * 5)
        );
        const scaleAmmount = Math.max(data.scale * (ref.current.position.y * .4), .2)
        ref.current.scale.set(scaleAmmount, scaleAmmount, scaleAmmount)
        if (data.y >= (heightC / 1.5) - 0.1) {
            ref.current.scale.set(0, 0, 0)
        }
        if (data.y >= heightC / 1.5) {
            data.y = 0;
            ref.current.position.x = 0;
            ref.current.position.y = 0;
            data.x = 0;
            data.movement = Math.min(Math.random(), 0.7);
        }
    })
    
    return (
        <mesh ref={ref} material={ material } geometry={ geometry } />
    );
}

export default SmokeParticle;