import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { ModelBase } from "./ModelBase";

export function ModelBattery(props) {
  const { nodes } = useGLTF("/models/UV/Battery.glb");

  const handle = useRef()

  useFrame((state) => {
    if (props.expStep === 5) {
      handle.current.rotation.x = Math.cos(state.clock.elapsedTime * 1.5) * .7 + 2.3
    }
  })

  /* useEffect(() => {
    if (props.expStep === 6) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
      <group scale={ 1.05 } position-z={.13}>
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_BATERIA_Borne2.geometry}
          material={ props.materials.baked }
          position={[0.08, 8.15, -2.92]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1.32, 1.32, 0.2]}
        />
        <mesh
          ref={ handle }
          geometry={nodes.DIA_BATERIA_Asa.geometry}
          material={ props.materials.baked }
          position={[-0.1, 13.83, -0.46]}
          rotation={[2.49, 0, Math.PI / 2]}
          scale={[-1.61, -10.21, -1.32]}
        />
      </group>

      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}
