import { useProgress } from "@react-three/drei";
import { SplitText } from "gsap/all";
import { gsap } from "gsap/all";
import { useEffect, useRef, useState } from "react"
import { DiamondSvgAnimation } from "./DiamondSvgAnimation";
import { SvgButton } from "./Experience/SvgButton";
import WindowHandler from "./Utils/WindowHandler";


export function LoadingScreen({ onStartClick, canStart }) {

    gsap.registerPlugin(SplitText);

    const loadingScreenRef = useRef()
    const startClicked = () => {
      loadingScreenRef.current.classList.add('loaded')
      onStartClick()
    }

    const isTouch = WindowHandler().isTouch

    useEffect(() => {
        gsap.utils.toArray(".ani_ini_fade").forEach((box) => {
            var AniFade = gsap.timeline({
              defaults: {
                duration: 1,
                ease: "power2.out",
              },
            });
    
            AniFade.to(box, { y: 0, opacity: 1 }, "0");
            AniFade.play();
          });
    
          gsap.utils.toArray(".ani_ini_mask span").forEach((box) => {
            var AniFade = gsap.timeline({
              defaults: {
                duration: 1,
                ease: "power2.out",
              },
            });
    
            AniFade.to(box, { y: 0 }, "0");
            AniFade.play();
          });
    
          gsap.utils.toArray(".ani_ini_mask_fade span").forEach((box) => {
            var AniFade = gsap.timeline({
              defaults: {
                duration: 1,
                ease: "power2.out",
              },
            });
    
            AniFade.to(box, { y: 0, opacity: 1 }, "0");
            AniFade.play();
          });
    
          var ani_header = gsap.timeline({
            defaults: {
              duration: 1,
              ease: "power2.out",
            },
          });
    
          ani_header.to("header", { top: "0" }, 0);
          ani_header.play();
    
          var ani_headerAniRombos = gsap.timeline({
            defaults: {
              duration: 1,
              ease: "power2.out",
            },
          });
    
          ani_headerAniRombos.to(
            ".header .header__ani_right .header__ani",
            { bottom: "-23.646vw", right: "-21.563vw" },
            0
          );
          ani_headerAniRombos.to(
            ".header .header__ani_right .header__ani_rombo",
            { bottom: "-19.271vw", right: "-48.594vw" },
            0
          );
    
          ani_headerAniRombos.to(
            ".header .header__ani_left .header__ani",
            { top: "-37.240vw", left: "-36.458vw" },
            0
          );
          ani_headerAniRombos.to(
            ".header .header__ani_left .header__ani_rombo",
            { top: " -17.813vw", left: "-56.146vw" },
            0
          );
    
          ani_headerAniRombos.to(
            ".header .header__arrow",
            { bottom: "3.229vw", width: "5.729vw" },
            0
          );
          ani_headerAniRombos.to(
            ".header .header__arrow .btn__rombo_svg ",
            { width: "5.729vw" },
            0
          );
          
          ani_headerAniRombos.to(
            ".pieza-header",
            { x: "0", opacity:1, scale:1 },
            0
          );
          ani_headerAniRombos.to(
            ".pieza-header.pieza .pieza__1",
            { width: "3.542vw" },
            0
          );
    
          ani_headerAniRombos.play();
    
          let split = SplitText.create("h1", {
            type: "lines,chars",
            linesClass: "split-line",
            charsClass: "split-char"
          });
    
          gsap.to(split.chars, {
            opacity: 1,
            y: 0,
            ease: "power2.out",
            duration: 0.8,
            stagger: 0.015,
            rotateX : "0deg"
          });
    }, [])


    const { progress } = useProgress()
    const [loaded, setLoaded] = useState(false)
    const [savedProgress, setSavedProgress] = useState(0)

    useEffect(() => {
      let canStartProgress = 0
      if (canStart) canStartProgress = 30
      const prog = progress * .7 + canStartProgress
      if (prog > savedProgress) {
        document.documentElement.style.setProperty('--loading-progress', prog);
        if (canStart) {
          setTimeout(() => {
              document.body.classList.add('loaded')
          }, 4500);
          setTimeout(() => {
            setLoaded(true)
          }, 5500);
        }
        setSavedProgress(prog)
      }
    }, [progress, canStart])

    return <>
    
        <div className="loading-screen" ref={ loadingScreenRef }>

            <div className="ls__center-div">
                <div className="ls__center-text">
                    <div className="header__data">
                        { !isTouch && <div className="pieza pieza-header">
                          <span className="pieza__line pieza__1"></span>
                          <span className="pieza__line pieza__2"></span>
                          <span className="pieza__line pieza__3"></span>
                          <span className="pieza__line pieza__4"></span>
                          <span className="pieza__rombo pieza__5"></span>
                        </div> }

                        <div className="header__pretitulo ani_ini_mask_fade">
                            <svg className="ls__header-logo" xmlns="http://www.w3.org/2000/svg" width="55.862" height="33.074" viewBox="0 0 55.862 33.074">
                              <g id="Grupo_7842" data-name="Grupo 7842" transform="matrix(1, 0.017, -0.017, 1, 0.56, 0)">
                                <path id="Trazado_37917" data-name="Trazado 37917" d="M0,0,25.986,32.114V19.039L10.559,0Z" transform="translate(0 0)" fill="#141515"/>
                                <path id="Trazado_37918" data-name="Trazado 37918" d="M393.78,0,378.352,19.039V32.114L404.339,0Z" transform="translate(-349.029 0)" fill="#141515"/>
                                <path id="Trazado_37919" data-name="Trazado 37919" d="M229.095,0l-3.684,4.431L235.588,17.16,245.836,4.577,242.225,0Z" transform="translate(-207.941 0)" fill="#141515"/>
                              </g>
                            </svg>

                            <svg id="Logo" xmlns="http://www.w3.org/2000/svg" width="150" height="20.444" viewBox="0 0 118.454 10.444">
                                <path id="Trazado_2" data-name="Trazado 2" d="M118.454,0V10.288h-1.2V3.819l.143-2.713h-.016l-3.433,9.182h-1.076l-3.433-9.182h-.016l.143,2.713v6.469h-1.2V0h1.946L112.6,6.251l.791,2.572h.032l.807-2.556L116.508,0Zm-19,0V6.454a3.925,3.925,0,0,1-1.06,2.985,5.08,5.08,0,0,1-6.068,0,3.908,3.908,0,0,1-1.068-2.985V0h1.266V6.142a3.459,3.459,0,0,0,.689,2.369,3.391,3.391,0,0,0,4.287,0,3.459,3.459,0,0,0,.688-2.369V0ZM77.983,9.119h5.981v1.169H76.717V0h1.266ZM60.928,10.288V0h7.325V1.169H62.193v3.32h4.715V5.658H62.193V9.119h6.3v1.169ZM51.814,0V10.288H50.249L45.882,3.4,44.774,1.465h-.016l.064,1.559v7.264h-1.2V0h1.566l4.351,6.859,1.124,1.98h.016l-.063-1.574V0ZM29.079,6.267h3.813L30.978,1.2ZM35.8,10.288H34.459L33.351,7.436h-4.73l-1.108,2.853H26.184L30.25,0h1.5Zm-17.435,0H17.1V0h1.266ZM3.5,9.119A3.678,3.678,0,0,0,6.289,8.067a4.059,4.059,0,0,0,1-2.923,4.06,4.06,0,0,0-1-2.923A3.678,3.678,0,0,0,3.5,1.169H1.266V9.119ZM3.37,0A5.36,5.36,0,0,1,7.222,1.333a5.013,5.013,0,0,1,1.4,3.812,5.012,5.012,0,0,1-1.4,3.811A5.36,5.36,0,0,1,3.37,10.288H0V0Z" fill="#141515"/>
                            </svg>
                        </div>
                            <div className="header__titulo">
                            <h1>
                                ES LO MISMO,<br/>PERO NO ES IGUAL.
                            </h1>
                        </div>
                        <div className="cita cita-header cita-header-index">
                            <div className="cita__title ani_ini_mask">
                                <span>Una historia real sobre el diamante</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ls__loading-button">

                <div className="ls__loading-button-main">
                    <div className="ls__square"></div>
                    <div className="ls__line">
                        <div className="ls__line-remover"></div>
                    </div>
                    <div className="ls__square"></div>

                    <div className="ls__diamond">
                        <DiamondSvgAnimation />
                    </div>

                </div>
                <span className="ls__loading-text">CARGANDO</span>

                <SvgButton onClick={ () => startClicked() } text={ 'EMPEZAR' } classes={ 'ls__start-button' } ready={ loaded } />


            </div>

        </div>
    
    </>
}