import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { gsap } from "gsap/all";
import { useFrame, useLoader } from "@react-three/fiber";
import * as THREE from 'three'

export function CarPath(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("/models/UV/carPath.glb");
  const { actions } = useAnimations(animations, group);
  const tlAnim = useRef(null)
  const lightFront = useRef()
  const cover = useRef()
  const car = useRef()
  const shadow = useRef()

  useEffect(() => {
    actions['Action.001'].play().paused = true
    actions['Action.001'].timeScale = .24
  }, [actions])

  const reset = () => {
    if (tlAnim.current !== null) {
        tlAnim.current.kill()
        actions['Action.001'].time = 0
        lightFront.current.material.color.setHex(props.windowsInitialColor)
        cover.current.rotation.x = -1.278
    }
  }


  const shadowTexture = useLoader(THREE.TextureLoader, '/textures/car/shadow.jpg')
  const shadowAlpha = useLoader(THREE.TextureLoader, '/textures/car/shadow_alpha.jpg')
    
  shadowTexture.encoding = THREE.sRGBEncoding;
  const shadowMaterial = useMemo(() => new THREE.MeshBasicMaterial({ map: shadowTexture, alphaMap: shadowAlpha, transparent: true, color: props.shadowColor }), [])

  useFrame(() => {
    if (!shadow.current) return
    shadow.current.position.x = car.current.position.x
    shadow.current.position.z = car.current.position.z
    shadow.current.rotation.z = -car.current.rotation.z
  })

  useEffect(() => {

    if (props.positivePartState) {
      reset()
      const p = { p: 0 }
      gsap.to(p, {
        p: 1, 
        duration: props.generalDuration,
        onComplete: () => { 
          tlAnim.current = gsap.fromTo(actions['Action.001'], {time: 0}, {
            time: 20,
            duration: 130,
          })
          gsap.to(cover.current.rotation, {
            x: 0,
            duration: 2
          })
          
          lightFront.current.material.color.setRGB(props.windowsLightColor.r, props.windowsLightColor.g, props.windowsLightColor.b)
        }
      })
    } else {
      setTimeout(() => {
        reset()
      }, 2000);
    }

  }, [props.positivePartState])
  
  return (
    <group ref={group} 
    scale={ 150 } 
    position={ [ 79.5, -3, 56 ] } 
    rotation={ [ 0, Math.PI * -.35, 0 ] }
    dispose={null}>
      <group name="Scene">
        <group name="NurbsPath">
          <mesh ref={ shadow } rotation-x={ Math.PI * -.5 } position-y={ .002 } scale={ [ .3, .43, 1 ] } material={ shadowMaterial }>
            <planeGeometry />
          </mesh>
          <mesh
            ref={ car }
            name="DIA_COCHE_CHAPA"
            geometry={nodes.DIA_COCHE_CHAPA.geometry}
            material={props.material}
            position={[-0.567, 0.021, 0.293]}
            rotation={[1.558, -0.025, -1.063]}
            scale={0.026}
          >
            <mesh
              ref={ lightFront }
              name="DIA_COCHE_FARO_DELANTERO"
              geometry={nodes.DIA_COCHE_FARO_DELANTERO.geometry}
              position={[0, -1.083, -1.507]}
            >
              <meshBasicMaterial color={ props.windowsInitialColor } />
            </mesh>
            <mesh
              name="DIA_COCHE_NEUMATICO1"
              geometry={nodes.DIA_COCHE_NEUMATICO1.geometry}
              material={props.material}
              position={[-1.278, 1.605, -0.54]}
              scale={[0.866, 0.937, 0.937]}
            />
            <mesh
              name="DIA_COCHE_NEUMATICO3"
              geometry={nodes.DIA_COCHE_NEUMATICO3.geometry}
              material={props.material}
              position={[1.275, 1.605, -0.54]}
              rotation={[Math.PI, 0, -0.089]}
              scale={[-0.866, -0.937, -0.937]}
            />
            <mesh
              ref={ cover }
              name="DIA_COCHE_TAPA_MANGUERA"
              geometry={nodes.DIA_COCHE_TAPA_MANGUERA.geometry}
              material={props.material}
              position={[-0.005, 3.039, -1.294]}
              rotation={[-1.278, 0, 0]}
            />
          </mesh>

        </group>
      </group>
    </group>
  );
}