import React, { useEffect, useRef } from "react";
import { useAnimations, useGLTF, useScroll } from "@react-three/drei";
import { ModelBase } from "./ModelBase";
import { _BEFORE_ARC } from "../settings";
import { useFrame } from "@react-three/fiber";

export function ModelScientist(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("/models/UV/Scientist.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions['_ANIMATION'].play()
    actions['_ANIMATION'].paused = true
  }, [])

  useEffect(() => {
    if (props.expStep !== 2) {
      actions['_ANIMATION'].paused = true
    } else {
      actions['_ANIMATION'].paused = false
    } 
    /* if (props.expStep === 3) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    } */
  }, [props.expStep])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" scale={ .7 } rotation={ [ 0, Math.PI, 0 ] }>
        <group>
          <group name="DIA_3B_RIG_Ctrl_Reference" position={[0.04, 0, -0.51]}>
            <group
              name="DIA_3B_RIG_Ctrl_HipsEffector"
              position={[0, 34.36, -0.1]}
              rotation={[0, 0, -0.08]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_LeftAnkleEffector"
              position={[7.41, 3.2, -5.88]}
              rotation={[0, 0.13, 0.05]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightAnkleEffector"
              position={[-8.05, 3.2, -1.01]}
              rotation={[0, -0.13, -0.05]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_LeftWristEffector"
              position={[13.94, 34.25, -5.25]}
              rotation={[-0.22, -0.22, -1.4]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightWristEffector"
              position={[-8.89, 68.11, 11.91]}
              rotation={[2.07, 1.09, -1.87]}
            >
              <group
                name="DIA_3B_BOTTLE_LOCATOR"
                position={[-2.92, 1.21, -0.06]}
                rotation={[-2.26, -1.09, -1.86]}
                scale={2.9}
              >
                <mesh
                  name="DIA_SCIENTIST_BOTTLE"
                  castShadow
                  receiveShadow
                  geometry={nodes.DIA_SCIENTIST_BOTTLE.geometry}
                  material={ props.materials.baked }
                  position={[0, -15.73, -7.25]}
                  scale={0.35}
                />
              </group>
            </group>
            <group
              name="DIA_3B_RIG_Ctrl_LeftKneeEffector"
              position={[6.08, 16.3, 1.7]}
              rotation={[0.52, 0.08, 0.09]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightKneeEffector"
              position={[-6.48, 17.5, 3.9]}
              rotation={[0.32, -0.08, -0.1]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_LeftElbowEffector"
              position={[12.43, 42.99, -7.58]}
              rotation={[-0.22, -0.22, -1.4]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightElbowEffector"
              position={[-13.11, 60.88, 8.17]}
              rotation={[2.91, 0.9, 2.4]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_ChestOriginEffector"
              position={[0.32, 38.55, -2.26]}
              rotation={[0, 0, -0.08]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_ChestEndEffector"
              position={[1.52, 54.29, 1.26]}
              rotation={[0, 0, -0.08]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_LeftShoulderEffector"
              position={[8.79, 54.14, -1.31]}
              rotation={[0.53, -0.05, -1.29]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightShoulderEffector"
              position={[-5.68, 55.25, -1.31]}
              rotation={[-1.34, 0.8, 0.64]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_HeadEffector"
              position={[2.09, 69.08, 1.33]}
              rotation={[0.22, -0.46, -0.03]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_LeftHipEffector"
              position={[5.28, 33.96, -0.1]}
              rotation={[-0.1, -0.09, 0.04]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightHipEffector"
              position={[-5.28, 34.76, -0.1]}
              rotation={[-0.22, 0.09, -0.07]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_Hips"
              position={[-0.17, 32.1, -0.09]}
              rotation={[0, 0, -0.08]}
            >
              <group
                name="DIA_3B_RIG_Ctrl_LeftUpLeg"
                position={[5.3, 2.27, -0.01]}
                rotation={[-0.09, -0.09, 0.12]}
              >
                <group
                  name="DIA_3B_RIG_Ctrl_LeftLeg"
                  position={[0, -17.76, 0]}
                  rotation={[0.63, 0.13, 0]}
                >
                  <group
                    name="DIA_3B_RIG_Ctrl_LeftFoot"
                    position={[0, -15.19, 0]}
                    rotation={[-0.51, 0.1, -0.07]}
                  >
                    <group
                      name="DIA_3B_RIG_Ctrl_LeftToeBase"
                      position={[0, -2.61, 7.4]}
                    />
                  </group>
                </group>
              </group>
              <group
                name="DIA_3B_RIG_Ctrl_RightUpLeg"
                position={[-5.3, 2.27, -0.01]}
                rotation={[-0.22, 0.07, 0.01]}
              >
                <group
                  name="DIA_3B_RIG_Ctrl_RightLeg"
                  position={[0, -17.76, 0]}
                  rotation={[0.55, -0.12, 0]}
                >
                  <group
                    name="DIA_3B_RIG_Ctrl_RightFoot"
                    position={[0, -15.19, 0]}
                    rotation={[-0.31, -0.09, 0.08]}
                  >
                    <group
                      name="DIA_3B_RIG_Ctrl_RightToeBase"
                      position={[0, -2.61, 7.4]}
                    />
                  </group>
                </group>
              </group>
              <group name="DIA_3B_RIG_Ctrl_Spine" position={[0, 6.47, -2.17]}>
                <group
                  name="DIA_3B_RIG_Ctrl_Spine1"
                  position={[0, 6.47, -1.15]}
                >
                  <group
                    name="DIA_3B_RIG_Ctrl_Spine2"
                    position={[0, 6.47, 0.66]}
                  >
                    <group
                      name="DIA_3B_RIG_Ctrl_LeftShoulder"
                      position={[3.39, 2.85, 4.01]}
                    >
                      <group
                        name="DIA_3B_RIG_Ctrl_LeftArm"
                        position={[3.87, 0.41, -2.57]}
                        rotation={[0.53, -0.01, -1.23]}
                      >
                        <group
                          name="DIA_3B_RIG_Ctrl_LeftForeArm"
                          position={[13.3, 0, 0]}
                          rotation={[0, -0.76, 0]}
                        >
                          <group
                            name="DIA_3B_RIG_Ctrl_LeftHand"
                            position={[9.17, 0, 0]}
                          />
                        </group>
                      </group>
                    </group>
                    <group
                      name="DIA_3B_RIG_Ctrl_RightShoulder"
                      position={[-3.39, 2.85, 4.01]}
                    >
                      <group
                        name="DIA_3B_RIG_Ctrl_RightArm"
                        position={[-3.87, 0.41, -2.57]}
                        rotation={[-1.36, 0.72, 0.67]}
                      >
                        <group
                          name="DIA_3B_RIG_Ctrl_RightForeArm"
                          position={[-13.3, 0, 0]}
                          rotation={[0, 1.19, 0]}
                        >
                          <group
                            name="DIA_3B_RIG_Ctrl_RightHand"
                            position={[-9.17, 0, 0]}
                            rotation={[0.48, 0, 1.29]}
                          />
                        </group>
                      </group>
                    </group>
                    <group
                      name="DIA_3B_RIG_Ctrl_Neck"
                      position={[0, 7.72, 1.66]}
                      rotation={[0.25, -0.44, 0.06]}
                    >
                      <group
                        name="DIA_3B_RIG_Ctrl_Head"
                        position={[0, 10.23, 0.12]}
                      />
                    </group>
                  </group>
                </group>
              </group>
            </group>
            <group
              name="DIA_3B_RIG_Ctrl_LeftFootEffector"
              position={[8.5, 0.59, 1.44]}
              rotation={[0, 0.13, 0.05]}
            />
            <group
              name="DIA_3B_RIG_Ctrl_RightFootEffector"
              position={[-9.14, 0.62, 6.31]}
              rotation={[0, -0.13, -0.05]}
            />
          </group>
          <group name="DIA_3B_RIG_Reference" position={[0.04, 0, -0.51]}>
            <primitive object={nodes.DIA_3B_RIG_Hips} />
          </group>
          <skinnedMesh
            name="DIA_SCIENTIST"
            geometry={nodes.DIA_SCIENTIST.geometry}
            material={ props.materials.baked }
            skeleton={nodes.DIA_SCIENTIST.skeleton}
          />
        </group>
      </group>
      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}