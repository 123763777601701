import { useAnimations, useGLTF, useScroll } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import * as THREE from 'three';
import { BaseTube } from "./BaseTube";
import { ModelBase } from "./ModelBase";
import { gsap } from "gsap/all";
import { CarPath } from "./CarPath";
import hexToRgb from "../../Utils/hexToRgb";

export function ModelCar(props) {
  const { nodes } = useGLTF("/models/UV/Car.glb");

  const group = useRef();
  const manguera = useGLTF("/models/UV/CarCable.glb");
  const { actions } = useAnimations(manguera.animations, group);

  const screen = useRef()

  const windowsInitialColor = props.colors.element
  const windowsLightColor = hexToRgb(props.colors.light)
  windowsLightColor.r *= .005
  windowsLightColor.g *= .03
  windowsLightColor.b *= .005

  useEffect(() => {
    actions['Armature.001Action'].play().paused = true
  }, [actions])

  const reset = () => {
    screen.current.material.color.setHex(windowsInitialColor) 
    actions['Armature.001Action'].time = 0
  }

  const generalDuration = 5

  useEffect(() => {

    if (props.positivePartState) {
      reset()
      const p = { p: 0 }
      gsap.to(p, {
        p: 1, 
        duration: generalDuration,
        onComplete: () => { 
          gsap.to(actions['Armature.001Action'], {
            time: 2.9,
            duration: 4,
          })
          screen.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
        }
      })
    } else {
      setTimeout(() => {
        reset()
      }, 2000);
    }

  }, [props.positivePartState])


  return (
    <group {...props} dispose={null}>

      <group ref={group}  dispose={null}>
        <group name="Scene">
          <group
            name="Armature001"
            position={[-9.24, 2.9, 2.2]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={.68}
          >
            <primitive object={manguera.nodes.Bone} />
            <skinnedMesh
              name="DIA_COCHE_Manguera5"
              geometry={manguera.nodes.DIA_COCHE_Manguera5.geometry}
              material={ props.materials.baked }
              skeleton={manguera.nodes.DIA_COCHE_Manguera5.skeleton}
            />
            <skinnedMesh
              name="DIA_COCHE_MANGUERA_COCHE"
              geometry={manguera.nodes.DIA_COCHE_MANGUERA_COCHE.geometry}
              material={ props.materials.baked }
              skeleton={manguera.nodes.DIA_COCHE_MANGUERA_COCHE.skeleton}
            />
          </group>
          
        </group>
      </group>

      <CarPath 
        material={ props.materials.baked }
        positivePartState={ props.positivePartState }
        windowsInitialColor={ windowsInitialColor }
        windowsLightColor={ windowsLightColor }
        shadowColor={ props.elementColor }
        generalDuration={ generalDuration }
      />

      <group scale={ 3.44 } position={ [ 0, 2.2, 0 ] }>
        <mesh
          ref={ screen }
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_COCHE_PANTALLA_SURTIDOR.geometry}
          material={nodes.DIA_COCHE_PANTALLA_SURTIDOR.material}
          position={[-2.677, -0.044, 0.259]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.513, 0.92, 1.236]}
        >
          <meshBasicMaterial color={ windowsInitialColor } />
        </mesh>
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_COCHE_SURTIDOR.geometry}
          material={ props.materials.baked }
          position={[-2.677, -0.044, 0.259]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.513, 0.92, 1.236]}
        />
      </group>
      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } secondMaterial={ props.materials.baked_floor_2 } secondFloorMaterial={ props.materials.baked_floor_3 } transition={ { duration: 1, delay: 5.7 } } trigger={ props.positivePartState } color={ props.elementColor } />
      <BaseTube matrixAutoUpdate={ props.matrixAutoUpdate } position={ [ -12.65, .002, 11 ] } rotation={ [ 0, Math.PI * .8, 0 ] } color={ props.elementColor } positivePart={ props.positivePart } expStep={ props.expStep } positivePartState={ props.positivePartState } />
    </group>
  );
}