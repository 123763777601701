import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef, useState } from "react";
import Sizes from "../../Utils/Sizes";
import WindowHandler from "../../Utils/WindowHandler";

let cursor

export function hideCursor(){
    cursor.current.classList.add('is-hidden');
}

export function showCursor(){
    cursor.current.classList.remove('is-hidden');
}

export function overCursor(){
    cursor.current.classList.add('hover');
}

export function outCursor(){
    cursor.current.classList.remove('hover');
}

export function Cursor() {
    const [load, setLoaded] = useState(false)
    const { width } = Sizes();
    const isTouch = WindowHandler().isTouch

    const reload = () => {
        setLoaded(false)
        setTimeout(() => {
            setLoaded(true)
        }, (width <= 1024) ? 500 : 2000);
    }

    useEffect(() => {
        ScrollTrigger.addEventListener("refresh", reload);
        if (isTouch) {
            cursorWeb.current.classList.add('display-none')
        }
        return () => {
            ScrollTrigger.removeEventListener("refresh", reload);
        }
    }, [])


    const cursorWeb = useRef();
    let iniCurso = 0;

    function movecursor(e) {
        cursor.current.classList.add('active');

        gsap.to(cursorWeb.current, {
            duration: (iniCurso == 0)? 0 : 0.2,
            x: e.clientX,
            y: e.clientY,
        });	

        iniCurso = 1;
    }
    

    useEffect(() => {
        cursor = cursorWeb

        gsap.set(cursorWeb.current, {
            xPercent: -50,
            yPercent: -50,
        });

        document.addEventListener('mousemove', movecursor);
    
        const hoverCursor = document.querySelectorAll("a, button, .faqs__header, input, textarea, .checkbox__container");
    
        hoverCursor.forEach((i) => {
            i.addEventListener('mouseover', overCursor );
            i.addEventListener('mouseout', outCursor );
        });

        return () => {
            document.removeEventListener('mousemove', movecursor);
            hoverCursor.forEach((i) => {
                i.removeEventListener('mouseover', overCursor );
                i.removeEventListener('mouseout', outCursor );
            });
        }
    }, [load])

    return <>
        <div className="cursor" ref={ cursorWeb } id="cursor"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.725 34.725"><path fill="#141515" d="M0 17.363 17.363 0l17.363 17.363-17.363 17.363z" data-name="Rectángulo 10"/></svg></div>      
    </>
	
}