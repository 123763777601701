import { useEffect, useRef, useState } from 'react';
import { Footer } from './Front';
import { gsap } from 'gsap';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { NavLink } from 'react-router-dom';
import Video from './Components/Video';
import preloadImages from '../Utils/preloadImages';
import Image from './Components/Image';
import WindowHandler from '../Utils/WindowHandler';

export function Marca() {
  
	const [loaded, setLoaded] = useState(false);
	const [canPlayVideos, setCanPlayVideos] = useState(false);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const [pageReady, setPageReady] = useState(false);
  const isTouch = WindowHandler().isTouch

	useEffect(() => {
    document.body.classList.remove('page-producto');
    document.body.classList.add('page-marca');
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

    iniScrollSmoother();

    setTimeout(function(){
      animaciones('marca', isTouch);
    },0);

	}, [loaded]);

  useEffect(() => {
    if ((canPlayVideos || isTouch) && imagesLoaded && pageReady) {
      setTimeout(() => {
        setLoaded(true)
      }, 0);
    }
  }, [canPlayVideos, imagesLoaded, pageReady])


  useEffect(() => {
    const onPageLoad = () => {
      setPageReady(true);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

	useEffect(() => {
    const allImg = document.querySelectorAll('img')
    const p = []
    allImg.forEach((img) => {
      p.push(img.src)
    })
    preloadImages(p, () => setImagesLoaded(true))
	}, []);

  const [sliderState, setSliderState] = useState(1)

// setTimeout(function(){
//   let aniSliderTxt = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
//   aniSliderTxt.to(".slider__cont_1 .ail_line div",{y: 0,rotate:0,stagger: 0.1},0);
//   aniSliderTxt.play();
// },1000)

	const slider = (e, n) => {
		e.preventDefault();
    
    let aniSliderTxtR = gsap.timeline({defaults: {duration: 1.2,ease: 'cubic'}});
		aniSliderTxtR.to('.slider__cont_'+(sliderState)+" .ail_line div",{y: "120%"},0);
    aniSliderTxtR.to('.slider__cont_'+(sliderState + n)+" .ail_line div",{y: 0,rotate:0,stagger: 0.1},0);
		aniSliderTxtR.play();
   
		setSliderState(sliderState + n)
	};

	return (
		<>
<div id="smooth-wrapper">
  <div id="smooth-content">
    <div className="header header1 header-marca">
      <div className="header__data">
        <div className="pieza pieza-header">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>

        <div className="header__pretitulo ani_ini_mask_fade">
          <span>NUESTRA COMPAÑIA</span>
        </div>
       
        <div className='header__titulo_ani'>
          <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>DESTACANDO</span><span className='linet linet2'>CON CONCIENCIA</span><span className='linet linet3'>Y ÉTICA.</span></div></div>
          <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>DESTACANDO</span><span className='linet linet2'>CON CONCIENCIA</span><span className='linet linet3'>Y ÉTICA.</span></div></div>
          <div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>DES</span>TACA<span className='ocu'>NDO</span></span><span className='linet linet2'><span className='ocu'>CON </span>CONCIE<span className='ocu'>NCIA</span></span><span className='linet linet3'><span className='ocu'>Y </span>ÉTICA.</span></div></div>
        </div>
        

        <div className="cita cita-header">
          <div className="cita__title ani_ini_mask">
            <span>Redefiniendo los diamantes</span>
          </div>
          <div className="cita__cita ani_ini_line">
            Únete a la revolución de los diamantes sostenibles y éticos de Dianelum
          </div>
        </div>
      </div>

      <div className="header__ani_right">
        <svg xmlns="http://www.w3.org/2000/svg" className="header__ani_rombo" viewBox="0 0 1452.165 841.42">
          <path fill="#81bc8f" d="M-.002 420.711 726.085.002l726.08 420.711-726.08 420.709Z" data-name="Unión 5" />
        </svg>
        <div className="header__ani">
          <Video 
            src={ 'DIA_2' }
            poster={ '/images/DIA_2.png' }
            autoPlay={ true }
            triggerPlay={ { trigger: loaded, delay: .5 } }
          />
        </div>
      </div>

      <div className="header__ani_left">
        <svg xmlns="http://www.w3.org/2000/svg" className="header__ani_rombo" viewBox="0 0 1452.165 841.42">
          <path fill="#81bc8f" d="M-.002 420.711 726.085.002l726.08 420.711-726.08 420.709Z" data-name="Unión 5" />
        </svg>
        <div className="header__ani">
          <Video 
            src={ 'DIA_1' }
            poster={ '/images/DIA_1.png' }
            autoPlay={ true }
            triggerPlay={ { trigger: loaded, delay: .8 } }
            onCanPlay={ () => setCanPlayVideos(true) }
          />
        </div>
      </div>

      <div className="header__arrow">
        <a href="#" onClick={()=> ancla('dianelum')}
          className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
          <span className="btn__rombo_cont"></span>
          <span className="btn__rombo_txt">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                  <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                  <path d="M7.543 13.804V0" data-name="Línea 43" />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div className="header__back"></div>
    </div>

    <div className="bloque__blanco" id="dianelum">
      <div className="bloque__blanco-fondo"></div>

      <div className="pieza pieza-blanco pieza-center pieza-2 ani-scroll-rotate">
        <span className="pieza__line pieza__1"></span>
        <span className="pieza__line pieza__2"></span>
        <span className="pieza__rombo pieza__5"></span>
      </div>

      <div className="texto__grande-marca">
        Dianelum es una distribuidora mundial de diamantes creados en
        laboratorio a partir de un carbono exclusivo y utilizando energías
        renovables.
      </div>

      <div className="bloque__blanco-diamante">
        <svg xmlns="http://www.w3.org/2000/svg" className="ani-scroll-rotate" viewBox="0 0 120.322 120.321">
          <g fill="none" stroke="#1c1e1d" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6991">
            <path d="m60.161.75-42.01 17.4-17.4 42.01 17.4 42.01 42.01 17.4 42.01-17.4 17.4-42.01-17.4-42.01Z" data-name="Trazado 37434" />
            <path strokeWidth="1" d="m9.448 81.167 29.73-71.725 71.725 29.73-29.73 71.725z" data-name="Rectángulo 70" />
            <path d="M9.448 39.154 81.163 9.449l29.706 71.716-71.715 29.705z" data-name="Rectángulo 71" />
            <path d="M47.989 60.163h24.342" data-name="Línea 56" />
            <path d="M60.16 72.334V47.992" data-name="Línea 57" />
          </g>
        </svg>
      </div>

      <div className="texto__pequeno-marca ani-scroll-text-small">
        Dianelum destaca como una empresa comprometida con la
        responsabilidad ecológica. Nuestros diamantes, con el mismo brillo
        y pureza que los de mina, ofrecen una alternativa ética y de
        alta calidad para aquellos que buscan joyas con conciencia.
      </div>

      <div className={"slider slider_active_" + sliderState}>
        <div className="slider__line1"></div>
        <div className="slider__line2"></div>

        <div className="slider__rombo">
          <div className="slider__rombo_figura">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 367.575 367.575">
              <path fill="#fff" stroke="#141515" d="M.707 183.787 183.787.707l183.081 183.08-183.08 183.08z" data-name="Rectángulo 2620" />
            </svg>
          </div>
          <div className="slider__rombo_data">
            <div className="slider__rombo_data_inner">
              <span className='ani-scroll-enter'>01</span>
              <span>02</span>
              <span>03</span>
              <span>04</span>
            </div>
          </div>
        </div>

        <div className="slider__data">
          <div className="slider__title">
            <div className="slider__title__inner">
              <div className="slider__title_title slider__title_1">
                Huella de carbono 0
              </div>
              <div className="slider__title_title slider__title_2">
                Garantia certificada
              </div>
              <div className="slider__title_title slider__title_3">
                Calidad y lujo
              </div>
              <div className="slider__title_title slider__title_4">
                Exclusividad garantizada
              </div>
            </div>
          </div>

          <div className="slider__cont">
            <div className="slider__cont__inner">
              <div className="slider__cont_cont slider__cont_1">
                Creamos diamantes sostenibles sin emisiones de carbono.
              </div>
              <div className="slider__cont_cont slider__cont_2">
                Nuestros diamantes cuentan con certificación de institutos
                independientes reconocidos, como el Instituto Gemológico
                Internacional.
              </div>
              <div className="slider__cont_cont slider__cont_3">
                Brindamos una propuesta de lujo al ofrecer un producto que
                se guarda en bancos de diamantes.
              </div>
              <div className="slider__cont_cont slider__cont_4">
                Nuestros diamantes son exclusivos, ya que se producen
                utilizando carbonos limitados bajo licencias controladas.
              </div>
            </div>
          </div>
        </div>

        <div className="slider__figuras">
          <div className="slider__figuras__figura slider__ff1">
            {/* <img src="/images/slider-huella.png" alt="Huella de carbono 0" /> */}
            <Video
              src="DIA_4"
              poster="/images/slider-huella.png"
              loop={ true }
            />
          </div>
          <div className="slider__figuras__figura slider__ff2">
            {/* <img src="/images/slider-garantia.png" alt="Garantia certificada" /> */}
            <Video
              src="DIA_5"
              poster="/images/slider-garantia.png"
              loop={ true }
            />
          </div>
          <div className="slider__figuras__figura slider__ff3">
            {/* <img src="/images/slider-calidad.png" alt="Calidad y lujo" /> */}
            <Video
              src="DIA_6"
              poster="/images/slider-calidad.png"
              loop={ true }
            />
          </div>
          <div className="slider__figuras__figura slider__ff4">
            {/* <img src="/images/slider-exclusividad.png" alt="Exclusividad garantizada" /> */}
            <Video
              src="DIA_7"
              poster="/images/slider-exclusividad.png"
              loop={ true }
            />
          </div>
        </div>

        <div className="slider__controller">
          <a href="#" onClick={(e)=> slider(e, -1)} className="btn__rombo btn__rombo_svg btn__rombo_svg-blanco slider__controller-left">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421" />
                    <path d="M14.644 12.156h12.947" data-name="Línea 44" />
                  </g>
                </svg>
              </span>
            </span>
          </a>

          <a href="#" onClick={(e)=> slider(e, 1)} className="btn__rombo btn__rombo_svg btn__rombo_svg-blanco slider__controller-right">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421" />
                    <path d="M14.644 12.156h12.947" data-name="Línea 44" />
                  </g>
                </svg>
              </span>
            </span>
          </a>
        </div>
      </div>

      <div className="bloque__triangulo bloque__triangulo-producto">
        
        <div className="bloque__triangulo__titulo btt1">NUESTRA</div>
        <div className="bloque__triangulo__titulo btt2">MISIÓN</div>

        <div className="bloque__triangulo__tri">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 730.785 632.763">
            <path fill="#81bc8f" d="m365.392 0 365.393 632.763H0Z" data-name="Polígono 12" />
          </svg>
          {/* <img src="/images/nuestra-mision.png" alt="Nuestra misión" /> */}
          <Video
            src="DIA_8"
            poster="/images/nuestra-mision.png"
            loop={ true }
            className='img__diana'
          />
        </div>
        <div className="bloque__triangulo__txt">
          <span>Redefining Diamonds</span>
        </div>

        <div className="bloque__triangulo__medium bloque__triangulo__medium-marca kentoMedium ani-scroll-enter">
          Invierte con Dianelum y multiplica tus ganancias en 5 años
        </div>

        <div className="bloque__triangulo__rombodeco ani_scroll_rombodeco">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404" />
          </svg>
        </div>

        <div className="cita">
          <div className="cita__title ani_scroll_mask">
            <span>Proposito y desafio</span>
          </div>
          <div className="cita__cita ani_scroll_line">
            Dianelum ofrece a los inversores un préstamo participativo con
            rendimientos incrementales durante 5 años. Al finalizar,
            pueden recuperar su inversión o convertirla en acciones de la
            compañía, brindando flexibilidad y opciones adaptadas a sus
            necesidades y objetivos.
          </div>
        </div>
      </div>
    </div>

    <div className="bloque__vision">
      <div className="bloque__vision_fondo"></div>
      <div className="bloque__vision_maque">
        <div className="marquesina__scroll">
          <div className="marquesina__scroll_inner">
            &nbsp;VISIÓN&nbsp;
            <img src='/images/estrella1.svg' alt='Estrella' />
            &nbsp;VISIÓN&nbsp;
            <img src='/images/estrella1.svg' alt='Estrella' />
            &nbsp;VISIÓN&nbsp;
            <img src='/images/estrella1.svg' alt='Estrella' />
            &nbsp;VISIÓN&nbsp;
            <img src='/images/estrella1.svg' alt='Estrella' />
            &nbsp;VISIÓN&nbsp;
            <img src='/images/estrella1.svg' alt='Estrella' />
          </div>
        </div>

        <div className="vision_figura1_fondo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 542.021 620.246"><path fill="#81bc8f" d="M0 0h542.021v620.246H0z" data-name="Rectángulo 2400"/></svg>
        </div>
        <div className="vision_figura1_figura">
          {/* <img src="/images/vision-1.png" alt="Imagen Visión 1" /> */}
          <Video
            src="DIA_9"
            poster="/images/vision-1.png"
            loop={ true }
          />
        </div>

        <div className="vision_figura2_fondo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 821.076 821.076"><path fill="#81bc8f" d="M0 410.538 410.538 0l410.539 410.538-410.539 410.538z" data-name="Rectángulo 2428" /></svg>
        </div>
        <div className="vision_figura2_figura">
          {/* <img src="/images/vision-2.png" alt="Imagen Visión 2" /> */}
          <Video
            src="DIA_16"
            poster="/images/vision-2.png"
            loop={ true }
          />
        </div>
      </div>

      <div className="bloque__vision__txtbig_container">
        <div className="bloque__vision__txtbig bloque__vision__txtbig-marca ani-scroll-enter">
          Nuestra visión es un futuro en el que el lujo y la
          responsabilidad medioambiental estén entrelazados.
        </div>
        <div className="bloque__vision__txtbig-line">
          <div className="line line-h-l-r"></div>
        </div>
        <div className="bloque__vision__txtbig-cols">
          <div>
            <span>Un futuro sostenible y lujoso</span>
          </div>
          <div>
            <span>Nuestra visión en acción</span>
          </div>
        </div>

        <div className="bloque__vision__txtbig-cols bloque__vision__txtbig-cols-bottom">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="ani-scroll-rotate bloque__vision__figura" viewBox="0 0 120.322 120.321">
              <g fill="none" stroke="#1c1e1d" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6991">
                <path d="m60.161.75-42.01 17.4-17.4 42.01 17.4 42.01 42.01 17.4 42.01-17.4 17.4-42.01-17.4-42.01Z" data-name="Trazado 37434"></path>
                <path strokeWidth="1" d="m9.448 81.167 29.73-71.725 71.725 29.73-29.73 71.725z" data-name="Rectángulo 70"></path>
                <path d="M9.448 39.154 81.163 9.449l29.706 71.716-71.715 29.705z" data-name="Rectángulo 71"></path>
                <path d="M47.989 60.163h24.342" data-name="Línea 56"></path>
                <path d="M60.16 72.334V47.992" data-name="Línea 57"></path>
              </g>
            </svg>
          </div>

          <div>
            <div className="cita">
              <div className="cita__title ani_scroll_mask">
                <span>Un futuro más responsable</span>
              </div>
              <div className="cita__cita ani_scroll_line_p">
                <p>
                  Queremos ser una fuente de inspiración para que otros
                  adopten prácticas sostenibles y valoren productos que no
                  comprometan nuestro planeta.
                </p>
                <p>
                  Nos esforzamos por ser reconocidos como una marca líder
                  en el mercado, destacando por la calidad excepcional de
                  nuestros productos y nuestro compromiso con la
                  sostenibilidad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bloque__listado bloque__listado-marca">
      <div className="bloque__listado_item" id="img__innovacion">
        <div className="bloque__listado_item-img-movil">
          <Image src="images/listado-item-innovacion-movil.png" />
        </div>
        <div className="bloque__listado_item-num">
          <span>01</span>
        </div>
        <div className="bloque__listado_item-titulo">INNOVACIÓN</div>
        <div className="bloque__listado_item-txt">Impulsamos el desarrollo y la innovación, rompiendo con modelos obsoletos y construyendo un futuro en constante evolución.</div>
        <div className="bloque__listado_item-linea">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
          <span className="line"></span>
        </div>
      </div>

      <div className="bloque__listado_item" id="img__sostenibilidad">
        <div className="bloque__listado_item-img-movil">
          <Image src="images/listado-item-sostenibilidad-movil.png" />
        </div>
        <div className="bloque__listado_item-num">
          <span>02</span>
        </div>
        <div className="bloque__listado_item-titulo">Sostenibilidad</div>
        <div className="bloque__listado_item-txt">El pilar fundamental de Dianelum que hace posible el cambio.</div>
        <div className="bloque__listado_item-linea">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
          <span className="line"></span>
        </div>
      </div>

      <div className="bloque__listado_item" id="img__revolucion">
        <div className="bloque__listado_item-img-movil">
          <Image src="images/listado-item-revolucion-movil.png" />
        </div>
        <div className="bloque__listado_item-num">
          <span>03</span>
        </div>
        <div className="bloque__listado_item-titulo">Revolución</div>
        <div className="bloque__listado_item-txt">Transformamos la sostenibilidad apoyando el desarrollo de nuevas soluciones energéticas.</div>
        <div className="bloque__listado_item-linea">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
          <span className="line"></span>
        </div>
      </div>

      <div className="bloque__listado_item" id="img__equilibrio">
        <div className="bloque__listado_item-img-movil">
          <Image src="images/listado-item-equilibrio-movil.png" />
        </div>
        <div className="bloque__listado_item-num">
          <span>04</span>
        </div>
        <div className="bloque__listado_item-titulo">Equilibrio</div>
        <div className="bloque__listado_item-txt">Comprometidos con la estabilidad climática y las comunidades vulnerables.</div>
        <div className="bloque__listado_item-linea">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
          <span className="line"></span>
        </div>
      </div>

      <div className="bloque__listado_item" id="img__dignidad">
        <div className="bloque__listado_item-img-movil">
          <Image src="images/listado-item-dignidad-movil.png" />
        </div>
        <div className="bloque__listado_item-num">
          <span>05</span>
        </div>
        <div className="bloque__listado_item-titulo">DIGNIDAD</div>
        <div className="bloque__listado_item-txt">Ayudamos a evitar y eliminar la explotación laboral e infantil de las prácticas mineras tradicionales</div>
        <div className="bloque__listado_item-linea">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
          <span className="line"></span>
        </div>
      </div>
    </div>

    <div className="bloque__verde">
     
      {/* CABECERA INTERIOR */}
      <div className="bloque__txtc">
        <div className="pieza pieza__grande bloque__txtc_pieza ">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>

        <div className="bloque__txtc__pretitulo">
          <span>SOSTENIBILIDAD E INNOVACIÓN</span>
        </div>
        <div className="bloque__txtc__titulo">
          <div className='int_header__titulo_ani  --center'>
            <div className='int_header__titulo_ani_1'><div className='tit'><span className='linet linet1'>FORJANDO</span><span className='linet linet2'>UN FUTURO MÁS</span><span className='linet linet3'>SOSTENIBLE</span></div></div>
            <div className='int_header__titulo_ani_2'><div className='tit'><span className='linet linet1'>FORJANDO</span><span className='linet linet2'>UN FUTURO MÁS</span><span className='linet linet3'>SOSTENIBLE</span></div></div>
            <div className='int_header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>FO</span>RJAN<span className='ocu'>DO</span></span><span className='linet linet2'><span className='ocu'>UN </span>FUTURO<span className='ocu'> MÁS</span></span><span className='linet linet3'><span className='ocu'>SO</span>STENIB<span className='ocu'>LE</span></span></div></div>
          </div>
        </div>

        <div className="bloque__txtc__imgani">
          <div className="bloque__txtc__imgani_cont">
            <img src="images/animaciones/header_int_sostenibilidad/DIA_12000.png" id='int_header_img' className='int_header__ani_img' />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 761.398 442.169"><path fill="#81bc8f" d="M0 221.087 380.7 0l380.7 221.084-380.7 221.085Z" data-name="Unión 74"/></svg>
          </div>
        </div>

        {/* <div className="cita">
          <div className="cita__title ani_scroll_mask">
            <span>Forjando un mañana ético</span>
          </div>
          <div className="cita__cita ani_scroll_fade">
            Nuestra pasión por la excelencia y el respeto al medio
            ambiente nos guía hacia una joyería ética y deslumbrante.
          </div>
        </div> */}
      </div>
 {/* CABECERA INTERIOR */}


      <div className="bloque__colscroll">
        <div className="bloque__colscroll_colimgs" id="bloque__colscroll_colimgs">

          <div className='bloque__colscroll_colimgs_ani' id='bloque__colscroll_colimgs_ani'>
            <div className='bloque__colscroll_colimgs_ani_rombo'></div>
            {/* <img src="images/evitando-la-mineria.png" id="colscroll_1_img" alt="Imagen animacion 1" /> */}
            <Video
              id="colscroll_1_img"
              src="DIA_17"
              poster="images/evitando-la-mineria.png"
              loop={ true }
            />
            {/* <img src="images/huella-de-carbono-cero.png" id="colscroll_2_img" alt="Imagen animacion 2" /> */}
            <Video
              id="colscroll_2_img"
              src="DIA_18"
              poster="images/huella-de-carbono-cero.png"
              loop={ true }
            />
            {/* <img src="images/acercando-el-futuro.png" id="colscroll_3_img" alt="Imagen animacion 3" /> */}
            <Video
              id="colscroll_3_img"
              src="DIA_19"
              poster="images/acercando-el-futuro.png"
              loop={ true }
            />
            {/* <img src="images/investigacion-y-desarrollo.png" id="colscroll_4_img" alt="Imagen animacion 4" /> */}
            <Video
              id="colscroll_4_img"
              src="DIA_20"
              poster="images/investigacion-y-desarrollo.png"
              loop={ true }
            />
          </div>
          
        </div>
        <div className="bloque__colscroll_coldata">
          <div className="bloque__colscroll_info-space"></div>
          <div className="bloque__colscroll_info" id="colscroll_1">
            <div className="bloque__colscroll_info_img-movil">
              <Image src="images/listado-item-evitando-movil.png" />
            </div>
            <div className="bloque__colscroll_info_number">01</div>
            <div className="bloque__colscroll_info_title">
              Evitando la minería
            </div>
            <div className="bloque__colscroll_info_desc">
              Dianelum ofrece una solución sin minería, evitando la
              explotación laboral y el daño ambiental irreversible.
              <br />
              <br />
              En países como la República Democrática del Congo, donde 4
              de cada 10 niños son víctimas de trabajo infantil en minas
              de diamantes, esta innovación ayuda a poner fin a tan
              alarmantes cifras.
            </div>
          </div>
          <div className="bloque__colscroll_info" id="colscroll_2">
            <div className="bloque__colscroll_info_img-movil">
              <Image src="images/listado-item-huella-movil.png" />
            </div>
            <div className="bloque__colscroll_info_number">02</div>
            <div className="bloque__colscroll_info_title">
              Huella de carbono cero
            </div>
            <div className="bloque__colscroll_info_desc">
              Creamos nuestros diamantes utilizando reactores de plasma y
              energías renovables, eliminando por completo su huella de
              carbono, ayudando a evitar la minería y sus impactos
              contaminantes como son gases y residuos generados en el
              proceso de extracción.
            </div>
          </div>
          <div className="bloque__colscroll_info" id="colscroll_3">
            <div className="bloque__colscroll_info_img-movil">
              <Image src="images/listado-item-futuro-movil.png" />
            </div>
            <div className="bloque__colscroll_info_number">03</div>
            <div className="bloque__colscroll_info_title">
              Acercando el futuro
            </div>
            <div className="bloque__colscroll_info_desc">
              Nuestra empresa se caracteriza por su innovación, basada en
              tres principios fundamentales: lujo ético, sostenibilidad y
              futuro.
              <br />
              <br />
              Innovamos hacia un futuro sostenible con soluciones
              exclusivas respaldadas por energías verdes ilimitadas.
            </div>
          </div>
          <div className="bloque__colscroll_info" id="colscroll_4">
            <div className="bloque__colscroll_info_img-movil">
              <Image src="images/listado-item-investigacion-movil.png" />
            </div>
            <div className="bloque__colscroll_info_number">04</div>
            <div className="bloque__colscroll_info_title">
              Investigación y desarrollo
            </div>
            <div className="bloque__colscroll_info_desc">
              En Dianelum, invertimos en investigación y desarrollo para
              crear diamantes de laboratorio de máxima calidad y pureza.
              Nuestros avances nos acercan cada vez más a un futuro donde
              la belleza y la sostenibilidad se combinan.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bloque__negro headerVerde">
      <div className="bloque__equipo">
        <div className="bloque__equipo_item">
          <div className="bloque__equipo_nombre bloque__equipo_nombre1 --1">
            <img src='/images/estrella2.svg' alt='Estrella' />JONATHAN
          </div>
          <div className="bloque__equipo_img">
            <img src="images/animaciones/david_locco/DIA_2100.png" id="team_img" alt="Imagen Equipo" draggable="false" />
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2 --1">
            GONZÁLEZ<img src='/images/estrella2.svg' alt='Estrella' />CEO
          </div>

          <div className="bloque__equipo_nombre bloque__equipo_nombre1 --2">
            <img src='/images/estrella2.svg' alt='Estrella' />SONIA
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2 --2">
            TORRUBIANO<img src='/images/estrella2.svg' alt='Estrella' />COO
          </div>

          <div className="bloque__equipo_nombre bloque__equipo_nombre1 --3">
           <img src='/images/estrella2.svg' alt='Estrella' />JONATHAN
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2 --3">
            ESTRADA<img src='/images/estrella2.svg' alt='Estrella' />CFO
          </div>

          <div className="bloque__equipo_nombre bloque__equipo_nombre1 --4">
          SEDE<img src='/images/estrella2.svg' alt='Estrella' />BLOCKCHAIN
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2 --4">
          PARTNER<img src='/images/estrella2.svg' alt='Estrella' />TECNOLÓGICO
          </div>

          <div className="bloque__equipo_nombre bloque__equipo_nombre1 --5">
            <img src='/images/estrella2.svg' alt='Estrella' />SANTIAGO
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2 --5">
            BACH<img src='/images/estrella2.svg' alt='Estrella' />ASESOR
          </div>

        </div>
        <div className="bloque__equipo_item" style={{ opacity: 0 }}>
        <div className="bloque__equipo_nombre bloque__equipo_nombre1">
            <img src='/images/estrella2.svg' alt='Estrella' />SONIA
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2">
            TORRUBIANO<img src='/images/estrella2.svg' alt='Estrella' />COO
          </div>
        </div>
        <div className="bloque__equipo_item" style={{ opacity: 0 }}>
        <div className="bloque__equipo_nombre bloque__equipo_nombre1">
           <img src='/images/estrella2.svg' alt='Estrella' />JONATHAN
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2">
            ESTRADA<img src='/images/estrella2.svg' alt='Estrella' />CFO
          </div>

        </div>
        <div className="bloque__equipo_item" style={{ opacity: 0 }}>
        <div className="bloque__equipo_nombre bloque__equipo_nombre1">
            <img src='/images/estrella2.svg' alt='Estrella' />SEDE
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2">
            BLOCKCHAIN<img src='/images/estrella2.svg' alt='Estrella' />EQUIPO TECNOLÓGICO
          </div>

        </div>
        <div className="bloque__equipo_item" style={{ opacity: 0 }}>
        <div className="bloque__equipo_nombre bloque__equipo_nombre1">
            <img src='/images/estrella2.svg' alt='Estrella' />SANTIAGO
          </div>
          <div className="bloque__equipo_nombre bloque__equipo_nombre2">
            BACH<img src='/images/estrella2.svg' alt='Estrella' />ASESOR
          </div>
        </div>

        <div className="end-element"></div>
      </div>

      <div className="bloque__hablamos bloque__hablamos-marca cta-hablamos black">
        <div className="bloque_hablamos_txt">
          Estamos aquí para resolver tus dudas.
        </div>
        <div className="bloque__hablamos_cont_btn">
          <NavLink to="/contacto" className="btn_hablamos">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1436.96 453.688"><g stroke="#9bcaa6" data-name="Grupo 7722"><g fill="none" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6307"><path d="M1436.21 338.157V115.538L1321.428.75H115.532L.75 115.538v222.619l114.782 114.782h1205.9Z" data-name="Trazado 37422"/><path d="M.752 115.535 718.479.753l717.733 114.782" data-name="Trazado 37423"/><path d="m.752 338.157 717.727 114.782 717.733-114.782" data-name="Trazado 37424"/><path d="M115.53.751V452.94" data-name="Línea 45"/><path d="M1321.431.751V452.94" data-name="Línea 46"/></g><g data-name="Grupo 6309"><path fill="none" strokeMiterlimit="10" d="M744.237 74.461h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.06 74.461h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.681 101.019V47.905" data-name="Línea 65"/><path fill="#141515" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="none" strokeMiterlimit="10" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g><g data-name="Grupo 6310"><path fill="none" strokeMiterlimit="10" d="M744.236 388.808h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.059 388.808h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.68 415.366v-53.114" data-name="Línea 65"/><path fill="#96c495" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="#141515" strokeMiterlimit="10" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g></g></svg>
            <span>HABLAMOS</span>
          </NavLink>
        </div>
      </div>
    </div>

    <Footer tipo="verde" />
  </div>
</div>
<div className="bloque__listado_hover">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 357">
    <path fill="#9bcaa6" d="M0 178.5 308 0l308 178.5L308 357Z" data-name="Unión 1" />
  </svg>
  {/* <img src="/images/listado-item-innovacion.png" className="bloque__listado_hover_img" id="img__innovacion_img" /> */}
  <Video
    className="bloque__listado_hover_img" 
    id="img__innovacion_img"
    src="DIA_11"
    poster="/images/listado-item-innovacion.png"
    loop={ true }
  />
  <Video
    className="bloque__listado_hover_img" 
    id="img__sostenibilidad_img"
    src="DIA_12"
    poster="/images/listado-item-sostenibilidad.png"
    loop={ true }
  />
  <Video
    className="bloque__listado_hover_img" 
    id="img__revolucion_img"
    src="DIA_40"
    poster="/images/listado-item-revolucion.png"
    loop={ true }
  />
  <Video
    className="bloque__listado_hover_img" 
    id="img__equilibrio_img"
    src="DIA_14"
    poster="/images/listado-item-equilibrio.png"
    loop={ true }
  />
  <Video
    className="bloque__listado_hover_img" 
    id="img__dignidad_img"
    src="DIA_15"
    poster="/images/listado-item-dignidad.png"
    loop={ true }
  />
  {/* <img src="/images/listado-item-sostenibilidad.png" className="bloque__listado_hover_img" id="img__sostenibilidad_img" />
  <img src="/images/listado-item-revolucion.png" className="bloque__listado_hover_img" id="img__revolucion_img" />
  <img src="/images/listado-item-equilibrio.png" className="bloque__listado_hover_img" id="img__equilibrio_img" />
  <img src="/images/listado-item-dignidad.png" className="bloque__listado_hover_img" id="img__dignidad_img" /> */}
</div>
		</>
	);
}

// IMG TO VIDEO BRANCH