import { PerspectiveCamera, useAnimations, useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { CustomEase, Power1, gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";
import * as THREE from 'three'
gsap.registerPlugin(CustomEase)
const eases = [
  CustomEase.create("custom", "M0,0,C0,0,0.143,0,0.225,0.009,0.246,0.011,0.263,0.018,0.282,0.028,0.303,0.039,0.318,0.05,0.335,0.067,0.356,0.089,0.369,0.107,0.385,0.135,0.405,0.17,0.415,0.195,0.43,0.235,0.448,0.284,0.572,0.804,0.778,0.938,0.889,1.01,1,1,1,1"),
  Power1.easeInOut
]

const timeStamps = [
  { frames: 0, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 0, slowFrames: 0 }, // MINA
  { frames: 11.3, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 10, slowFrames: 1.4 }, // CHIMENEAS
  { frames: 19.5, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 15, slowFrames: 1.5 }, // MINERO
  { frames: 27, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 15, slowFrames: 1 }, // MINA CIELO ABIERTO
  { frames: 35.5, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 15, slowFrames: .5 }, // BALAS
  { frames: 44.7, duration: 1.5, accelerate: 0, decelerate: 1, slowDur: 15, slowFrames: 1.6 }, // VAGONETA
  { frames: 64, duration: 8, accelerate: 0, decelerate: 1, slowDur: 0, slowFrames: 0 }, // PORTAL
]

const stamp7 = (camera, endCallback, loupeFalse, expStep_PREV, stepBack, stepForward) => {
  loupeFalse()
  gsap.to(camera.position, {
    z: .9,
    y: 0,
    duration: 4,
    ease: eases[1],
    onComplete: () => {
      endCallback()
      if (expStep_PREV === 8) {
        stepBack()
      } else if (expStep_PREV === 6) {
        stepForward()
      }
    }
  })
}

const stamp8 = (camera, endCallback, loupeTrue) => {
  gsap.to(camera.position, {
    y: -.3,
    duration: 4,
    ease: eases[1],
    onComplete: () => {
      loupeTrue()
    }
  })
}

const stamp9 = (camera, endCallback, loupeFalse, expStep_PREV, stepBack, stepForward) => {
  loupeFalse()
  gsap.to(camera.position, {
    y: -.6,
    z: .9,
    duration: 4,
    ease: eases[1],
    onComplete: () => {
      endCallback()
      if (expStep_PREV === 10) {
        stepBack()
      } else if (expStep_PREV === 8) {
        stepForward()
      }
    }
  })
}

const stamp10 = (camera, endCallback) => {
  gsap.to(camera.position, {
    y: -.52,
    z: 1.4,
    duration: 4,
    ease: eases[1],
  })
}
const stamp11 = (camera, endCallback) => {
  gsap.to(camera.position, {
    y: -.52,
    z: 1.8,
    duration: 4,
    ease: eases[1],
    onComplete: () => {
      endCallback()
    }
  })
}

let tween1 = [null, null], tween2 = [null, null]

function cameraPathAnimation(actions, stamp, endCallback, cameraWrapper, expStep_PREV, loupeTrue, loupeFalse, stepBack, stepForward, cameraMoveSound, volume) {
    if (tween1[0] !== null) { tween1[0].kill(); tween1[1].kill() }
    if (tween2[0] !== null) { tween2[0].kill(); tween2[1].kill() }
    if (stamp > 6) {

      if (stamp === 7) stamp7(cameraWrapper, endCallback, loupeFalse, expStep_PREV, stepBack, stepForward)
      if (stamp === 8) stamp8(cameraWrapper, endCallback, loupeTrue)
      if (stamp === 9) stamp9(cameraWrapper, endCallback, loupeFalse, expStep_PREV, stepBack, stepForward)
      if (stamp === 10) stamp10(cameraWrapper, endCallback)
      if (stamp === 11) stamp11(cameraWrapper, endCallback)

    } else {
      for (let i = 0; i < actions.length; i++) {
        tween1[i] = gsap.to(actions[i], {
          time: timeStamps[stamp].frames,
          duration: (expStep_PREV === 6 && stamp === 5) ? 8 : timeStamps[stamp].duration,
          ease: CustomEase.create("custom", "M0,0,C0,0,0.044,0.003,0.069,0.009,0.092,0.015,0.109,0.021,0.129,0.033,0.175,0.063,0.207,0.087,0.249,0.125,0.278,0.151,0.293,0.172,0.318,0.204,0.347,0.241,0.364,0.263,0.388,0.304,0.466,0.438,0.502,0.522,0.582,0.655,0.624,0.724,0.653,0.763,0.702,0.825,0.727,0.858,0.746,0.876,0.776,0.903,0.803,0.926,0.821,0.94,0.851,0.957,0.876,0.972,0.889,0.974,0.916,0.982,0.942,0.989,1,0.99,1,0.99"),
          onComplete: () => {
            if (stamp !== 6) {
              tween2[i] = gsap.to(actions[i], {
                time: timeStamps[stamp].frames + timeStamps[stamp].slowFrames,
                duration: timeStamps[stamp].slowDur,
                ease: Power1.easeOut,
              })
            }
          }
        })
      }
      if (stamp === 6) {
        gsap.to(cameraWrapper.position, {
          z: .4,
          y: .02,
          duration: 4.5, 
          delay: 3.5,
        })
      } else if (stamp === 5) {
        gsap.to(cameraWrapper.position, {
          z: 0,
          y: 0,
          duration: 4
        })
      }

      
      if (stamp !== 6 && !(stamp === 5 && expStep_PREV === 6) && !(stamp === 0 && expStep_PREV === 0)) {
        if (volume.current)
          cameraMoveSound.current.play()
      }
    }
    
} 

export function PathingScene(props) {
  const group = useRef();
  const { animations } = useGLTF("/DIA_RECORRIDO_CAMARA.glb");
  const { actions } = useAnimations(animations, group);
  const actionsRef = useRef([])
  const cameraWrapper = useRef()

  const cameraMoveSound = useRef()

  useEffect(() => {
    const paused = true

    cameraMoveSound.current = new Audio('/sounds/camera_move.mp3')
    cameraMoveSound.current.volume = .25

    actionsRef.current = [
      actions['TRASLATION|_CAMERA_BAKED_2|BaseLayer'],
      actions['camera1|_CAMERA_BAKED_2|BaseLayer.001']
    ]

    for (let i = 0; i < actionsRef.current.length; i++) {
      actionsRef.current[i].timeScale = 1.5
      actionsRef.current[i].play().paused = paused
    }
  }, [])

  useEffect(() => {
    if (props.renderEverything) {
      if (cameraWrapper.current) {
        if (window.innerWidth > 1024)
          cameraWrapper.current.position.z = 3
        props.canRender()
      }
    } 
    if (props.animation) {
      cameraPathAnimation(actionsRef.current, props.expStep, props.endCallback, cameraWrapper.current, props.expStep_PREV, props.loupeTrue, props.loupeFalse, props.stepBack, props.stepForward, cameraMoveSound, props.volume)
      if (props.expStep < 6 && props.expStep_PREV !== 6) {
        cameraWrapper.current.position.z = 0
      }
    } 

  }, [props.animation, props.expStep, props.expStep_PREV, props.renderEverything, props.volume])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        
        <group
          name="curve1"
          position={[0, 0.03, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <group ref={ cameraWrapper }>
          <group
            name="TRASLATION"
            position={[-0.001, 0.061, -0.938]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={0.01}
          >
            <PerspectiveCamera
              name="camera1"
              makeDefault={ props.experience }
              far={600}
              near={1}
              fov={window.innerWidth < 600 ? 70 : 30}
              position={[0, 0, -1.3]}
              rotation={[3.127, 1.473, 1.586]}
            >
              { props.children }
            </PerspectiveCamera>
          </group>

        </group>

      </group>
    </group>
  );
}
