import { MeshPortalMaterial } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { gsap } from "gsap/all";
import * as easing from "maath/easing";
import { useEffect, useRef, useState } from "react";
import WindowHandler from "../Utils/WindowHandler";

export function Lantern({ cursor, hovered, children, expStep, expStep_PREV, setPositivePart, setHiddenBases, blend, show, dianelumnizer, turnDianelumnizerOn, turnDianelumnizerOff }) {

    const lantern = useRef()
    const followcursor = useRef(true)
    const scaleLantern = useRef(1)
    const isTouch = WindowHandler().isTouch

    useEffect(() => {
        if (isTouch) {
            followcursor.current = false
        }
    }, [isTouch])

    useEffect(() => {
        if (expStep === 6 && expStep_PREV === 5) {
            if (isTouch) {
                setHiddenBases(true)
            }
            const pp = {p: 0}
            gsap.to(pp, {
                p: 1,
                delay: 6,
                onComplete: function() {
                    if (!isTouch) {
                        followcursor.current = false
                        setTimeout(() => {
                            setHiddenBases(true)
                        }, 300);
                    }
                }
            })
            gsap.to(scaleLantern, {
                current: 10.5,
                duration: 1,
                delay: /* (isTouch) ? 8.5 :  */7.5,
            })
            const p = {p:0}
            gsap.to(p, {p:1,duration: 7.5,
            onComplete: () => {
                setPositivePart(true)
                turnDianelumnizerOn()
            }})
        } else if (expStep === 5 && expStep_PREV === 6) {
            const pp = {p: 0}
            gsap.to(pp, {
                p: 1,
                delay: 1,
                onComplete: function() {
                    if (!isTouch)
                        followcursor.current = true
                        
                    setHiddenBases(false)
                }
            })
            gsap.to(scaleLantern, {
                current: 1,
                duration: 1,
                onStart: function() {
                    setPositivePart(false)
                    
                }
            })
            if (isTouch)
            turnDianelumnizerOff()
        }
    }, [ expStep, expStep_PREV ])

    useEffect(() => {
        lantern.current.position.x = lantern.current.position.y = 0
        lantern.current.position.z = -1
    }, [])

    const [showLantern, setShowLantern] = useState(false)
    useEffect(() => {
        if (show) {
            const p = {p:0}
            gsap.to(p, {p: 1, duration: 4, onComplete: () => {
                setShowLantern(true)
                document.body.classList.add('show-lantern')
            }})
        }
    }, [show])

    useFrame((state, delta) => {

        if (!isTouch)
        easing.damp3(
            lantern.current.position,
            [
                (cursor.x * 1.05)/*  * (hovered.current ? 1 : 1) */ * (followcursor.current ? 1 : 0),
                (-cursor.y * .5)/*  * (hovered.current ? 1 : 1) */ * (followcursor.current ? 1 : 0),
                -1
            ],
            0.15,
            delta
        )

        let dianMobile = 1
        if (isTouch) {
            if (!dianelumnizer) {
                dianMobile = 0
                //console.log(dianelumnizer);
            }
            else if (dianelumnizer && scaleLantern.current == 1 && expStep <= 6) {
                dianMobile = 11
                //console.log(8.5);
            }
            else if (scaleLantern.current != 1) {
                dianMobile = 1
                //console.log(2);
            }
        }

        easing.damp3(
            lantern.current.scale,
            [
                (.1 + ((hovered.current && followcursor.current) ? .24 : 0)) * scaleLantern.current * dianMobile,
                (.1 + ((hovered.current && followcursor.current) ? .24 : 0)) * scaleLantern.current * dianMobile,
                1
            ],
            0.25,
            delta
        )

    })

    return <>
        <mesh ref={ lantern } scale={ .16 } visible={ (isTouch) ? true : showLantern }>
            <circleGeometry args={[.8, 64]} />
            <MeshPortalMaterial blur={ (isTouch) ? 0 : 0 } transparent worldUnits={ true } blend={ (blend) ? 1 : 0 }>
                { children }
            </MeshPortalMaterial>
        </mesh>
    </>
}