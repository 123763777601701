import { useAnimations, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";

export function ModelCushion(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF(
    "/models/UV/Cushion.glb"
  );

  const { actions } = useAnimations(animations, group);
  
  const cushion1 = useRef()
  const cushion2 = useRef()
  const op = useRef(0)

  useEffect(() => {
    actions.KeyAction.play().paused = true
    cushion1.current.material.transparent = true
    cushion2.current.material.transparent = true
    cushion2.current.material.opacity = 0
  }, [])

  useEffect(() => {
    if (props.expStep === 9 && props.expStep_PREV === 8) {
      gsap.to(actions['KeyAction'], {
        time: 3,
        duration: .3,
        delay: 3.1
      })
      gsap.to(op, {
        current: 1,
        duration: 1,
        delay: 2.4
      })
      gsap.to(cushion1.current.material, {
        opacity: 0,
        duration: 1,
        delay: 2.4
      })
      group.current.rotation.y = 0
    } else if (props.expStep === 8 && props.expStep_PREV === 9) {
      gsap.to(actions['KeyAction'], {
        time: 0,
        duration: .4,
        delay: .3
      })
      gsap.to(op, {
        current: 0,
        duration: 2,
        delay: .3
      })
      gsap.to(cushion1.current.material, {
        opacity: 1,
        duration: 2,
        delay: .3
      })
    }
  }, [props.expStep])

  useFrame((state, delta) => {
    cushion2.current.material.opacity = op.current
    if (!(props.expStep === 10 || props.expStep === 11)) return
    group.current.rotation.y += delta * .3 
  })

  return (
    <group ref={group} {...props} dispose={null}>
    <group name="Scene">
      <mesh
        ref={ cushion1 }
        name="DIA_COJIN"
        geometry={nodes.DIA_COJIN.geometry}
        material={ props.materials.baked }
        morphTargetDictionary={nodes.DIA_COJIN.morphTargetDictionary}
        morphTargetInfluences={nodes.DIA_COJIN.morphTargetInfluences}
      />
      <mesh
        ref={ cushion2 }
        name="DIA_COJIN"
        geometry={nodes.DIA_COJIN.geometry}
        material={ props.materials.baked2 }
        morphTargetDictionary={nodes.DIA_COJIN.morphTargetDictionary}
        morphTargetInfluences={nodes.DIA_COJIN.morphTargetInfluences}
      />
    </group>
  </group>
  );
}