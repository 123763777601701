import React, { useRef } from "react";

export default React.forwardRef( function Base(props, ref) {
    const rotationRef = useRef()

    return (
        <group {...props} dispose={null} ref={ ref }>
            <group
                position={[0, 0.4, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.5}
            >
                <group ref={ rotationRef } rotation-z={ props.yStartRotationPoint || 0 }>
                    
                    <group rotation-x={ -Math.PI * .5 }>
                        { props.children }
                    </group>
                    
                </group>
            </group>
        </group>
    );
})