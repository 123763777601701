import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { ModelBase } from "./ModelBase";

export function ModelLaboratory(props) {
  const { nodes } = useGLTF("/models/UV/Laboratory.glb");

  const antenaRef = useRef()

  useFrame((state) => {
    if (props.expStep === 0) {
      antenaRef.current.rotation.y = Math.cos(state.clock.elapsedTime) * .3 + .5
    }
  })

  /* useEffect(() => {
    if (props.expStep === 1) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
        <group rotation={ [ 0, Math.PI, 0 ] } /* position-y={ -.38 } */>
            <mesh
                ref={ antenaRef }
                geometry={nodes.DIA_1B_Antena.geometry}
                material={props.materials.baked}
                position={[-9.32, 10.2, -0.5]}
                rotation={[0.62, 0.41, -3.11]}
                scale={1.81}
            />
            <mesh
                matrixAutoUpdate={ props.matrixAutoUpdate }
                geometry={nodes.DIA_1B_Edificio.geometry}
                material={props.materials.baked}
                position={[0.72, 4.64, -2.45]}
                rotation={[Math.PI / 2, 0, Math.PI]}
                scale={1.81}
            />

            <ModelBase material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } />
        </group>

    </group>
  );
}