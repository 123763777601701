import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from 'three';
import { TubeShaderMaterial } from "../Shaders/TubeShaderMaterial";
import { gsap } from "gsap/all";
import hexToRgb from "../../Utils/hexToRgb";

export function ModelPortal(props) {
  const group = useRef()
  const floor1 = useRef()
  const floor2 = useRef()
  const { nodes, animations } = useGLTF("/models/UV/Portal.glb");
  const floor = useGLTF("/models/UV/Portal_floor.glb");
  const { actions } = useAnimations(animations, group);
  const actionsRef = useRef([])

  const glassOpacity = (props.negative) ? .01 : .06
  const glassMaterial = useMemo(() => new THREE.MeshBasicMaterial({ transparent: true, opacity: glassOpacity, color: new THREE.Color(1, 1, 1) }), [])
  const condesadorMaterial = useMemo(() => new THREE.MeshMatcapMaterial({ 
    color: (props.negative) ? props.cableColor.negative : props.cableColor.positive, 
    map: (props.negative) ? props.materials.baked0.map : props.materials.baked1.map 
  }), [])

  useEffect(() => {
    actions['Cable_Animation_1'].play().paused = true
    actions['Cable_Animation_2'].play().paused = true
    actions['Cable_Animation_3'].play().paused = true
    actions['Cable_Animation_4'].play().paused = true
    actions['DIA_PORTAL_Puerta1_Hidraulico_ArrastreAction'].play().paused = true
    actions['DIA_PORTAL_Puerta2_Hidraulico_ArrastreAction'].play().paused = true
    actions['Anim_Puerta_Dcha'].play().paused = true
    actions['Anim_Puerta_Izq'].play().paused = true
    actionsRef.current = [
      actions['Cable_Animation_1'],
      actions['Cable_Animation_2'],
      actions['Cable_Animation_3'],
      actions['Cable_Animation_4'],
      actions['DIA_PORTAL_Puerta1_Hidraulico_ArrastreAction'],
      actions['DIA_PORTAL_Puerta2_Hidraulico_ArrastreAction'],
      actions['Anim_Puerta_Dcha'],
      actions['Anim_Puerta_Izq'],
    ]
  }, [])

  useEffect(() => {
    if (props.expStep === 6) {
      for (let i = 0; i < actionsRef.current.length; i++) {
        gsap.to(actionsRef.current[i], {
          time: 1,
          duration: 1,
          delay: 7
        })
      }
    } else if (props.expStep === 5) {
      for (let i = 0; i < actionsRef.current.length; i++) {
        gsap.to(actionsRef.current[i], {
          time: 0,
          duration: 2,
        })
      }
    }
  }, [ props.expStep ])

  const lightConstant = useRef(false)

  const mult = .8
  const lightColor = hexToRgb('#ffffff')
  lightColor.r *= .005 * mult
  lightColor.g *= .02 *  mult
  lightColor.b *= .0075 * mult

  const generalDuration = 1.5
  useEffect(() => {
    if (props.positivePartState) {
      const p = { p1: 0, p2: 0, p3: 0 }
      gsap.to(p, {
        p1: 1,
        duration: generalDuration,
        onComplete: () => {
          lightConstant.current = true
        }
      })
      gsap.to(p, {
        p2: 1,
        duration: 8.5,
        onComplete: () => {
          if (props.negative) {
            /* group.current.visible = false */
          }
        }
      })
    } else {
      lightConstant.current = false
      if (props.negative) {
        /* group.current.visible = true */
      }
    }
  }, [props.positivePartState])

  const m = TubeShaderMaterial({ texture: (props.negative) ? props.materials.baked0.map : props.materials.baked1.map, color: (props.negative) ? props.cableColor.negative : props.cableColor.positive, isX: true, negative: props.negative, positivePart: props.positivePart, expStep: props.expStep, positivePartState: props.positivePartState, size: .008 })


  useFrame(() => {
    if (lightConstant.current) condesadorMaterial.color.setRGB(lightColor.r, lightColor.g, lightColor.b)
    else condesadorMaterial.color.set((props.negative) ? props.cableColor.negative : props.cableColor.positive)
  })

  return (<>
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Empty"
          position={[3.718, 0.253, -0.039]}
          rotation={[0, 0, -0.13]}
        >
          <mesh
            name="DIA_PORTAL_Puerta1_Condensador"
            geometry={nodes.DIA_PORTAL_Puerta1_Condensador.geometry}
            material={condesadorMaterial}
            position={[2.526, 5.724, 0.169]}
            rotation={[Math.PI / 2, 0.835, -Math.PI]}
            scale={[-0.348, -0.348, -1.609]}
          />
          <mesh
            name="DIA_PORTAL_Puerta1_Cristal"
            geometry={nodes.DIA_PORTAL_Puerta1_Cristal.geometry}
            material={glassMaterial}
            position={[2.826, 5.903, 0.423]}
            rotation={[-Math.PI / 2, 0.127, 0]}
            scale={-1.01}
          />
          <mesh
            name="DIA_PORTAL_Puerta1_Sporte1"
            geometry={nodes.DIA_PORTAL_Puerta1_Sporte1.geometry}
            material={(props.negative) ? props.materials.baked0 : props.materials.baked1}
            position={[3.627, 4.91, 0.169]}
            rotation={[-Math.PI / 2, 0.124, 0]}
            scale={-1}
          />
        </group>
        <group
          name="Empty2"
          position={[-3.73, 0.249, -0.039]}
          rotation={[0, 0, 0.13]}
        >
          <mesh
            name="DIA_PORTAL_Puerta2_Condensador"
            geometry={nodes.DIA_PORTAL_Puerta2_Condensador.geometry}
            material={condesadorMaterial}
            position={[-2.525, 5.728, 0.169]}
            rotation={[-Math.PI / 2, 0.835, Math.PI]}
            scale={[0.348, 0.348, 1.609]}
          />
          <mesh
            name="DIA_PORTAL_Puerta2_Cristal"
            geometry={nodes.DIA_PORTAL_Puerta2_Cristal.geometry}
            material={glassMaterial}
            position={[-2.986, 6.038, 0.356]}
            rotation={[0, 0, 0.001]}
          />
          <mesh
            name="DIA_PORTAL_Puerta2_Soporte2"
            geometry={nodes.DIA_PORTAL_Puerta2_Soporte2.geometry}
            material={(props.negative) ? props.materials.baked0 : props.materials.baked1}
            position={[-1.607, 6.743, 0.169]}
            rotation={[Math.PI / 2, 0.124, 0]}
          />
        </group>
        <mesh
          name="DIA_PORTAL_Rampa_Laterales"
          geometry={nodes.DIA_PORTAL_Rampa_Laterales.geometry}
          material={(props.negative) ? props.materials.baked0 : props.materials.baked1}
          position={[-0.006, 0.362, 0.129]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 5.048, 1]}
        />
        <mesh
          name="DIA_PORTAL_Puerta1_Hidraulico_Arrastre"
          geometry={nodes.DIA_PORTAL_Puerta1_Hidraulico_Arrastre.geometry}
          material={(props.negative) ? props.materials.baked0 : props.materials.baked1}
          position={[8.546, 0.4, 0.129]}
          rotation={[Math.PI / 2, 0.136, 0]}
        />
        <mesh
          name="DIA_PORTAL_Puerta2_Hidraulico_Arrastre"
          geometry={nodes.DIA_PORTAL_Puerta2_Hidraulico_Arrastre.geometry}
          material={(props.negative) ? props.materials.baked0 : props.materials.baked1}
          position={[-8.546, 0.4, 0.129]}
          rotation={[Math.PI / 2, -0.136, 0]}
        />
        <group name="Armature_Cable1" position={[10.104, 0.162, -3.511]}>
          <skinnedMesh
            name="DIA_PORTAL_Cable1"
            geometry={nodes.DIA_PORTAL_Cable1.geometry}
            material={m}
            skeleton={nodes.DIA_PORTAL_Cable1.skeleton}
          />
          <primitive object={nodes.Bone} />
        </group>
        <group name="Armature_Cable2" position={[0, 0, -3.508]}>
          <skinnedMesh
            name="DIA_PORTAL_Cable2"
            geometry={nodes.DIA_PORTAL_Cable2.geometry}
            material={m}
            skeleton={nodes.DIA_PORTAL_Cable2.skeleton}
          />
          <primitive object={nodes.Bone_1} />
        </group>
        <group
          name="Armature_Cable4"
          position={[-10.095, 0.162, -3.511]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.992, -1, -1]}
        >
          <skinnedMesh
            name="DIA_PORTAL_Cable4"
            geometry={nodes.DIA_PORTAL_Cable4.geometry}
            material={m}
            skeleton={nodes.DIA_PORTAL_Cable4.skeleton}
          />
          <primitive object={nodes.Bone_2} />
        </group>
        <group
          name="Armature_Cable3"
          position={[-0.076, 0, -3.508]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.992, -1, -1]}
        >
          <skinnedMesh
            name="DIA_PORTAL_Cable3"
            geometry={nodes.DIA_PORTAL_Cable3.geometry}
            material={m}
            skeleton={nodes.DIA_PORTAL_Cable3.skeleton}
          />
          <primitive object={nodes.Bone_3} />
        </group>
      </group>


      {/* SUELO */}
        { props.negative && <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={floor.nodes.Suelo.geometry}
          material={ props.materials.baked_floor_negative }
          scale={[18.52, 18.52, 9.24]}
        /> }
        { !props.negative && <>
          <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
            ref={ floor1 }
            geometry={floor.nodes.Suelo.geometry}
            material={ props.materials.baked_floor_positive }
            scale={[18.52, 18.52, 9.24]}
          />
          <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
            ref={ floor2 }
            geometry={floor.nodes.Suelo.geometry}
            material={ props.materials.baked_floor_3 }
            scale={[18.52, 18.52, 9.24]}
          />
        </>}
    </group>

  </>
  );
}