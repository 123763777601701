import { useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { TextureLoader } from 'three'

function LoadTexture(url) {
    const textureLoaded = useLoader(TextureLoader, url)
    return textureLoaded
}

function fixTexture(texture, flip) {
    texture.flipY = flip
    texture.encoding = THREE.sRGBEncoding
    return texture
}

const mobileRoute = '/mobile'

export default class TextureManager {
    textureMaterials = []

    constructor(modelsInfo, colors, floorColors, isTouch) {
        this.modelsInfo = modelsInfo
        this.colors = colors
        this.floorColors = floorColors
        this.isTouch = isTouch
    }
    
    loadTextures() {
        let mobilePath = (this.isTouch) ? mobileRoute : ''
        for (let i = 0; i < this.modelsInfo.length; i++) {
            
            const textureObject = {}
            textureObject.model = this.modelsInfo[i].model

            if (this.modelsInfo[i].model != null) {
                textureObject.textures = []
                if (!this.modelsInfo[i].mobile && mobilePath !== '') mobilePath = mobileRoute
                const side = (this.modelsInfo[i].double_side) ? THREE.DoubleSide : THREE.FrontSide
                const flipY = this.modelsInfo[i].flipY
                const flipYBase = this.modelsInfo[i].flipYBase
                const bakedElementTexture = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked.jpg')
                fixTexture(bakedElementTexture, flipY)
                textureObject.textures.push(bakedElementTexture)

                let color = (this.modelsInfo[i].positive) ? this.colors.positive : this.colors.negative
                let floorColor = (this.modelsInfo[i].positive) ? this.floorColors.positive : this.floorColors.negative

                if (this.modelsInfo[i].color) {
                    const length = this.modelsInfo[i].color.length
                    for (let j = 0; j < length; j++) {
                        const elColor = this.modelsInfo[i].color[j];
                        const bakedElementMaterial = new THREE.MeshMatcapMaterial({ map: bakedElementTexture, color: elColor, side: side })
                        textureObject['baked' + j] = bakedElementMaterial
                    }
                } else if (this.modelsInfo[i].double_color) {
                    const bakedElementMaterial = new THREE.MeshMatcapMaterial({ map: bakedElementTexture, color: this.colors.negative, side: side })
                    textureObject.baked0 = bakedElementMaterial
                    const bakedElementMaterial2 = new THREE.MeshMatcapMaterial({ map: bakedElementTexture, color: this.colors.positive, side: side })
                    textureObject.baked1 = bakedElementMaterial2
                } else {
                    const bakedElementMaterial = new THREE.MeshMatcapMaterial({ map: bakedElementTexture, color: color, side: side })
                    textureObject.baked = bakedElementMaterial
                }
                

                if (this.modelsInfo[i].dynamic_map) {
                    const bakedElementTexture = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked2.jpg')
                    fixTexture(bakedElementTexture, flipY)
                    textureObject.textures.push(bakedElementTexture)
                    const bakedElementMaterial2 = new THREE.MeshMatcapMaterial({ map: bakedElementTexture, color: color, side: side, transparent: true })
                    textureObject.baked2 = bakedElementMaterial2
                }
    
                
                if (this.modelsInfo[i].double_mask_floor != undefined) {

                    const bakedFloorTextureNegative = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked_floor.jpg')
                    const bakedFloorTexturePositive = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked_floor.jpg')
                    fixTexture(bakedFloorTextureNegative, flipY)
                    fixTexture(bakedFloorTexturePositive, flipY)
                    textureObject.textures.push(bakedFloorTextureNegative)
                    textureObject.textures.push(bakedFloorTexturePositive)
                    const bakedFloorMaterialNegative = new THREE.MeshMatcapMaterial({ map: bakedFloorTextureNegative, color: this.floorColors.negative })
                    const bakedFloorMaterialPositive = new THREE.MeshMatcapMaterial({ map: bakedFloorTexturePositive, color: this.floorColors.positive })
                    bakedFloorMaterialPositive.transparent = true
                    textureObject.baked_floor_negative = bakedFloorMaterialNegative
                    textureObject.baked_floor_positive = bakedFloorMaterialPositive

                } else if(this.modelsInfo[i].floor != false) {

                    const bakedFloorTexture = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked_floor.jpg')
                    fixTexture(bakedFloorTexture, flipYBase)
                    textureObject.textures.push(bakedFloorTexture)

                    const bakedBaseMaterial = new THREE.MeshMatcapMaterial({ map: bakedFloorTexture, color: color })
                    const bakedFloorMaterial = new THREE.MeshMatcapMaterial({ map: bakedFloorTexture, color: floorColor })
                    textureObject.baked_base = bakedBaseMaterial
                    textureObject.baked_floor = bakedFloorMaterial

                }

                if (this.modelsInfo[i].dynamic_floor != undefined) {

                    const bakedFloorTextureAdditional = LoadTexture('textures/'+this.modelsInfo[i].model+mobilePath+'/baked_floor2.jpg')
                    fixTexture(bakedFloorTextureAdditional, flipYBase)
                    textureObject.textures.push(bakedFloorTextureAdditional)
                    const bakedFloorMaterialAdditional = new THREE.MeshMatcapMaterial({ 
                        map: bakedFloorTextureAdditional, 
                        color: (this.modelsInfo[i].dynamic_floor == 'negative') ? this.colors.negative : this.colors.positive, 
                    })
                    const bakedFloorMaterialAdditional2 = new THREE.MeshMatcapMaterial({ 
                        map: bakedFloorTextureAdditional, 
                        color: (this.modelsInfo[i].dynamic_floor == 'negative') ? this.floorColors.negative : this.floorColors.positive, 
                    })
                    bakedFloorMaterialAdditional.transparent = true
                    textureObject.baked_floor_2 = bakedFloorMaterialAdditional
                    textureObject.baked_floor_3 = bakedFloorMaterialAdditional2

                }
                
                this.textureMaterials.push(textureObject)
            }

        }

        return this.textureMaterials
    }
}
