import { useEffect, useRef, useState } from 'react';
import { Footer } from './Front';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { gsap } from 'gsap';
import { NavLink } from 'react-router-dom';
import Video from './Components/Video';
import Image from './Components/Image';
import WindowHandler from '../Utils/WindowHandler';
import preloadImages from '../Utils/preloadImages';

export function Inversion() {

	const [loaded, setLoaded] = useState(false);
  const [canPlayVideos, setCanPlayVideos] = useState(false);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const [pageReady, setPageReady] = useState(false);
  const isTouch = WindowHandler().isTouch

	useEffect(() => {
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

		iniScrollSmoother();

		setTimeout(function () {
			animaciones('inversion', isTouch);
		}, 0);
	}, [loaded]);

  useEffect(() => {
    if ((canPlayVideos || isTouch) && imagesLoaded && pageReady) {
      setTimeout(() => {
        setLoaded(true)
      }, 0);
    }
  }, [canPlayVideos, imagesLoaded, pageReady])


  useEffect(() => {
    const onPageLoad = () => {
      setPageReady(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

	useEffect(() => {
    const allImg = document.querySelectorAll('img')
    const p = []
    allImg.forEach((img) => {
      p.push(img.src)
    })
    preloadImages(p, () => setImagesLoaded(true))
	}, []);




	var carrouselActual = 0;
	const carrousel = (e, n) => {
		e.preventDefault();

		if (carrouselActual + n >= 0 && carrouselActual + n <= 3) {
			var AniCarr = gsap.timeline({
				defaults: {
					duration: 1,
					ease: 'power2.out'
				}
			});
			carrouselActual = carrouselActual + n;

      let tr = carrouselActual * -75;
			if (window.innerWidth <= 1024) {
				tr = carrouselActual * -80;
			}
      if (window.innerWidth <= 600) {
				tr = carrouselActual * -100;
			}

			AniCarr.to('.carrousel__inner', { x: tr + 'vw' }, '0');
			AniCarr.play();
		}
		return false;
	};

	return (<>
<div id="smooth-wrapper">
  <div id="smooth-content">

  <div className="header-pin">
      <div className="header header2 header-ani header__inversion">
          <div className="header__data">
            
            <div className="pieza-header-container">
              <div className="pieza pieza-header">
                <span className="pieza__line pieza__1"></span>
                <span className="pieza__line pieza__2"></span>
                <span className="pieza__line pieza__3"></span>
                <span className="pieza__line pieza__4"></span>
                <span className="pieza__rombo pieza__5"></span>
              </div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            <span data-delay="1.2" >LA INVERSIÓN</span>
            </div>
 
            <div className='header__titulo_ani'>
              <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>INVIERTE EN</span><span className='linet linet2'>UN MERCADO EN</span><span className='linet linet3'>CRECIMIENTO</span></div></div>
              <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>INVIERTE EN</span><span className='linet linet2'>UN MERCADO EN</span><span className='linet linet3'>CRECIMIENTO</span></div></div>
              <div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>INVI</span>ERTE<span className='ocu'> EN</span></span><span className='linet linet2'><span className='ocu'>UN </span>MERCADO<span className='ocu'> EN</span></span><span className='linet linet3'><span className='ocu'>CRE</span>CIMIE<span className='ocu'>NTO</span></span></div></div>
            </div>
            
            <div className="header__ani_center">
              <div className='header__ani_rombo'></div>
              <div className="header__ani --inversion">
                <img src="images/animaciones/header_inversion/DIA_43000.png" id='header_img' className='header__ani_img' />
              </div>
            </div>
          </div>

          <div className="cita cita-header">
            <div className="cita__title ani_ini_mask">
              <span data-delay="2">Invierte con confianza</span>
            </div>
            <div className="cita__cita ani_ini_line" data-delay="2">
              Aprovecha las oportunidades de expansión y rentabilidad que ofrece de la mano de nuestra sólida trayectoria.
            </div>
          </div>

          <div className="header__arrow">
            <a href="#" onClick={(e)=> ancla('inversion')}
              className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
              <span className="btn__rombo_cont"></span>
              <span className="btn__rombo_txt">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                    <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                      <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                      <path d="M7.543 13.804V0" data-name="Línea 43" />
                    </g>
                  </svg>
                </span>
              </span>
            </a>
          </div>
      </div>
    </div>


    <div className="fondo__verde" id='inversion'>
      <div className="page__inversion">
        <div className="bloque__vision__txtbig_container bloque__vision__txtbig_container-inversion">
          <div className="bloque__vision__txtbig">
            Dianelum ofrece a los inversores un préstamo participativo con
            rendimientos incrementales durante 5 años. Al finalizar,
            pueden recuperar su inversión o convertirla en acciones de la
            compañía, brindando flexibilidad y opciones adaptadas a sus
            necesidades y objetivos.
          </div>
          <div className="bloque__vision__txtbig-line">
            <div className="line line-h-l-r"></div>
          </div>
          <div className="bloque__vision__txtbig-cols">
            <div>
              <span>¿Por qué invertir?</span>
            </div>
            <div>
              <span>Aprovecha las ventajas de invertir</span>
            </div>
          </div>
        </div>

        <div className="bloque__scroll__later">
          <div className="bloque__sl__fixed">
            <div className="bloque__sl__mask">
              <div className="bloque__sl__rombo"></div>
              {/* <img src="images/rentabilidad.png" id="bloque__sl01_img" />
              <img src="images/garantia-2.png" id="bloque__sl02_img" />
              <img src="images/seguridad.png" id="bloque__sl03_img" />
              <img src="images/sostenibilidad-2.png" id="bloque__sl04_img" />
              <img src="images/exclusivos.png" id="bloque__sl05_img" /> */}

              <Video 
                id="bloque__sl01_img"
                src="DIA_25"
                poster="images/DIA_2500.png"
                loop={ true }
              />
              <Video 
                id="bloque__sl02_img"
                src="DIA_45"
                poster="images/DIA_4500.png"
                loop={ true }
              />
              <Video 
                id="bloque__sl03_img"
                src="DIA_67"
                poster="images/seguridad.png"
                loop={ true }
              />
              <Video 
                id="bloque__sl04_img"
                src="DIA_68"
                poster="images/sostenibilidad-2.png"
                loop={ true }
              />
              <Video 
                id="bloque__sl05_img"
                src="DIA_48"
                poster="images/exclusivos.png"
                loop={ true }
              />
            </div>
          </div>

          <div className="bloque__sl__pantalla" id="bloque__sl01">
          <div className="bloque__sl__data_img_movil">
            <Image src='images/rentabilidad-movil.png' alt='Rentabilidad' />
          </div>
            <div className="bloque__sl__data">
              <div className="bloque__sl__data_number">01</div>
              <div className="bloque__sl__data_title">Rentabilidad</div>
              <div className="bloque__sl__data_cont">
                Mercado en crecimiento con tasas anuales de dos dígitos.
                Nuestro modelo de negocio exclusivo ofrece a los
                inversionistas oportunidades de rentabilidad atractivas,
                con retornos esperados entre el 12,5% y el 24%.
              </div>
            </div>
          </div>

          <div className="bloque__sl__pantalla" id="bloque__sl02">
            <div className="bloque__sl__data_img_movil">
              <Image src='images/garantia2-movil.png' alt='Garantía' />
            </div>
            <div className="bloque__sl__data">
              <div className="bloque__sl__data_number">02</div>
              <div className="bloque__sl__data_title">Garantía</div>
              <div className="bloque__sl__data_cont">
                Su inversión está respaldada por bancos exclusivos de
                diamantes de alta calidad de 2500 ct, certificados y de
                gran valor. Esto proporciona una base sólida y segura para
                su inversión, con un potencial de apreciación a largo
                plazo.
              </div>
            </div>
          </div>

          <div className="bloque__sl__pantalla" id="bloque__sl03">
            <div className="bloque__sl__data_img_movil">
              <Image src='images/seguridad-movil.png' alt='Seguridad' />
            </div>
            <div className="bloque__sl__data">
              <div className="bloque__sl__data_number">03</div>
              <div className="bloque__sl__data_title">Seguridad</div>
              <div className="bloque__sl__data_cont">
                Nuestros productos son auditados externamente para
                garantizar la trazabilidad y autenticidad en cada etapa
                del proceso, desde la creación hasta el almacenamiento,
                asegurando la calidad y origen ético de nuestros
                diamantes.
              </div>
            </div>
          </div>

          <div className="bloque__sl__pantalla" id="bloque__sl04">
            <div className="bloque__sl__data_img_movil">
              <Image src='images/sostenibilidad-movil.png' alt='Sostenibilidad y ética' />
            </div>
            <div className="bloque__sl__data">
              <div className="bloque__sl__data_number">04</div>
              <div className="bloque__sl__data_title">
                Sostenibilidad y ética
              </div>
              <div className="bloque__sl__data_cont">
                Al invertir apoyas prácticas sostenibles y éticas. Creados
                en un entorno controlado, reducen el impacto ambiental y
                aseguran la ausencia de explotación humana.
              </div>
            </div>
          </div>

          <div className="bloque__sl__pantalla" id="bloque__sl05">
          <div className="bloque__sl__data_img_movil">
              <Image src='images/desarrollo-exclusivo-movil.png' alt='Desarrollos exclusivos' />
            </div>
            <div className="bloque__sl__data">
              <div className="bloque__sl__data_number">05</div>
              <div className="bloque__sl__data_title">
                Desarrollos exclusivos
              </div>
              <div className="bloque__sl__data_cont">
                Ofrecemos un nivel de exclusividad inigualable a través de
                la creación de diseños de joyería únicos o el desarrollo
                de colecciones exclusivas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="fondo_blanco fondo_blanco-inversion">
      <div className="bloque__triangulo bloque__triangulo-inversion">
        <div className="bloque__triangulo__pretitulo">
          Sobre la inversión
        </div>

        <div className="bloque__triangulo__titulo btt1">PROPUESTA</div>
        <div className="bloque__triangulo__titulo btt2">DE VALOR</div>

        <div className="bloque__triangulo__tri">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 730.785 632.763">
            <path fill="#81bc8f" d="m365.392 0 365.393 632.763H0Z" data-name="Polígono 12" />
          </svg>
          <Video 
            src="DIA_49"
            poster="images/DIA_4900.png"
            loop={ true }
          />
        </div>

        <div className="bloque__triangulo__medium kentoMedium ani-scroll-enter">
          Rendimiento flexible y adaptado: Opciones de inversión
          atractivas con Dianelum{' '}
        </div>
        <div className="bloque__triangulo__rombodeco ani_scroll_rombodeco">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404" />
          </svg>
        </div>

        <div className="cita">
          <div className="cita__title ani_scroll_mask">
            <span>Propuesta de valor</span>
          </div>
          <div className="cita__cita ani_scroll_line">
            Dianelum ofrece a los inversores un préstamo participativo con
            rendimientos incrementales durante 5 años. Al finalizar,
            pueden recuperar su inversión o convertirla en acciones de la
            compañía, brindando flexibilidad y opciones adaptadas a sus
            necesidades y objetivos.
          </div>
        </div>
      </div>

      <div className="pieza-center">
        <div className="pieza pieza__grande pieza__blanco ani_scroll_pieza_grande">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>
      </div>

      <div className="bloque__triangulo bloque__triangulo-inversion">
        <div className="bloque__triangulo__pretitulo">
          Redefining Diamonds
        </div>

        <div className="bloque__triangulo__titulo btt1">RETORNO</div>
        <div className="bloque__triangulo__titulo btt2">
          DE LA INVERSIÓN
        </div>

        <div className="bloque__triangulo__tri bloque__triangulo__tri-inversion-hexa bloque__triangulo__tri-hexa">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528.106 624.041">
            <path fill="#81bc8f" d="M87.855 624.041 0 550.57V73.471L87.855 0h352.391l87.861 73.471v477.1l-87.861 73.471Z" data-name="Unión 21" />
          </svg>
          {/* <img src="images/retorno-de-la-inversion.png" alt="Imagen animacion 2" /> */}
          <Video 
            src="DIA_50"
            className="coins"
            poster="images/DIA_5000.png"
            loop={ true }
          />
        </div>

        <div className="bloque__triangulo__medium kentoMedium ani-scroll-enter">
          Aprovecha el potencial de inversión
        </div>

        <div className="bloque__triangulo__cols">
          <div className="bloque__triangulo__col">
            <div className="bloque__triangulo__col_title ani_scroll_line">
              CONTRATOS CON CLIENTES
            </div>
            <div >
              <div className='ani_scroll_line'>
              Dianelum tiene acuerdos estratégicos con importantes
              clientes y socios comerciales, como Brilianto, para impulsar
              el crecimiento y asegurar ingresos constantes.<br /><br />
              <a href="https://www.brilianto.com/" target="_blank" className="btn__ver btn__ver-visita" id="button-3d-1-inversion" color={ 'blanco' }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.727 62.066"><g fill="none" stroke="#141515" data-name="Grupo 3017"><path strokeLinejoin="round" d="M252.355.5H21.372L.5 12.713v36.64l20.872 12.213h230.983l20.872-12.213v-36.64Z" data-name="Trazado 37445"/><path strokeLinejoin="round" d="m.5 49.353 136.363 12.213 136.364-12.213" data-name="Trazado 37447"/><path strokeLinejoin="round" d="M273.227 12.713 136.864.5.5 12.713" data-name="Trazado 37448"/><path d="M19.564.5v61.066" data-name="Línea 107"/><path d="M254.163.5v61.066" data-name="Línea 108"/></g></svg>
                <span>VISITA BRILIANTO</span>
              </a>
            </div>
          </div>
          <div className="bloque__triangulo__col">
            <div className="bloque__triangulo__col_title ani_scroll_line">
              ROTACIÓN DE ACTIVOS
            </div>
            <div className="ani_scroll_line">
              La empresa comercia con diamantes en varios mercados,
              optimizando el rendimiento y ofreciendo productos de
              calidad. A través de alianzas estratégicas, Dianelum
              diversifica sus canales de venta y garantiza un flujo
              constante de ventas. La empresa se adapta a las tendencias
              del mercado y busca oportunidades para asegurar un flujo de
              ingresos sostenible.
            </div>
          </div>
        </div>
      </div>

      <div className="pieza-center">
        <div className="pieza pieza__grande pieza__blanco ani_scroll_pieza_grande">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>
      </div>

      <div className="bloque__triangulo bloque__triangulo-inversion">
        <div className="bloque__triangulo__pretitulo">
          Redefining Diamonds
        </div>

        <div className="bloque__triangulo__titulo btt1">GARANTÍAS</div>
        <div className="bloque__triangulo__titulo btt2">Y RIESGOS</div>

        <div className="bloque__triangulo__tri bloque__triangulo__tri-rombo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 713.734 713.734">
            <path fill="#81bc8f" d="M0 356.867 356.867 0l356.867 356.867-356.867 356.867z" data-name="Rectángulo 2590" />
          </svg>
          {/* <img src="images/garantias-y-riesgo.png" alt="Imagen animación" /> */}
          <Video 
            src="DIA_51"
            className="umbrella"
            poster="images/DIA_5100.png"
            loop={ true }
          />
        </div>

        <div className="bloque__triangulo__medium kentoMedium ani-scroll-enter">
          Invierte con confianza
        </div>
        <div className="bloque__triangulo__rombodeco ani_scroll_rombodeco">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404" />
          </svg>
        </div>

        <div className="cita">
          <div className="cita__title ani_scroll_mask">
            <span>Garantias y riesgos</span>
          </div>
          <div className="cita__cita ani_scroll_line">
            En el PDB (Dianelum - Permanent Diamond Bank), se requiere un colateral
            en diamantes para respaldar las operaciones financieras. Se
            implementará un sistema de trazabilidad basado en blockchain y
            se lanzará una STO regulada por la CNMV para proteger a los
            inversores.
          </div>
        </div>
      </div>
    </div>

    <div className="fondo__verde">
      <div className="bloque__vision__txtbig_container bloque__vision__txtbig_container-pmin bloque__vision__txtbig_container-inv2">
        <div className="bloque__vision__txtbig bloque__vision__txtbig-max">
          Invierte en Dianelum a través de tokens respaldados por
          diamantes en una STO
        </div>
        <div className="bloque__vision__txtbig-line">
          <div className="line line-h-l-r"></div>
        </div>
        <div className="bloque__vision__txtbig-cols">
          <div>
            <span>LA INVERSIÓN</span>
          </div>
          <div>
            <span className="w14">
              Beneficios de invertir a través de Blockchain
            </span>
          </div>
        </div>
      </div>

      <div className="bloque__colscroll bloque__colscroll-inversion">
        <div className="bloque__colscroll_colimgs" id="bloque__colscroll_colimgs">
          <div className='bloque__colscroll_colimgs_ani' id='bloque__colscroll_colimgs_ani'>
            <div className='bloque__colscroll_colimgs_ani_rombo'></div>
            <Video
              id="colscroll_1_img"
              src="DIA_52"
              poster="images/DIA_5200.png"
              loop={ true }
            />
            <Video
              id="colscroll_2_img"
              src="DIA_53"
              poster="images/DIA_5300.png"
              loop={ true }
            />
            <Video
              id="colscroll_3_img"
              src="DIA_54"
              poster="images/DIA_5400.png"
              loop={ true }
            />
            
          </div>
        </div>

        <div className="bloque__colscroll_coldata">
          <div className="bloque__colscroll_info-space"></div>
          <div className="bloque__colscroll_info" id="colscroll_1">
            <div className="bloque__colscroll_info_img-movil">
              <Image src="images/ventaja1.png" />
            </div>
            <div className="bloque__colscroll_info_number">01</div>
            <div className="bloque__colscroll_info_title">
              Mayor transparencia y cumplimiento normativo
            </div>
            <div className="bloque__colscroll_info_desc">
              La STO combina eficiencia y agilidad digital con garantías
              regulatorias de la CNMV proporcionando mayor seguridad y
              confianza.
            </div>
          </div>
          <div className="bloque__colscroll_info" id="colscroll_2">
          <div className="bloque__colscroll_info_img-movil">
              <Image src="images/ventaja2.png" />
            </div>
            <div className="bloque__colscroll_info_number">02</div>
            <div className="bloque__colscroll_info_title">
              Acceso global
            </div>
            <div className="bloque__colscroll_info_desc">
              Las Ofertas Iniciales de Tokens (STO) derriban las fronteras financieras, brindando la oportunidad de invertir en proyectos globales desde cualquier rincón del mundo.
            </div>
          </div>
          <div className="bloque__colscroll_info" id="colscroll_3">
          <div className="bloque__colscroll_info_img-movil">
              <Image src="images/ventaja3.png" />
            </div>
            <div className="bloque__colscroll_info_number">03</div>
            <div className="bloque__colscroll_info_title">
              fraccionamiento de activos
            </div>
            <div className="bloque__colscroll_info_desc">
              Las STOs permiten el
              fraccionamiento de activos, facilitando la diversificación
              de la cartera y reduciendo la barrera de entrada a activos
              costosos.
            </div>
          </div>
        </div>
      </div>


  {/* CABECERA INTERIOR */}
  <div className="bloque__txtc bloque__txtc-inversion">
        <div className="pieza pieza__grande bloque__txtc_pieza ">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>

        <div className="bloque__txtc__pretitulo">
          <span>CÓMO INVERTIR</span>
        </div>
        <div className="bloque__txtc__titulo">
          <div className='int_header__titulo_ani --center'>
            <div className='int_header__titulo_ani_1'><div className='tit'><span className='linet linet1'>FLEXIBILIDAD</span><span className='linet linet2'>ADAPTADA A SUS</span><span className='linet linet3'>NECESIDADES.</span></div></div>
            <div className='int_header__titulo_ani_2'><div className='tit'><span className='linet linet1'>FLEXIBILIDAD</span><span className='linet linet2'>ADAPTADA A SUS</span><span className='linet linet3'>NECESIDADES.</span></div></div>
            <div className='int_header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>FLE</span>XIBILI<span className='ocu'>DAD</span></span><span className='linet linet2'><span className='ocu'>ADAP</span>TADA <span className='ocu'>A SUS</span></span><span className='linet linet3'><span className='ocu'>NEC</span>ESIDAD<span className='ocu'>ES.</span></span></div></div>
          </div>
        </div>

        <div className="bloque__txtc__imgani">
          <div className="bloque__txtc__imgani_cont">
            <img src="images/animaciones/header_int_flexibilidad/DIA_55000.png" id='int_header_img' className='int_header__ani_img' />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 761.398 442.169"><path fill="#81bc8f" d="M0 221.087 380.7 0l380.7 221.084-380.7 221.085Z" data-name="Unión 74"/></svg>
          </div>
        </div>

        <div className="cita">
          <div className="cita__title ani_scroll_mask">
          <span>Invierte con seguridad</span>
          </div>
          <div className="cita__cita ani_scroll_line">
            Invertir en nuestra plataforma es sencillo, accesible y
            seguro, brindando una experiencia conveniente y fluida en la
            inversión en diamantes a través de Blockchain.
          </div>
        </div>
      </div>
 {/* CABECERA INTERIOR */}




      <div className="bloque__carrousel bloque__carrousel-inversion">
        <div className="carrousel">
          <div className="carrousel__inner">
            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">01</div>
                <div className="diapo__img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.93 573.021"><path fill="#81bc8f" d="M80.672 573.022 0 505.557V67.464L80.672 0h323.581l80.677 67.464v438.093l-80.678 67.464Z" data-name="Unión 21"/></svg>
                  <Video
                    src="DIA_56"
                    poster="images/DIA_5600.png"
                    loop={ true }
                  />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Registro y verificación
                    </div>
                    <div className="diapo__data_cont_cont">
                      Regístrate, verifica tu cuenta y explora nuestras
                      opciones de inversión para comenzar a invertir según
                      tus preferencias y objetivos financieros.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">02</div>
                <div className="diapo__img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.93 573.021"><path fill="#81bc8f" d="M80.672 573.022 0 505.557V67.464L80.672 0h323.581l80.677 67.464v438.093l-80.678 67.464Z" data-name="Unión 21"/></svg>
                  <Video
                    src="DIA_57"
                    poster="images/DIA_5700.png"
                    loop={ true }
                  />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Información detallada
                    </div>
                    <div className="diapo__data_cont_cont">
                      Obtén información detallada sobre la STO, incluyendo
                      detalles del proyecto, beneficios y riesgos.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">03</div>
                <div className="diapo__img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.93 573.021"><path fill="#81bc8f" d="M80.672 573.022 0 505.557V67.464L80.672 0h323.581l80.677 67.464v438.093l-80.678 67.464Z" data-name="Unión 21"/></svg>
                  <Video
                    src="DIA_58"
                    poster="images/DIA_5800.png"
                    loop={ true }
                  />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Facilidad de pago
                    </div>
                    <div className="diapo__data_cont_cont">
                      Adquiere los tokens de manera segura y transparente
                      utilizando diversos métodos de pago.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">04</div>
                <div className="diapo__img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.93 573.021"><path fill="#81bc8f" d="M80.672 573.022 0 505.557V67.464L80.672 0h323.581l80.677 67.464v438.093l-80.678 67.464Z" data-name="Unión 21"/></svg>
                  <Video
                    src="DIA_59"
                    poster="images/DIA_5900.png"
                    loop={ true }
                    onCanPlay={ () => setCanPlayVideos(true) }
                  />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Monitorización de tu inversión
                    </div>
                    <div className="diapo__data_cont_cont">
                      Obtén actualizaciones sobre rendimientos, proyectos
                      y otra información importante para mantener un
                      seguimiento preciso.
                      {/* <a href="http://invest.dianelum.com/" target="_blank" className="btn__ver btn__ver-invierte">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.846 56"><g fill="none" stroke="#141515" data-name="Grupo 3017"><path strokeLinejoin="round" d="M153.654.5H13.192L.5 11.5v33l12.692 11h140.462l12.692-11v-33Z" data-name="Trazado 37445"/><path strokeLinejoin="round" d="m.5 42.599 82.923 12.9 82.923-12.9" data-name="Trazado 37447"/><path strokeLinejoin="round" d="M166.346 13.401 83.423.501.5 13.401" data-name="Trazado 37448"/><path d="M15.636.5v55" data-name="Línea 107"/><path d="M151.21.5v55" data-name="Línea 108"/></g></svg>
                      <span>INVIERTE</span>
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">05</div>
                <div className="diapo__img">
                <img src="images/opciones-de-salida.png" />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Opciones de salida
                    </div>
                    <div className="diapo__data_cont_cont">
                      Vende tus tokens antes del final del proyecto a
                      través de opciones como el mercado secundario o
                      transacciones P2P.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="carrousel__controller">
          <a href="#" onClick={(e)=> carrousel(e, -1)}
            className="btn__rombo btn__rombo_svg btn__rombo_svg-negro carrousel__controller-left">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421" />
                    <path d="M14.644 12.156h12.947" data-name="Línea 44" />
                  </g>
                </svg>
              </span>
            </span>
          </a>

          <a href="#" onClick={(e)=> carrousel(e, 1)}
            className="btn__rombo btn__rombo_svg btn__rombo_svg-negro carrousel__controller-right">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421"></path>
                    <path d="M14.644 12.156h12.947" data-name="Línea 44"></path>
                  </g>
                </svg>
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div className="bloque__negro bloque__negro-inversion-hablamos headerVerde cta-hablamos black">
      <div className="bloque__hablamos">
        <div className="bloque_hablamos_txt ani_scroll_line">Estamos aquí para resolver tus dudas.</div>
        <div className="bloque__hablamos_cont_btn">
          <NavLink to="/contacto" className="btn_hablamos">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1436.96 453.688"><g stroke="#9bcaa6" data-name="Grupo 7722"><g fill="none" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6307"><path d="M1436.21 338.157V115.538L1321.428.75H115.532L.75 115.538v222.619l114.782 114.782h1205.9Z" data-name="Trazado 37422"/><path d="M.752 115.535 718.479.753l717.733 114.782" data-name="Trazado 37423"/><path d="m.752 338.157 717.727 114.782 717.733-114.782" data-name="Trazado 37424"/><path d="M115.53.751V452.94" data-name="Línea 45"/><path d="M1321.431.751V452.94" data-name="Línea 46"/></g><g data-name="Grupo 6309"><path fill="none" strokeMiterlimit="10" d="M744.237 74.461h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.06 74.461h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.681 101.019V47.905" data-name="Línea 65"/><path fill="#141515" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="none" strokeMiterlimit="10" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g><g data-name="Grupo 6310"><path fill="none" strokeMiterlimit="10" d="M744.236 388.808h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.059 388.808h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.68 415.366v-53.114" data-name="Línea 65"/><path fill="#96c495" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="#141515" strokeMiterlimit="10" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g></g></svg>
            <span>HABLAMOS</span>
          </NavLink>
        </div>
      </div>
    </div>

    <Footer tipo="verde" />
  </div>
</div>
</div>
		</>);
}
