import React, { useEffect, useRef } from "react";
import { useAnimations, useGLTF, useScroll } from "@react-three/drei";
import { ModelBase } from "./ModelBase";
import { useFrame } from "@react-three/fiber";
import { _BEFORE_ARC } from "../settings";
import { TextRevealer } from "../TextRevealer";

export function ModelMiner(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("/models/UV/Miner.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions._ANIMATION_DEF.timeScale = .8
    actions._ANIMATION_DEF.play()
    actions._ANIMATION_DEF.paused = true
  }, [])

  useEffect(() => {
    if (props.expStep !== 2) {
      actions._ANIMATION_DEF.paused = true
    } else {
      actions._ANIMATION_DEF.paused = false
    }
    /* if (props.expStep === 3) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    } */
  }, [props.expStep])


  return (
    <group ref={group} {...props} dispose={null}>
       <group name="Scene" scale={ .7 } rotation={ [ 0, Math.PI, 0 ] }>
        <group name="DIA_3Aglb">
          <group>
            <group name="DIA_3A_RIG_Ctrl_Reference" position={[0, -0.1, -0.44]}>
              <group
                name="DIA_3A_RIG_Ctrl_HipsEffector"
                position={[0, 22.18, -1.06]}
                rotation={[0.7, -0.02, 0.01]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_LeftWristEffector"
                position={[11.1, 16.83, 23.4]}
                rotation={[-0.97, 0.01, -1.76]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightWristEffector"
                position={[-12.52, 18.1, 23.62]}
                rotation={[-1.07, -0.02, 1.72]}
              >
                <group
                  name="locator1"
                  position={[-5.25, -11.2, 1.83]}
                  rotation={[-0.79, -1.31, -2.42]}
                  scale={2.33}
                >
                  <mesh
                    name="DIA_3A_AGITADOR"
                    geometry={nodes.DIA_3A_AGITADOR.geometry}
                    material={ props.materials.baked }
                    position={[0, -18.89, -6.38]}
                    scale={0.43}
                  />
                </group>
              </group>
              <group
                name="DIA_3A_RIG_Ctrl_LeftElbowEffector"
                position={[11.13, 21.42, 16.22]}
                rotation={[-1.04, -0.56, -1.59]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightElbowEffector"
                position={[-11.91, 22.08, 16.3]}
                rotation={[-0.97, 0.64, 1.49]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_ChestOriginEffector"
                position={[-0.01, 43.21, -2.42]}
                rotation={[0.05, -0.02, 0.01]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_ChestEndEffector"
                position={[-0.13, 54.66, 2.29]}
                rotation={[0.41, -0.02, 0.01]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_LeftShoulderEffector"
                position={[8.11, 31.17, 9.59]}
                rotation={[-0.48, -0.5, -1.3]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightShoulderEffector"
                position={[-8.3, 31.37, 9.39]}
                rotation={[-0.46, 0.53, 1.2]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_HeadEffector"
                position={[-0.19, 39.39, 22.57]}
                rotation={[0.86, -0.02, 0.01]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_Hips"
                position={[0.02, 19.98, -3.1]}
                rotation={[0.74, -0.02, 0.01]}
              >
                <group
                  name="DIA_3A_RIG_Ctrl_Spine"
                  position={[0, 6.87, -1.19]}
                  rotation={[0.09, 0, 0]}
                >
                  <group
                    name="DIA_3A_RIG_Ctrl_Spine1"
                    position={[0, 5.5, -0.83]}
                    rotation={[0.21, 0, 0]}
                  >
                    <group
                      name="DIA_3A_RIG_Ctrl_Spine2"
                      position={[0, 5.65, -0.18]}
                      rotation={[0.16, 0, 0]}
                    >
                      <group
                        name="DIA_3A_RIG_Ctrl_LeftShoulder"
                        position={[3.42, 1.98, 3.49]}
                      >
                        <group
                          name="DIA_3A_RIG_Ctrl_LeftArm"
                          position={[4.78, -2, -1.39]}
                          rotation={[-1.64, -0.48, -1.27]}
                        >
                          <group
                            name="DIA_3A_RIG_Ctrl_LeftForeArm"
                            position={[12.16, 0, 0]}
                            rotation={[0, -0.48, 0]}
                          >
                            <group
                              name="DIA_3A_RIG_Ctrl_LeftHand"
                              position={[8.5, 0, 0]}
                              rotation={[-0.55, -0.05, -0.17]}
                            />
                          </group>
                        </group>
                      </group>
                      <group
                        name="DIA_3A_RIG_Ctrl_RightShoulder"
                        position={[-3.42, 1.98, 3.49]}
                      >
                        <group
                          name="DIA_3A_RIG_Ctrl_RightArm"
                          position={[-4.78, -2, -1.39]}
                          rotation={[-1.65, 0.56, 1.23]}
                        >
                          <group
                            name="DIA_3A_RIG_Ctrl_RightForeArm"
                            position={[-12.16, 0, 0]}
                            rotation={[0, 0.4, 0]}
                          >
                            <group
                              name="DIA_3A_RIG_Ctrl_RightHand"
                              position={[-8.5, 0, 0]}
                              rotation={[-0.67, 0.06, 0.22]}
                            />
                          </group>
                        </group>
                      </group>
                      <group
                        name="DIA_3A_RIG_Ctrl_Neck"
                        position={[0, 6.04, 1.06]}
                        rotation={[-0.33, 0, 0]}
                      >
                        <group
                          name="DIA_3A_RIG_Ctrl_Head"
                          position={[0, 9.37, 1.01]}
                        />
                      </group>
                    </group>
                  </group>
                </group>
                <group
                  name="DIA_3A_RIG_Ctrl_LeftUpLeg"
                  position={[3.85, 3.05, 0]}
                  rotation={[-1.59, -0.05, 0.37]}
                >
                  <group
                    name="DIA_3A_RIG_Ctrl_LeftLeg"
                    position={[0, -19.8, 0]}
                    rotation={[2.1, -0.04, 0]}
                  >
                    <group
                      name="DIA_3A_RIG_Ctrl_LeftFoot"
                      position={[0, -18.38, 0]}
                      rotation={[-1.12, 0.08, -0.17]}
                    >
                      <group
                        name="DIA_3A_RIG_Ctrl_LeftToeBase"
                        position={[0, -2.06, 7.45]}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="DIA_3A_RIG_Ctrl_RightUpLeg"
                  position={[-3.85, 3.05, 0]}
                  rotation={[-1.02, 0.01, -0.41]}
                >
                  <group
                    name="DIA_3A_RIG_Ctrl_RightLeg"
                    position={[0, -19.8, 0]}
                    rotation={[2.09, 0.13, 0]}
                  >
                    <group
                      name="DIA_3A_RIG_Ctrl_RightFoot"
                      position={[0, -18.38, 0]}
                      rotation={[-1.05, 0.06, 0.05]}
                    >
                      <group
                        name="DIA_3A_RIG_Ctrl_RightToeBase"
                        position={[0, -2.06, 7.45]}
                      />
                    </group>
                  </group>
                </group>
              </group>
              <group
                name="DIA_3A_RIG_Ctrl_LeftAnkleEffector"
                position={[8.31, 3.31, -4.59]}
                rotation={[0.11, 0.32, 0.05]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightAnkleEffector"
                position={[-8.31, 2.75, -1.1]}
                rotation={[0, -0.23, -0.06]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_LeftKneeEffector"
                position={[10.68, 8.9, 12.76]}
                rotation={[1.3, 0.29, -0.14]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightKneeEffector"
                position={[-11.82, 11.97, 14.41]}
                rotation={[1.08, -0.23, 0.2]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_LeftFootEffector"
                position={[10.78, 0.49, 2.17]}
                rotation={[0.11, 0.32, 0.05]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightFootEffector"
                position={[-10.11, 0.73, 6.15]}
                rotation={[0, -0.23, -0.06]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_LeftHipEffector"
                position={[3.85, 21.39, -1]}
                rotation={[-0.81, -0.05, 0.35]}
              />
              <group
                name="DIA_3A_RIG_Ctrl_RightHipEffector"
                position={[-3.85, 21.42, -1.06]}
                rotation={[-1.02, 0.01, -0.41]}
              />
            </group>
            <group name="DIA_3A_RIG_Reference" position={[0, -0.1, -0.44]}>
              <primitive object={nodes.DIA_3A_RIG_Hips} />
            </group>
            <skinnedMesh
              name="DIA_3A"
              geometry={nodes.DIA_3A.geometry}
              material={ props.materials.baked }
              skeleton={nodes.DIA_3A.skeleton}
            />
          </group>
        </group>
      </group>
           
      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
  </group>
  );
}