import { useEffect, useRef, useState } from "react";
import { SvgButton } from "./Experience/SvgButton";
import { NavLink } from "react-router-dom";

export function CookieConsent() {
    
    const [readMore, setReadMore] = useState(false)
    const [state, setState] = useState(0)
    const [analitics, setAnalitics] = useState(false)
    const cookiePopup = useRef()
    const cookieConfiguration = useRef()

    useEffect(() => {
        const allcookies = localStorage.getItem("allcookies")
        if ((allcookies == 'true')) {
            document.body.classList.add('cookies-accepted')
            applyAnalitics()
        } {
            setTimeout(() => {
                setState(1)
                if (cookiePopup.current)
                cookiePopup.current.classList.add('--show')
            }, 1500);
        }
    }, [])
    
    const applyAnalitics = () => {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N7KDNS2M');
    }

    const onAccept = () => {
        localStorage.setItem("allcookies", "true")
        cookiePopup.current.classList.remove('--show')
        cookieConfiguration.current.classList.remove('--show')
        document.body.classList.add('cookies-accepted')
        applyAnalitics() 
    }
    const onConfigure = () => {
        setState(2)
        cookieConfiguration.current.classList.add('--show')
        cookiePopup.current.classList.remove('--show')
    }

    const onSaveCookies = () => {
        if (analitics) {
            localStorage.setItem("allcookies", "true")
            document.body.classList.add('cookies-accepted')
            applyAnalitics()
        }
    }

    const onCloseConfiguration = () => {
        setState(1)
        cookieConfiguration.current.classList.remove('--show')
        cookiePopup.current.classList.add('--show')
    }

    const analiticsChange = (e) => {
        setAnalitics(e.target.checked);
    }

    return <>
    
        <div className="cookie-popup" ref={ cookiePopup }>
            <p>
                Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios. Si pinchas en “aceptar” o continúas navegando consideramos que aceptas su uso. ver <NavLink to="/aviso-legal-y-condiciones-de-uso">política de cookies</NavLink>.
            </p>

            <div className="cp__buttons">
                <SvgButton text="CONFIGURAR" classes={ 'cookie-button first-cookie-button' } onClick={ onConfigure } ready={ true } x={ -75 } />
                <SvgButton text="ACEPTAR" classes={ 'cookie-button' } onClick={ onAccept } ready={ true }/>
            </div>
        </div>


        <div className="cc__wrapper" ref={ cookieConfiguration }>
            <div className="cookie-configuration">
                <div className="cc__close" onClick={ onCloseConfiguration }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.483" height="20.253" viewBox="0 0 19.483 20.253">
                        <g id="Grupo_6684" data-name="Grupo 6684" transform="translate(0.542 0.519)">
                            <line id="Línea_6" data-name="Línea 6" x2="18.4" y2="19.216" transform="translate(0 0)" fill="none" stroke="#9bcaa6" strokeLinejoin="round" strokeWidth="1.5"/>
                            <line id="Línea_337" data-name="Línea 337" x1="18.4" y2="19.216" transform="translate(0 0)" fill="none" stroke="#9bcaa6" strokeLinejoin="round" strokeWidth="1.5"/>
                        </g>
                    </svg>
                </div>

                <p className="cc__title">PREFERENCIAS DE CONSENTIMIENTO</p>

                <div className="cc__scrollable-mobile">

                    <div className="cc__scrollable">

                        <div className={ "cc__info" + ((readMore) ? ' read-more' : '') }>
                            <p>
                                Utilizamos cookies para ayudarlo a navegar de manera eficiente y realizar ciertas funciones. Encontrará información detallada sobre todas las cookies en cada categoría de consentimiento a continuación.
                            </p>
                            <p>
                                Las cookies que se clasifican como "Necesarias" se almacenan en su navegador, ya que son esenciales para habilitar las funcionalidades básicas del sitio.
                            </p>
                            <p>
                                También utilizamos cookies de terceros que nos ayudan a analizar cómo usa este sitio web, almacenar sus preferencias y proporcionar el contenido y los anuncios que son relevantes para usted. Estas cookies solo se almacenarán en su navegador con su consentimiento previo.
                            </p>
                            <p>
                                Puede optar por habilitar o deshabilitar algunas o todas estas cookies, pero deshabilitar algunas de ellas puede afectar su experiencia de navegación.
                            </p>
                        </div>
                        <div className="cc__read-more" onClick={ () => setReadMore(!readMore) }>{ (readMore) ? 'Leer menos' : 'Leer más' }</div>

                        <div className="cc__table">
                            
                            <div className="cc__table-item">
                                <div className="cc__table-item-header">
                                    <div className="cc__table-header">NECESARIO</div>
                                    <div>Siempre activas</div>
                                </div>
                                <p>Se requieren cookies necesarias para habilitar las funciones básicas de este sitio, como proporcionar un inicio de sesión seguro o ajustar sus preferencias de consentimiento. Estas cookies no almacenan ningún dato de identificación personal.</p>
                            </div>
                            
                            <div className="cc__table-item">
                                <div className="cc__table-item-header">
                                    <div className="cc__table-header">ANALÍTICAS</div>
                                    <input type="checkbox" className="cc__switch" onChange={ analiticsChange }/>
                                </div>
                                <p>Las cookies analíticas se utilizan para comprender cómo interactúan los visitantes con el sitio web. Estas cookies ayudan a proporcionar información sobre métricas como el número de visitantes, la tasa de rebote, la fuente de tráfico, etc.</p>
                            </div>
                            
                        </div>
                    </div>

                    <div className="cc__buttons">
                        <SvgButton text="GUARDAR" classes={ 'cookie-button first-cookie-button' } onClick={ onSaveCookies } ready={ true } x={ -58 } />
                        <SvgButton text="ACEPTAR" classes={ 'cookie-button' } onClick={ onAccept } ready={ true }/>
                    </div>

                </div>

            </div>
        </div>

    </>
}