// ===============================================================================
// The addition of all these values must be 1, as the page scroll goes from 0 to 1
// ===============================================================================
export let _BEFORE_ARC = .35, // Crossing the road to get to the arc
    _ON_ARC = .3, // While under the arc
    _AFTER_ARC = .035, // Right after arc animation ended until it gets to the edge of the first terrain
    _FORCEP_SCENE = .04, // Forcep grabs diamond
    _MAGNIFIER = .073, // Magnifier appears and follows cursor
    _GOING_BOTTOM = .07, // Magnifier disappears and forcep drives diamond down
    _STOP_ALTAR = .03, // Minimal delay between going down and going up again
    _UP_ALTAR = .03, // Altar and camera rising
    _BACK_ALTAR = .02, // Camera zooming out
    _GRAPHS = .015, // The rest of the altars rise
    _END = 1 - (_BEFORE_ARC + _ON_ARC + _AFTER_ARC + _FORCEP_SCENE + _MAGNIFIER + _GOING_BOTTOM + _STOP_ALTAR + _UP_ALTAR + _GRAPHS) // Camera zooming out, smartphone appears from outside of the camera edges

export const steps = [
    {
        name: 'SOSTENIBILIDAD',
        km: 0,
        mark: 0
    },
    {
        name: 'USOS DERIVADOS',
        km: 1,
        mark: .33
    },
    {
        name: 'CALIDAD',
        km: 2,
        mark: .67 
    },
    {
        name: 'INVERSIÓN',
        km: 3,
        mark: 1
    },
]