import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import gsap, { CustomEase } from 'gsap/all';
import React, { useEffect, useRef } from "react";
import * as THREE from 'three';
import { BaseTube } from "./BaseTube";
import { ModelBase } from "./ModelBase";
import hexToRgb from "../../Utils/hexToRgb";

export function ModelRobot(props) {
  const { nodes } = useGLTF("/models/UV/Robot.glb");

  const base = useRef()
  const joint1 = useRef()
  const joint2 = useRef()
  const joint3 = useRef()
  const joint4 = useRef()
  const joint5 = useRef()
  const joint6 = useRef()
  const forcep1 = useRef()
  const forcep1_2 = useRef()
  const forcep2 = useRef()
  const forcep2_2 = useRef()
  const lightSphere = useRef()

  const timeline = useRef()

  const refEx = useRef(false)

  const windowsInitialColor = props.colors.element
  const windowsLightColor = hexToRgb(props.colors.light)
  windowsLightColor.r *= .005
  windowsLightColor.g *= .03
  windowsLightColor.b *= .005

  const generalDuration = 4.9

  useEffect(() => {
    if (props.positivePartState) {
      gsap.to(refEx, {
        current: true,
        delay: generalDuration,
        onComplete: function() {
          lightSphere.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
          timeline.current.data[0].play()
        }
      })
    } else {
      gsap.to(refEx, {
        current: false,
        delay: generalDuration,
        onComplete: function() {
          lightSphere.current.material.color.setHex(windowsInitialColor)
          timeline.current.data[0].pause()
        }
    })
    }
  }, [ props.positivePartState ])

  useEffect(() => {
    gsap.registerPlugin(CustomEase)

    const ease = CustomEase.create("custom", "M0,0,C0.296,0.384,0.765,0.981,0.772,0.998,0.852,0.934,0.892,0.998,0.911,0.998,0.922,0.994,0.939,1,0.954,1,0.969,1,1,1,1,1")

    let ctx = gsap.context(() => {
      const timeDuration = 2.2
      const tl = gsap.timeline({repeat: -1});
      tl.fromTo(base.current.rotation, {z: 0}, {z: Math.PI * .5, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(base.current.rotation, {z: Math.PI * .5}, {z: 0, duration: timeDuration, ease: ease}, timeDuration * .5);

      tl.fromTo(joint1.current.rotation, {x: 0}, {x: Math.PI * .2, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(joint1.current.rotation, {x: Math.PI * .2}, {x: 0, duration: timeDuration, ease: ease}, timeDuration * .5);

      tl.fromTo(joint2.current.rotation, {z: -Math.PI * .1}, {z: 0, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(joint2.current.rotation, {z: 0}, {z: -Math.PI * .1, duration: timeDuration, ease: ease}, timeDuration * .5);

      tl.fromTo(joint3.current.rotation, {x: Math.PI * .2}, {x: Math.PI * .4, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(joint3.current.rotation, {x: Math.PI * .4}, {x: Math.PI * .2, duration: timeDuration, ease: ease}, timeDuration * .5);

      tl.fromTo(joint5.current.rotation, {x: -Math.PI * .2}, {x: Math.PI * .2, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(joint5.current.rotation, {x: Math.PI * .2}, {x: -Math.PI * .2, duration: timeDuration, ease: ease}, timeDuration * .5);

      tl.fromTo(joint6.current.rotation, {z: -Math.PI * .5}, {z: Math.PI * 1.5, duration: timeDuration, ease: ease}, timeDuration * 1.5);
      tl.fromTo(joint6.current.rotation, {z: Math.PI * 1.5}, {z: -Math.PI * .5, duration: timeDuration, ease: ease}, timeDuration * .5);
      
      tl.fromTo(forcep1.current.rotation, {y: -Math.PI * .05}, {y: -Math.PI * .2, duration: timeDuration * .25, ease: ease}, timeDuration * 1.5);
      tl.fromTo(forcep1.current.rotation, {y: -Math.PI * .2}, {y: -Math.PI * .05, duration: timeDuration * .25, ease: ease}, 0);
      
      tl.fromTo(forcep2.current.rotation, {y: -Math.PI * .05}, {y: -Math.PI * .2, duration: timeDuration * .25, ease: ease}, timeDuration * 1.5);
      tl.fromTo(forcep2.current.rotation, {y: -Math.PI * .2}, {y: -Math.PI * .05, duration: timeDuration * .25, ease: ease}, 0);

    })

    timeline.current = ctx

    return () => ctx.revert(); // cleanup
  }, [])

  return (
    <group {...props} dispose={null}>
        <group scale={ 3.5 } >
          <mesh matrixAutoUpdate={ props.matrixAutoUpdate } position={ [ .5, .6, .8 ] } scale={ .2 } ref={ lightSphere }>
            <sphereGeometry args={[1, 10]} />
            <meshBasicMaterial color={ windowsInitialColor } />
          </mesh>
          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            geometry={nodes.DIA_BRAZO_ROBOT_Base.geometry}
            material={ props.materials.baked }
            position={[0.01, .76, -0.02]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.66, 0.66, 0.46]}
          >
            <mesh
              ref={ base }
              geometry={nodes.DIA_BRAZO_ROBOT_Tramo1.geometry}
              material={ props.materials.baked }
              position={[0, 0.02, -2.56]}
              scale={[0.3, 0.3, 0.43]}
            >
              <mesh
                geometry={nodes.DIA_BRAZO_ROBOT_Tramo1001.geometry}
                material={ props.materials.baked }
              />
              <mesh
                ref={ joint1 }
                geometry={nodes.DIA_BRAZO_ROBOT_Tramo2001.geometry}
                material={ props.materials.baked }
                position={[-11.22, 0, -0.03]}
              >
                <mesh
                  matrixAutoUpdate={ props.matrixAutoUpdate }
                  geometry={nodes.DIA_BRAZO_ROBOT_Tramo2002.geometry}
                  material={ props.materials.baked }
                />
                <mesh
                  ref={ joint2 }
                  geometry={nodes.DIA_BRAZO_ROBOT_Tramo3.geometry}
                  material={ props.materials.baked }
                  position={[0, 0, -22.48]}
                >
                  <mesh
                    matrixAutoUpdate={ props.matrixAutoUpdate }
                    geometry={nodes.DIA_BRAZO_ROBOT_Tramo3001.geometry}
                    material={ props.materials.baked }
                  />
                  <mesh
                    ref={ joint3 }
                    geometry={nodes.DIA_BRAZO_ROBOT_Tramo4.geometry}
                    material={ props.materials.baked }
                    position={[8.96, -0.06, 0.01]}
                  >
                    <mesh
                      matrixAutoUpdate={ props.matrixAutoUpdate }
                      geometry={nodes.DIA_BRAZO_ROBOT_Tramo4001.geometry}
                      material={ props.materials.baked }
                    />
                    <mesh
                      ref={ joint4 }
                      geometry={nodes.DIA_BRAZO_ROBOT_Tramo5.geometry}
                      material={ props.materials.baked }
                      position={[-0.01, -0.01, -22.55]}
                    >
                      <mesh
                        matrixAutoUpdate={ props.matrixAutoUpdate }
                        geometry={nodes.DIA_BRAZO_ROBOT_Tramo5001.geometry}
                        material={ props.materials.baked }
                      />
                      <mesh
                        ref={ joint5 }
                        geometry={nodes.DIA_BRAZO_ROBOT_Tramo6.geometry}
                        material={ props.materials.baked }
                        position={[-7.46, 0, 0.01]}
                        rotation={[0, 0, Math.PI]}
                      >
                        <mesh
                          matrixAutoUpdate={ props.matrixAutoUpdate }
                          geometry={nodes.DIA_BRAZO_ROBOT_Tramo6001.geometry}
                          material={ props.materials.baked }
                        />
                        <mesh
                          ref={ joint6 }
                          geometry={nodes.DIA_BRAZO_ROBOT_Tramo7001.geometry}
                          material={ props.materials.baked }
                          position={[0.06, 0, -8.81]}
                        >
                          <mesh
                            matrixAutoUpdate={ props.matrixAutoUpdate }
                            geometry={nodes.DIA_BRAZO_ROBOT_Tramo7.geometry}
                            material={ props.materials.baked }
                          >
                            <mesh
                              ref={ forcep1 }
                              geometry={nodes.DIA_BRAZO_ROBOT_Pinza1.geometry}
                              material={ props.materials.baked }
                              position={[0, 0, -0.52]}
                              rotation={[0, -0.7, 0]}
                              scale={[2.02, 1.21, 4.65]}
                            >
                              <mesh
                                ref={ forcep1_2 }
                                geometry={nodes.DIA_BRAZO_ROBOT_Pinza4.geometry}
                                material={ props.materials.baked }
                                position={[0.1, 0.01, -1.45]}
                                rotation={[0, 0.69, Math.PI]}
                                scale={[0.08, 0.13, 0.07]}
                              />
                            </mesh>
                            <mesh
                              ref={ forcep2 }
                              geometry={nodes.DIA_BRAZO_ROBOT_Pinza3.geometry}
                              material={ props.materials.baked }
                              position={[0, 0, -0.52]}
                              rotation={[Math.PI, -0.7, 0]}
                              scale={[-2.02, -1.21, -4.65]}
                            >
                              <mesh
                                ref={ forcep2_2 }
                                geometry={nodes.DIA_BRAZO_ROBOT_Pinza2.geometry}
                                material={ props.materials.baked }
                                position={[0.1, -0.07, -1.41]}
                                rotation={[0, 0.69, -Math.PI]}
                                scale={[0.08, 0.13, 0.07]}
                              />
                            </mesh>
                          </mesh>
                        </mesh>
                      </mesh>
                    </mesh>
                  </mesh>
                </mesh>
              </mesh>
            </mesh>
          </mesh>
        </group>
        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } />
        <BaseTube matrixAutoUpdate={ props.matrixAutoUpdate } position={ [ -14.6, .002, 8 ] } rotation={ [ 0, Math.PI * .7, 0 ] } color={ props.elementColor } positivePart={ props.positivePart }  expStep={ props.expStep } positivePartState={ props.positivePartState } />
    </group>
  );
}