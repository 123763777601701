import { useEffect, useRef, useState } from "react";
import { Footer } from "./Front";
import { ancla, animaciones, iniScrollSmoother } from "./Animations";
import emailjs from '@emailjs/browser';
import { NavLink } from "react-router-dom";

export function Contacto() {
  const contenedor = useRef();

  const configFront = {
    pages: 6,
  };

  const [loaded, setLoaded] = useState(false);
  const form = useRef();
  const sliderdia = useRef();

  useEffect(() => {
    if (!loaded) return;

    const html = document.querySelector("html");
    if (html) {
      html.classList.add("front");
    }

    iniScrollSmoother();
  	setTimeout(function () {
			animaciones('contacto');
		}, 2000);
  }, [loaded]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    let envio = true;
    
    document.getElementById("contacto__input_nombre").classList.remove("active");
    document.getElementById("contacto__input_empresa").classList.remove("active");
    document.getElementById("contacto__input_telefono").classList.remove("active");
    document.getElementById("contacto__input_email").classList.remove("active");
    document.getElementById("error_mensaje").classList.remove("active");
    document.getElementById("error_check").classList.remove("active");

    if(form.current.nombre.value == ''){
        document.getElementById("contacto__input_nombre").classList.add("active");
        envio = false;
    }
    if(form.current.empresa.value == ''){
        document.getElementById("contacto__input_empresa").classList.add("active");
        envio = false;
    }
    if(form.current.telefono.value == ''){
      document.getElementById("contacto__input_telefono").classList.add("active");
      envio = false;
    }
    
    var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!form.current.email.value.match(validRegex)){
      document.getElementById("contacto__input_email").classList.add("active");
      envio = false;
    }

    if(form.current.mensaje.value == ''){
      document.getElementById("error_mensaje").classList.add("active");
      envio = false;
    }
    
    if(form.current.politica.checked == false){
      document.getElementById("error_check").classList.add("active");
      envio = false;
    }

    if(envio){
      emailjs.sendForm('service_i0eupay', 'template_uyfkk31', form.current, 'X5btBnH_h4ZCksbGB')
      .then((result) => {
          document.getElementById("mensaje__ok").classList.add("active");
          setTimeout(function(){
            document.getElementById("mensaje__ok").classList.remove("active");
            form.current.nombre.value = '';
            form.current.empresa.value = '';
            form.current.telefono.value = '';
            form.current.email.value = '';
            form.current.mensaje.value = '';
            form.current.politica.checked = false;
          },4000)
      }, (error) => {
          console.log(error.text);
      });
    }
  };

  return (
    <>
<div id="smooth-wrapper">
  <div id="smooth-content">

    
  <div className="header-pin">
      <div className="header header2 header-ani header__contacto">
          <div className="header__data">
            
            <div className="pieza-header-container">
              <div className="pieza pieza-header">
                <span className="pieza__line pieza__1"></span>
                <span className="pieza__line pieza__2"></span>
                <span className="pieza__line pieza__3"></span>
                <span className="pieza__line pieza__4"></span>
                <span className="pieza__rombo pieza__5"></span>
              </div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            <span data-delay="1.2" >CONTACTO</span>
            </div>

            <div className='header__titulo_ani'>
              <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>TU OPINIÓN</span><span className='linet linet2'>ES IMPORTANTE.</span><span className='linet linet3'>HABLEMOS.</span></div></div>
              <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>TU OPINIÓN</span><span className='linet linet2'>ES IMPORTANTE.</span><span className='linet linet3'>HABLEMOS.</span></div></div>
              <div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>TU </span>OPIN<span className='ocu'>IÓN</span></span><span className='linet linet2'><span className='ocu'>ES IM</span>PORTA<span className='ocu'>NTE.</span></span><span className='linet linet3'><span className='ocu'>HA</span>BLEM<span className='ocu'>OS.</span></span></div></div>
            </div>
            
            <div className="header__ani_center">
              <div className='header__ani_rombo'></div>
              <div className="header__ani">
                <img src="images/animaciones/header_contacto/DIA_63000.png" id='header_img' className='header__ani_img' />
              </div>
            </div>
          </div>

          <div className="cita cita-header">
        <div className="cita__title ani_ini_mask">
          <span data-delay="2">Hablemos</span>
        </div>
        <div className="cita__cita ani_ini_line" data-delay="2">Hablemos de tu proyecto. Estamos encantados de poder atenderte.</div>
      </div>
          <div className="header__arrow">
            <a href="#" onClick={(e)=> ancla('contacto')}
              className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
              <span className="btn__rombo_cont"></span>
              <span className="btn__rombo_txt">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                    <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                      <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                      <path d="M7.543 13.804V0" data-name="Línea 43" />
                    </g>
                  </svg>
                </span>
              </span>
            </a>
          </div>
      </div>
    </div>


    <div className="header__contacto_contenedor" id="contacto">
      <div className="header__contacto_data">
        <div className="header__contacto_data_left ">PASEO DE LA CASTELLANA 9<br />28046 MADRID</div>
        <div className="header__contacto_data_right ">
          <a href="tel:+34626919322">+34 626 91 93 22</a><br />
          <a href="mailto:info@dianelum.com">info@dianelum.com</a>
        </div>
        <div className="header__contacto_data_line"></div>
      </div>
    </div>

    <div className="fondo__verde">
      <div className="page__contacto">
            <div className="mensaje__ok" id="mensaje__ok">
                <div className="mensaje__ok_title">Su mensaje Ha sido enviado Con exito.</div>
            </div>

        <form ref={form} onSubmit={sendEmail}>

          <div className="contacto">
            <div className="contacto__col1">
              <div className="contacto__formcont">
                <div className="contacto__input" id="contacto__input_nombre">
                  <svg className="contacto__input_error_ico" xmlns="http://www.w3.org/2000/svg" width="52.643" height="52.643"><g data-name="Grupo 7819"><path fill="#141515" d="M0 26.322 26.321.001l26.322 26.321L26.32 52.643z" data-name="Rectángulo 2450"/><path fill="#fff" d="M25.32 18.388h2.162v10.9H25.32Zm.069 13.57h2.024l.138 2.438h-2.3Z" data-name="Trazado 37931"/></g></svg>
                  <input type="text" className="form__control ani_scroll_fade" name="nombre" placeholder="Nombre"  />
                  <span className="contacto__input_error" >El nombre es obligatorio</span>
                </div>
                <div className="contacto__input" id="contacto__input_empresa">
                  <svg className="contacto__input_error_ico" xmlns="http://www.w3.org/2000/svg" width="52.643" height="52.643"><g data-name="Grupo 7819"><path fill="#141515" d="M0 26.322 26.321.001l26.322 26.321L26.32 52.643z" data-name="Rectángulo 2450"/><path fill="#fff" d="M25.32 18.388h2.162v10.9H25.32Zm.069 13.57h2.024l.138 2.438h-2.3Z" data-name="Trazado 37931"/></g></svg>
                  <input type="text" className="form__control ani_scroll_fade" name="empresa" placeholder="Empresa"  />
                  <span className="contacto__input_error">La empresa es obligatoria</span>
                </div>
                <div className="contacto__input" id="contacto__input_telefono">
                  <svg className="contacto__input_error_ico" xmlns="http://www.w3.org/2000/svg" width="52.643" height="52.643"><g data-name="Grupo 7819"><path fill="#141515" d="M0 26.322 26.321.001l26.322 26.321L26.32 52.643z" data-name="Rectángulo 2450"/><path fill="#fff" d="M25.32 18.388h2.162v10.9H25.32Zm.069 13.57h2.024l.138 2.438h-2.3Z" data-name="Trazado 37931"/></g></svg>
                  <input type="text" className="form__control ani_scroll_fade" name="telefono" placeholder="Teléfono"  />
                  <span className="contacto__input_error">El teléfono es obligatorio</span>
                </div>
                <div className="contacto__input" id="contacto__input_email">
                  <svg className="contacto__input_error_ico" xmlns="http://www.w3.org/2000/svg" width="52.643" height="52.643"><g data-name="Grupo 7819"><path fill="#141515" d="M0 26.322 26.321.001l26.322 26.321L26.32 52.643z" data-name="Rectángulo 2450"/><path fill="#fff" d="M25.32 18.388h2.162v10.9H25.32Zm.069 13.57h2.024l.138 2.438h-2.3Z" data-name="Trazado 37931"/></g></svg>
                  <input type="email" className="form__control ani_scroll_fade" name="email" placeholder="Email"  />
                  <span className="contacto__input_error">La dirección de email no es válida</span>
                </div>
                
              </div>
            </div>
            <div className="contacto__col2">
              <div className="contacto__mensaje">
                Estimado dianelum,
                <textarea name="mensaje"  className="form__control form__control_textarea" placeholder="Envíanos un mensaje con tu nombre, apellido, correo electrónico y solicitud y nos pondremos en contacto contigo antes de 48h."></textarea>
              </div>
              <div className="contacto__mensaje_error" id="error_mensaje">El mensaje es obligatorio</div>
            </div>
          </div>
          <div className="contacto">
            <div className="contacto__col1">

            </div>
            <div className="contacto__col2">
              <div className="contacto__legalsub">
                <div className="contacto__legal ani_scroll_fade">
                  <label className="checkbox__container">
                    <input type="checkbox" name="politica" />
                    <span className="checkmark"></span>
                  </label>
                  <p>He leído y acepto la Política de privacidad. Como responsable del tratamiento y legitimado por su consentimiento trataremos sus datos para el envío de publicidad y boletines informativos. No cedemos la info. Tiene derecho a acceso, rectificación, oposición y limitación de los datos y otros derechos, como se explica en nuestra <NavLink to="/aviso-legal-y-condiciones-de-uso">Política de privacidad</NavLink>.
                  </p>
                  
                </div>
                

                <div className="contacto__submit ani_scroll_fade">
                  <button className="form__submit" type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.5 65.503">
                      <g fill="none" stroke="#141515" data-name="Grupo 3017">
                        <path strokeLinejoin="round" d="M180.115.5H15.385L.5 13.4v38.7L15.385 65h164.73L195 52.1V13.4Z" data-name="Trazado 37445" />
                        <path strokeLinejoin="round" d="m.5 52.102 97.25 12.9 97.25-12.9" data-name="Trazado 37447" />
                        <path strokeLinejoin="round" d="M195 13.401 97.75.501.5 13.401" data-name="Trazado 37448" />
                        <path d="M15.636.5v64.503" data-name="Línea 107" />
                        <path d="M179.864.5v64.503" data-name="Línea 108" />
                      </g>
                    </svg>
                    <span>ENVIAR</span>
                  </button>
                </div>

              </div>
              <div className="contacto__check_error" id="error_check">Tienes que aceptar la politica de privacidad</div>

            </div>
          </div>
        </form>

      </div>
    </div>

    <Footer  tipo="headerVerde" />
  </div>
</div>
    </>
  );
}
