import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { ModelBase } from "./ModelBase";
import * as THREE from 'three';
import { useFrame } from "@react-three/fiber";
import { folder, useControls } from "leva";
import { TextRevealer } from "../TextRevealer";

function Blade(props) {

  const config = useControls('Modelos', {
    'Chimeneas': folder({
      bladeSpeed: { value: .5, min: 0, max: 5, step: .001 },
    }),
  })

  const blade = useRef()

  useFrame((state, delta) => {
    if (props.expStep <= 8) {
      blade.current.rotation.y -= config.bladeSpeed * delta
    }
  })

  return <>
    <group {...props}>
      <group rotation={ [ Math.PI * .5, 0, 0 ] }>
        <mesh
          ref={ blade }
          geometry={ props.geometry }
          material={ props.material }
          scale={ .62 }
        />
      </group>
    </group>
  </>
}

export function ModelWindmills(props) {
  const { nodes } = useGLTF("/models/UV/Windmills.glb");

  /* useEffect(() => {
    if (props.expStep === 2) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
      <Blade 
        geometry={nodes.DIA_2B_MOLINO1_Aspas1.geometry} 
        material={ props.materials.baked }
        position={[-9.98, 16.91, -1.6]}
        rotation={[0, -.5, 2.6]}
        expStep={ props.expStep }
        />
      <Blade 
        geometry={nodes.DIA_2B_MOLINO1_Aspas1.geometry} 
        material={ props.materials.baked } 
        position={[-0.01, 16.91, 11.63]}
        expStep={ props.expStep }
        />
      <Blade 
        geometry={nodes.DIA_2B_MOLINO1_Aspas1.geometry} 
        material={ props.materials.baked } 
        position={[10.06, 16.91, -1.26]}
        rotation={[0, .65, -2.6]}
        expStep={ props.expStep }
        />
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_2B_MOLINO1_Molinos.geometry}
        material={ props.materials.baked }
        position={[0.08, 8.49, 8.62]}
        rotation={[Math.PI / 2, 0, -3.12]}
        scale={[0.81, 0.81, 9.13]}
      />

      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}