import { useEffect } from "react"


export default function Resizer() {

    useEffect(() => {
        let widthScreen = window.innerWidth

        const handleResize = () => {
            setTimeout(() => {
                if (widthScreen !== window.innerWidth) {
                    window.location.reload()
                }
                widthScreen = window.innerWidth
                console.log(widthScreen, window.innerWidth);
            }, 400);
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return <></>
}