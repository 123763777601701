import { useGLTF } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import React, { useEffect, useMemo } from "react";
import * as THREE from 'three';
import SmokeParticle from "../SmokeParticle";
import { ModelBase } from "./ModelBase";

export function ModelChimeneys(props) {
  const { nodes } = useGLTF("/models/UV/Chimeneys.glb");

  const smokeMaterial = useMemo(() => new THREE.MeshMatcapMaterial({ color: props.smokeColor }), [])
  const smokeGeometry = useMemo(() => new THREE.SphereGeometry(1, 6, 6), [])

  const { gl } = useThree()

  /* useEffect(() => {
    if (props.expStep === 2) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_2A_Chimeneas.geometry}
        material={ props.materials.baked }
        position={[-5.53, 6.05, 2.35]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={5.45}
      />

      <group scale={ 4.5 } position={ [ -6, 7, 2 ] } rotation-y={ Math.PI * .5 }>
        {Array.from({ length: 20 }, (_, i) => (
          <SmokeParticle key={i} material={ smokeMaterial } geometry={ smokeGeometry } active={ props.expStep === 1 } />
        ))}
      </group>

      <group scale={ 2.5 } position={ [ 1.3, 19, -5.7 ] } rotation-y={ Math.PI * .5 }>
        {Array.from({ length: 13 }, (_, i) => (
          <SmokeParticle key={i} material={ smokeMaterial } geometry={ smokeGeometry } active={ props.expStep === 1 } />
        ))}
      </group>

      <group scale={ 1.5 } position={ [ 4, 20, 1.7 ] } rotation-y={ Math.PI * .5 }>
        {Array.from({ length: 13 }, (_, i) => (
          <SmokeParticle key={i} material={ smokeMaterial } geometry={ smokeGeometry } active={ props.expStep === 1 } />
        ))}
      </group>

      <group scale={ 1.5 } position={ [ 8.2, 13, -3.5 ] } rotation-y={ Math.PI * .5 }>
        {Array.from({ length: 13 }, (_, i) => (
          <SmokeParticle key={i} material={ smokeMaterial } geometry={ smokeGeometry } active={ props.expStep === 1 } />
        ))}
      </group>

      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}