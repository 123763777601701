import { useFrame } from '@react-three/fiber';
import { gsap } from 'gsap/all';
import { folder, useControls } from 'leva';
import { useEffect } from 'react';
import * as THREE from 'three';

export function TubeShaderMaterial({ texture, color, size, offset = 0, isX, negative, positivePart, expStep, positivePartState }) {
    
  const config = useControls('Cables', {
    'Luz': folder({
      color: '#ffffff',
      longitud: { value: .015, min: 0, max: .5, step: .01 },
    }),
  })

  const m = new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { value: texture },
      uColor: { value: new THREE.Color(color) },
      uTime: { value: 0 },
      uLightColor: { value: new THREE.Color(config.color) },
      uXuv: { value: isX },
      uTrailIntensity: { value: /* config.longitud */ size },
      uNegative: { value: negative },
    },
    vertexShader: [

      "uniform vec4 offsetRepeat;",
  
      "varying vec3 vNormal;",
      "varying vec2 vUv;",
  
      "varying vec3 vViewPosition;",
  
      THREE.ShaderChunk[ "common" ],
      THREE.ShaderChunk[ "lights_pars" ],
      THREE.ShaderChunk[ "shadowmap_pars_vertex" ],
      THREE.ShaderChunk[ "morphtarget_pars_vertex" ],
      THREE.ShaderChunk[ "skinning_pars_vertex" ],
  
      "void main() {",
        "vec4 worldPosition = modelMatrix * vec4( position, 1.0 );",

        THREE.ShaderChunk[ "skinbase_vertex" ],
        THREE.ShaderChunk[ "begin_vertex" ],
        THREE.ShaderChunk[ "morphtarget_vertex" ],
        THREE.ShaderChunk[ "skinning_vertex" ],
        THREE.ShaderChunk[ "project_vertex" ],

        "vViewPosition = -mvPosition.xyz;",

        "vNormal = normalize( normalMatrix * normal );",

        "vUv = uv;",

        "gl_Position = projectionMatrix * mvPosition;",

        THREE.ShaderChunk[ "shadowmap_vertex" ],
  
      "}"
  
  ].join( "\n" ),
    fragmentShader: /* glsl */`
      uniform sampler2D uTexture;
      uniform vec3 uColor;
      uniform float uTime;
      uniform vec3 uLightColor;
      uniform bool uXuv;
      uniform float uTrailIntensity; // Trail intensity factor
      uniform bool uNegative;

      float trailSpeed = 1.5; // Trail animation speed
      
      varying vec2 vUv;
      
      
      void main() {
        // Get the texture color
        vec4 textureColor = texture2D(uTexture, vUv);
    
        float mult = .6;
        if (uNegative) {mult = .6;}
        vec4 textureCable = textureColor * vec4(uColor.rgb * mult, 1);

        // Calculate the trail effect offset based on time
        float trailOffset = 1.0 - fract(uTime * trailSpeed);
    
        // Calculate the distance from the trail
        float uvCoord = vUv.y;
        if (uXuv) { uvCoord = vUv.x; }
        float distanceFromTrail = abs(uvCoord - trailOffset);
    
        // Apply the trail effect if the current pixel is close to the trail
        if (distanceFromTrail < uTrailIntensity) {
        // Calculate the trail color based on distance
        vec3 trailColor = vec3(1.0 - (distanceFromTrail / uTrailIntensity));

        vec3 lightColor = vec3(uLightColor.r * 1.0, uLightColor.g * 40.0, uLightColor.r * 1.0);
    
        // Blend the trail color with the texture color
        textureCable.rgb = mix(textureCable.rgb, lightColor, trailColor.r);
        }
    
        gl_FragColor = textureCable;
      }
    `,
  });

  useEffect(() => {
    if (positivePartState) {
      gsap.fromTo(m.uniforms.uTime, {
        value: 0,
      }, {
        value: .7,
        duration: 4,
        delay: 1 + offset
      })
    }
  }, [positivePartState])

  return m

}