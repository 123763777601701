import { useGLTF } from "@react-three/drei";
import { gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";
import { BaseTube } from "./BaseTube";
import { ModelBase } from "./ModelBase";
import hexToRgb from "../../Utils/hexToRgb";

export function ModelBuildings(props) {
  const { nodes } = useGLTF("/models/UV/Buildings.glb");

  const window1 = useRef()
  const window2 = useRef()
  const window3 = useRef()

  const mult = .8

  const windowsInitialColor = props.colors.element
  const windowsLightColor = hexToRgb(props.colors.light)
  windowsLightColor.r *= .005 * mult
  windowsLightColor.g *= .02 *  mult
  windowsLightColor.b *= .0075 * mult

  const generalDuration = 5

  useEffect(() => {
    if (props.positivePartState) {
      const p = { p1: 0, p2: 0, p3: 0 }
      gsap.to(p, {
        p1: 1,
        duration: generalDuration,
        onComplete: () => {
          window1.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
        }
      })
      gsap.to(p, {
        p2: 1,
        duration: generalDuration + .2,
        onComplete: () => {
          window2.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
          window3.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
        }
      })
      gsap.to(p, {
        p3: 1,
        duration: generalDuration + .6,
        onComplete: () => {
          window3.current.material.color.setRGB(windowsLightColor.r, windowsLightColor.g, windowsLightColor.b)
        }
      })
    } else {
      window1.current.material.color.setHex(windowsInitialColor)
      window2.current.material.color.setHex(windowsInitialColor)
      window3.current.material.color.setHex(windowsInitialColor)
    }
  }, [props.positivePartState])


  return (
    <group {...props} dispose={null}>
        <group scale={ 3.4 } position={ [ 0, 2.3, 0 ] }>

          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            ref={ window3 }
            geometry={nodes.DIA_EDIFICIOS_Ventanas3.geometry}
            position={[-2.08, 2.16, 1.99]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.36}
          >
            <meshBasicMaterial color={ windowsInitialColor } />
          </mesh>

          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            ref={ window2 }
            geometry={nodes.DIA_EDIFICIOS_Ventanas2.geometry}
            position={[1.93, 0.98, -1.96]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.36}
            >
            <meshBasicMaterial color={ windowsInitialColor } />
          </mesh>
          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            ref={ window1 }
            geometry={nodes.DIA_EDIFICIOS_Ventanas1.geometry}
            position={[2.02, 1.6, 2.18]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.36}
            >
            <meshBasicMaterial color={ windowsInitialColor } />
          </mesh>
          <mesh
            matrixAutoUpdate={ props.matrixAutoUpdate }
            geometry={nodes.DIA_EDIFICIOS.geometry}
            material={ props.materials.baked }
            position={[0.12, 1.38, 0.83]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.36}
          />

        </group>

        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } rotationspeed={ 0 } />
        <BaseTube matrixAutoUpdate={ props.matrixAutoUpdate } position={ [ -9, .002, -14 ] } rotation={ [ 0, Math.PI * 0.15, 0 ] } color={ props.elementColor } positivePart={ props.positivePart } expStep={ props.expStep } positivePartState={ props.positivePartState } />
      </group>
  );
}