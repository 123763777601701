import { Suspense, useEffect, useRef, useState } from "react"
import supportsHEVCAlpha from "../../Utils/supportsHEVCAlpha"
import useOnScreen from "../../Utils/useOnScreen";
import WindowHandler from "../../Utils/WindowHandler";

export default function Video({ src, id, poster, className, autoPlay, loop, onScroll, triggerPlay = { trigger: null, delay: 0 }, triggerHover, onCanPlay }) {
    const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    
    const [computedSrc, setSrc] = useState()
    const [alreadyPlayed, setAlreadyPlayed] = useState(false)
    const videoRef = useRef()
    const isTouch = WindowHandler().isTouch
    
    const canPlay = () => {
        onCanPlay()
        if (videoRef.current)
            videoRef.current.removeEventListener('canplaythrough', canPlay)
    }

    useEffect(() => {
        if (isTouch) return
        if (!supportsHEVCAlpha()) {
            setSrc('videos/'+src+'.webm')
        } else {
            setSrc('videos/'+src+'.mov')
        }

        if (onCanPlay)
            videoRef.current.addEventListener('canplaythrough', canPlay)
        
    }, [])

    useEffect(() => {
        if (alreadyPlayed || isTouch) return
        setTimeout(() => {
            if (triggerPlay.trigger) {
                if (isVideoPlaying(videoRef.current)) {
                    videoRef.current.currentTime = 0
                }
                videoRef.current.play()
                setAlreadyPlayed(true)
            }
        }, triggerPlay.delay * 1000)
    }, [triggerPlay])

    const isVisible = useOnScreen(videoRef);

    useEffect(() => {
        if (autoPlay || onScroll || isTouch) return
        if (isVisible) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }, [isVisible])

    useEffect(() => {
        if (triggerHover) {
            videoRef.current.loop = true
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }, [triggerHover])


    return <>
        <video 
            ref={ videoRef }
            type={ 'video/mp4; codecs="hvc1"' }
            src={ computedSrc }
            id={ id }
            className={ className }
            playsInline
            muted
            loop={ loop }
            poster={ poster }
        >
        </video>
    </>
}