import { useGLTF } from "@react-three/drei";
import { gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";

export function ModelBase(props) {
  const { nodes } = useGLTF("/models/UV/Base.glb");

  const hasSecondMaterial = props.secondMaterial
  
  const rotationRef = useRef()

  const reset = () => {
    props.material.opacity = 1
    props.secondMaterial.opacity = 0
    props.secondFloorMaterial.opacity = 0
  }

  useEffect(() => {
    if (!hasSecondMaterial) return
    reset()
  }, [])

  useEffect(() => {
    if (!hasSecondMaterial) return
      
    if (props.trigger) {
      reset() 
      gsap.to(props.material, {
        opacity: 0,
        duration: props.transition.duration,
        delay: props.transition.delay,
      })
  
      gsap.to(props.secondMaterial, {
        opacity: 1,
        duration: props.transition.duration,
        delay: props.transition.delay,
      })
      gsap.to(props.secondFloorMaterial, {
        opacity: 1,
        duration: props.transition.duration,
        delay: props.transition.delay,
      })
    } else {
      setTimeout(() => {
        reset()
      }, 2000);
    }

  }, [props.trigger])


  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.DIA_PEANA_Bajo.geometry}
        material={ props.material }
        position={[0, -1.674, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[13.612, 13.612, 1.504]}
      />
      <mesh
        geometry={nodes.DIA_SUELO.geometry}
        material={ props.floorMaterial }
        position={[0, -2.761, 0]}
        scale={37.362}
      />
      {/* <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_PEANA_Alto.geometry}
        material={ props.material }
        position={[0, -0.45, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[13.61, 13.61, 1.5]}
      />
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        ref={ rotationRef }
        geometry={nodes.DIA_PEANA_Bajo.geometry}
        material={ props.material }
        position={[0, -1.67, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[13.61, 13.61, 1.5]}
      />
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_SUELO.geometry}
        material={ props.floorMaterial }
        position={[0, -2.79, 0]}
        scale={37.36}
      /> */}

      {/* <Merged meshes={nodes} material={ props.material }>
        {({ DIA_PEANA_Alto, DIA_PEANA_Bajo }) => (
          <>
            <DIA_PEANA_Alto 
              matrixAutoUpdate={ props.matrixAutoUpdate }
              position={[0, -0.45, 0]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[13.61, 13.61, 1.5]}
            />
            <DIA_PEANA_Bajo 
              matrixAutoUpdate={ props.matrixAutoUpdate }
              position={[0, -1.67, 0]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[13.61, 13.61, 1.5]}
            />
          </>
        )}
      </Merged>

      <mesh 
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_SUELO.geometry}
        material={ props.floorMaterial }
        position={[0, -2.79, 0]}
        scale={37.36}
      /> */}

      { 
        hasSecondMaterial &&
        <>
          <mesh
            geometry={nodes.DIA_PEANA_Bajo.geometry}
            material={ props.secondMaterial }
            position={[0, -1.674, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[13.612, 13.612, 1.504]}
          />
          <mesh
            geometry={nodes.DIA_SUELO.geometry}
            material={ props.secondFloorMaterial }
            position={[0, -2.761, 0]}
            scale={37.362}
          />
        </>
      }
    </group>
  );
}