import { useEffect, useRef } from "react"


export function SvgButton({ onClick, text, classes, ready, x }) {

    const button = useRef()

    useEffect(() => {

        if (ready) {
            button.current.classList.add('load')
            setTimeout(() => {
                if (button.current) {
                    button.current.classList.add('can-hover')
                }
            }, 1000)
        } else {
            button.current.classList.remove('load')
            button.current.classList.remove('can-hover')
        }

    }, [ready])

    return <>
        <svg ref={ button } onClick={ onClick } className={ classes + ' svg-button' } xmlns="http://www.w3.org/2000/svg" width="195.5" height="65.503" viewBox="0 0 195.5 65.503">
            <g id="Grupo_6413" data-name="Grupo 6413" transform="translate(0.5 0.5)">
                <g id="Button_Std1" data-name="Button&gt;Std1">
                <g id="Grupo_3017" data-name="Grupo 3017">
                    <path className="path1" id="Trazado_37445" data-name="Trazado 37445" d="M185.115.5H20.385L5.5,13.4V52.1L20.385,65h164.73L200,52.1V13.4Z" transform="translate(-5.5 -0.5)" fill="none" stroke="#141515" strokeLinejoin="round" strokeWidth="1"/>
                    <path className="path2" id="Trazado_37447" data-name="Trazado 37447" d="M.5,55.5,97.75,68.4,195,55.5" transform="translate(-0.5 -3.898)" fill="none" stroke="#141515" strokeLinejoin="round" strokeWidth="1"/>
                    <path className="path3" id="Trazado_37448" data-name="Trazado 37448" d="M0,0,97.25,12.9,194.5,0" transform="translate(194.5 12.901) rotate(180)" fill="none" stroke="#141515" strokeLinejoin="round" strokeWidth="1"/>
                    <line className="line1" id="Línea_107" data-name="Línea 107" y2="64.503" transform="translate(15.136)" fill="none" stroke="#141515" strokeWidth="1"/>
                    <line className="line2" id="Línea_108" data-name="Línea 108" y2="64.503" transform="translate(179.364)" fill="none" stroke="#141515" strokeWidth="1"/>
                </g>
                    <text className="svg-button__text" transform="translate(95.858 39.485)" fill="#141515" fontSize="18"><tspan x={ (x) ? x : -55.881 } y="0">{ text }</tspan></text>
                </g>
            </g>
        </svg>
    
    </>
}