import { useAnimations, useGLTF } from "@react-three/drei";
import React, { useEffect, useRef, useState } from "react";
import { ModelBase } from "./ModelBase";

export function ModelOpenSkyMine(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("/models/UV/OpenSkyMine.glb");
  const { actions } = useAnimations(animations, group);
  const [animPlaying, setAnimPlaying] = useState(false)

  useEffect(() => {
    for (const key in actions) {
      const action = actions[key]
      action.play()
      action.paused = true
    }
  }, [])
  useEffect(() => {
    if (props.expStep === 3) {
      for (const key in actions) {
        const action = actions[key]
        action.paused = false
      }
      setAnimPlaying(true)
    } else if (animPlaying === true) {
      for (const key in actions) {
        const action = actions[key]
        action.paused = true
      }
      setAnimPlaying(false)
    }
    /* if (props.expStep === 4) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    } */
  }, [props.expStep])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        
      <group
          name="DIA_PERFORADORA"
          position={[0.748, 2.308, -1.89]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.086}
        >
          <mesh
            name="DIA_PERFORADORA_GRUA"
            geometry={nodes.DIA_PERFORADORA_GRUA.geometry}
            material={props.materials.baked}
          >
            <group name="DIA_GRUPO_DIENTES">
              <mesh
                name="DIA_PERFORADORA_DIENTES"
                geometry={nodes.DIA_PERFORADORA_DIENTES.geometry}
                material={props.materials.baked}
              />
            </group>
          </mesh>
        </group>
        <group
          name="DIA_CAMION_1"
          position={[0.452, 0.376, 1.475]}
          rotation={[Math.PI / 2, 0, 0.22]}
          scale={0.086}
        >
          <mesh
            name="DIA_CAMION_CHASIS"
            geometry={nodes.DIA_CAMION_CHASIS.geometry}
            material={props.materials.baked}
          />
        </group>
        <group
          name="DIA_CAMION_2"
          position={[1.705, 0.376, -6.01]}
          rotation={[Math.PI / 2, 0, 2.362]}
          scale={0.086}
        >
          <mesh
            name="DIA_CAMION_CHASIS001"
            geometry={nodes.DIA_CAMION_CHASIS001.geometry}
            material={props.materials.baked}
            position={[0, 5.963, 0]}
          >
            <mesh
              name="DIA_CAMION_VOLQUETE001"
              geometry={nodes.DIA_CAMION_VOLQUETE001.geometry}
              material={props.materials.baked}
            />
          </mesh>
        </group>
        <group
          name="DIA_EXCAVADORA_2"
          position={[-4.189, 0.583, 9.701]}
          rotation={[Math.PI / 2, 0, -2.392]}
          scale={0.071}
        >
          <mesh
            name="DIA_EXCAVADORA1_CUERPO001"
            geometry={nodes.DIA_EXCAVADORA1_CUERPO001.geometry}
            material={props.materials.baked}
            position={[25.779, -8.284, 0]}
            rotation={[0, 0, 0.085]}
          >
            <mesh
              name="DIA_EXCAVADORA1_PALA001"
              geometry={nodes.DIA_EXCAVADORA1_PALA001.geometry}
              material={props.materials.baked}
            />
          </mesh>
        </group>
        <group
          name="DIA_EXCAVADORA_3"
          position={[-2.707, 0.583, 11.642]}
          rotation={[Math.PI / 2, 0, -1.342]}
          scale={0.071}
        >
          <mesh
            name="DIA_EXCAVADORA1_CUERPO002"
            geometry={nodes.DIA_EXCAVADORA1_CUERPO002.geometry}
            material={props.materials.baked}
          >
            <mesh
              name="DIA_EXCAVADORA1_PALA002"
              geometry={nodes.DIA_EXCAVADORA1_PALA002.geometry}
              material={props.materials.baked}
              position={[0, 6.454, -4.928]}
              rotation={[0.733, 0, 0]}
            />
          </mesh>
        </group>

      </group>
      <ModelBase material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}