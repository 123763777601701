

export function DiamondSvgAnimation() {

    const path1Style = {fill:'transparent', opacity:1, strokeWidth:0}
    const path2Style = {stroke:'rgb(1,1,1)', strokeWidth: 4, strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none'}
    const dur = "0.07s"

    return <>
    
        <svg width="400pt" height="400pt" viewBox="0 0 400 400">
            <g  id="FRAME_0" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_0" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="0.0s;A_29.end"/>
                <path style={ path1Style }
                d="M168.750000,104.500000 L230.100006,104.500000 L273.600006,104.599998 L349.350006,160.350006 L344.100006,165.850006 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.500000,306.250000 L197.750000,305.850006 L49.599998,160.350006 L125.250000,104.750000 L125.500000,104.349998 L168.750000,104.500000 "/>
                <path style={ path2Style }
                d="M230.100006,104.500000 L305.350006,160.100006 L273.600006,104.599998 L230.100006,104.500000 L199.350006,160.350006 L168.750000,104.500000 L93.599998,160.100006 L125.250000,104.750000 L125.500000,104.349998 L168.750000,104.500000 L230.100006,104.500000"/>
                <path style={ path2Style }
                d="M125.250000,104.750000 L49.599998,160.350006 L93.349998,160.350006 L93.599998,160.100006"/>
                <path style={ path2Style }
                d="M305.350006,160.100006 L305.500000,160.350006 L199.350006,160.350006 L93.349998,160.350006 L198.500000,306.250000 L197.750000,305.850006 L49.599998,160.350006"/>
                <path style={ path2Style }
                d="M273.600006,104.599998 L349.350006,160.350006 L305.500000,160.350006 L199.750000,307.100006 L344.100006,165.850006 L349.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,160.350006 L199.250000,307.100006 L198.500000,306.250000"/>
                <path style={ path2Style }
                d="M199.250000,307.100006 L199.600006,307.350006 L199.750000,307.100006"/>
            </g>
            <g  id="FRAME_1" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_1" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_0.end"/>
                <path style={ path1Style }
                d="M133.750000,104.349998 L185.350006,104.500000 L245.350006,104.500000 L273.250000,104.349998 Q278.100006,104.000000 278.600006,104.750000 L345.850006,160.350006 L343.000000,163.600006 L200.100006,306.750000 L199.350006,307.250000 L199.000000,306.750000 L53.099998,160.350006 L54.349998,159.250000 L54.750000,159.100006 L120.099998,104.750000 L120.599998,104.349998 L133.750000,104.349998 "/>
                <path style={ path2Style }
                d="M133.750000,104.349998 L120.599998,104.349998 L120.099998,104.750000 L54.750000,159.100006 L133.600006,104.599998 L133.750000,104.349998 L185.350006,104.500000 L245.350006,104.500000 L273.250000,104.349998 Q278.100006,104.000000 278.600006,104.750000 L325.350006,159.850006 L245.350006,104.500000 L231.600006,160.350006 L185.350006,104.500000 L118.750000,160.100006 L133.600006,104.599998"/>
                <path style={ path2Style }
                d="M54.750000,159.100006 L54.349998,159.250000 L53.099998,160.350006 L118.599998,160.350006 L118.750000,160.100006"/>
                <path style={ path2Style }
                d="M53.099998,160.350006 L199.000000,306.750000 L118.599998,160.350006 L231.600006,160.350006 L325.750000,160.350006 L325.350006,159.850006"/>
                <path style={ path2Style }
                d="M345.850006,160.350006 L343.000000,163.600006 L200.100006,306.750000 L325.750000,160.350006 L345.850006,160.350006 L278.600006,104.750000"/>
                <path style={ path2Style }
                d="M199.000000,306.750000 L199.350006,307.250000 L231.600006,160.350006"/>
                <path style={ path2Style }
                d="M200.100006,306.750000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_2" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_2" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_1.end"/>
                <path style={ path1Style }
                d="M145.100006,104.500000 L202.600006,104.349998 L258.350006,104.500000 L279.500000,104.349998 L340.100006,160.250000 L199.600006,307.250000 L199.250000,307.000000 L192.000000,299.500000 L191.600006,299.100006 L190.350006,297.750000 L58.750000,160.350006 L115.849998,107.599998 L116.250000,107.599998 L119.500000,104.349998 L145.100006,104.500000 "/>
                <path style={ path2Style }
                d="M202.600006,104.349998 L262.350006,160.350006 L258.350006,104.500000 L340.100006,160.250000 L279.500000,104.349998 L258.350006,104.500000 L202.600006,104.349998 L147.750000,160.350006 L145.100006,104.500000 L63.849998,159.850006 L116.250000,107.599998 L119.500000,104.349998 L145.100006,104.500000 L202.600006,104.349998"/>
                <path style={ path2Style }
                d="M63.849998,159.850006 L63.250000,160.350006 L58.750000,160.350006 L115.849998,107.599998 L116.250000,107.599998"/>
                <path style={ path2Style }
                d="M262.350006,160.350006 L199.600006,307.250000 L340.100006,160.250000 L262.350006,160.350006 L147.750000,160.350006 L63.250000,160.350006 L190.350006,297.750000 L58.750000,160.350006"/>
                <path style={ path2Style }
                d="M190.350006,297.750000 L191.600006,299.100006 L192.000000,299.500000 L199.250000,307.000000 L147.750000,160.350006"/>
                <path style={ path2Style }
                d="M199.250000,307.000000 L199.600006,307.250000"/>
            </g>
            <g  id="FRAME_3" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_3" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_2.end"/>
                <path style={ path1Style }
                d="M159.100006,104.500000 L219.750000,104.349998 L268.600006,104.349998 L277.000000,104.349998 L347.250000,159.750000 L347.500000,159.850006 L347.750000,160.100006 L348.000000,160.350006 L337.850006,170.750000 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.000000,305.750000 L196.350006,304.500000 L51.000000,160.350006 L121.599998,104.849998 L122.000000,104.349998 L159.100006,104.500000 "/>
                <path style={ path2Style }
                d="M268.600006,104.349998 L277.000000,104.349998 L347.250000,159.750000 L268.850006,104.750000 L268.600006,104.349998 L219.750000,104.349998 L179.100006,160.350006 L159.100006,104.500000 L80.349998,160.000000 L121.599998,104.849998 L122.000000,104.349998 L159.100006,104.500000 L219.750000,104.349998 L290.250000,160.350006 L268.850006,104.750000"/>
                <path style={ path2Style }
                d="M347.250000,159.750000 L347.500000,159.850006 L347.750000,160.100006 L348.000000,160.350006 L290.250000,160.350006 L179.100006,160.350006 L80.000000,160.350006 L80.349998,160.000000"/>
                <path style={ path2Style }
                d="M121.599998,104.849998 L51.000000,160.350006 L80.000000,160.350006 L198.000000,305.750000 L196.350006,304.500000 L51.000000,160.350006"/>
                <path style={ path2Style }
                d="M198.000000,305.750000 L199.250000,307.100006 L179.100006,160.350006"/>
                <path style={ path2Style }
                d="M348.000000,160.350006 L337.850006,170.750000 L199.750000,307.100006 L290.250000,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_4" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_4" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_3.end"/>
                <path style={ path1Style }
                d="M128.250000,104.349998 L174.850006,104.500000 L236.100006,104.500000 L275.850006,104.599998 L348.850006,160.350006 L349.100006,160.500000 L199.850006,307.000000 L199.350006,307.250000 L198.750000,306.500000 L197.350006,305.500000 L50.099998,160.350006 L54.099998,157.250000 L54.500000,157.100006 L54.599998,156.850006 L55.000000,156.750000 L122.349998,105.000000 Q122.750000,103.849998 128.250000,104.349998 "/>
                <path style={ path2Style }
                d="M128.250000,104.349998 Q122.750000,103.849998 122.349998,105.000000 L55.000000,156.750000 L128.000000,104.750000 L128.250000,104.349998 L174.850006,104.500000 L236.100006,104.500000 L275.850006,104.599998 L313.500000,160.000000 L236.100006,104.500000 L211.500000,160.350006 L174.850006,104.500000 L102.500000,160.100006 L128.000000,104.750000"/>
                <path style={ path2Style }
                d="M55.000000,156.750000 L54.599998,156.850006 L54.500000,157.100006 L54.099998,157.250000 L50.099998,160.350006 L102.349998,160.350006 L102.500000,160.100006"/>
                <path style={ path2Style }
                d="M50.099998,160.350006 L197.350006,305.500000 L198.750000,306.500000 L102.349998,160.350006 L211.500000,160.350006 L313.750000,160.350006 L313.500000,160.000000"/>
                <path style={ path2Style }
                d="M348.850006,160.350006 L349.100006,160.500000 L199.850006,307.000000 L313.750000,160.350006 L348.850006,160.350006 L275.850006,104.599998"/>
                <path style={ path2Style }
                d="M198.750000,306.500000 L199.350006,307.250000 L211.500000,160.350006"/>
                <path style={ path2Style }
                d="M199.850006,307.000000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_5" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_5" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_4.end"/>
                <path style={ path1Style }
                d="M137.750000,104.500000 L191.850006,104.500000 L250.500000,104.500000 L277.350006,104.349998 L279.250000,104.349998 L279.850006,105.000000 L342.850006,160.350006 L200.350006,306.500000 L199.600006,307.350006 L199.350006,307.250000 L199.100006,306.850006 L56.099998,160.350006 L57.099998,159.500000 L119.599998,104.500000 L137.750000,104.500000 "/>
                <path style={ path2Style }
                d="M191.850006,104.500000 L243.350006,160.350006 L250.500000,104.500000 L331.350006,159.750000 L279.850006,105.000000 L279.250000,104.349998 L277.350006,104.349998 L250.500000,104.500000 L191.850006,104.500000 L129.100006,160.350006 L137.750000,104.500000 L57.099998,159.500000 L119.599998,104.500000 L137.750000,104.500000 L191.850006,104.500000"/>
                <path style={ path2Style }
                d="M57.099998,159.500000 L56.099998,160.350006 L129.100006,160.350006 L243.350006,160.350006 L331.850006,160.350006 L331.350006,159.750000"/>
                <path style={ path2Style }
                d="M279.850006,105.000000 L342.850006,160.350006 L331.850006,160.350006 L200.350006,306.500000 L342.850006,160.350006"/>
                <path style={ path2Style }
                d="M129.100006,160.350006 L199.100006,306.850006 L56.099998,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,306.850006 L199.350006,307.250000 L243.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,307.250000 L199.600006,307.350006 L200.350006,306.500000"/>
            </g>
            <g  id="FRAME_6" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_6" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_5.end"/>
                <path style={ path1Style }
                d="M150.100006,104.500000 L209.100006,104.349998 L262.600006,104.599998 L279.000000,104.349998 L343.500000,160.000000 L343.750000,160.100006 L344.000000,160.350006 L199.600006,307.250000 L199.250000,307.000000 L196.750000,304.500000 L55.000000,160.350006 L119.000000,105.500000 L120.000000,104.349998 L150.100006,104.500000 "/>
                <path style={ path2Style }
                d="M209.100006,104.349998 L273.250000,160.350006 L262.600006,104.599998 L343.500000,160.000000 L279.000000,104.349998 L262.600006,104.599998 L209.100006,104.349998 L159.250000,160.350006 L150.100006,104.500000 L69.349998,159.850006 L119.000000,105.500000 L120.000000,104.349998 L150.100006,104.500000 L209.100006,104.349998"/>
                <path style={ path2Style }
                d="M343.500000,160.000000 L343.750000,160.100006 L344.000000,160.350006 L273.250000,160.350006 L159.250000,160.350006 L68.849998,160.350006 L69.349998,159.850006"/>
                <path style={ path2Style }
                d="M119.000000,105.500000 L55.000000,160.350006 L68.849998,160.350006 L196.750000,304.500000 L55.000000,160.350006"/>
                <path style={ path2Style }
                d="M159.250000,160.350006 L199.250000,307.000000 L196.750000,304.500000"/>
                <path style={ path2Style }
                d="M344.000000,160.350006 L199.600006,307.250000 L273.250000,160.350006"/>
                <path style={ path2Style }
                d="M199.600006,307.250000 L199.250000,307.000000"/>
            </g>
            <g  id="FRAME_7" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_7" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_6.end"/>
                <path style={ path1Style }
                d="M164.850006,104.500000 L226.000000,104.349998 L271.750000,104.349998 L275.000000,104.349998 L346.000000,158.000000 L346.500000,158.350006 L346.850006,158.600006 L347.100006,158.750000 L347.350006,159.000000 L347.600006,159.100006 L347.850006,159.350006 L348.100006,159.500000 L348.600006,159.850006 L349.250000,160.350006 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.350006,306.100006 L197.600006,305.750000 L49.750000,160.350006 L123.599998,104.849998 L124.000000,104.349998 L164.850006,104.500000 "/>
                <path style={ path2Style }
                d="M271.750000,104.349998 L275.000000,104.349998 L346.000000,158.000000 L271.850006,104.599998 L271.750000,104.349998 L226.000000,104.349998 L191.250000,160.350006 L164.850006,104.500000 L88.099998,160.000000 L123.599998,104.849998 L124.000000,104.349998 L164.850006,104.500000 L226.000000,104.349998 L299.500000,160.100006 L271.850006,104.599998"/>
                <path style={ path2Style }
                d="M346.000000,158.000000 L346.500000,158.350006 L346.850006,158.600006 L347.100006,158.750000 L347.350006,159.000000 L347.600006,159.100006 L347.850006,159.350006 L348.100006,159.500000 L348.600006,159.850006 L349.250000,160.350006 L299.600006,160.350006 L299.500000,160.100006"/>
                <path style={ path2Style }
                d="M88.099998,160.000000 L87.750000,160.350006 L49.750000,160.350006 L123.599998,104.849998"/>
                <path style={ path2Style }
                d="M349.250000,160.350006 L199.750000,307.100006 L299.600006,160.350006 L191.250000,160.350006 L87.750000,160.350006 L198.350006,306.100006 L197.600006,305.750000 L49.750000,160.350006"/>
                <path style={ path2Style }
                d="M198.350006,306.100006 L199.250000,307.100006 L191.250000,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_8" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_8" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_7.end"/>
                <path style={ path1Style }
                d="M131.350006,104.349998 L181.100006,104.500000 L241.750000,104.500000 L277.600006,104.599998 L347.500000,160.350006 L200.000000,306.850006 L199.350006,307.250000 L198.850006,306.600006 L51.500000,160.350006 L53.349998,158.850006 L53.750000,158.750000 L120.849998,104.849998 L121.500000,104.349998 L131.350006,104.349998 "/>
                <path style={ path2Style }
                d="M131.350006,104.349998 L121.500000,104.349998 L120.849998,104.849998 L53.750000,158.750000 L131.250000,104.599998 L131.350006,104.349998 L181.100006,104.500000 L241.750000,104.500000 L277.600006,104.599998 L320.850006,159.850006 L241.750000,104.500000 L223.600006,160.350006 L181.100006,104.500000 L111.849998,160.350006 L131.250000,104.599998"/>
                <path style={ path2Style }
                d="M53.750000,158.750000 L53.349998,158.850006 L51.500000,160.350006 L111.849998,160.350006 L223.600006,160.350006 L321.250000,160.350006 L320.850006,159.850006"/>
                <path style={ path2Style }
                d="M277.600006,104.599998 L347.500000,160.350006 L321.250000,160.350006 L200.000000,306.850006 L347.500000,160.350006"/>
                <path style={ path2Style }
                d="M111.849998,160.350006 L198.850006,306.600006 L51.500000,160.350006"/>
                <path style={ path2Style }
                d="M223.600006,160.350006 L199.350006,307.250000 L198.850006,306.600006"/>
                <path style={ path2Style }
                d="M200.000000,306.850006 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_9" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_9" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_8.end"/>
                <path style={ path1Style }
                d="M142.100006,104.500000 L198.350006,104.500000 L255.350006,104.500000 L279.100006,104.349998 L279.600006,104.349998 L281.350006,106.099998 L282.750000,107.500000 L283.500000,108.099998 L338.750000,160.350006 L210.350006,296.000000 L208.500000,297.850006 L208.100006,298.350006 L206.600006,299.850006 L205.850006,300.750000 L204.750000,301.850006 L203.500000,303.250000 L202.850006,303.850006 L201.250000,305.600006 L201.000000,305.850006 L199.600006,307.350006 L199.350006,307.250000 L199.100006,307.000000 L60.250000,160.350006 L61.000000,159.600006 L119.349998,104.349998 L142.100006,104.500000 "/>
                <path style={ path2Style }
                d="M198.350006,104.500000 L254.850006,160.350006 L255.350006,104.500000 L336.600006,159.750000 L283.500000,108.099998 L282.750000,107.500000 L281.350006,106.099998 L279.600006,104.349998 L279.100006,104.349998 L255.350006,104.500000 L198.350006,104.500000 L140.100006,160.350006 L142.100006,104.500000 L61.000000,159.600006 L119.349998,104.349998 L142.100006,104.500000 L198.350006,104.500000"/>
                <path style={ path2Style }
                d="M61.000000,159.600006 L60.250000,160.350006 L140.100006,160.350006 L254.850006,160.350006 L337.250000,160.350006 L336.600006,159.750000"/>
                <path style={ path2Style }
                d="M283.500000,108.099998 L338.750000,160.350006 L337.250000,160.350006 L210.350006,296.000000 L338.750000,160.350006"/>
                <path style={ path2Style }
                d="M210.350006,296.000000 L208.500000,297.850006 L208.100006,298.350006 L206.600006,299.850006 L205.850006,300.750000 L204.750000,301.850006 L203.500000,303.250000 L202.850006,303.850006 L201.250000,305.600006 L201.000000,305.850006 L199.600006,307.350006 L199.350006,307.250000 L199.100006,307.000000 L60.250000,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,307.000000 L140.100006,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,307.250000 L254.850006,160.350006"/>
            </g>
            <g  id="FRAME_10" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_10" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_9.end"/>
                <path style={ path1Style }
                d="M155.350006,104.500000 L215.600006,104.349998 L266.350006,104.349998 L278.000000,104.349998 L346.250000,160.000000 L346.600006,160.250000 L199.600006,307.250000 L199.250000,307.100006 L197.600006,305.350006 L52.250000,160.350006 L120.500000,105.000000 L121.000000,104.349998 L155.350006,104.500000 "/>
                <path style={ path2Style }
                d="M266.350006,104.349998 L278.000000,104.349998 L346.250000,160.000000 L266.500000,104.599998 L266.350006,104.349998 L215.600006,104.349998 L171.100006,160.350006 L155.350006,104.500000 L75.599998,160.000000 L120.500000,105.000000 L121.000000,104.349998 L155.350006,104.500000 L215.600006,104.349998 L283.500000,160.100006 L266.500000,104.599998"/>
                <path style={ path2Style }
                d="M346.250000,160.000000 L346.600006,160.250000 L283.600006,160.350006 L283.500000,160.100006"/>
                <path style={ path2Style }
                d="M120.500000,105.000000 L52.250000,160.350006 L75.250000,160.350006 L75.599998,160.000000"/>
                <path style={ path2Style }
                d="M346.600006,160.250000 L199.600006,307.250000 L283.600006,160.350006 L171.100006,160.350006 L75.250000,160.350006 L197.600006,305.350006 L52.250000,160.350006"/>
                <path style={ path2Style }
                d="M171.100006,160.350006 L199.250000,307.100006 L197.600006,305.350006"/>
                <path style={ path2Style }
                d="M199.250000,307.100006 L199.600006,307.250000"/>
            </g>
            <g  id="FRAME_11" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_11" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_10.end"/>
                <path style={ path1Style }
                d="M126.349998,104.349998 L170.750000,104.500000 L232.100006,104.500000 L274.250000,104.349998 L349.350006,160.350006 L199.850006,307.000000 L199.350006,307.250000 L198.600006,306.350006 L197.600006,305.750000 L49.599998,160.350006 L61.500000,151.500000 L61.849998,151.350006 L62.000000,151.100006 L62.349998,151.000000 L62.849998,150.500000 L63.250000,150.350006 L63.349998,150.100006 L63.750000,150.000000 L63.849998,149.750000 L64.250000,149.600006 L64.750000,149.250000 L65.250000,148.850006 L124.099998,104.750000 L124.599998,104.349998 L126.349998,104.349998 "/>
                <path style={ path2Style }
                d="M126.349998,104.349998 L124.599998,104.349998 L124.099998,104.750000 L65.250000,148.850006 L126.099998,104.750000 L126.349998,104.349998 L170.750000,104.500000 L232.100006,104.500000 L274.250000,104.349998 L308.100006,160.000000 L232.100006,104.500000 L203.350006,160.350006 L170.750000,104.500000 L96.500000,160.100006 L126.099998,104.750000"/>
                <path style={ path2Style }
                d="M65.250000,148.850006 L64.750000,149.250000 L64.250000,149.600006 L63.849998,149.750000 L63.750000,150.000000 L63.349998,150.100006 L63.250000,150.350006 L62.849998,150.500000 L62.349998,151.000000 L62.000000,151.100006 L61.849998,151.350006 L61.500000,151.500000 L49.599998,160.350006 L96.250000,160.350006 L96.500000,160.100006"/>
                <path style={ path2Style }
                d="M308.100006,160.000000 L308.350006,160.350006 L203.350006,160.350006 L96.250000,160.350006 L198.600006,306.350006 L197.600006,305.750000 L49.599998,160.350006"/>
                <path style={ path2Style }
                d="M349.350006,160.350006 L199.850006,307.000000 L308.350006,160.350006 L349.350006,160.350006 L274.250000,104.349998"/>
                <path style={ path2Style }
                d="M198.600006,306.350006 L199.350006,307.250000 L203.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.850006,307.000000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_12" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_12" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_11.end"/>
                <path style={ path1Style }
                d="M135.000000,104.349998 L187.500000,104.500000 L247.100006,104.500000 L278.850006,104.599998 L345.000000,160.350006 L200.250000,306.600006 L199.350006,307.250000 L199.000000,306.750000 L54.000000,160.350006 L55.099998,159.350006 L55.500000,159.250000 L120.250000,104.349998 L135.000000,104.349998 "/>
                <path style={ path2Style }
                d="M135.000000,104.349998 L120.250000,104.349998 L55.500000,159.250000 L134.850006,104.599998 L135.000000,104.349998 L187.500000,104.500000 L247.100006,104.500000 L278.850006,104.599998 L327.500000,159.850006 L247.100006,104.500000 L235.500000,160.350006 L187.500000,104.500000 L122.099998,160.350006 L134.850006,104.599998"/>
                <path style={ path2Style }
                d="M55.500000,159.250000 L55.099998,159.350006 L54.000000,160.350006 L122.099998,160.350006 L235.500000,160.350006 L327.850006,160.350006 L327.500000,159.850006"/>
                <path style={ path2Style }
                d="M278.850006,104.599998 L345.000000,160.350006 L327.850006,160.350006 L200.250000,306.600006 L345.000000,160.350006"/>
                <path style={ path2Style }
                d="M122.099998,160.350006 L199.000000,306.750000 L54.000000,160.350006"/>
                <path style={ path2Style }
                d="M199.000000,306.750000 L199.350006,307.250000 L235.500000,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,307.250000 L200.250000,306.600006"/>
            </g>
            <g  id="FRAME_13" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_13" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_12.end"/>
                <path style={ path1Style }
                d="M146.750000,104.500000 L204.850006,104.349998 L259.850006,104.599998 L279.350006,104.349998 L341.100006,160.000000 L341.500000,160.350006 L199.600006,307.250000 L199.250000,307.000000 L194.500000,302.100006 L57.349998,160.350006 L117.099998,106.849998 L119.599998,104.349998 L146.750000,104.500000 "/>
                <path style={ path2Style }
                d="M204.850006,104.349998 L266.000000,160.100006 L259.850006,104.599998 L341.100006,160.000000 L279.350006,104.349998 L259.850006,104.599998 L204.850006,104.349998 L151.500000,160.350006 L146.750000,104.500000 L65.599998,159.850006 L117.099998,106.849998 L119.599998,104.349998 L146.750000,104.500000 L204.850006,104.349998"/>
                <path style={ path2Style }
                d="M117.099998,106.849998 L57.349998,160.350006 L65.000000,160.350006 L65.599998,159.850006"/>
                <path style={ path2Style }
                d="M266.000000,160.100006 L266.100006,160.350006 L151.500000,160.350006 L65.000000,160.350006 L194.500000,302.100006 L57.349998,160.350006"/>
                <path style={ path2Style }
                d="M341.100006,160.000000 L341.500000,160.350006 L266.100006,160.350006 L199.600006,307.250000 L341.500000,160.350006"/>
                <path style={ path2Style }
                d="M151.500000,160.350006 L199.250000,307.000000 L194.500000,302.100006"/>
                <path style={ path2Style }
                d="M199.600006,307.250000 L199.250000,307.000000"/>
            </g>
            <g  id="FRAME_14" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_14" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_13.end"/>
                <path style={ path1Style }
                d="M161.000000,104.500000 L221.850006,104.349998 L269.750000,104.349998 L276.350006,104.349998 L346.850006,159.100006 L347.350006,159.500000 L347.750000,159.750000 L348.000000,159.850006 L348.250000,160.100006 L348.500000,160.350006 L340.850006,168.250000 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.100006,305.850006 L197.000000,305.100006 L50.500000,160.350006 L122.099998,105.000000 L122.599998,104.349998 L161.000000,104.500000 "/>
                <path style={ path2Style }
                d="M269.750000,104.349998 L276.350006,104.349998 L346.850006,159.100006 L269.850006,104.599998 L269.750000,104.349998 L221.850006,104.349998 L183.850006,159.600006 L183.100006,160.350006 L161.000000,104.500000 L82.849998,160.000000 L122.099998,105.000000 L122.599998,104.349998 L161.000000,104.500000 L221.850006,104.349998 L293.350006,160.350006 L269.850006,104.599998"/>
                <path style={ path2Style }
                d="M346.850006,159.100006 L347.350006,159.500000 L347.750000,159.750000 L348.000000,159.850006 L348.250000,160.100006 L348.500000,160.350006 L293.350006,160.350006 L183.100006,160.350006 L82.500000,160.350006 L82.849998,160.000000"/>
                <path style={ path2Style }
                d="M50.500000,160.350006 L197.000000,305.100006 L198.100006,305.850006 L82.500000,160.350006 L50.500000,160.350006 L122.099998,105.000000"/>
                <path style={ path2Style }
                d="M198.100006,305.850006 L199.250000,307.100006 L183.100006,160.350006"/>
                <path style={ path2Style }
                d="M348.500000,160.350006 L340.850006,168.250000 L199.750000,307.100006 L293.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_15" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_15" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_14.end"/>
                <path style={ path1Style }
                d="M129.250000,104.349998 L177.000000,104.500000 L238.000000,104.500000 L276.500000,104.599998 L348.500000,160.350006 L340.850006,168.250000 L199.850006,307.000000 L199.350006,307.250000 L198.850006,306.600006 L162.350006,271.000000 L50.500000,160.350006 L53.500000,158.000000 L54.000000,157.750000 L121.750000,105.000000 Q122.349998,103.750000 129.250000,104.349998 "/>
                <path style={ path2Style }
                d="M129.250000,104.349998 Q122.349998,103.750000 121.750000,105.000000 L54.000000,157.750000 L129.100006,104.599998 L129.250000,104.349998 L177.000000,104.500000 L238.000000,104.500000 L276.500000,104.599998 L316.100006,160.000000 L238.000000,104.500000 L215.600006,160.350006 L177.000000,104.500000 L105.599998,160.100006 L129.100006,104.599998"/>
                <path style={ path2Style }
                d="M54.000000,157.750000 L53.500000,158.000000 L50.500000,160.350006 L105.500000,160.350006 L105.599998,160.100006"/>
                <path style={ path2Style }
                d="M316.100006,160.000000 L316.350006,160.350006 L215.600006,160.350006 L105.500000,160.350006 L198.850006,306.600006 L162.350006,271.000000 L50.500000,160.350006"/>
                <path style={ path2Style }
                d="M276.500000,104.599998 L348.500000,160.350006 L316.350006,160.350006 L199.850006,307.000000 L340.850006,168.250000 L348.500000,160.350006"/>
                <path style={ path2Style }
                d="M198.850006,306.600006 L199.350006,307.250000 L215.600006,160.350006"/>
                <path style={ path2Style }
                d="M199.850006,307.000000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_16" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_16" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_15.end"/>
                <path style={ path1Style }
                d="M139.100006,104.349998 L194.000000,104.500000 L252.100006,104.500000 L279.600006,104.599998 L341.600006,160.350006 L200.500000,306.350006 L199.600006,307.350006 L199.350006,307.250000 L199.100006,306.850006 L57.349998,160.350006 L58.349998,159.500000 L119.099998,104.750000 L119.599998,104.349998 L139.100006,104.349998 "/>
                <path style={ path2Style }
                d="M139.100006,104.349998 L119.599998,104.349998 L119.099998,104.750000 L58.349998,159.500000 L139.000000,104.599998 L139.100006,104.349998 L194.000000,104.500000 L252.100006,104.500000 L279.600006,104.599998 L333.350006,159.850006 L252.100006,104.500000 L247.250000,160.350006 L194.000000,104.500000 L132.850006,160.100006 L139.000000,104.599998"/>
                <path style={ path2Style }
                d="M58.349998,159.500000 L57.349998,160.350006 L132.750000,160.350006 L132.850006,160.100006"/>
                <path style={ path2Style }
                d="M333.350006,159.850006 L333.750000,160.350006 L247.250000,160.350006 L132.750000,160.350006 L199.100006,306.850006 L57.349998,160.350006"/>
                <path style={ path2Style }
                d="M279.600006,104.599998 L341.600006,160.350006 L333.750000,160.350006 L200.500000,306.350006 L341.600006,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,306.850006 L199.350006,307.250000 L247.250000,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,307.250000 L199.600006,307.350006 L200.500000,306.350006"/>
            </g>
            <g  id="FRAME_17" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_17" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_16.end"/>
                <path style={ path1Style }
                d="M151.850006,104.500000 L211.250000,104.349998 L263.850006,104.349998 L278.750000,104.349998 L344.500000,160.000000 L344.850006,160.250000 L199.600006,307.250000 L199.250000,307.100006 L197.000000,304.750000 L54.000000,160.350006 L119.250000,105.500000 L120.250000,104.349998 L151.850006,104.500000 "/>
                <path style={ path2Style }
                d="M263.850006,104.349998 L278.750000,104.349998 L344.500000,160.000000 L264.000000,104.599998 L263.850006,104.349998 L211.250000,104.349998 L163.250000,160.350006 L151.850006,104.500000 L71.500000,159.850006 L119.250000,105.500000 L120.250000,104.349998 L151.850006,104.500000 L211.250000,104.349998 L276.750000,160.350006 L264.000000,104.599998"/>
                <path style={ path2Style }
                d="M344.500000,160.000000 L344.850006,160.250000 L276.750000,160.350006 L163.250000,160.350006 L70.849998,160.350006 L71.500000,159.850006"/>
                <path style={ path2Style }
                d="M119.250000,105.500000 L54.000000,160.350006 L70.849998,160.350006 L197.000000,304.750000 L54.000000,160.350006"/>
                <path style={ path2Style }
                d="M197.000000,304.750000 L199.250000,307.100006 L163.250000,160.350006"/>
                <path style={ path2Style }
                d="M344.850006,160.250000 L199.600006,307.250000 L276.750000,160.350006"/>
                <path style={ path2Style }
                d="M199.600006,307.250000 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_18" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_18" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_17.end"/>
                <path style={ path1Style }
                d="M166.750000,104.500000 L228.000000,104.349998 L272.600006,104.349998 L274.350006,104.349998 L343.600006,156.100006 L344.100006,156.500000 L344.600006,156.850006 L345.000000,157.100006 L345.250000,157.250000 L345.500000,157.500000 L345.750000,157.600006 L346.000000,157.850006 L346.250000,158.000000 L346.750000,158.350006 L347.250000,158.750000 L348.100006,159.350006 L348.600006,159.750000 L349.350006,160.350006 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.500000,306.250000 L197.600006,305.750000 L49.599998,160.350006 L124.349998,104.750000 L124.599998,104.349998 L166.750000,104.500000 "/>
                <path style={ path2Style }
                d="M272.600006,104.349998 L274.350006,104.349998 L343.600006,156.100006 L272.850006,104.750000 L272.600006,104.349998 L228.000000,104.349998 L195.350006,160.350006 L166.750000,104.500000 L90.849998,160.000000 L124.349998,104.750000 L124.599998,104.349998 L166.750000,104.500000 L228.000000,104.349998 L302.500000,160.100006 L272.850006,104.750000"/>
                <path style={ path2Style }
                d="M343.600006,156.100006 L344.100006,156.500000 L344.600006,156.850006 L345.000000,157.100006 L345.250000,157.250000 L345.500000,157.500000 L345.750000,157.600006 L346.000000,157.850006 L346.250000,158.000000 L346.750000,158.350006 L347.250000,158.750000 L348.100006,159.350006 L348.600006,159.750000 L349.350006,160.350006 L302.600006,160.350006 L302.500000,160.100006"/>
                <path style={ path2Style }
                d="M90.849998,160.000000 L90.500000,160.350006 L49.599998,160.350006 L124.349998,104.750000"/>
                <path style={ path2Style }
                d="M349.350006,160.350006 L199.750000,307.100006 L302.600006,160.350006 L195.350006,160.350006 L90.500000,160.350006 L198.500000,306.250000 L197.600006,305.750000 L49.599998,160.350006"/>
                <path style={ path2Style }
                d="M198.500000,306.250000 L199.250000,307.100006 L195.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_19" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_19" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_18.end"/>
                <path style={ path1Style }
                d="M132.600006,104.349998 L183.250000,104.500000 L243.500000,104.500000 L278.000000,104.500000 L346.750000,160.350006 L200.100006,306.750000 L199.350006,307.250000 L199.000000,306.750000 L52.250000,160.350006 L53.750000,159.100006 L54.099998,159.000000 L54.250000,158.750000 L121.000000,104.349998 L132.600006,104.349998 "/>
                <path style={ path2Style }
                d="M132.600006,104.349998 L121.000000,104.349998 L54.250000,158.750000 L132.500000,104.599998 L132.600006,104.349998 L183.250000,104.500000 L243.500000,104.500000 L278.000000,104.500000 L323.250000,160.000000 L243.500000,104.500000 L227.600006,160.350006 L183.250000,104.500000 L115.349998,160.100006 L132.500000,104.599998"/>
                <path style={ path2Style }
                d="M54.250000,158.750000 L54.099998,159.000000 L53.750000,159.100006 L52.250000,160.350006 L115.250000,160.350006 L115.349998,160.100006"/>
                <path style={ path2Style }
                d="M323.250000,160.000000 L323.500000,160.350006 L227.600006,160.350006 L115.250000,160.350006 L199.000000,306.750000 L52.250000,160.350006"/>
                <path style={ path2Style }
                d="M278.000000,104.500000 L346.750000,160.350006 L323.500000,160.350006 L200.100006,306.750000 L346.750000,160.350006"/>
                <path style={ path2Style }
                d="M199.000000,306.750000 L199.350006,307.250000 L227.600006,160.350006"/>
                <path style={ path2Style }
                d="M200.100006,306.750000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_20" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_20" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_19.end"/>
                <path style={ path1Style }
                d="M143.600006,104.500000 L200.500000,104.349998 L256.850006,104.500000 L279.600006,104.349998 L338.500000,160.100006 L338.750000,160.350006 L199.600006,307.250000 L199.100006,307.000000 L175.500000,282.000000 L174.850006,281.350006 L173.600006,280.000000 L172.600006,279.000000 L171.750000,278.000000 L170.350006,276.600006 L169.850006,276.000000 L168.000000,274.100006 L165.850006,272.100006 L60.250000,160.350006 L104.250000,118.599998 L106.849998,116.500000 L107.849998,115.349998 L109.000000,114.500000 L119.349998,104.349998 L143.600006,104.500000 "/>
                <path style={ path2Style }
                d="M200.500000,104.349998 L258.750000,160.350006 L256.850006,104.500000 L338.500000,160.100006 L279.600006,104.349998 L256.850006,104.500000 L200.500000,104.349998 L143.850006,160.350006 L143.600006,104.500000 L62.349998,159.750000 L106.849998,116.500000 L107.849998,115.349998 L109.000000,114.500000 L119.349998,104.349998 L143.600006,104.500000 L200.500000,104.349998"/>
                <path style={ path2Style }
                d="M62.349998,159.750000 L61.599998,160.350006 L60.250000,160.350006 L104.250000,118.599998 L106.849998,116.500000"/>
                <path style={ path2Style }
                d="M338.500000,160.100006 L338.750000,160.350006 L258.750000,160.350006 L143.850006,160.350006 L61.599998,160.350006 L168.000000,274.100006 L165.850006,272.100006 L60.250000,160.350006"/>
                <path style={ path2Style }
                d="M168.000000,274.100006 L169.850006,276.000000 L170.350006,276.600006 L171.750000,278.000000 L172.600006,279.000000 L173.600006,280.000000 L174.850006,281.350006 L175.500000,282.000000 L199.100006,307.000000 L143.850006,160.350006"/>
                <path style={ path2Style }
                d="M338.750000,160.350006 L199.600006,307.250000 L258.750000,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,307.000000 L199.600006,307.250000"/>
            </g>
            <g  id="FRAME_21" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_21" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_20.end"/>
                <path style={ path1Style }
                d="M157.250000,104.500000 L217.600006,104.349998 L267.600006,104.599998 L277.500000,104.349998 L346.600006,159.750000 L347.000000,160.000000 L347.350006,160.350006 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L197.750000,305.500000 L51.500000,160.350006 L120.849998,105.099998 L121.500000,104.349998 L157.250000,104.500000 "/>
                <path style={ path2Style }
                d="M217.600006,104.349998 L287.000000,160.350006 L267.600006,104.599998 L346.600006,159.750000 L277.500000,104.349998 L267.600006,104.599998 L217.600006,104.349998 L175.100006,160.350006 L157.250000,104.500000 L78.099998,159.850006 L120.849998,105.099998 L121.500000,104.349998 L157.250000,104.500000 L217.600006,104.349998"/>
                <path style={ path2Style }
                d="M346.600006,159.750000 L347.000000,160.000000 L347.350006,160.350006 L287.000000,160.350006 L175.100006,160.350006 L77.599998,160.350006 L78.099998,159.850006"/>
                <path style={ path2Style }
                d="M120.849998,105.099998 L51.500000,160.350006 L77.599998,160.350006 L197.750000,305.500000 L51.500000,160.350006"/>
                <path style={ path2Style }
                d="M197.750000,305.500000 L199.250000,307.100006 L175.100006,160.350006"/>
                <path style={ path2Style }
                d="M347.350006,160.350006 L199.750000,307.100006 L287.000000,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_22" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_22" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_21.end"/>
                <path style={ path1Style }
                d="M127.250000,104.349998 L172.850006,104.500000 L234.100006,104.500000 L275.100006,104.500000 L349.250000,160.350006 L199.850006,307.000000 L199.350006,307.250000 L198.750000,306.500000 L197.600006,305.750000 L49.750000,160.350006 L55.349998,156.100006 L55.750000,156.000000 L55.849998,155.750000 L56.250000,155.600006 L56.349998,155.350006 L56.750000,155.250000 L57.250000,154.850006 L121.750000,106.000000 Q123.500000,103.500000 127.250000,104.349998 "/>
                <path style={ path2Style }
                d="M127.250000,104.349998 Q123.500000,103.500000 121.750000,106.000000 L57.250000,154.850006 L127.099998,104.599998 L127.250000,104.349998 L172.850006,104.500000 L234.100006,104.500000 L275.100006,104.500000 L310.850006,160.000000 L234.100006,104.500000 L207.500000,160.350006 L172.850006,104.500000 L99.500000,160.100006 L127.099998,104.599998"/>
                <path style={ path2Style }
                d="M57.250000,154.850006 L56.750000,155.250000 L56.349998,155.350006 L56.250000,155.600006 L55.849998,155.750000 L55.750000,156.000000 L55.349998,156.100006 L49.750000,160.350006 L99.250000,160.350006 L99.500000,160.100006"/>
                <path style={ path2Style }
                d="M49.750000,160.350006 L197.600006,305.750000 L198.750000,306.500000 L99.250000,160.350006 L207.500000,160.350006 L311.100006,160.350006 L310.850006,160.000000"/>
                <path style={ path2Style }
                d="M349.250000,160.350006 L199.850006,307.000000 L311.100006,160.350006 L349.250000,160.350006 L275.100006,104.500000"/>
                <path style={ path2Style }
                d="M198.750000,306.500000 L199.350006,307.250000 L207.500000,160.350006"/>
                <path style={ path2Style }
                d="M199.850006,307.000000 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_23" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_23" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_22.end"/>
                <path style={ path1Style }
                d="M136.350006,104.349998 L189.750000,104.500000 L248.850006,104.500000 L274.350006,104.349998 Q278.850006,104.000000 279.350006,104.750000 L344.000000,160.350006 L200.250000,306.600006 L199.350006,307.250000 L199.100006,306.850006 L55.000000,160.350006 L56.000000,159.500000 L56.349998,159.350006 L119.500000,104.750000 L120.000000,104.349998 L136.350006,104.349998 "/>
                <path style={ path2Style }
                d="M136.350006,104.349998 L120.000000,104.349998 L119.500000,104.750000 L56.349998,159.350006 L136.250000,104.599998 L136.350006,104.349998 L189.750000,104.500000 L248.850006,104.500000 L274.350006,104.349998 Q278.850006,104.000000 279.350006,104.750000 L329.600006,159.850006 L248.850006,104.500000 L239.500000,160.350006 L189.750000,104.500000 L125.599998,160.350006 L136.250000,104.599998"/>
                <path style={ path2Style }
                d="M56.349998,159.350006 L56.000000,159.500000 L55.000000,160.350006 L125.599998,160.350006 L239.500000,160.350006 L330.000000,160.350006 L329.600006,159.850006"/>
                <path style={ path2Style }
                d="M344.000000,160.350006 L200.250000,306.600006 L330.000000,160.350006 L344.000000,160.350006 L279.350006,104.750000"/>
                <path style={ path2Style }
                d="M55.000000,160.350006 L199.100006,306.850006 L125.599998,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,306.850006 L199.350006,307.250000 L239.500000,160.350006"/>
                <path style={ path2Style }
                d="M200.250000,306.600006 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_24" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_24" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_23.end"/>
                <path style={ path1Style }
                d="M148.500000,104.500000 L207.000000,104.349998 L261.250000,104.500000 L279.250000,104.349998 L342.500000,160.100006 L342.750000,160.350006 L199.600006,307.250000 L199.250000,307.000000 L196.250000,304.000000 L195.750000,303.500000 L195.100006,302.750000 L56.099998,160.350006 L118.349998,105.849998 L119.750000,104.349998 L148.500000,104.500000 "/>
                <path style={ path2Style }
                d="M207.000000,104.349998 L269.750000,160.350006 L261.250000,104.500000 L342.500000,160.100006 L279.250000,104.349998 L261.250000,104.500000 L207.000000,104.349998 L155.350006,160.350006 L148.500000,104.500000 L67.599998,159.750000 L118.349998,105.849998 L119.750000,104.349998 L148.500000,104.500000 L207.000000,104.349998"/>
                <path style={ path2Style }
                d="M118.349998,105.849998 L56.099998,160.350006 L66.849998,160.350006 L67.599998,159.750000"/>
                <path style={ path2Style }
                d="M342.500000,160.100006 L342.750000,160.350006 L269.750000,160.350006 L155.350006,160.350006 L66.849998,160.350006 L195.100006,302.750000 L56.099998,160.350006"/>
                <path style={ path2Style }
                d="M195.100006,302.750000 L195.750000,303.500000 L196.250000,304.000000 L199.250000,307.000000 L155.350006,160.350006"/>
                <path style={ path2Style }
                d="M342.750000,160.350006 L199.600006,307.250000 L269.750000,160.350006"/>
                <path style={ path2Style }
                d="M199.250000,307.000000 L199.600006,307.250000"/>
            </g>
            <g  id="FRAME_25" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_25" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_24.end"/>
                <path style={ path1Style }
                d="M162.850006,104.500000 L224.000000,104.349998 L270.750000,104.349998 L275.750000,104.349998 L346.750000,158.750000 L347.250000,159.100006 L347.600006,159.350006 L347.850006,159.500000 L348.100006,159.750000 L348.350006,159.850006 L348.850006,160.350006 L349.100006,160.500000 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.250000,306.000000 L197.350006,305.500000 L50.099998,160.350006 L122.849998,104.849998 L123.250000,104.349998 L162.850006,104.500000 "/>
                <path style={ path2Style }
                d="M270.750000,104.349998 L275.750000,104.349998 L346.750000,158.750000 L271.000000,104.750000 L270.750000,104.349998 L224.000000,104.349998 L187.250000,160.350006 L162.850006,104.500000 L85.500000,160.000000 L122.849998,104.849998 L123.250000,104.349998 L162.850006,104.500000 L224.000000,104.349998 L296.500000,160.350006 L271.000000,104.750000"/>
                <path style={ path2Style }
                d="M346.750000,158.750000 L347.250000,159.100006 L347.600006,159.350006 L347.850006,159.500000 L348.100006,159.750000 L348.350006,159.850006 L348.850006,160.350006 L296.500000,160.350006 L187.250000,160.350006 L85.099998,160.350006 L85.500000,160.000000"/>
                <path style={ path2Style }
                d="M50.099998,160.350006 L197.350006,305.500000 L198.250000,306.000000 L85.099998,160.350006 L50.099998,160.350006 L122.849998,104.849998"/>
                <path style={ path2Style }
                d="M198.250000,306.000000 L199.250000,307.100006 L187.250000,160.350006"/>
                <path style={ path2Style }
                d="M348.850006,160.350006 L349.100006,160.500000 L199.750000,307.100006 L296.500000,160.350006"/>
                <path style={ path2Style }
                d="M199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006"/>
            </g>
            <g  id="FRAME_26" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_26" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_25.end"/>
                <path style={ path1Style }
                d="M130.250000,104.349998 L179.000000,104.500000 L239.850006,104.500000 L277.000000,104.500000 L348.000000,160.350006 L337.850006,170.750000 L200.000000,306.850006 L199.350006,307.250000 L198.850006,306.600006 L174.000000,282.350006 L51.000000,160.350006 L53.099998,158.600006 L53.500000,158.500000 L53.599998,158.250000 L121.500000,104.750000 L122.000000,104.349998 L130.250000,104.349998 "/>
                <path style={ path2Style }
                d="M130.250000,104.349998 L122.000000,104.349998 L121.500000,104.750000 L53.599998,158.250000 L130.100006,104.750000 L130.250000,104.349998 L179.000000,104.500000 L239.850006,104.500000 L277.000000,104.500000 L318.600006,160.000000 L239.850006,104.500000 L219.600006,160.350006 L179.000000,104.500000 L108.599998,160.350006 L130.100006,104.750000"/>
                <path style={ path2Style }
                d="M53.599998,158.250000 L53.500000,158.500000 L53.099998,158.600006 L51.000000,160.350006 L108.599998,160.350006 L219.600006,160.350006 L318.850006,160.350006 L318.600006,160.000000"/>
                <path style={ path2Style }
                d="M277.000000,104.500000 L348.000000,160.350006 L318.850006,160.350006 L200.000000,306.850006 L337.850006,170.750000 L348.000000,160.350006"/>
                <path style={ path2Style }
                d="M51.000000,160.350006 L174.000000,282.350006 L198.850006,306.600006 L108.599998,160.350006"/>
                <path style={ path2Style }
                d="M198.850006,306.600006 L199.350006,307.250000 L219.600006,160.350006"/>
                <path style={ path2Style }
                d="M200.000000,306.850006 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_27" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_27" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_26.end"/>
                <path style={ path1Style }
                d="M140.600006,104.500000 L196.100006,104.349998 L253.750000,104.500000 L275.600006,104.349998 L279.250000,104.349998 L279.850006,104.750000 L340.250000,160.350006 L202.250000,304.500000 L201.600006,305.250000 L200.750000,306.100006 L199.600006,307.350006 L199.350006,307.250000 L199.100006,306.850006 L58.750000,160.350006 L59.349998,159.750000 L59.750000,159.600006 L118.849998,104.849998 L119.500000,104.349998 L140.600006,104.500000 "/>
                <path style={ path2Style }
                d="M196.100006,104.349998 L251.000000,160.350006 L253.750000,104.500000 L335.100006,159.850006 L279.850006,104.750000 L279.250000,104.349998 L275.600006,104.349998 L253.750000,104.500000 L196.100006,104.349998 L136.500000,160.350006 L140.600006,104.500000 L59.750000,159.600006 L118.849998,104.849998 L119.500000,104.349998 L140.600006,104.500000 L196.100006,104.349998"/>
                <path style={ path2Style }
                d="M59.750000,159.600006 L59.349998,159.750000 L58.750000,160.350006 L136.500000,160.350006 L251.000000,160.350006 L335.500000,160.350006 L335.100006,159.850006"/>
                <path style={ path2Style }
                d="M279.850006,104.750000 L340.250000,160.350006 L335.500000,160.350006 L202.250000,304.500000 L340.250000,160.350006"/>
                <path style={ path2Style }
                d="M202.250000,304.500000 L201.600006,305.250000 L200.750000,306.100006 L199.600006,307.350006 L199.350006,307.250000 L251.000000,160.350006"/>
                <path style={ path2Style }
                d="M58.750000,160.350006 L199.100006,306.850006 L136.500000,160.350006"/>
                <path style={ path2Style }
                d="M199.100006,306.850006 L199.350006,307.250000"/>
            </g>
            <g  id="FRAME_28" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_28" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_27.end"/>
                <path style={ path1Style }
                d="M153.600006,104.500000 L213.350006,104.349998 L265.100006,104.349998 L278.350006,104.349998 L345.600006,160.100006 L345.850006,160.350006 L343.000000,163.600006 L199.600006,307.250000 L199.250000,307.100006 L197.350006,305.100006 L53.099998,160.350006 L119.750000,105.349998 L120.599998,104.349998 L153.600006,104.500000 "/>
                <path style={ path2Style }
                d="M265.100006,104.349998 L278.350006,104.349998 L345.600006,160.100006 L265.250000,104.599998 L265.100006,104.349998 L213.350006,104.349998 L167.100006,160.350006 L153.600006,104.500000 L73.599998,159.850006 L119.750000,105.349998 L120.599998,104.349998 L153.600006,104.500000 L213.350006,104.349998 L280.100006,160.100006 L265.250000,104.599998"/>
                <path style={ path2Style }
                d="M119.750000,105.349998 L53.099998,160.350006 L73.000000,160.350006 L73.599998,159.850006"/>
                <path style={ path2Style }
                d="M280.100006,160.100006 L280.250000,160.350006 L167.100006,160.350006 L73.000000,160.350006 L197.350006,305.100006 L53.099998,160.350006"/>
                <path style={ path2Style }
                d="M345.600006,160.100006 L345.850006,160.350006 L280.250000,160.350006 L199.600006,307.250000 L343.000000,163.600006 L345.850006,160.350006"/>
                <path style={ path2Style }
                d="M167.100006,160.350006 L199.250000,307.100006 L197.350006,305.100006"/>
                <path style={ path2Style }
                d="M199.250000,307.100006 L199.600006,307.250000"/>
            </g>
            <g  id="FRAME_29" style={{visibility:"hidden"}} fillRule="evenodd">
                <animate id="A_29" attributeName="visibility" attributeType="CSS" values="visible" dur={dur} begin="A_28.end"/>
                <path style={ path1Style }
                d="M168.750000,104.500000 L230.100006,104.500000 L273.600006,104.599998 L349.350006,160.350006 L344.100006,165.850006 L199.750000,307.100006 L199.600006,307.350006 L199.250000,307.100006 L198.500000,306.250000 L197.750000,305.850006 L49.599998,160.350006 L125.250000,104.750000 L125.500000,104.349998 L168.750000,104.500000 "/>
                <path style={ path2Style }
                d="M230.100006,104.500000 L305.350006,160.100006 L273.600006,104.599998 L230.100006,104.500000 L199.350006,160.350006 L168.750000,104.500000 L93.599998,160.100006 L125.250000,104.750000 L125.500000,104.349998 L168.750000,104.500000 L230.100006,104.500000"/>
                <path style={ path2Style }
                d="M125.250000,104.750000 L49.599998,160.350006 L93.349998,160.350006 L93.599998,160.100006"/>
                <path style={ path2Style }
                d="M305.350006,160.100006 L305.500000,160.350006 L199.350006,160.350006 L93.349998,160.350006 L198.500000,306.250000 L197.750000,305.850006 L49.599998,160.350006"/>
                <path style={ path2Style }
                d="M273.600006,104.599998 L349.350006,160.350006 L305.500000,160.350006 L199.750000,307.100006 L344.100006,165.850006 L349.350006,160.350006"/>
                <path style={ path2Style }
                d="M199.350006,160.350006 L199.250000,307.100006 L198.500000,306.250000"/>
                <path style={ path2Style }
                d="M199.250000,307.100006 L199.600006,307.350006 L199.750000,307.100006"/>
            </g>
        </svg>

    </>
}