

export default function Image({ src, alt = 'Imagen de Dianelum', className, id }) {


    return <>
        <img
            src={ src }
            alt={ alt }
            className={ className }
            id={ id }
            loading="lazy"
        />
    </>
}