import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { folder, useControls } from "leva";
import React, { useEffect, useRef } from "react";
import { ModelBase } from "./ModelBase";

export function ModelForest(props) {
  const { nodes } = useGLTF("/models/UV/Forest.glb");

  const config = useControls('Modelos', {
    'Mina cielo abierto': folder({
      wingsSpeed: { value: 0, min: -5, max: 5, step: .01 },
      birdsRange: { value: 0, min: -5, max: 5, step: .01 },
      birdsSpeed: { value: 2.66, min: -15, max: 15, step: .01 },
    }),
  })

  const birdsGroup = useRef()
  const bird1 = useRef()
  const bird2 = useRef()
  const bird3 = useRef()
  const bird4 = useRef()

  useFrame((state, delta) => {
    if (props.expStep === 3) {
      bird1.current.scale.z = Math.cos(state.clock.elapsedTime * 3 + config.wingsSpeed) * 5 + config.birdsRange
      bird2.current.scale.z = Math.cos(state.clock.elapsedTime * 3.2 + config.wingsSpeed) * 4 + config.birdsRange
      bird3.current.scale.z = Math.cos(state.clock.elapsedTime * 3 + config.wingsSpeed) * 5 + config.birdsRange
      bird4.current.scale.z = Math.cos(state.clock.elapsedTime * 2.5 + config.wingsSpeed) * 5 + config.birdsRange
      birdsGroup.current.rotation.y += delta * .3 * config.birdsSpeed
    }
  })

  /* useEffect(() => {
    if (props.expStep === 4) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
        <group ref={ birdsGroup }>
          <mesh
            geometry={nodes.DIA_4B_Pajaro4.geometry}
            material={ props.materials.baked }
            position={[-4.6, 15.82, 3.64]}
            rotation={[1.58, -0.35, 0.43]}
            scale={[4.6, 6.23, 4.6]}
            ref={ bird1 }
          />
          <mesh
            geometry={nodes.DIA_4B_Pajaro3.geometry}
            material={ props.materials.baked }
            position={[-9.07, 17.7, 2.54]}
            rotation={[1.58, -0.35, 0.43]}
            scale={[5.55, 5.88, 3.1]}
            ref={ bird2 }
          />
          <mesh
            geometry={nodes.DIA_4B_Pajaro1.geometry}
            material={ props.materials.baked }
            position={[-5.34, 16.73, 1.33]}
            rotation={[1.58, -0.35, 0.43]}
            scale={6.32}
            ref={ bird3 }
          />
          <mesh
            geometry={nodes.DIA_4B_Pajaro2.geometry}
            material={ props.materials.baked }
            position={[-7.28, 17.74, 2.13]}
            rotation={[1.58, -0.35, 0.43]}
            scale={[5.33, 7.22, 5.33]}
            ref={ bird4 }
          />
        </group>
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_4B_Bosque.geometry}
          material={ props.materials.baked }
          position={[-6.25, 0.47, 3.3]}
          rotation={[-2.99, 0.79, -0.25]}
          scale={[0.83, 0.68, 0.77]}
        />
      <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}