import { useEffect, useRef, useState } from "react"

export function LightSwitch({ onClick, visible, dianelumnizer }) {
    const lightSwitch = useRef()
    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(dianelumnizer)
    }, [dianelumnizer])

    return <>
        <div ref={ lightSwitch } className={ "light-switch " + ((active) ? "is-active" : "") + ((visible) ? " is-hidden" : "") } onClick={ onClick }>
            {/* <span className="light-switch__text">DIANELUMNIZER</span> */}
            <div className="light-switch__ball">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <g id="Grupo_7611" data-name="Grupo 7611" transform="translate(-3.5 -3.5)">
                        <line id="Línea_470" data-name="Línea 470" y1="12" transform="translate(9.5 3.5)" fill="none" stroke="#9bcaa6" strokeWidth="1"/>
                        <line id="Línea_471" data-name="Línea 471" x2="12" transform="translate(3.5 9.5)" fill="none" stroke="#9bcaa6" strokeWidth="1"/>
                        <line id="Línea_472" data-name="Línea 472" y1="12" transform="translate(13.743 5.257) rotate(45)" fill="none" stroke="#9bcaa6" strokeWidth="1"/>
                        <line id="Línea_473" data-name="Línea 473" x2="12" transform="translate(5.257 5.257) rotate(45)" fill="none" stroke="#9bcaa6" strokeWidth="1"/>
                    </g>
                </svg>
            </div>
        </div>
    </>
}