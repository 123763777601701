import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useMask } from "@react-three/drei";
import { ModelBase } from "./ModelBase";
import BulletSmokeCustomMaterial from "../Shaders/BulletSmoke/BulletSmokeShader";
import * as THREE from 'three';

export function ModelBullets(props) {
  const { nodes } = useGLTF("/models/UV/Bullets.glb");

  const smoke = useRef()

  useEffect(() => {
    smoke.current.material.emissiveIntensity = 0
  })

  /* useEffect(() => {
    if (props.expStep === 5) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  const point1 = useMemo(() => new THREE.Vector3( 0, 10, 0 ), [])
  const point2 = useMemo(() => new THREE.Vector3( 0, 20, 0 ), [])
  const curve2 = new THREE.CatmullRomCurve3([
    new THREE.Vector3( 0, -1, 0 ),
    new THREE.Vector3( 0, 1, 0 ),
    point1,
    point2,
  ])

  return (
    <group {...props} dispose={null}>
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_BALA_2.geometry}
          material={ props.materials.baked }
          position={[2.49, 2.1, 3.93]}
          rotation={[0, -1.12, -Math.PI / 2]}
          scale={[2.11, 2.11, 7.18]}
        />
        <mesh
          ref={ smoke }
          position={ [ -2.3, 27, -4 ] }
          scale={ 2.5 }
          rotation={ [ 0, Math.PI * .2, 0 ] }
          >
          <tubeGeometry args={ [ curve2, 200, .3, 3, false ] } />
          <BulletSmokeCustomMaterial color={ props.smokeColor } {...useMask(1, true)} expStep={ props.expStep } />
        </mesh>
        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}