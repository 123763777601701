import React from "react";

export default React.forwardRef( function BaseGroup(props, ref) {

    return (
        <group
            position-x={ props.positionX || 0 }
            position-y={ props.scale || 0 }
            position-z={ props.positionZ || 0 }
            scale={ props.scale || 1 }
            ref={ ref }
        >
            {props.children}
        </group>
    );
})