import { useGLTF } from "@react-three/drei";
import React, { useEffect } from "react";
import { Bubbles } from "../Bubbles";
import { ModelBase } from "./ModelBase";

export function ModelTestTubes(props) {
  const { nodes } = useGLTF("/models/UV/TestTubes.glb");

 /*  useEffect(() => {
    if (props.expStep === 5) {
      for (let i = 0; i < props.materials.textures.length; i++) {
        props.materials.textures[i].dispose()
      }
    }
  }, [props.expStep]) */

  return (
    <group {...props} dispose={null}>
        <mesh
          matrixAutoUpdate={ props.matrixAutoUpdate }
          geometry={nodes.DIA_PROBETA_3.geometry}
          material={ props.materials.baked }
          position={[-5.22, 1.65, 4.61]}
          rotation={[3.11, -1.1, -1.59]}
          scale={[1.65, 1.65, 9.22]}
        />
        <Bubbles
          color={ props.bubblesColor }
          range={ 4 }
          sizes={ .5 }
          position={ [ 2, 18, -3 ] }
          bubblesId={ 0 }
          scale={ 2 }
          expStep={ props.expStep }
        />

        <ModelBase matrixAutoUpdate={ props.matrixAutoUpdate } material={ props.materials.baked_base } floorMaterial={ props.materials.baked_floor } />
    </group>
  );
}