import { MeshTransmissionMaterial } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useControls } from "leva";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import Sizes from "../../Utils/Sizes";
import WindowHandler from "../../Utils/WindowHandler";

gsap.registerPlugin(
	ScrollTrigger,
);

export default function DiamondFront({ trigger, scale, frontEnv, color, positionX = 0, fixed = false, producto, nodes }) {

    const config = useControls({
        samples: { value: 10, min: 1, max: 32, step: 1 },
        resolution: { value: 2048, min: 256, max: 2048, step: 256 },
        roughness: { value: 0, min: 0, max: 1, step: 0.01 },
        thickness: { value: 3.5, min: 0, max: 10, step: 0.01 },
        ior: { value: 1.5, min: 1, max: 5, step: 0.01 },
        chromaticAberration: { value: 0, min: 0, max: 1 },
        anisotropy: { value: 0.1, min: 0, max: 1, step: 0.01 },
        distortion: { value: 0.0, min: 0, max: 1, step: 0.01 },
        distortionScale: { value: 0.3, min: 0.01, max: 1, step: 0.01 },
        temporalDistortion: { value: 0.5, min: 0, max: 1, step: 0.01 },
        /* attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
        attenuationColor: '#ffffff', 
        color: '#ffffff', */
    })

    // MOBILE
    // thickness 3.79
    // ior 3.28
    const configDiamond = {}
    if (color === 'white') {
        configDiamond.transmission = 10
        configDiamond.attenuationDistance = 10
        configDiamond.attenuationColor = '#ffffff'
        configDiamond.clearcoat = 1
    } else if (color === 'green') {
        configDiamond.transmission = 1
        configDiamond.attenuationDistance = 10
        configDiamond.attenuationColor = '#90c89d'
        configDiamond.clearcoat = 0
    }
    
    const diamond = useRef()
    const diamondGroup = useRef()
    const diamondMouse = useRef()
	const [pageReady, setPageReady] = useState(false)
    const windowHandler = WindowHandler()
    const { invalidate } = useThree()
    const { width } = Sizes();
    const [diamondVisible, setDiamondVisible] = useState(false)

    useEffect(() => {
        if (!pageReady) return
        if (!document.querySelector(trigger)) return

        const duration = 1
        let diamondScrollTl = gsap.timeline({defaults: {duration: duration, ease: 'linear'}});
        if (producto && width > 1024) {
            diamondScrollTl.fromTo(diamond.current.position, {y: -5}, {y: 0, duration: duration * 3}, 0);
            diamondScrollTl.fromTo(diamond.current.position, {y: 0}, {y: 5, duration: duration * 3}, duration * 3);
            /* diamondScrollTl.to(diamond.current.position, {x: ((width * .5) * -.01), duration: duration * 3}, duration * 3); */
            diamondScrollTl.to(diamond.current.rotation, {x: Math.PI * 1.8, y: -Math.PI * 1, z: Math.PI * 1.5, duration: duration * 6}, 0);
        } else if (fixed) {
            diamondScrollTl.fromTo(diamond.current.position, {y: -5}, {y: -2}, 0);
            diamondScrollTl.fromTo(diamond.current.position, {y: -2}, {y: -2}, duration);
            diamondScrollTl.fromTo(diamondGroup.current.position, {y: 0}, {y: 5, duration: duration * 3}, 0);
            diamondScrollTl.fromTo(diamond.current.position, {y: -2}, {y: 0.5}, duration * 2);
            diamondScrollTl.to(diamond.current.rotation, {x: Math.PI * -.2, y: Math.PI * .4, z: -Math.PI * 1.5, duration: duration * 3}, 0);
        } else {
            diamondScrollTl.to(diamond.current.position, {y: 6}, 0);
            diamondScrollTl.to(diamond.current.rotation, {x: Math.PI * -.2, y: Math.PI * .4, z: -Math.PI * 1.5}, 0);
        }

        let start, end
        if (fixed) {
            start = '0% 100%'
            end = '100% 0%'
        } else {
            if (width <= 768) {
                start = '0% 100%'
                end = '100% 0%'
            } else {
                start = '35% 100%'
                end = '100% 35%'
            }
        }

        ScrollTrigger.create({
            trigger: trigger,
            animation: diamondScrollTl,
            start: start,
            end: end,
            scrub: true,
            onEnter() {
                setDiamondVisible(true)
            },
            onEnterBack() {
                setDiamondVisible(true)
            },
            onLeave() {
                setDiamondVisible(false)
            },
            onLeaveBack() {
                setDiamondVisible(false)
            },
            onUpdate: () => {
                invalidate()
            }
        });

        return () => {
            diamondScrollTl.kill(true)
        }
    }, [pageReady])

    // ON PAGE LOADED
    useEffect(() => {
        const onPageLoad = () => {
          setPageReady(true);
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const textureBack = useLoader(THREE.TextureLoader, '/textures/front_env/front_env_'+frontEnv+'.png')
    textureBack.encoding = THREE.sRGBEncoding;

    let posX = positionX
    if (producto) {
        posX = -.3
    }

    return <>
        <group ref={ diamondMouse } visible={ diamondVisible }>
            <group ref={ diamondGroup }>
                <mesh ref={ diamond } scale={ (windowHandler.width <= 768) ? scale[1] : scale[0] } geometry={nodes.Diamante_3.geometry} position={ [ ((width * .5) * .005) * posX, -6, -40 ] } rotation={ [ Math.PI * 1, 0, 0 ] }>
                    <MeshTransmissionMaterial background={ textureBack } {...configDiamond} {...config} />
                </mesh>
            </group>
        </group>
    </>
}
