import { CubeCamera, MeshRefractionMaterial, useGLTF } from "@react-three/drei"
import { useFrame, useLoader } from "@react-three/fiber"
import { Power3 } from "gsap/all"
import { CustomEase, Power1, gsap } from "gsap/all"
import { useControls } from 'leva'
import * as easing from "maath/easing"
import React, { useEffect, useRef, useState } from "react"
import * as THREE from 'three'
import { RGBELoader } from 'three-stdlib'
import WindowHandler from "./Utils/WindowHandler"
gsap.registerPlugin(CustomEase)
const eases = [
    CustomEase.create("custom", "M0,0,C0,0,0.143,0,0.225,0.009,0.246,0.011,0.263,0.018,0.282,0.028,0.303,0.039,0.318,0.05,0.335,0.067,0.356,0.089,0.369,0.107,0.385,0.135,0.405,0.17,0.415,0.195,0.43,0.235,0.448,0.284,0.572,0.804,0.778,0.938,0.889,1.01,1,1,1,1"),
    Power3.easeInOut,
    Power1.easeInOut,
]

const timeStampsDiamond = [
    { z: -7.75/* -30 */, y: 5.5, duration: .5, ease: eases[0] }, // MINA
    { z: 6, y: 5.5, duration: .5, ease: eases[0]  }, // CHIMENEAS
    { z: 98, y: 5.5, duration: 1, ease: eases[0] }, // MINERO
    { z: 60, y: 5.5, duration: 1, ease: eases[0] }, // MINA CIELO ABIERTO
    { z: 158, y: 5.5, duration: 1, ease: eases[0] }, // BALAS
    { z: 90, y: 5.5, duration: 1, ease: eases[0] }, // VAGONETA
    { z: 286, y: 8.5, duration: 8, ease: eases[0] }, // PORTAL
    { z: 375, y: 5.5, rotY: 0, rotX: Math.PI * .5, duration: 4, ease: eases[1] }, // FORCEP
    { z: 405, y: -33, rotY: Math.PI * -.1, rotX: Math.PI * .6, duration: 4, ease: eases[1] }, // LOUPE
    { z: 390, y: -79, rotY: Math.PI * .04, rotX: Math.PI * .5, duration: 4, ease: eases[1] }, // GRAPHS
    { z: 390, y: -56, rotY: 0, rotX: Math.PI * .5, duration: 4, ease: eases[2] }, // GRAPHS UP
    {  }, // END
    {  }, // MOBILE ONLY: 1
    {  }, // MOBILE ONLY: 2
    {  }, // MOBILE ONLY: 3
    {  }, // MOBILE ONLY: 4 
]

const playAnimation = (diamond, stamp, expStep_PREV, disableRotation, enableRotation, shadow, positiveMap) => {

    if (!timeStampsDiamond[stamp].duration) return
    if (!diamond.current) return
    if (stamp === null || stamp === undefined) return
    gsap.to(diamond.current.position, {
        z: timeStampsDiamond[stamp].z,
        y: timeStampsDiamond[stamp].y,
        duration: (expStep_PREV === 6 && stamp === 5) ? 6.7 : timeStampsDiamond[stamp].duration,
        ease: (expStep_PREV === 10 && stamp === 9) ? eases[2] : timeStampsDiamond[stamp].ease,
        delay: 0
    })
    if (timeStampsDiamond[stamp].rotY != undefined && timeStampsDiamond[stamp].rotX != undefined) {
        gsap.to(diamond.current.rotation, {
            y: timeStampsDiamond[stamp].rotY,
            x: timeStampsDiamond[stamp].rotX,
            duration: timeStampsDiamond[stamp].duration,
            ease: timeStampsDiamond[stamp].ease,
        })
    }
    if (stamp === 6) {
        gsap.to(diamond.current.scale, {
            x: 1.4,
            y: 1.4,
            z: 1.4,
            duration: 8,
            ease: eases[0],
            onComplete: () => {
                positiveMap()
            }
        })
        if (expStep_PREV === 7) {
            enableRotation()
            const p = { p: 0 }
            gsap.to(p, {
                p: 2,
                delay: 0,
                onUpdate: () => {
                    shadow.current.material.opacity = p.p
                }
            })
        }
    } else if (stamp === 5 || stamp === 7 || stamp === 8) {
        gsap.to(diamond.current.scale, {
            x: 1,
            y: 1,
            z: 1,
            duration: 4,
            ease: eases[1],
            onComplete: () => {
                if (stamp === 7) {
                    disableRotation()
                }
            }
        })
    }
    if (stamp === 7 && expStep_PREV === 6) {
        const p = { p: 3 }
        gsap.to(p, {
            p: 0,
            duration: 3,
            delay: 2.9,
            onUpdate: () => {
                shadow.current.material.opacity = p.p
            }
        })
    }
}

export default React.forwardRef( function Diamond(props, ref) {

    const diamondRef = useRef()
    const diamondGroupRef = useRef()
    const shadow = useRef()
    const rotating = useRef(true)
    const [showChildren, setShowChildren] = useState(true)
    const [showDiamond, setShowDiamond] = useState(true)
    const isTouch = WindowHandler().isTouch

    const disableRotation = () => {
        rotating.current = false
    }
    const enableRotation = () => {
        rotating.current = true
    }

    const diamondShadowTexture = useLoader(THREE.TextureLoader, '/textures/diamond/baked_floor.jpg')
    const diamondShadowAlpha = useLoader(THREE.TextureLoader, '/textures/diamond/alpha_floor.jpg')

    const { nodes } = useGLTF('./models/diamond.glb')

    const [textureDiamond, setTextureDiamond] = useState(null)

    const texture = useLoader(RGBELoader, 'cyclorama_hard_light_4k.hdr')
    useEffect(() => {
        setTextureDiamond(texture)
    }, [])

    const postexture = useLoader(RGBELoader, 'diamond_negative_1.hdr')
    const positiveMap = () => {
        /* setTextureDiamond(postexture) */
    }

    const config = useControls('Diamante (refracción)', {
        bounces: { value: 1, min: 0, max: 8, step: 1 },
        aberrationStrength: { value: 0.00, min: 0, max: 0.1, step: 0.01 },
        ior: { value: 2, min: 0, max: 10 },
        fresnel: { value: 1, min: 0, max: 1 },
        color: 'white',
        fastChroma: true
    })


    useFrame((state, delta) => {
        if (!diamondGroupRef.current) return

        let negativeOut = false
        if (props.negative && props.positivePart.current) {
            negativeOut = true
        }
        if (negativeOut) return

        if (rotating.current)
            /* easing.damp(diamondGroupRef.current.rotation, "z", diamondGroupRef.current.rotation.z + props.configDiamond.rotSpeed * .4, 0.25, delta) */ // More natural at resuming rotation but both diamonds rotate at different offsets
            diamondGroupRef.current.rotation.z = state.clock.elapsedTime * .2

        if (!(props.expStep === 10 || props.expStep === 11)) return
        diamondGroupRef.current.rotation.z -= delta * .3 
    })

    useEffect(() => {
        if (!diamondGroupRef.current) return
        if (props.negative) {
            /* if (props.positivePartState) {
                gsap.to(diamondGroupRef.current, {visible: false, duration: 8.5})
            } else {
                diamondGroupRef.current.visible = true
            } */
        }
    }, [props.positivePartState])
    
    useEffect(() => {
        if (!diamondGroupRef.current) return
        if (!shadow.current) return
        if (props.negative && props.expStep > 6) return
        playAnimation(diamondGroupRef, props.expStep, props.expStep_PREV, disableRotation, enableRotation, shadow, positiveMap)

    }, [props.expStep, props.expStep_PREV, props.negative])
    
    useEffect(() => {
        /* if (!isTouch) return
        if (!props.negative && props.dianelumnizer) {
            setShowChildren(true)
        } else if ((!props.negative && !props.dianelumnizer)) {
            setTimeout(() => {
                setShowChildren(false)
            }, 1000);
        }
        if (props.negative && props.dianelumnizer) {
            setTimeout(() => {
                setShowDiamond(false)
            }, 1000);
        } else if (props.negative && !props.dianelumnizer) {
            setShowDiamond(true)
        } */
    }, [props.dianelumnizer, showChildren, showDiamond])

    return (
        <>
            
            <group position={ [ 0, -1, 0 ] }>

                { textureDiamond !== null && <group ref={ diamondGroupRef } {...props} position-y={ props.configDiamond.yPos } visible={ showDiamond }>
                    <mesh ref={ diamondRef } geometry={nodes.Diamante_3.geometry}>
                        <MeshRefractionMaterial envMap={textureDiamond} {...config} toneMapped={false} />
                    </mesh>
                    <mesh ref={ shadow } rotation-x={ Math.PI } position={ [ 0, 0, 4.3 ] } scale={ 17 }>
                        <meshBasicMaterial map={ diamondShadowTexture } alphaMap={ diamondShadowAlpha } transparent color='black' opacity={3} />
                        <planeGeometry args={ [ 1, 1, 1 ] } />
                    </mesh>
                </group> }

                <mesh
                    geometry={ props.domeGeometry }
                    material={ props.standardBackgroundMaterialShow }
                    scale={500}
                    position={ [ 0, 0, 170 ] }
                />

            </group>

            <group visible={ showChildren }>
                { props.children}
            </group>

        </>
    )
})