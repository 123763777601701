import { useMask, useScroll } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { folder, useControls } from "leva"
import React, { useMemo, useRef } from "react"
import * as THREE from 'three'

const generateArray = (id) => {
    let rArray
    if (id === 0) {
        rArray = Array.from({ length: 1000 }, (r = .8, i) => ({ random: i, positionX: r / 1 - (Math.random() * .5) * r, positionZ: r / 1 - (Math.random() * .5) * r, scale: Math.max(Math.random(), .6) }))
    } else {
        rArray = Array.from({ length: 1000 }, (r = .8, i) => ({ random: i * 2.5, positionX: r / 2 - Math.random() * r, positionZ: r / 2 - Math.random() * r }))
    }
    return rArray
}

export function Bubbles(props) {

    const config = useControls('Modelos', {
        'Balas': folder({
            bubblesOpacity: { value: .7, min: .1, max: 1, step: .01 },
        }),
    })

    const randomArray = useMemo(() => generateArray(props.bubblesId), [props.bubblesId])

    const instancedRef = useRef()

    const temp = new THREE.Object3D()

    const scroll = useScroll()
    useFrame((state) => {
        if (!instancedRef.current) return
        if (props.expStep === 4) {
            for (let i = 0; i < props.range; i++) {
                if (props.bubblesId === 0) {
                    temp.position.set(
                        randomArray[i].positionX,
                        (temp.position.y + 1.015 + randomArray[i].random) % 10,
                        randomArray[i].positionZ
                    )
                    temp.scale.set(
                        randomArray[i].scale,
                        randomArray[i].scale,
                        randomArray[i].scale,
                    )
                } else {
                    temp.position.set(
                        randomArray[i].positionX,
                        (temp.position.y + 3.77 + randomArray[i].random) % 10,
                        randomArray[i].positionZ
                    )
                }
                
                temp.updateMatrix()
                instancedRef.current.setMatrixAt(i, temp.matrix)
            }
            instancedRef.current.instanceMatrix.needsUpdate = true
            instancedRef.current.material.opacity = config.bubblesOpacity
        } else {
            instancedRef.current.material.opacity = 0
        }
    })

    return <>
        <group {...props}>
            <instancedMesh ref={ instancedRef } args={[ null, null, props.range || 1 ]} /* material={ props.material } */>
                <sphereGeometry args={[props.sizes || 1 , 8, 8]} />
                <meshLambertMaterial color={ props.color } transparent opacity={ config.bubblesOpacity } depthWrite={false} />
            </instancedMesh>
        </group>
    </>
}