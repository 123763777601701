import { useEffect, useRef, useState } from 'react';
import { Footer, Header } from './Front';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { gsap } from 'gsap';
import { NavLink } from 'react-router-dom';
import Video from './Components/Video';

export function Page404() {
	const contenedor = useRef();

	const configFront = {
		pages: 6
	};

	const [loaded, setLoaded] = useState(false);
	const sliderdia = useRef();
	const [openAccordion, setOpenAccordion] = useState(null);
	const accordionRefs = useRef([]);

	useEffect(() => {
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

		iniScrollSmoother();

		setTimeout(function () {
			animaciones('404');
		}, 2000);
	}, [loaded]);

	useEffect(() => {
		setLoaded(true);
	}, []);

	return (
		<>
        <Header />

<div id="smooth-wrapper">
  <div id="smooth-content">
      <div className="header header2 header-ani header__404" >
          <div className="header__data" id='header__404_data'>
            
            <div className="pieza-header-container">
              <div className="pieza pieza-header">
                <span className="pieza__line pieza__1"></span>
                <span className="pieza__line pieza__2"></span>
                <span className="pieza__line pieza__3"></span>
                <span className="pieza__line pieza__4"></span>
                <span className="pieza__rombo pieza__5"></span>
              </div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            <span data-delay="1.2" >ERROR 404</span>
            </div>

            <div className='header__titulo_ani'>
              <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>PÁGINA NO</span><span className='linet linet2'>ENCONTRADA</span><span className='linet linet3'></span></div></div>
              <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>PÁGINA NO</span><span className='linet linet2'>ENCONTRADA</span><span className='linet linet3'></span></div></div>
              <div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'><span className='ocu'>PÁ</span>GINA <span className='ocu'>NO</span></span><span className='linet linet2'><span className='ocu'>ENC</span>ONTR<span className='ocu'>ADA</span></span><span className='linet linet3'><span className='ocu'></span><span className='ocu'></span></span></div></div>
            </div>
            
            <div className="header__ani_center">
              <div className='header__ani_rombo'></div>
              <div className="header__ani">
              <Video 
            src={ 'DIA_66' }
            poster={ '/images/DIA_6600.png' }
            loop={ true }
          />
                {/* <img src="images/animaciones/header_404/DIA_6600.png" id='header_img' className='header__ani_img' /> */}
              </div>
            </div>


            <div className="header__btn404">
              <NavLink className="btn__ver " to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.5 65.503"><g fill="none" stroke="#141515" data-name="Grupo 3017"><path strokeLinejoin="round" d="M180.115.5H15.385L.5 13.4v38.7L15.385 65h164.73L195 52.1V13.4Z" data-name="Trazado 37445"/><path strokeLinejoin="round" d="m.5 52.102 97.25 12.9 97.25-12.9" data-name="Trazado 37447"/><path strokeLinejoin="round" d="M195 13.401 97.75.501.5 13.401" data-name="Trazado 37448"/><path d="M15.636.5v64.503" data-name="Línea 107"/><path d="M179.864.5v64.503" data-name="Línea 108"/></g></svg>
                <span>HOME</span>
              </NavLink>
            </div>
          </div>
    </div>

    
  </div>
</div>
		</>
	);
}
