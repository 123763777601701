export default function preloadImages(array, callbackFunction) {
    if (!preloadImages.list) {
        preloadImages.list = [];
    }
    const list = preloadImages.list;
    for (let i = 0; i < array.length; i++) {
        const img = new Image();
        img.onload = function() {
            var index = list.indexOf(this);
            if (index !== -1) {
                // remove image from the array once it's loaded
                // for memory consumption reasons
                list.splice(index, 1);
                callbackFunction()
            }
        }
        list.push(img);
        img.src = array[i];
    }
}