import { PerspectiveCamera, useGLTF } from "@react-three/drei";
import { createPortal, useFrame, useLoader, useThree } from "@react-three/fiber";
import { useControls } from "leva";
import * as easing from "maath/easing";
import {
  CopyPass,
  EffectComposer,
  EffectPass,
  RenderPass,
} from "postprocessing";
import React, { useMemo, useRef } from "react";
import * as THREE from 'three';
import LensDistortionEffect from "../Shaders/LensDistortionEffect";
import WindowHandler from "../../Utils/WindowHandler";

export function ModelLoupe(props) {
  const { nodes } = useGLTF("/models/UV/Loupe.glb");
  const { gl, width } = useThree()

  const renderTarget = useMemo(() => new THREE.WebGLRenderTarget( width * 512, width * 512 ))
  const otherCamera = useRef()
  const otherScene = useMemo(() => new THREE.Scene())
  otherScene.outputColorSpace = THREE.SRGBColorSpace

  const plane = useRef()
  const magGroup = useRef()
  const image = useRef()
  
  const isTouch = WindowHandler().isTouch
  const widthScreen = WindowHandler().width
  const heightScreen = WindowHandler().height

  const config = useControls('Lupa', {
    distortion: { value: .5, min: 0, max: .5, step: .01 },
    imgScale: { value: .003, min: .001, max: 0.007, step: .0001 },
  })

  const distortion = useMemo(() => new THREE.Vector2(config.distortion, config.distortion))
  const principalPoint = useMemo(() => new THREE.Vector2(0, 0))
  const focalLength = useMemo(() => new THREE.Vector2(0.5, 0.5))

  let composer = useMemo(() => {
    if (!otherCamera.current?.position) return;

    const composer = new EffectComposer(gl);
    composer.autoRenderToScreen = false;

    const renderPass = new RenderPass(otherScene, otherCamera.current);

    const lensDistortionPass = new EffectPass(otherCamera.current, new LensDistortionEffect({
      distortion: new THREE.Vector2(.2, .2),
      principalPoint: principalPoint,
      focalLength: focalLength,
      skew: 0,
    }))
    const copyPass = new CopyPass(renderTarget);
    
    composer.addPass(renderPass);
    composer.addPass(lensDistortionPass);
    composer.addPass(copyPass);

    return composer;
  }, [gl, otherScene] );


  useFrame((state, delta) => {
    if (props.loupeActive.current) {
      if (composer) composer.render();
      gl.setRenderTarget(null);

      easing.damp3(
        magGroup.current.position,
        [
          (props.cursor.x * .01 * state.size.width) * .4,
          ((-props.cursor.y) * .004 * state.size.height) - 28,
          370
        ],
        0.15,
        delta
      )
      easing.damp3(
        image.current.position,
        [
          (-props.cursor.x * 1.9) * .9 + .2,
          ((-props.cursor.y * -.6 ) * 1) - .2 /* -.3 */,
          -1
        ],
        0.15,
        delta
      )
    } else {
      magGroup.current.position.set(
        THREE.MathUtils.lerp(magGroup.current.position.x, -50, 0.035),
        THREE.MathUtils.lerp(magGroup.current.position.y, -20, 0.035),
        370
      )
      image.current.position.set(
        THREE.MathUtils.lerp(image.current.position.x, 2.3, 0.08),
        THREE.MathUtils.lerp(image.current.position.y, -2, 0.08),
        -1
      )
    }
  })

  const texture = useLoader(THREE.TextureLoader, '/textures/landscape.jpg')
  texture.encoding = THREE.sRGBEncoding

  return (
    <group {...props} dispose={null}>
      
      <group ref={ magGroup } scale={ 1 } position={ [ .3, -1.2, -1 ] } rotation={ [ 0, 0, Math.PI * -.15 ] }>
        <mesh
          geometry={nodes.DIA_LUPA_BOTON1.geometry}
          material={ props.materials.baked }
          rotation={[Math.PI, 0, 0]}
          scale={[1.52, 1.52, 0.27]}
        />

        <mesh position={ [ 0, 0, .1 ] } ref={ plane } rotation={ [ 0, 0, Math.PI * .15 ] }> 
          <circleGeometry args={[4.6, 64]}/>
          <meshBasicMaterial map={ renderTarget.texture } />
        </mesh>
      </group>

      { createPortal(<>
        <PerspectiveCamera
          ref={otherCamera}
          position={[0, 0, 1]}
        />
        <group position={ [ 0, .3, 0 ] }>
          <mesh 
            ref={ image } 
            scale={ [ window.innerWidth * config.imgScale * (2048 * .0005) * ((isTouch && widthScreen <= 1023 && heightScreen <= 400) ? 2.5 : 1), window.innerHeight * config.imgScale * (2048 * .0005) * ((isTouch && widthScreen <= 1023 && heightScreen <= 400) ? 2.5 : 1), 1 ] } 
            rotation={ [ 0, 0, 0 ] }>
            <planeGeometry />
            <meshBasicMaterial map={ texture } />
          </mesh>
        </group>
      </>, otherScene) }

    </group>
  );
}