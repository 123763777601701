import React, { useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from 'three'
import { useFrame, useLoader } from "@react-three/fiber";
import * as THREE from 'three';
import { TubeShaderMaterial } from "../Shaders/TubeShaderMaterial";

export function BaseTube(props) {
  const { nodes } = useGLTF("/models/UV/BaseTube.glb");
  
  const texture = useLoader(TextureLoader, '/textures/basetube/baked.png')
  const alpha = useLoader(TextureLoader, '/textures/basetube/baked_alpha.jpg')
  texture.flipY = false
  alpha.flipY = false
  texture.encoding = THREE.sRGBEncoding
  const bakedElementMaterial = useMemo(() =>new THREE.MeshMatcapMaterial({ map: texture, color: props.color, transparent: true, alphaMap: alpha }), [])

  const m = TubeShaderMaterial({ texture: texture, color: props.color, offset: 2, positivePart: props.positivePart, expStep: props.expStep, positivePartState: props.positivePartState, size: .015 })

  return (
    <group {...props} dispose={null}>
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_SUELO.geometry}
        material={ bakedElementMaterial }
        position={[0, -2.76, 0]}
        scale={5}
      />
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_CABLE.geometry}
        material={ bakedElementMaterial }
        position={[0, -1.28, -0.04]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={2.95}
      />
      <mesh
        matrixAutoUpdate={ props.matrixAutoUpdate }
        geometry={nodes.DIA_CABLE001.geometry}
        material={ bakedElementMaterial }
        position={[0, -1.28, -0.04]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={2.95}
      />
      <mesh
        geometry={nodes.DIA_CABLE002.geometry}
        material={ m }
        position={[0, -1.28, -0.04]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={2.95}
      />
    </group>
  );
}