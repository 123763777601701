import { useEffect, useRef, useState } from 'react';
import { Footer } from './Front';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { gsap } from 'gsap';
import WindowHandler from '../Utils/WindowHandler';

export function Aviso() {
	const contenedor = useRef();

	const configFront = {
		pages: 6
	};

	const [loaded, setLoaded] = useState(false);
	const sliderdia = useRef();
	const [openAccordion, setOpenAccordion] = useState(null);
	const accordionRefs = useRef([]);
  const isTouch = WindowHandler().isTouch

  
	useEffect(() => {
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

		iniScrollSmoother();
    setTimeout(function(){
      animaciones('aviso', isTouch);
    },0);
	}, [loaded]);

	useEffect(() => {
		setLoaded(true);
	}, []);

	return (
		<>
<div id="smooth-wrapper">
  <div id="smooth-content">


  <div className="header-pin">
      <div className="header header2 header-ani header__aviso">
          <div className="header__data">
            
            <div className="pieza-header-container">
              <div className="pieza pieza-header">
                <span className="pieza__line pieza__1"></span>
                <span className="pieza__line pieza__2"></span>
                <span className="pieza__line pieza__3"></span>
                <span className="pieza__line pieza__4"></span>
                <span className="pieza__rombo pieza__5"></span>
              </div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            <span data-delay="1.2" >Politica de privacidad</span>
            </div>

            <div className='header__titulo_ani'>
              <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>AVISO LEGAL</span><span className='linet linet2'>Y CONDICIONES</span><span className='linet linet3'>DE USO</span></div></div>
              <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>AVISO LEGAL</span><span className='linet linet2'>Y CONDICIONES</span><span className='linet linet3'>DE USO</span></div></div>
              <div className='header__titulo_ani_3'><div className='tit'><span className='linet linet1'>AVI<span className='ocu'></span>SO LE<span className='ocu'>GAL</span></span><span className='linet linet2'><span className='ocu'>Y CO</span>NDICIO<span className='ocu'>NES</span></span><span className='linet linet3'><span className='ocu'>D</span>E US<span className='ocu'>O</span></span></div></div>
            </div>
            
            <div className="header__ani_center --aviso">
              <div className='header__ani_rombo'></div>
              <div className="header__ani">
                <img src="images/animaciones/header_aviso/DIA_64000.png" id='header_img' className='header__ani_img' />
              </div>
            </div>
          </div>

          <div className="cita cita-header">
        <div className="cita__title ani_ini_mask">
          <span>Aviso Legal</span>
        </div>
        <div className="cita__cita ani_ini_line">
          Antes de utilizar este sitio, te pedimos que leas detenidamente el aviso legal.
        </div>
      </div>
          <div className="header__arrow">
            <a href="#" onClick={(e)=> ancla('legal')}
              className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
              <span className="btn__rombo_cont"></span>
              <span className="btn__rombo_txt">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                    <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                      <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                      <path d="M7.543 13.804V0" data-name="Línea 43" />
                    </g>
                  </svg>
                </span>
              </span>
            </a>
          </div>
      </div>
    </div>

    <div className="bloque__legal" id="legal">
      <div className='bloque__legal__header'>
        <div className="bloque__legal__header_left ">AVISO LEGAL Y<br /> CONDICIONES DE USO</div>
        <div className="bloque__legal__header_right ">POLÍTICA DE<br /> PRIVACIDAD</div>
      </div>

      <div className='bloque__legal__data'>
        <div className="bloque__legal__left ">
          <p>El presente aviso legal se interpreta de acuerdo con lo dispuesto en la LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI), sin perjuicio de lo que pudiera establecer otra
            normativa vigente específica o fuera del ámbito normativo coordinado en materia de servicios de la sociedad de la información y de la contratación por vía electrónica.</p>
          <p>Dianelum S.A., responsable del sitio web https://www.dianelum.com, en adelante Dianelum, pone a disposición de los usuarios el presente documento, con el objetivo de cumplir con las
            obligaciones establecidas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), así como informar a todos los usuarios del
            sitio web sobre las condiciones de uso.</p>
          <p>Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a observar y cumplir rigurosamente las disposiciones aquí establecidas, así como cualquier otra
            disposición legal aplicable.</p>
          <p>Este Aviso Legal y Condiciones de Uso se establece entre usted (en adelante, “usted” o “su”) y Dianelum (tal como se define anteriormente). Al acceder, utilizar o intentar utilizar los
            servicios de Dianelum, usted reconoce y acepta que acepta estar sujeto a estas condiciones. Si no está de acuerdo, no acceda al sitio web de Dianelum ni utilice sus servicios.</p>
          <p>Dianelum se reserva el derecho de modificar cualquier información que pueda aparecer en el sitio web, sin obligación de preaviso o notificación a los usuarios, siendo suficiente la
            publicación en el sitio web de Dianelum.</p>
        </div>
        <div className="bloque__legal__right ">
          <p>El presente aviso legal se interpreta de acuerdo con lo dispuesto en la LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI), sin perjuicio de lo que pudiera establecer otra
            normativa vigente específica o fuera del ámbito normativo coordinado en materia de servicios de la sociedad de la información y de la contratación por vía electrónica.</p>
          <p>Dianelum S.A., responsable del sitio web https://www.dianelum.com, en adelante Dianelum, pone a disposición de los usuarios el presente documento, con el objetivo de cumplir con las
            obligaciones establecidas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), así como informar a todos los usuarios del
            sitio web sobre las condiciones de uso.</p>
          <p>Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a observar y cumplir rigurosamente las disposiciones aquí establecidas, así como cualquier otra
            disposición legal aplicable.</p>
          <p>Este Aviso Legal y Condiciones de Uso se establece entre usted (en adelante, “usted” o “su”) y Dianelum (tal como se define anteriormente). Al acceder, utilizar o intentar utilizar los
            servicios de Dianelum, usted reconoce y acepta que acepta estar sujeto a estas condiciones. Si no está de acuerdo, no acceda al sitio web de Dianelum ni utilice sus servicios.</p>
          <p>Dianelum se reserva el derecho de modificar cualquier información que pueda aparecer en el sitio web, sin obligación de preaviso o notificación a los usuarios, siendo suficiente la
            publicación en el sitio web de Dianelum.</p>

          <h3>Datos identificativos</h3>
          <p>
            Denominación social: Dianelum S.L. Nombre comercial: Dianelum<br />
            CIF: A44881779 <br />
            Registro mercantil de Madrid<br />
            Inscripción 2, Tomo 44847, Folio 214<br />
            Fecha inscripción: 25 de octubre de 2023<br />
            Domicilio social: Plaza de autos 14, 28340 (Valdemoro)<br />
          </p>

          <h3>Objeto</h3>
          <p>A través del Sitio Web, ofrecemos a los Usuarios la posibilidad de acceder a información sobre nuestros servicios.</p>

          <h3>PRIVACIDAD Y TRATAMIENTO DE DATOS</h3>
          <p>Cuando sea necesario proporcionar datos personales para acceder a determinados contenidos o servicios, los Usuarios garantizarán la veracidad, exactitud, autenticidad y vigencia de los
            mismos. Dianelum dará a dichos datos el tratamiento automatizado correspondiente según su naturaleza o finalidad, en los términos indicados en la sección de Política de Privacidad.</p>

          <h3>PROPIEDAD INDUSTRIAL E INTELECTUAL</h3>
          <p>El Usuario reconoce y acepta que todos los contenidos mostrados en el sitio web, especialmente diseños, textos, imágenes, logotipos, iconos, botones, software, nombres comerciales, marcas
            u otros signos susceptibles de uso industrial y/o comercial, están sujetos a derechos de propiedad intelectual. Todas las marcas, nombres comerciales o signos distintivos,</p>

          <hr />

          <h2>POLÍTICA DE cookies</h2>
          <p>Dianelum se reserva el derecho de utilizar tecnología de “cookies” en nuestro sitio web con el fin de reconocerte como usuario frecuente y personalizar tu experiencia al utilizar nuestro
            sitio web, como la preselección de tu idioma o la presentación de contenidos más relevantes y específicos para ti.</p>
          <p>Las cookies recopilan la dirección IP del usuario, siendo Google el responsable del tratamiento de esta información.</p>
          <p>Las cookies son archivos enviados a tu navegador desde un servidor web para registrar tu navegación en nuestro sitio web cuando permitas su recepción. Si lo deseas, puedes configurar tu
            navegador para recibir una notificación en pantalla sobre la recepción de cookies y para impedir la instalación de cookies en tu disco duro. Consulta las instrucciones y manuales de tu
            navegador para obtener más información sobre cómo hacerlo.</p>
          <p>Gracias a las cookies, podemos reconocer el navegador del dispositivo que estás utilizando para facilitar contenido relevante y ofrecerte preferencias de navegación o publicidad adaptadas
            a tus intereses y perfiles demográficos, así como para medir las visitas, los parámetros de tráfico y controlar el progreso y número de visitas.</p>
          <p>Nuestro sitio web utiliza cookies de terceros, las cuales son enviadas a tu ordenador o dispositivo desde un dominio o página web que no es gestionada por nosotros, sino por otra entidad
            que trata los datos obtenidos a través de las cookies.</p>
          <p>En este caso, las cookies se utilizan con fines estadísticos relacionados con las visitas recibidas y las páginas consultadas. Al navegar por nuestro sitio web, aceptas el uso de estas
            cookies.</p>

          <table>
            <tbody>
              <tr>
                <th>
                  <p>COOKIES (PROVEEDOR)</p>
                </th>
                <th>
                  <p>DURACI&Oacute;N&nbsp;</p>
                </th>
                <th>
                  <p>DESCRIPCI&Oacute;N&nbsp;</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p>_ga (Google)</p>
                </td>
                <td>
                  <p>2 a&ntilde;os</p>
                </td>
                <td>
                  <p>Se utiliza para distinguir a los usuarios</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_gid (Google)</p>
                </td>
                <td>
                  <p>24 horas&nbsp;</p>
                </td>
                <td>
                  <p>Se utiliza para distinguir a los usuarios</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_gat (Google)</p>
                </td>
                <td>
                  <p>1 minuto</p>
                </td>
                <td>
                  <p>Se utiliza para limitar el porcentaje de solicitudes<br /><br />Si has implementado Google Analytics a trav&eacute;s de Google Tag Manager, esta cookie se denominar&aacute; dc_gtm
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_gali (Google)</p>
                </td>
                <td>
                  <p>30 segundos</p>
                </td>
                <td>
                  <p>Atribuci&oacute;n de enlace mejorada</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p><br /><br />Si deseas obtener m&aacute;s informaci&oacute;n sobre los tipos de cookies de seguimiento y an&aacute;lisis de datos de Google, haz <a
              href="https://policies.google.com/technologies/cookies?hl=es&amp;gl=es#types-of-cookies">clic aqu&iacute;</a>.</p>

        </div>
      </div>

    </div>
    <Footer />
  </div>
</div>
		</>
	);
}
